import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmDialogData, ConfirmDialogResultData } from "../../../core/models/dialogs/confirm-dialog-data";
import { UnsavedChangesOptions } from "../../../core/models/dialogs/enums/unsaved-changes-options.enum";
import { UnsavedChangesDialogData, UnsavedChangesDialogResultData } from "../../../core/models/dialogs/unsaved-changes-dialog-data";
import { ConfirmDialog } from "../confirm/confirm.component";

@Component({
  selector: "unsaved-changes-confirm-dialog",
  templateUrl: "./unsaved-changes-confirm-dialog.component.html",
  styleUrls: ["./unsaved-changes-confirm-dialog.component.scss"]
})
export class UnsavedChangesConfirmDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: UnsavedChangesDialogData,
    public cdRef: ChangeDetectorRef
  ) { }


  public remainOnPage() {
    const dialogResult: UnsavedChangesDialogResultData = { response: UnsavedChangesOptions.STAY };
    this.dialogRef.close(dialogResult);
  }

  public discardChanges() {
    const dialogResult: UnsavedChangesDialogResultData = { response: UnsavedChangesOptions.DISCARD };
    this.dialogRef.close(dialogResult);
  }

  public saveChanges() {
    if (!this.data.isSaveAllowed) {
      return;
    }

    const dialogResult: UnsavedChangesDialogResultData = { response: UnsavedChangesOptions.SAVE };
    this.dialogRef.close(dialogResult);
  }
}
