/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TerminalDTO { 
    id?: number;
    code?: string;
    description?: string;
    street?: string;
    houseNumber?: string;
    portUnloCode?: string;
    portIataCode?: string;
    portId?: number;
    portName?: string;
    placeId?: number;
    placeName?: string;
    placeZipCode?: string;
    countryName?: string;
    isDeleted?: boolean;
    isBlocked?: boolean;
    quayCode?: string;
    waterwaySection?: string;
    validationOfficeCode?: string;
    exitOfficeCode?: string;
    locationCode?: string;
    masterDataStatus?: TerminalDTO.MasterDataStatusEnum;
}
export namespace TerminalDTO {
    export type MasterDataStatusEnum = 0 | 1;
    export const MasterDataStatusEnum = {
        NUMBER_0: 0 as MasterDataStatusEnum,
        NUMBER_1: 1 as MasterDataStatusEnum
    };
}