import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FileRenameDialogComponent } from "../file-rename-dialog/file-rename-dialog.component";

export class MoveFileData {
  public requestUrl: string;
  public currentItemText: string;
  public dropPath: string;
  public dragPath: string;
  public initialItemName: string;
}

@Component({
  selector: "file-move-fail-dialog",
  templateUrl: "./file-move-fail-dialog.component.html",
  styleUrls: ["./file-move-fail-dialog.component.scss"]
})
export class FileMoveFailDialogComponent implements OnInit {

  @Input()
  public data: MoveFileData;

  public fullFileName: string;
  public isFile: boolean;

  constructor(
    private http: HttpClient,
    public dialogRef: MatDialogRef<FileMoveFailDialogComponent>,
    private modal: MatDialog,
  ) {
  }

  public ngOnInit() {
    this.fullFileName = this.data.currentItemText;
    this.isFile = this.fullFileName.includes(".");
  }

  public cancel() {
    this.dialogRef.close({ success: false });
  }

  public overwrite() {
    this.http.put(this.data.requestUrl, {
      newFileNamePath: `${this.data.dropPath}${this.fullFileName}`,
      path: this.data.dragPath,
      overwrite: true
    }, { responseType: "blob" as "json" })
      .subscribe({
        next: () => {
          this.dialogRef.close({ success: true });
        }
      });

  }

  public rename() {

    const renameDialogComponent = this.modal.open(FileRenameDialogComponent, {
      disableClose: true,
      maxWidth: "25vw",
      width: "25vw"
    });

    renameDialogComponent.componentInstance.data = {
      fileName: this.fullFileName,
      initialItemName: this.data.initialItemName
    };

    renameDialogComponent.afterClosed().subscribe((newFileName) => {

      if (newFileName) {
        // Need to set it to empty so that if the new file name also exists,
        // set the new name and redraw the component.
        // If the component is not redrawn the old name will always be used
        // Because the translation does not redraw the component when the passed value changes
        this.fullFileName = "";

        this.http.put(this.data.requestUrl, {
          newFileNamePath: `${this.data.dropPath}${newFileName}`,
          path: this.data.dragPath,
          overwrite: false
        }, { responseType: "blob" as "json" })
          .subscribe({
            next: () => {
              this.dialogRef.close({ success: true });
            },
            error: () => {
              this.fullFileName = newFileName;
            }
          });
      }
    });
  }
}
