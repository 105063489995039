/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OperationalBudgetDetailsPerFileSearchCondition { 
    pageSize?: number;
    page?: number;
    readonly listSortDirection?: OperationalBudgetDetailsPerFileSearchCondition.ListSortDirectionEnum;
    sortDirection?: string;
    sortProperty?: string;
    minDate?: Date;
    maxDate?: Date;
    showFilesWithoutBudget?: boolean;
}
export namespace OperationalBudgetDetailsPerFileSearchCondition {
    export type ListSortDirectionEnum = 0 | 1;
    export const ListSortDirectionEnum = {
        NUMBER_0: 0 as ListSortDirectionEnum,
        NUMBER_1: 1 as ListSortDirectionEnum
    };
}