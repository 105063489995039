import { LoadingOverlayService } from "../../services/loading-overlay/loading-overlay.service";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { timer, Observable } from "rxjs";
import { skipUntil, distinctUntilChanged, debounceTime } from "rxjs/operators";

@Component({
  selector: "loading-overlay",
  templateUrl: "./loading-overlay.component.html",
  styleUrls: ["./loading-overlay.component.scss"]
})
export class LoadingOverlayComponent implements OnInit {
  public loading: boolean;

  constructor(
    public loadingOverlayService: LoadingOverlayService,
    private cdRef: ChangeDetectorRef
  ) { }

  public ngOnInit() {
    this.loadingOverlayService
      .getLoadingSubjectAsObservable()
      .pipe(
        distinctUntilChanged())
      .subscribe(res => {
        this.loading = res;
        this.cdRef.detectChanges();
      });
  }
}
