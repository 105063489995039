import { Component, Input } from "@angular/core";
import { IncomingInvoiceStatus } from "../../../enumerations/incoming-invoice-status.enum";

@Component({
  selector: "incoming-invoice-status-badge",
  templateUrl: "./incoming-invoice-status-badge.component.html",
  styleUrls: ["./incoming-invoice-status-badge.component.scss"]
})
export class IncomingInvoiceStatusBadgeComponent {
  @Input()
  public status: IncomingInvoiceStatus;

  @Input()
  public protestReason: string;

  /**
  * This sets the placement of the tooltip, 'auto' by default.
  */
  @Input()
  public tooltipPlacement: string = "auto";

  // tslint:disable-next-line: typedef
  public IncomingInvoiceStatus = IncomingInvoiceStatus;

  constructor() { }
}
