import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'exact-online-integration-auth-callback',
  template: `<p>Processing authentication...</p>`,
})
export class ExactOnlineIntegrationAuthCallbackComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');

    if (code) {
      window.opener.postMessage({ code: code, type: "exactOnlineIntegrationAuthCode" }, environment.applicationUrl);
      window.close();
    } else {
      console.error('Authorization code not found');
    }
  }
}