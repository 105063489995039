import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { StoredListViewProperties } from "../../../models/utilities/stored-list-view-properties";
import { FoundGridLayoutDTO, GridLayoutLookupDTO, GridLayoutsService } from "../../../services/swagger-gen/fordesk";
import { ColumnsCacheService } from "../../../services/utilities/columns-cache.service";
import { FilterCacheService } from "../../../services/utilities/filter-cache.service";
import { GridLayoutCacheService } from "../../../services/utilities/grid-layout-cache.service";
import { GridType } from "../../../types/grid-type.type";
import { ComponentBase } from "../../abstractions/component-base";
import { GridLayoutCommunicationService } from "../../../services/grid/grid-layout-communication.service";

@Component({
  selector: "grid-layout",
  templateUrl: "./grid-layout.component.html",
  styleUrls: ["./grid-layout.component.scss"]
})
export class GridLayoutComponent extends ComponentBase implements OnInit {
  @Input()
  public gridType: GridType;

  @Input()
  public defaultDisplayedColumns: string[];

  @Output()
  public refresh: EventEmitter<null> = new EventEmitter<null>();

  public currentGrid: GridLayoutLookupDTO;
  public expanded: boolean = false;

  constructor(private ref: ElementRef,
    private gridLayoutService: GridLayoutsService,
    private gridLayoutCacheService: GridLayoutCacheService,
    private gridLayoutCommunicationService: GridLayoutCommunicationService,
    private filterCacheService: FilterCacheService<string>,
    private columnsCacheService: ColumnsCacheService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.registerSubscriptions();
    this.onFirstLoad();
  }

  public toggleDropdown() {
    this.expanded = !this.expanded;
  }

  public hideDropDown() {
    this.expanded = false;
  }

  public onLayoutSelected(dto: GridLayoutLookupDTO) {
    this.gridLayoutService.getDetails(dto.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((grid) => this.handleLayoutSelected(grid, dto));
  }

  private onFirstLoad() {
    const currentlySelectedKey = this.gridLayoutCacheService.mapGridTypeToCurrentlySelectedKeys(this.gridType);

    if (!currentlySelectedKey) {
      this.currentGrid = null;
      return;
    }

    const gridInLocalStorage = this.filterCacheService.getCurrentlySelectedLayout(currentlySelectedKey);

    if (!gridInLocalStorage) {
      this.currentGrid = null;
      this.filterCacheService.remove(currentlySelectedKey, false);
      return;
    }

    this.currentGrid = gridInLocalStorage;

    const filtersKey = this.gridLayoutCacheService.mapGridTypeToFilterKeys(this.gridType);
    const columnsKey = this.gridLayoutCacheService.mapGridTypeToColumnKeys(this.gridType);

    const localStorageFilterInfo = this.filterCacheService.get(filtersKey);
    const localStorageColumnsInfo = this.columnsCacheService.get(columnsKey);

    if (!localStorageColumnsInfo && !localStorageFilterInfo) {
      this.onLayoutSelected(this.currentGrid);
    }
  }

  private registerSubscriptions(): void {
    this.gridLayoutCacheService
      .gridChanged$
      .pipe(takeUntil(this.unsubscribe)).subscribe((type: GridType) => {
        if (type === this.gridType) {
          this.currentGrid = null;
        }
      });

    this.gridLayoutCommunicationService.getLayoutDeleted$()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dto => {
        if (dto.id === this.currentGrid?.id) {
          const currentlySelectedLayoutKey = this.gridLayoutCacheService.mapGridTypeToCurrentlySelectedKeys(this.gridType);
          this.filterCacheService.remove(currentlySelectedLayoutKey, false);
          this.currentGrid = null;
        }
      });

    this.gridLayoutCommunicationService.getLayoutEdited$()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dto => {
        if (!dto?.id) {
          return;
        }

        if (dto.id === this.currentGrid?.id) {
          this.currentGrid.name = dto.name;
        }
      });
  }

  private handleLayoutSelected(grid: FoundGridLayoutDTO, dto: GridLayoutLookupDTO) {
    this.currentGrid = dto;
    this.expanded = false;

    const filtersKey = this.gridLayoutCacheService.mapGridTypeToFilterKeys(this.gridType);
    const columnsKey = this.gridLayoutCacheService.mapGridTypeToColumnKeys(this.gridType);
    const currentlySelectedKey = this.gridLayoutCacheService.mapGridTypeToCurrentlySelectedKeys(this.gridType);
    const filtersDTO = this.mapToFilterStoredViewProperties(grid);

    this.filterCacheService.storeFilters(filtersKey, filtersDTO.filters, false);
    this.filterCacheService.storePaging(filtersKey, filtersDTO.pageSize, filtersDTO.currentPage, false);
    this.filterCacheService.storeSorting(filtersKey, filtersDTO.sortProperty, filtersDTO.sortDirection, false);
    this.filterCacheService.storeCurrentlySelectedLayout(currentlySelectedKey, this.currentGrid);
    this.columnsCacheService.store(columnsKey, grid?.displayedColumns ?? this.defaultDisplayedColumns, false);

    this.refresh.emit();
  }

  private mapToFilterStoredViewProperties(currentGrid: FoundGridLayoutDTO): StoredListViewProperties<string> {
    const dto: StoredListViewProperties<string> = {
      currentPage: currentGrid.page,
      pageSize: currentGrid.pageSize,
      sortDirection: currentGrid.sortDirection,
      sortProperty: currentGrid.sortProperty,
      filters: currentGrid.filters?.map(x => ({ value: JSON.parse(x.jsonValue), type: x.type, description: x.type }))
    };

    return dto;
  }
}
