import { Inject, Injectable } from "@angular/core";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { StoredColumnsData } from "../../models/utilities/stored-columns-data";
import { GridLayoutCacheService } from "./grid-layout-cache.service";

@Injectable({ providedIn: "root" })
export class ColumnsCacheService {

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService, private gridLayoutCacheService: GridLayoutCacheService) {
  }

  public store(key: string, displayedColumns: string[], notifyGridLayoutServiceForReset: boolean = true): void {
    const storedColumnsData: StoredColumnsData = {
      displayedColumns: displayedColumns
    };

    this.storage.set(key, storedColumnsData);

    if (notifyGridLayoutServiceForReset) {
      this.gridLayoutCacheService.notifyGridChanged(key);
    }
  }

  public get(key: string): StoredColumnsData {
    return this.storage.get(key);
  }

  public remove(key: string, notifyGridLayoutServiceForReset: boolean = true): void {
    this.storage.remove(key);

    if (notifyGridLayoutServiceForReset) {
      this.gridLayoutCacheService.notifyGridChanged(key);
    }
  }
}
