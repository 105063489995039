import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  IncomingTaxCodeDTO,
  IncomingTaxCodesService,
  IncomingTaxSystemDTO,
  IncomingTaxSystemsService
} from "../../../core/services/swagger-gen/fordesk";
import { EntityDetailsComponent } from "../../../core/components/abstractions/entity-details.component";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { UnsavedChangesService } from "../../../core/services/helpers/unsaved-changes.service";
import { ActivatedRoute } from "@angular/router";
import { CustomValidators } from "ngx-custom-validators";
import { DecimalFormatterService } from "../../../core/helpers/decimal-formatter.service";
import { takeUntil } from "rxjs/operators";

export interface IncomingInvoiceLinesRelatedFinanceData {
  exchangeRate: number;
  taxSystemId: number;
  taxCodeId: number;
  disableExchangeRate?: boolean;
}

@Component({
  selector: "incoming-invoice-lines-validation-dialog",
  templateUrl: "./incoming-invoice-lines-validation-dialog.component.html",
  styleUrls: ["./incoming-invoice-lines-validation-dialog.component.css"]
})
export class IncomingInvoiceLinesValidationDialogComponent extends EntityDetailsComponent implements OnInit {

  public incomingTaxCodes: IncomingTaxCodeDTO[];
  public incomingTaxSystems: IncomingTaxSystemDTO[];

  constructor(public incomingTaxCodeService: IncomingTaxCodesService,
    public incomingTaxSystemService: IncomingTaxSystemsService,
    private fb: UntypedFormBuilder,
    unsavedChangedService: UnsavedChangesService,
    route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public incomingData: IncomingInvoiceLinesRelatedFinanceData,
    public dialogRef: MatDialogRef<IncomingInvoiceLinesValidationDialogComponent>,
    public formatService: DecimalFormatterService,
    public cdRef: ChangeDetectorRef) {
    super(route, unsavedChangedService);
  }

  public ngOnInit(): void {
    this.resolveLookups();
    this.createForm();
  }

  public cancel() {
    this.dialogRef.close(null);
  }

  protected saveInternal(shouldRedirect?: boolean) {
    const data: IncomingInvoiceLinesRelatedFinanceData = {
      taxCodeId: this.getFormValue("taxCodeId"),
      taxSystemId: this.getFormValue("taxSystemId"),
      exchangeRate: this.getFormValue("exchangeRate")
    };

    this.dialogRef.close(data);
  }

  private createForm(): void {
    this.detailsForm = this.fb.group({
      taxCodeId: [this.incomingData.taxCodeId, [Validators.required]],
      taxSystemId: [this.incomingData.taxSystemId, [Validators.required]],
      exchangeRate: [this.incomingData.exchangeRate, [Validators.required, CustomValidators.gt(0), Validators.pattern(this.formatService.getRequiredExchangeRateRegex())]],
    });

    if (this.incomingData.disableExchangeRate) {
      this.f.exchangeRate.disable();
    }

    this.cdRef.detectChanges();
  }

  private resolveLookups(): void {
    this.incomingTaxCodeService.getAllIncomingTaxCodes()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.incomingTaxCodes = response.results;
        this.cdRef.detectChanges();
      });

    this.incomingTaxSystemService.getAllIncomingTaxSystems()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        this.incomingTaxSystems = response.results;
        this.cdRef.detectChanges();
      });
  }
}
