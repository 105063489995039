import { Injectable } from "@angular/core";
import { NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { DateRange } from "../models/date-range";

@Injectable()
export class DateInputsHelperService {
  public readonly dateFormat: string = "DD-MM-YYYY";
  public readonly dateTimeFormat: string = "DD-MM-YYYY HH:mm";
  public readonly dateIso8106Format: string = "YYYY-MM-DDTHH:mm:ss. sssZ";

  constructor() { }

  public transformDateInput(inputDate: Date): string {
    return moment(inputDate).format(this.dateFormat);
  }

  public formatDateTime(inputDate: Date): string {
    return moment(inputDate).format(this.dateTimeFormat);
  }

  public toNgbDate(date: Date): NgbDate {
    const actualDate = new Date(date);
    // The function to get the day is 'getDate()'
    // JS dates are 0-based
    return new NgbDate(actualDate.getFullYear(), actualDate.getMonth() + 1, actualDate.getDate());
  }

  public fromNgbDateStructToDate(dateStruct: NgbDateStruct): Date {
    return new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day);
  }

  public fromNgbDateToDate(date: NgbDate): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  public getDateFormat(): string {
    return this.dateFormat;
  }

  public parseDate(dateString: string, format: string): Date {
    return moment(dateString, format).toDate();
  }

  public isDateValid(date: Date): boolean {
    return !isNaN(date.valueOf());
  }

  public transformDateRangeToString(dateRange: DateRange): string {
    if (dateRange.min && dateRange.max) {
      return `${this.transformDateInput(dateRange.min)} - ${this.transformDateInput(dateRange.max)}`;
    } else if (dateRange.min) {
      return `>= ${this.transformDateInput(dateRange.min)}`;
    } else if (dateRange.max) {
      return `<= ${this.transformDateInput(dateRange.max)}`;
    }
  }

  public getTodayDate(): Date {
    return new Date();
  }
}
