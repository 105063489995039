/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DepotDTO } from './depotDTO';
import { PartyCompanyAddressDTO } from './partyCompanyAddressDTO';
import { TerminalDTO } from './terminalDTO';

export interface PartyAddressDTO { 
    companyAddress?: PartyCompanyAddressDTO;
    terminal?: TerminalDTO;
    depot?: DepotDTO;
    id?: number;
    type?: PartyAddressDTO.TypeEnum;
    masterDataStatus?: PartyAddressDTO.MasterDataStatusEnum;
}
export namespace PartyAddressDTO {
    export type TypeEnum = 0 | 1 | 2;
    export const TypeEnum = {
        NUMBER_0: 0 as TypeEnum,
        NUMBER_1: 1 as TypeEnum,
        NUMBER_2: 2 as TypeEnum
    };
    export type MasterDataStatusEnum = 0 | 1;
    export const MasterDataStatusEnum = {
        NUMBER_0: 0 as MasterDataStatusEnum,
        NUMBER_1: 1 as MasterDataStatusEnum
    };
}