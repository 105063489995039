import { Component, OnInit } from "@angular/core";
import { FormlyHelper } from "../../../helpers/formly-helper";
import { Validators } from "@angular/forms";
import { OperationalFileEditIdHelper } from "../../../helpers/operational-file-edit-id-helper";
import { ActivatedRoute } from "@angular/router";
import { OperationalFileService } from "../../../services/swagger-gen/fordesk";
import { takeUntil } from "rxjs/operators";
import { OperationalFileType } from "../../../enumerations/operational-file-type.enum";
import { FormlyBase } from "../formly-base";

@Component({
  selector: "formly-customer-reference",
  templateUrl: "./formly-customer-reference.component.html",
  styleUrls: ["./formly-customer-reference.component.scss"]
})
export class FormlyCustomerReferenceComponent extends FormlyBase implements OnInit {
  public submitted: boolean;
  public showWarning: boolean = false;
  private isExport: boolean = false;

  constructor(
    private formlyHelper: FormlyHelper,
    private operationalFileHelper: OperationalFileEditIdHelper,
    private operationalFileService: OperationalFileService,
    private route: ActivatedRoute
  ) {
    super();
  }

  public ngOnInit() {
    this.formlyHelper
      .getGeneralFormSubmission$()
      .subscribe(() => this.submitted = true);

    const operationalFileId = this.operationalFileHelper.getOperationalFileId(this.route);

    if (operationalFileId) {
      this.operationalFileService.getCompactDetails(operationalFileId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(data => {
          this.isExport = data.operationalFileType === OperationalFileType.Export;
        });
    }

    if (this.field.templateOptions.required) {
      this.formControl.setValidators([Validators.required, Validators.maxLength(512)]);
    } else {
      this.formControl.setValidators([Validators.maxLength(512)]);
    }

    this.formControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      this.showWarning = value?.length > 35 && this.isExport;
    });
  }
}
