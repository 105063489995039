import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BookingExtraConfirmationSelectedOption, BookingExtraConfirmationType } from './models/booking-extra-confirmation.data';

@Component({
  selector: 'booking-extra-confirmation',
  templateUrl: './booking-extra-confirmation.component.html',
  styleUrls: ['./booking-extra-confirmation.component.scss']
})
export class BookingExtraConfirmationComponent implements OnInit {

  public extraConfirmationText: string = "";

  public get bookingExtraConfirmationSelectedOption(): typeof BookingExtraConfirmationSelectedOption {
    return BookingExtraConfirmationSelectedOption;
  }

  public get bookingExtraConfirmationType(): typeof BookingExtraConfirmationType {
    return BookingExtraConfirmationType;
  }

  constructor(public dialogRef: MatDialogRef<BookingExtraConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BookingExtraConfirmationType, private translate: TranslateService) { }

  ngOnInit(): void {
    if (this.data === BookingExtraConfirmationType.TriesToSendAmmendmentButThereIsAnOriginalThatIsPending) {
      this.extraConfirmationText = this.translate.instant("MODALS.EXTRA_BOOKING_CONFIRMATION_MODAL.TRIES_TO_SEND_AMMENDMENT_BUT_THERE_IS_AN_ORIGINAL_THAT_IS_PENDING");
    }

    if (this.data === BookingExtraConfirmationType.TriesToSendAmmendmentButThereIsAnOriginalThatWasRejected) {
      this.extraConfirmationText = this.translate.instant("MODALS.EXTRA_BOOKING_CONFIRMATION_MODAL.TRIES_TO_SEND_AMMENDMENT_BUT_THERE_IS_AN_ORIGINAL_THAT_WAS_REJECTED");
    }

    if (this.data === BookingExtraConfirmationType.TriesToSendAmmendmentButThereIsPendingAmmendment) {
      this.extraConfirmationText = this.translate.instant("MODALS.EXTRA_BOOKING_CONFIRMATION_MODAL.TRIES_TO_SEND_AMMENDMENT_BUT_THERE_IS_PENDING_AMMENDMENT");
    }

    if (this.data === BookingExtraConfirmationType.TriesToSendOriginalButAcceptedExists) {
      this.extraConfirmationText = this.translate.instant("MODALS.EXTRA_BOOKING_CONFIRMATION_MODAL.TRIES_TO_SEND_ORIGINAL_BUT_ACCEPTED_EXISTS");
    }

    if (this.data === BookingExtraConfirmationType.TriesToSendOriginalButExistsPreviouslyPendingOriginal) {
      this.extraConfirmationText = this.translate.instant("MODALS.EXTRA_BOOKING_CONFIRMATION_MODAL.TRIES_TO_SEND_ORIGINAL_BUT_EXISTS_PREVIOUSLY_PENDING_ORIGINAL");
    }
  }

  public confirm(type: BookingExtraConfirmationSelectedOption) {

    this.dialogRef.close(type);
  }

  public cancel() {
    this.dialogRef.close();
  }
}
