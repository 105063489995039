/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface RevenueInvoiceSummary { 
    outgoingInvoiceId?: number;
    outgoingInvoiceAccountingNumber?: string;
    proformaReference?: string;
    taxPercentage?: number;
    taxSystem?: string;
    invoiceDate?: Date;
    invoiceStatus?: RevenueInvoiceSummary.InvoiceStatusEnum;
}
export namespace RevenueInvoiceSummary {
    export type InvoiceStatusEnum = 0 | 1 | 2 | 3;
    export const InvoiceStatusEnum = {
        NUMBER_0: 0 as InvoiceStatusEnum,
        NUMBER_1: 1 as InvoiceStatusEnum,
        NUMBER_2: 2 as InvoiceStatusEnum,
        NUMBER_3: 3 as InvoiceStatusEnum
    };
}