/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ShippingInstructionsPartyDTO } from './shippingInstructionsPartyDTO';

export interface ShippingInstructionsDetailsDTO { 
    billOfLadingType?: ShippingInstructionsDetailsDTO.BillOfLadingTypeEnum;
    freightPayable?: ShippingInstructionsDetailsDTO.FreightPayableEnum;
    numberOfOriginals?: number;
    numberOfCopies?: number;
    nonPrintableRemarks?: string;
    printableRemarks?: string;
    portOfDischarge?: string;
    portOfLoading?: string;
    placeOfReceipt?: string;
    placeOfDestination?: string;
    status?: ShippingInstructionsDetailsDTO.StatusEnum;
    timestamp?: Date;
    isFreighted?: boolean;
    proposedNextStateId?: number;
    operationalFileContainerIds?: Array<number>;
    containerGoodsIds?: Array<number>;
    operationalFileParties?: Array<ShippingInstructionsPartyDTO>;
    bookingReferences?: Array<string>;
}
export namespace ShippingInstructionsDetailsDTO {
    export type BillOfLadingTypeEnum = 0 | 1;
    export const BillOfLadingTypeEnum = {
        NUMBER_0: 0 as BillOfLadingTypeEnum,
        NUMBER_1: 1 as BillOfLadingTypeEnum
    };
    export type FreightPayableEnum = 0 | 1 | 2 | 3;
    export const FreightPayableEnum = {
        NUMBER_0: 0 as FreightPayableEnum,
        NUMBER_1: 1 as FreightPayableEnum,
        NUMBER_2: 2 as FreightPayableEnum,
        NUMBER_3: 3 as FreightPayableEnum
    };
    export type StatusEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const StatusEnum = {
        NUMBER_0: 0 as StatusEnum,
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum,
        NUMBER_3: 3 as StatusEnum,
        NUMBER_4: 4 as StatusEnum,
        NUMBER_5: 5 as StatusEnum,
        NUMBER_6: 6 as StatusEnum
    };
}