/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BlockCompanyDTO } from '../model/blockCompanyDTO';
import { CompanyDetailsDTO } from '../model/companyDetailsDTO';
import { CompanyWithTariffSuggestion } from '../model/companyWithTariffSuggestion';
import { PagedResponseFoundCompanyDTO } from '../model/pagedResponseFoundCompanyDTO';
import { Response } from '../model/response';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CompaniesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Block a company
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public blockAsync(id: number, body?: BlockCompanyDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public blockAsync(id: number, body?: BlockCompanyDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public blockAsync(id: number, body?: BlockCompanyDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public blockAsync(id: number, body?: BlockCompanyDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling blockAsync.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.patch<Response>(`${this.basePath}/api/masterdata/companies/${encodeURIComponent(String(id))}/block`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * count Companies And Related Tariffs
     * 
     * @param activityCodeId 
     * @param operationalFileId 
     * @param companyType 
     * @param unitOfMeasurementId 
     * @param currentCompanyId 
     * @param requestId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countCompaniesAndRelatedTariffs(activityCodeId: number, operationalFileId: number, companyType?: number, unitOfMeasurementId?: number, currentCompanyId?: number, requestId?: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<number>;
    public countCompaniesAndRelatedTariffs(activityCodeId: number, operationalFileId: number, companyType?: number, unitOfMeasurementId?: number, currentCompanyId?: number, requestId?: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<number>>;
    public countCompaniesAndRelatedTariffs(activityCodeId: number, operationalFileId: number, companyType?: number, unitOfMeasurementId?: number, currentCompanyId?: number, requestId?: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<number>>;
    public countCompaniesAndRelatedTariffs(activityCodeId: number, operationalFileId: number, companyType?: number, unitOfMeasurementId?: number, currentCompanyId?: number, requestId?: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (activityCodeId === null || activityCodeId === undefined) {
            throw new Error('Required parameter activityCodeId was null or undefined when calling countCompaniesAndRelatedTariffs.');
        }

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling countCompaniesAndRelatedTariffs.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (companyType !== undefined && companyType !== null) {
            queryParameters = queryParameters.set('CompanyType', <any>companyType);
        }
        if (activityCodeId !== undefined && activityCodeId !== null) {
            queryParameters = queryParameters.set('ActivityCodeId', <any>activityCodeId);
        }
        if (unitOfMeasurementId !== undefined && unitOfMeasurementId !== null) {
            queryParameters = queryParameters.set('UnitOfMeasurementId', <any>unitOfMeasurementId);
        }
        if (operationalFileId !== undefined && operationalFileId !== null) {
            queryParameters = queryParameters.set('OperationalFileId', <any>operationalFileId);
        }
        if (currentCompanyId !== undefined && currentCompanyId !== null) {
            queryParameters = queryParameters.set('CurrentCompanyId', <any>currentCompanyId);
        }
        if (requestId !== undefined && requestId !== null) {
            queryParameters = queryParameters.set('RequestId', <any>requestId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<number>(`${this.basePath}/api/masterdata/companies/countCompaniesAndRelatedTariffs`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get companies and related tariffs
     * 
     * @param activityCodeId 
     * @param operationalFileId 
     * @param companyType 
     * @param unitOfMeasurementId 
     * @param currentCompanyId 
     * @param partyTypesToExclude 
     * @param requestId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompaniesAndRelatedTariffs(activityCodeId: number, operationalFileId: number, companyType?: number, unitOfMeasurementId?: number, currentCompanyId?: number, partyTypesToExclude?: Array<number>, requestId?: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<CompanyWithTariffSuggestion>>;
    public getCompaniesAndRelatedTariffs(activityCodeId: number, operationalFileId: number, companyType?: number, unitOfMeasurementId?: number, currentCompanyId?: number, partyTypesToExclude?: Array<number>, requestId?: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<CompanyWithTariffSuggestion>>>;
    public getCompaniesAndRelatedTariffs(activityCodeId: number, operationalFileId: number, companyType?: number, unitOfMeasurementId?: number, currentCompanyId?: number, partyTypesToExclude?: Array<number>, requestId?: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<CompanyWithTariffSuggestion>>>;
    public getCompaniesAndRelatedTariffs(activityCodeId: number, operationalFileId: number, companyType?: number, unitOfMeasurementId?: number, currentCompanyId?: number, partyTypesToExclude?: Array<number>, requestId?: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (activityCodeId === null || activityCodeId === undefined) {
            throw new Error('Required parameter activityCodeId was null or undefined when calling getCompaniesAndRelatedTariffs.');
        }

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getCompaniesAndRelatedTariffs.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (companyType !== undefined && companyType !== null) {
            queryParameters = queryParameters.set('CompanyType', <any>companyType);
        }
        if (activityCodeId !== undefined && activityCodeId !== null) {
            queryParameters = queryParameters.set('ActivityCodeId', <any>activityCodeId);
        }
        if (unitOfMeasurementId !== undefined && unitOfMeasurementId !== null) {
            queryParameters = queryParameters.set('UnitOfMeasurementId', <any>unitOfMeasurementId);
        }
        if (operationalFileId !== undefined && operationalFileId !== null) {
            queryParameters = queryParameters.set('OperationalFileId', <any>operationalFileId);
        }
        if (currentCompanyId !== undefined && currentCompanyId !== null) {
            queryParameters = queryParameters.set('CurrentCompanyId', <any>currentCompanyId);
        }
        if (partyTypesToExclude) {
            partyTypesToExclude.forEach((element) => {
                queryParameters = queryParameters.append('PartyTypesToExclude', <any>element);
            })
        }
        if (requestId !== undefined && requestId !== null) {
            queryParameters = queryParameters.set('RequestId', <any>requestId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<CompanyWithTariffSuggestion>>(`${this.basePath}/api/masterdata/companies/getCompaniesAndRelatedTariffs`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get company details
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyDetails(id: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<CompanyDetailsDTO>;
    public getCompanyDetails(id: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<CompanyDetailsDTO>>;
    public getCompanyDetails(id: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<CompanyDetailsDTO>>;
    public getCompanyDetails(id: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getCompanyDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<CompanyDetailsDTO>(`${this.basePath}/api/masterdata/companies/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Search companies
     * 
     * @param searchTerm 
     * @param sortDirection 
     * @param sortProperty 
     * @param pageSize 
     * @param page 
     * @param companyType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchCompanies(searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, companyType?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PagedResponseFoundCompanyDTO>;
    public searchCompanies(searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, companyType?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PagedResponseFoundCompanyDTO>>;
    public searchCompanies(searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, companyType?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PagedResponseFoundCompanyDTO>>;
    public searchCompanies(searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, companyType?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchTerm !== undefined && searchTerm !== null) {
            queryParameters = queryParameters.set('SearchTerm', <any>searchTerm);
        }
        if (sortDirection !== undefined && sortDirection !== null) {
            queryParameters = queryParameters.set('SortDirection', <any>sortDirection);
        }
        if (sortProperty !== undefined && sortProperty !== null) {
            queryParameters = queryParameters.set('SortProperty', <any>sortProperty);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (companyType !== undefined && companyType !== null) {
            queryParameters = queryParameters.set('companyType', <any>companyType);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<PagedResponseFoundCompanyDTO>(`${this.basePath}/api/masterdata/companies/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Unblock a company
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unblockAsync(id: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public unblockAsync(id: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public unblockAsync(id: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public unblockAsync(id: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling unblockAsync.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.patch<Response>(`${this.basePath}/api/masterdata/companies/${encodeURIComponent(String(id))}/unblock`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

}
