import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Component that provides possibility to reload components.
// Example: this.router.navigate(['/reload'], { queryParams: { returnUrl: '/my/example/url' } });
@Component({
  template: '',
})
export class PageReloadComponent {
  constructor(private router: Router, private route: ActivatedRoute) {
    // Extract the 'returnUrl' from the route parameters or query parameters
    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    // Navigate back to the 'returnUrl'
    this.router.navigateByUrl(returnUrl);
  }
}