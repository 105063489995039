import { Component, OnInit, HostBinding, Input, AfterViewInit, ChangeDetectionStrategy } from "@angular/core";
import { LayoutConfigService } from "../../../../core/services/layout-config.service";
import * as objectPath from "object-path";
import { RoleService } from "../../../../core/auth/roles/role.service";
import { ForwardingRole } from "../../../../core/auth/roles/roles.enum";

@Component({
  selector: "m-topbar",
  templateUrl: "./topbar.component.html",
  changeDetection: ChangeDetectionStrategy.Default
})
export class TopbarComponent implements OnInit, AfterViewInit {
  @HostBinding("id") public id: string = "m_header_nav";
  @HostBinding("class")
  public classes: string = "m-stack__item m-stack__item--fluid m-header-head";

  @Input() public searchType: any;

  public hasNotificationsRole: boolean = false;

  constructor(
    private layoutConfigService: LayoutConfigService,
    private roleService: RoleService
  ) {
    this.layoutConfigService.onLayoutConfigUpdated$.subscribe(model => {
      const config = model.config;
      this.searchType = objectPath.get(config, "header.search.type");
    });
  }

  public ngOnInit(): void {
    this.roleService
      .getRolesFromUserProfile$()
      .subscribe(roles => this.hasNotificationsRole = this.roleService.userHasRole(roles, ForwardingRole.FordeskNotification));
  }

  public ngAfterViewInit(): void { }
}
