import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { FormlyHelper } from "../../../helpers/formly-helper";
import { takeUntil } from "rxjs/operators";
import { FieldType } from "@ngx-formly/core";
import { Validators, ValidatorFn, AbstractControl } from "@angular/forms";

@Component({
  selector: "formly-bill-of-lading",
  templateUrl: "./formly-bill-of-lading.component.html",
  styleUrls: ["./formly-bill-of-lading.component.scss"]
})
export class FormlyBillOfLadingComponent extends FieldType implements OnInit, OnDestroy {
  public submitted: boolean;
  public operationalFileId: number;
  private unsubscribe: Subject<void> = new Subject();
  constructor(
    private formlyHelper: FormlyHelper
  ) {
    super();
  }

  public maxLength: number = 1024;

  public ngOnInit() {
    this.formlyHelper
      .getGeneralFormSubmission$()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.submitted = true);

    if (this.formControl.value) {
      const uniqueBillOfLadingNumbers = this.getUniqueBillOfLadingNumbers(this.formControl.value);

      this.formControl.setValue(uniqueBillOfLadingNumbers);
    }

    if (this.field.templateOptions.required) {
      this.formControl.setValidators([Validators.required, this.max30CharsPerLineValidator(), Validators.maxLength(1024)]);
    } else {
      this.formControl.setValidators([this.max30CharsPerLineValidator(), Validators.maxLength(1024)]);
    }
  }

  private getUniqueBillOfLadingNumbers(currentValue: string): string {
    const billOfLoadingNumbers: string[] = currentValue.split("\n");

    const uniqueBillOfLoadingNumbers = billOfLoadingNumbers.filter((v, i, a) => a.indexOf(v) === i);

    return uniqueBillOfLoadingNumbers.join("\n");
  }

  private max30CharsPerLineValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const controlValue = control.value;
      const splitted = controlValue ? controlValue.split("\n") : [];

      const anyInvalid = splitted.find(element => element && (element.length > 30 || element.trim() == ''));

      return anyInvalid ? { "max30charsPerLineNoSpaces": { value: control.value } } : null;
    };
  }

  public ngOnDestroy(): void {
    if (!this.unsubscribe || !this.unsubscribe.observers || this.unsubscribe.observers.length <= 0) {
      return;
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
