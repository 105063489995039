import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RequestStorageService {
  private httpRequests: HttpRequest<any>[] = [];

  public addHttpRequest(req: HttpRequest<any>) {
    this.httpRequests.push(req);
  }

  public removeHttpRequest(req: HttpRequest<any>) {
    this.httpRequests = this.httpRequests.filter(x => x != req);
  }

  public clearAll() {
    this.httpRequests = [];
  }

  public getCountRequests(): number {
    return this.httpRequests.length;
  }
}
