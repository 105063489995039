import { Injectable } from "@angular/core";
import { Subject, Observable, of } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap, map, catchError, tap } from "rxjs/operators";
import { AbstractControl } from "@angular/forms";
import { PortDTO, PortsService } from "../services/swagger-gen/fordesk";

@Injectable({ providedIn: "root" })
export class PortLookupHelper {
  private maxLength: number = 3950;

  constructor() { }

  public getPortsLookup(service: PortsService, input: Subject<string>, onStart: Function, onComplete: Function)
    : Observable<PortDTO[]> {
    return input.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string) => {
        let ports: Observable<PortDTO[]>;
        if (term && term.length > 2 && term.length < this.maxLength) {
          onStart();
          ports = service.getPorts(term)
            .pipe(
              map((res) => {
                return res && res.results ? res.results : [];
              }),
              catchError(() => of([])), // empty list on error
              tap(() => onComplete?.())
            );
        } else {
          onComplete?.();
          ports = of([]);
        }
        return ports;
      })
    );
  }

  public getPortsLookupWithCountry(service: PortsService, input: Subject<string>, countryId: number, onStart: Function, onComplete: Function): Observable<PortDTO[]> {
    return input.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string) => {
        if (term && term.length > 2 && term.length < this.maxLength) {
          onStart();
          return service
            .searchInCountry(term, countryId)
            .pipe(map(res => res && res.results ? res.results : []),
              catchError(() => of<PortDTO[]>([])), // empty list on error
              tap(() => onComplete?.())
            );
        } else {
          onComplete?.();
          return of<PortDTO[]>([]);
        }
      })
    );
  }

  public getPortsByNameLookup(service: PortsService, input: AbstractControl, onStart: Function, onComplete: Function): Observable<PortDTO[]> {
    return input
      .valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((term: string) => {
          if (term && term.length > 2 && term.length < this.maxLength) {
            onStart();
            return service.searchPorts(term)
              .pipe(
                map(response => {
                  return response ? response.items : [];
                }),
                catchError(_ => {
                  return of([]);
                }),
                tap(() => onComplete?.())
              );
          } else {
            onComplete?.();
            return of([]);
          }
        })
      );
  }
}
