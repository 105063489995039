import { linked_credit_notes } from "./linked-credit-notes.en";

export const outgoing_invoices_new_locale = {
  LINKED_CREDIT_NOTES: linked_credit_notes,
  LINKED_FILES: "Linked operational files",
  INVOICES: "Invoices",
  GENERAL: {
    FILE_CLOSED_OR_CANCELLED: "The operational file {{fileNumber}} is closed or canceled and cannot be used.",
    OPERATIONAL_FILE_DOES_NOT_EXIST: "This operational-file does not exist.",
    OPERATIONAL_FILE_ALREADY_LOADED: "You already selected this operational-file.",
    OPERATIONAL_FILE_MISSING_DEPARTURE_LOCATION: "No port of loading or place of receipt has been provided on this operational file. Please do so first.",
    OPERATIONAL_FILE_MISSING_AIRPORT_DEPARTURE_LOCATION: "No departure airport has been provided on this operational file. Please do so first.",
    OPERATIONAL_FILE_MISSING_DESTINATION_LOCATION: "No port of discharge or place of destination has been provided on this operational file. Please do so first.",
    OPERATIONAL_FILE_MISSING_AIRPORT_DESTINATION_LOCATION: "No destination airport has been provided on this operational file. Please do so first."
  },
  ACTIONS: {
    FINAL: "finalize",
    PREVIEW: "preview",
    CREATE_CREDIT_NOTE: "create credit note",
    CREDIT_LINES: "credit lines",
    SEND_REMINDER: "send reminder"
  },
  TOTAL_REMAINING: "Total remaining",
  TOTAL_CREDITED: "Total credited",
  INVOICE_NUMBER: "Invoice number",
  ISSUE_DATE: "Issue date",
  TOTAL_INCL_VAT: "Total incl. VAT",
  INVOICE: "Invoice",
  CREDIT_NOTE: "Credit note",
  CREDIT_NOTES: "Credit notes",
  CREDIT_NOTES_DESCRIPTION: "The following credit note(s) were made for this invoice. If a credit note is made by manually creating budget lines for it, they will not show up here.",
  UNABLE_GENERATING_INVOICE: "Unable to generate invoice",
  TITLE: "New outgoing invoice",
  OPERATIONAL_FILE: "Operational file",
  INVOICE_LINES: "Invoice lines",
  INVOICE_LINES_TO_CREDIT: "Invoice lines to credit",
  DETAILS: "Details",
  FILENUMBER: "Operational file nr.",
  INVOICE_DATE: "Invoice date",
  INVOICE_EXPIRATION_DATE: "Expiration date",
  PAID_DATE: "Paid on",
  REMINDER_SENT_DATE: "Reminder sent date",
  CURRENCY: "Currency",
  STATUS: "Status",
  SELECTED_OPERATIONAL_FILE: "Selected operational-file",
  NO_INVOICEABLE_REVENUES_FOUND_IN_OP_FILE: "There are no invoiceable revenues in the selected operational-file.",
  NO_LINES_IN_INVOICE: "There are no lines in this outgoing invoice.",
  PARTY_NAME: "Name",
  DESCRIPTION: "Description",
  QUANTITY: "Quantity",
  PRICE_EXCL_VAT: "Price excl. VAT",
  TOTAL_EXCL_VAT: "Total excl. VAT",
  VAT: "VAT",
  TOTAL: "Total",
  REVENUE_COPIED: "The selected revenue has been copied to the outgoing invoice.",
  COPY_TO_INVOICE: "Copy to invoice",
  REMOVE_FROM_INVOICE: "Remove from invoice",
  FINALIZE_DONE: "Your outgoing invoice has been finalized and assigned number '{{ invoiceNumber }}'.",
  CONFIRM_FINALIZE: "Are you sure you want to finalize this outgoing invoice?",
  PROFORMA: "Proforma",
  FINAL: "Final",
  PAID: "Paid",
  NO_OPERATIONAL_FILES: "There are no operational files linked to this outgoing invoice.",
  IS_CREDITED: "Credited",
  PARTIALLY_CREDITED: "Partially credited",
  NEW_CREDIT_NOTE: "New credit note",
  MINIMUM_EXPIRATION_DATE: "A date after '{{ minDate }}' must be chosen.",
  MINIMUM_INVOICE_DATE: "The invoice date either falls within a closed accounting period or is too far in the past. Minimum date: {{minDate}}.",
  REVENUES_MAST_HAVE_SAME_CUSTOMER: "The revenue customer party must be the same for all selected revenues. Please move these revenues separatelly.",
  MESSAGES: {
    OUTGOING_INVOICE_LINES_ALLOCATED: "Identical revenues have been found on other proforma invoices. By continuing, the invoice lines related to these revenues on the resp. proforma invoices will be removed to avoid double invoicing.",
    LEAVE_ON_EMPTY_PROFORMA: "This proforma does not contain any lines and will be deleted if you leave. Are you sure?",
    EXCHANGE_RATE_UPDATE_WARNING: "Please note changing the exchange rate will cause all non-invoiced budget and proforma invoice lines to be updated as well.",
    AT_LEAST_ONE_REVENUE_ALREADY_FINALIZED: "At least one selected line is already on a finalized invoice and cannot be added to a new invoice. Do you want to proceed with just the open lines, or cancel the operation?",
    ALL_REVENUES_FINALIZED: "All of the selected lines are already on finalized invoices and cannot be placed on a new invoice. Do you want to proceed to create an empty outgoing invoice, or cancel the operation?"
  },
  MODALS: {
    TITLE_CREATE: "Create new outgoing invoice.",
    SET_PAID_DATE_TITLE: "Payment date",
    SET_PAID_DATE: "Choose a payment date"
  }
};
