import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { v4 as uuidv4 } from "uuid";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "../../../content/layout/toaster/toaster.service";

@Injectable({
  providedIn: "root"
})
export class LostInternetConnectionService {
  constructor(private httpClient: HttpClient,
    private toaster: ToasterService,
    private translate: TranslateService) { }

  public isOffline(): Observable<boolean> {
    // ping query is to avoid 304 http status
    return this.httpClient.get<Ping>(`/assets/ping.json?=ping${uuidv4()}`)
      .pipe(
        map(_ => false),
        catchError(_ => of(true))
      );
  }

  public notifyUser() {
    this.toaster.error(this.translate.instant("MESSAGES.INTERNET_DOWN"));
  }
}

interface Ping {
  isOffline: boolean;
}
