/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OperationalFileCompactDetailsDTO } from './operationalFileCompactDetailsDTO';

export interface OutgoingInvoiceDetailsDTO { 
    operationalFiles?: Array<OperationalFileCompactDetailsDTO>;
    outgoingInvoiceNumber?: string;
    proformaReference?: string;
    isProforma?: boolean;
    isCredited?: boolean;
    invoiceType?: OutgoingInvoiceDetailsDTO.InvoiceTypeEnum;
    currencyId?: number;
    invoiceDate?: Date;
    invoiceExpirationDate?: Date;
    paidDate?: Date;
    lastReminderSentDate?: Date;
    status?: OutgoingInvoiceDetailsDTO.StatusEnum;
    exchangeRate?: number;
    exportTimestamp?: Date;
    successfullyExported?: boolean;
    invoicedCustomerId?: number;
}
export namespace OutgoingInvoiceDetailsDTO {
    export type InvoiceTypeEnum = 0 | 1;
    export const InvoiceTypeEnum = {
        NUMBER_0: 0 as InvoiceTypeEnum,
        NUMBER_1: 1 as InvoiceTypeEnum
    };
    export type StatusEnum = 0 | 1 | 2 | 3;
    export const StatusEnum = {
        NUMBER_0: 0 as StatusEnum,
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum,
        NUMBER_3: 3 as StatusEnum
    };
}