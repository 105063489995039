/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FoundOperationalFileContainerSealNumberDTO } from './foundOperationalFileContainerSealNumberDTO';

export interface FoundOperationalFileContainerDTO { 
    id?: number;
    sizeType?: string;
    containerNumber?: string;
    cargoWeight?: number;
    goodsCargoWeight?: number;
    vgmWeight?: number;
    grossVolume?: number;
    containerTare?: number;
    goodsCount?: number;
    colliCount?: number;
    vgmStatus?: FoundOperationalFileContainerDTO.VgmStatusEnum;
    vgmTimestamp?: Date;
    releaseRightStatus?: FoundOperationalFileContainerDTO.ReleaseRightStatusEnum;
    goodsIds?: Array<number>;
    sealNumbers?: Array<FoundOperationalFileContainerSealNumberDTO>;
}
export namespace FoundOperationalFileContainerDTO {
    export type VgmStatusEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const VgmStatusEnum = {
        NUMBER_0: 0 as VgmStatusEnum,
        NUMBER_1: 1 as VgmStatusEnum,
        NUMBER_2: 2 as VgmStatusEnum,
        NUMBER_3: 3 as VgmStatusEnum,
        NUMBER_4: 4 as VgmStatusEnum,
        NUMBER_5: 5 as VgmStatusEnum,
        NUMBER_6: 6 as VgmStatusEnum
    };
    export type ReleaseRightStatusEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
    export const ReleaseRightStatusEnum = {
        NUMBER_0: 0 as ReleaseRightStatusEnum,
        NUMBER_1: 1 as ReleaseRightStatusEnum,
        NUMBER_2: 2 as ReleaseRightStatusEnum,
        NUMBER_3: 3 as ReleaseRightStatusEnum,
        NUMBER_4: 4 as ReleaseRightStatusEnum,
        NUMBER_5: 5 as ReleaseRightStatusEnum,
        NUMBER_6: 6 as ReleaseRightStatusEnum,
        NUMBER_7: 7 as ReleaseRightStatusEnum
    };
}