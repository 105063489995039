import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { UtilsService } from "../../utils.service";
import { ApiConfigService } from "../../api-config.service";
import { NamedBlob } from "../../../models/files/named-blob";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class OperationalFileDocumentService {
  constructor(protected http: HttpClient,
    protected util: UtilsService,
    protected apiConfig: ApiConfigService) {
  }

  public save(operationalFileId: number, fileName: string, file: Blob, documentTemplateId?: number, path: string = null): Observable<NamedBlob> {
    const url: string = this.apiConfig.createOperationalFileApiUrl2(operationalFileId, "documents");
    const formData: FormData = new FormData();
    formData.append(fileName, file, fileName);
    formData.append("overwrite", JSON.stringify(true));
    if (documentTemplateId) {
      formData.append("documentTemplateId", documentTemplateId.toString());
    }
    if (path) {
      formData.append("path", path.toString());
    }

    return this.http.post<Blob>(url, formData, { responseType: "blob" as "json" })
      .pipe(map(res => {
        if (!res) {
          return null;
        }

        return <NamedBlob>{
          blob: res,
          fileName: fileName
        };
      }));
  }
}
