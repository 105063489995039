import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PreviewResponse } from "../../../models/quotes/preview-response.model";
import { map } from "rxjs/operators";
import { HttpResponse } from "@angular/common/http";

@Injectable({
              providedIn: "root"
            })
export class PreviewFileHelper {

  constructor() {
  }

  public getPreviewDocument(request: Observable<HttpResponse<Blob>>): Observable<PreviewResponse> {
    return request.pipe(
      map((res: HttpResponse<Blob>) => {
        res.headers.keys();
        const fileName: string = res && res.headers ? res.headers.get("fileName") : "preview";
        const documentTemplateId: number = res && res.headers ? +res.headers.get("document-template-id") : 0;
        return <PreviewResponse>{ blob: { fileName: fileName, blob: res.body }, documentTemplateId: documentTemplateId };
      })
    );
  }
}
