/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ActivityCodeDetailsDTO } from '../model/activityCodeDetailsDTO';
import { MasterdataLookupResponse } from '../model/masterdataLookupResponse';
import { NewActivityCode } from '../model/newActivityCode';
import { PagedResponseFoundActivityCodeDTO } from '../model/pagedResponseFoundActivityCodeDTO';
import { Response } from '../model/response';
import { UpdatedActivityCode } from '../model/updatedActivityCode';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ActivityCodesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add activity code
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addActivityCode(body?: NewActivityCode, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<number>;
    public addActivityCode(body?: NewActivityCode, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<number>>;
    public addActivityCode(body?: NewActivityCode, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<number>>;
    public addActivityCode(body?: NewActivityCode, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<number>(`${this.basePath}/api/masterdata/activity-codes`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Delete activity codes
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteActivityCodes(id: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public deleteActivityCodes(id: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public deleteActivityCodes(id: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public deleteActivityCodes(id: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteActivityCodes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.delete<Response>(`${this.basePath}/api/masterdata/activity-codes/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get activity code by id
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityCodeById(id: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<ActivityCodeDetailsDTO>;
    public getActivityCodeById(id: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<ActivityCodeDetailsDTO>>;
    public getActivityCodeById(id: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<ActivityCodeDetailsDTO>>;
    public getActivityCodeById(id: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getActivityCodeById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<ActivityCodeDetailsDTO>(`${this.basePath}/api/masterdata/activity-codes/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get all activity codes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllActivityCodes(options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<MasterdataLookupResponse>;
    public getAllActivityCodes(options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<MasterdataLookupResponse>>;
    public getAllActivityCodes(options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<MasterdataLookupResponse>>;
    public getAllActivityCodes(options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<MasterdataLookupResponse>(`${this.basePath}/api/masterdata/activity-codes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get all activity codes respecting the blocking status
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllActivityCodesRespectingBlockingStatus(options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<MasterdataLookupResponse>;
    public getAllActivityCodesRespectingBlockingStatus(options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<MasterdataLookupResponse>>;
    public getAllActivityCodesRespectingBlockingStatus(options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<MasterdataLookupResponse>>;
    public getAllActivityCodesRespectingBlockingStatus(options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<MasterdataLookupResponse>(`${this.basePath}/api/masterdata/activity-codes/blocking-status`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Search activity code
     * 
     * @param searchTerm 
     * @param sortDirection 
     * @param sortProperty 
     * @param pageSize 
     * @param page 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchActivityCode(searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PagedResponseFoundActivityCodeDTO>;
    public searchActivityCode(searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PagedResponseFoundActivityCodeDTO>>;
    public searchActivityCode(searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PagedResponseFoundActivityCodeDTO>>;
    public searchActivityCode(searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchTerm !== undefined && searchTerm !== null) {
            queryParameters = queryParameters.set('SearchTerm', <any>searchTerm);
        }
        if (sortDirection !== undefined && sortDirection !== null) {
            queryParameters = queryParameters.set('SortDirection', <any>sortDirection);
        }
        if (sortProperty !== undefined && sortProperty !== null) {
            queryParameters = queryParameters.set('SortProperty', <any>sortProperty);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<PagedResponseFoundActivityCodeDTO>(`${this.basePath}/api/masterdata/activity-codes/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Update an activity code
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateActivityCode(id: number, body?: UpdatedActivityCode, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public updateActivityCode(id: number, body?: UpdatedActivityCode, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public updateActivityCode(id: number, body?: UpdatedActivityCode, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public updateActivityCode(id: number, body?: UpdatedActivityCode, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateActivityCode.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.put<Response>(`${this.basePath}/api/masterdata/activity-codes/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

}
