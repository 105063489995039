import { Directive, HostListener, ElementRef, Input } from "@angular/core";

@Directive({
  selector: "[alphaNumericVatNumber]"
})
export class AlphaNumericVatNumberDirective {
  private readonly regexStr: string = "^[a-zA-Z0-9\\/.-]+$";

  @Input() public isAlphaNumeric: boolean;

  constructor(private el: ElementRef) { }

  @HostListener("keypress", ["$event"]) public onKeyPress(event: KeyboardEvent): boolean {
    return new RegExp(this.regexStr).test(event.key);
  }

  @HostListener("paste", ["$event"]) public blockPaste(event: KeyboardEvent): void {
    this.validateFields(event);
  }

  public validateFields(event: any): void {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(new RegExp("((?![a-zA-Z0-9\/.-]).)+"), "");
      event.preventDefault();
    }, 100);
  }

}
