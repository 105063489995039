/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GetCourierInstructionDetailsDTO { 
    id?: number;
    invoiceReference?: string;
    ourReference?: string;
    instructionTypeId?: number;
    deadline?: Date;
    instructionDate?: Date;
    timePeriodType?: GetCourierInstructionDetailsDTO.TimePeriodTypeEnum;
    pickupLocation?: GetCourierInstructionDetailsDTO.PickupLocationEnum;
    amount?: number;
    containerNumbers?: Array<string>;
    billOfLadingNumbers?: Array<string>;
    remarks?: string;
    chequeNumber?: string;
    isExpressProcessing?: boolean;
    signatureTypeId?: number;
    paymentRequirementType?: number;
    operationalFileId?: number;
    portId?: number;
    vesselId?: number;
    operationalFilePartyIds?: Array<number>;
    operationalFileDocumentIds?: Array<string>;
    price?: number;
    documentUri?: string;
    trackingUri?: string;
    status?: string;
}
export namespace GetCourierInstructionDetailsDTO {
    export type TimePeriodTypeEnum = 0 | 1;
    export const TimePeriodTypeEnum = {
        NUMBER_0: 0 as TimePeriodTypeEnum,
        NUMBER_1: 1 as TimePeriodTypeEnum
    };
    export type PickupLocationEnum = 0 | 1;
    export const PickupLocationEnum = {
        NUMBER_0: 0 as PickupLocationEnum,
        NUMBER_1: 1 as PickupLocationEnum
    };
}