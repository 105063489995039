import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { EntityDetailsComponent } from '../../../core/components/abstractions/entity-details.component';
import { UnsavedChangesService } from '../../../core/services/helpers/unsaved-changes.service';
import { EmailTemplateTypesService, EmailTemplateTypeDTO, } from '../../../core/services/swagger-gen/fordesk';
import { MessageHelper } from "../../../core/helpers/message-helper";
import { DialogResult } from "../../../core/enumerations/dialog-result";
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '../../layout/toaster/toaster.service';
import { KeyValue } from "@angular/common";
import { EmailTemplateCategory } from "../../../core/enumerations/email-template-category.enum";

export class AddEmailTemplateDialogData {
  templateTypeId: number;
}

@Component({
  selector: 'add-email-template-type-dialog',
  templateUrl: './add-email-template-type-dialog.component.html',
  styleUrls: ['./add-email-template-type-dialog.component.scss']
})
export class AddEmailTemplateDialogComponent extends EntityDetailsComponent implements OnInit, AfterViewInit {
  public emailTemplateCategories: KeyValue<EmailTemplateCategory, string>[] = [];

  constructor(
    private emailTemplateTypeService: EmailTemplateTypesService,
    private fb: UntypedFormBuilder,
    unsavedChangedService: UnsavedChangesService,
    route: ActivatedRoute,
    public cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<AddEmailTemplateDialogComponent>,
    private messageHelper: MessageHelper,
    public translate: TranslateService,
    public toaster: ToasterService,
    @Inject(MAT_DIALOG_DATA) public data: AddEmailTemplateDialogData) {

    super(route, unsavedChangedService);
  }

  public ngOnInit(): void {
    this.emailTemplateCategories = [
      {
        key: EmailTemplateCategory.QuoteGeneral,
        value: this.translate.instant("MASTERDATA.EMAIL_TEMPLATE_TYPE.CATEGORIES.QUOTES_GENERAL"),
      },
      {
        key: EmailTemplateCategory.OperationalFileGeneral,
        value: this.translate.instant("MASTERDATA.EMAIL_TEMPLATE_TYPE.CATEGORIES.OPERATIONAL_FILE_GENERAL"),
      },
      {
        key: EmailTemplateCategory.NewInvoice,
        value: this.translate.instant("MASTERDATA.EMAIL_TEMPLATE_TYPE.CATEGORIES.NEW_INVOICE"),
      },
      {
        key: EmailTemplateCategory.InvoiceReminder,
        value: this.translate.instant("MASTERDATA.EMAIL_TEMPLATE_TYPE.CATEGORIES.INVOICE_REMINDER"),
      },
      {
        key: EmailTemplateCategory.SendQuote,
        value: this.translate.instant("MASTERDATA.EMAIL_TEMPLATE_TYPE.CATEGORIES.SEND_QUOTE"),
      },
    ];

    this.createForm();
  }

  public ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  private createForm(): void {
    this.detailsForm = this.fb.group({
      emailtemplateCategory: [null, [Validators.required, Validators.maxLength(1024)]],
      emailtemplateName: [null, [Validators.required, Validators.maxLength(1024)]]
    });
  }

  public saveInternal() {
    const emailTemplateType: EmailTemplateTypeDTO = {
      templateName: this.getFormValue("emailtemplateName"),
      category: this.getFormValue("emailtemplateCategory")
    };

    this.emailTemplateTypeService.addEmailTemplateType(emailTemplateType).subscribe((data) => {
      const message = this.messageHelper.getNewItemAddedMessage(DialogResult.Success,
        this.translate.instant("MASTERDATA.EMAIL_TEMPLATE_TYPE.TEMPLATE_TYPE")
      );
      this.toaster.success(message);
      this.dialogRef.close(data);
    });
  }

  public cancel() {
    this.dialogRef.close(null);
  }
}
