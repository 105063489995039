import { ExcelExportConfig } from "../../../content/dialogs/displayed-columns/displayed-columns.component";
import { ConfirmDialogData } from "./confirm-dialog-data";

export class DisplayedColumnsDialogData extends ConfirmDialogData {
  public stickyStartColumns: string[];
  public stickyEndColumns: string[];
  public displayedColumns: string[];
  public changeableColumns: string[];
  public translateInstant: any;

  public excelExportConfig: ExcelExportConfig;
}

export class DisplayedColumnsDialogResultData {
  public displayedColumns: string[];
  public isConfirmed: boolean;
}
