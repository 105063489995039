import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse, HttpClient } from "@angular/common/http";
import { Observable, timer } from "rxjs";
import { tap, delay } from "rxjs/operators";
import { OAuthService } from "angular-oauth2-oidc";
import { ToasterService } from "../../../content/layout/toaster/toaster.service";
import { TranslateService } from "@ngx-translate/core";
import { LoadingOverlayService } from "../../services/loading-overlay/loading-overlay.service";
import { UNAUTHORIZED } from "http-status-codes";

@Injectable()
export class AuthRedirectInterceptor implements HttpInterceptor {
  constructor(
    public authService: OAuthService,
    public toaster: ToasterService,
    public translateService: TranslateService,
    public loadingOverlay: LoadingOverlayService,
    public http: HttpClient
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap(() => { },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== UNAUTHORIZED) {
            return;
          }

          // delay for userexperience
          timer(500).pipe(
              tap(x => this.toaster.error(this.translateService.instant("AUTH.ERROR.NOT_AUTHORIZED"))),
              delay(1000),
              tap(x => this.loadingOverlay.startLoading()),
              delay(2000)
          ).subscribe(() => {
              this.authService.initImplicitFlow();
          });
        }
      }));
  }
}