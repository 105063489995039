import { Component, Input } from "@angular/core";
import { ContainerGoodsDTO } from "../../../../services/swagger-gen/fordesk";

@Component({
  selector: "container-goods-value",
  templateUrl: "./container-goods-value.html"
})
// tslint:disable-next-line: component-class-suffix
export class ContainerGoodsValue {
  @Input()
  public item: ContainerGoodsDTO;

  constructor() { }
}
