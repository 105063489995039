export enum FileExtensions {
  pdf = "pdf",
  doc = "doc",
  docx = "docx",
  jpg = "jpg",
  jpeg = "jpeg",
  png = "png",
  gif = "gif",
  xlsx = "xlsx",
  xltx = "xltx",
  xls = "xls",
  txt = "txt",
  msg = "msg",
  rtf = "rtf",
  eml = "eml",
  xlsm = "xlsm",
  xlsb = "xlsb",
  folder = "folder"
}
