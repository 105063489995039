export const outgoing_invoices_list_locale = {
  ACTIONS: {
    CREATE_CREDITNOTE: "create credit note",
    VIEW_INVOICE_COPY: "view copy",
    VIEW_INVOICE_ORIGINAL: "view original",
    SEND_REMINDER: "send reminder",
  },
  TITLE: "Outgoing invoices",
  SEARCH: "Search outgoing invoices",
  INVOICE_NUMBER: "Invoice nr.",
  STATUS: "Status",
  CUSTOMER_NAME: "Customer name",
  ACCOUNTING_NUMBER: "Accounting nr.",
  INVOICE_DATE: "Invoice date",
  INVOICE_EXPIRATION_DATE: "Invoice expiration date",
  CREATION_DATE: "Creation date",
  DUE_DATE: "Due date",
  TOTAL_INCL_VAT: "Amount (incl. VAT)",
  OPERATIONAL_FILE_NUMBER: "Operational file number",
  PAID: "Paid",
  CURRENCY: "Currency",
  PAID_YES: "Yes",
  PAID_NO: "No",
  INVOICE_REFERENCE: "Invoice reference",
  INVOICE_NUMBER_FULL_NAME: "Invoice number",
  FILE_REFERENCE: "Operational file nr.",
  CREDIT_NOTE_CREATED: "Credit note with number '{{ invoiceNumber }}' created.",
  CONFIRM_CREATE_CREDIT_NOTE: "Create a credit note for outgoing invoice '{{ invoiceNumber }}'?",
  CUSTOMER_ACCOUNTING_NUMBER: "Customer accounting nr.",
  TYPE: "Type",
  INVOICE_SHORT: "INV",
  CREDIT_NOTE_SHORT: "CN",
  EXPANDED: {
    FILE_NUMBER: "File number",
    FILE_TYPE: "File type",
    FROM: "From",
    TO: "To",
    NOT_FOUND: "No linked operational files."
  },
  MODALS: {
    SET_PAID_DATE_TITLE: "Payment date",
    SET_PAID_DATE: "Choose a payment date"
  }
};
