export const of_shipping_locale = {
  SHIPPING_DETAILS_TITLE: "Shipping details",
  BILL_OF_LADING: "Bill of lading",
  NUMBER_OF_ORIGINALS: "Number of originals",
  NUMBER_OF_COPIES: "Number of copies",
  PORT_OF_LOADING: "Port of loading",
  PORT_OF_DISCHARGE: "Port of discharge",
  PLACE_OF_RECEIPT: "Place of receipt",
  PLACE_OF_DESTINATION: "Place of destination",
  REMARKS: "Remarks",
  FREIGHT_PAYABLE_AT: "Freight payable at",
  CONTAINERS_REQUIRED: "At least one container with goods must be attached to this operational file before shipping instructions can be send.",
  SHIPPING_INSTRUCTIONS_SENT: "The shipping instructions have been sent successfully.",
  CONTAINERS_GOODS_BOOKING_REFERENCES_REQUIRED: "Goods, Containers and Booking references are required to send shipping instructions.",
  BOOKING_REFERENCES_REQUIRED: "Propertie Booking references are required",
  SHIPPING_INSTRUCTIONS_ERROR: "An error occurred while attempting to send the shipping instructions.",
  GENERAL_PORTS_NOT_FOUND: "No '{{name}}' found in the general tab",
  STATUS_ACCEPTED: "The shipping instructions were successfully accepted on {{timestamp}}.",
  STATUS_REFUSED: "The shipping instructions were refused on {{timestamp}}.",
  STATUS_AWAITING: "The shipping instructions are awaiting reply since {{timestamp}}.",
  NOT_YET_SENT: "The shipping instructions have not been sent yet.",
  CONFIRM_SEND: "Are you sure you want to send the shipping instructions?",
  ALREADY_ACCEPTED: "The shipping instructions have already been accepted. Are you sure you want to send an update?",
  STILL_AWAITING_REPLY: "This shipping instructions are still processing. Are you sure you want to send an update?",
  IS_FREIGHTED: "Is freighted?",
  ORIGINAL: "Original",
  AMENDMENT: "Amendment",
  CHOOSE_TYPE: "Please choose the type of shipping instructions:",
  CHOOSE_TYPE_TITLE: "Shipping instructions type",
  NON_PRINTABLE_REMARKS: "Non-printable remarks",
  PRINTABLE_REMARKS: "Printable remarks",
  NR_OF_ORIGINALS: "# of originals",
  NR_OF_COPIES: "# of copies",
  CONTAINERS: "Containers",
  CONTAINERS_NOT_FOUND: "No containers were found for your query. To add them, go to the 'Containers' tab.",
  GOODS_NOT_FOUND: "The selected containers do not contain such goods.",
  BOOKING_REFERENCES_NOT_FOUND: "No booking references were found for your query. To add them, go to the 'General' tab.",
  CONTAINER_REQUIRED: "At least one container is required.",
  NR_OF_CONTAINERS: "# of containers",
  PARTIES: {
    TITLE: "Parties",
    SHIPPER: "Shipper",
    CARRIER: "Carrier",
    CONSIGNEE: "Consignee",
    NOTIFY: "Notify",
    SECOND_NOTIFY: "Second notify"
  },
  BOOKING_REFERENCES: "Booking references",
  PREFILL_BOOKING_REFERENCES: "Booking references was prefilled. Save changes not to lose prefilled changes.",
  GOODS: "Goods",
  GOOD_REQUIRED: "At least one good is required.",
  STATUS: "Status",
  CONTAINER_GOODS: {
    OUTER_PACKAGE_TYPE_CODE_DESCRIPTION: "Outer package type code - description",
    GOODS_DESCRIPTION: "Goods description",
    NUMBER_OF_COLLIS: "Number of collis",
    GROSS_WEIGHT: "Gross weight",
    CONTAINER_NUMBER: "Container number",
    SHOW_CONTAINER_GOODS: "Show selected container goods details",
    HIDE_CONTAINER_GOODS: "Hide selected container goods details"
  }
};
