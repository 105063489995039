import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DecimalFormatterService } from "../../helpers/decimal-formatter.service";

@Component({
  selector: "exchange-rate",
  templateUrl: "./exchange-rate.component.html",
  styleUrls: ["./exchange-rate.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ExchangeRateComponent),
    }
  ]
})
export class ExchangeRateComponent implements ControlValueAccessor {
  @Input() public invalid: boolean = false;
  @Input() public disabled: boolean = false;

  public calculatedCourseAmount: number;
  public value: number;

  /**
   * Invoked when the model has been changed
   */
  public onChange: (_: any) => void = (_: any) => { };

  /**
   * Invoked when the model has been touched
   */
  public onTouched: () => void = () => { };

  constructor(public formatService: DecimalFormatterService) { }

  /**
   * Method that is invoked on an update of a model.
   */
  public updateChanges(value) {
    this.value = value;
    this.calculatedCourseAmount = this.calculateExchangeRateFromAmount(this.value, 1);
    this.onChange(this.value);
  }

  /**
   * Writes a new item to the element.
   * @param value the value
   */
  public writeValue(value: number): void {
    this.updateChanges(value);
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * @param fn
   */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * @param fn
   */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
    * Function that is called by the forms API when the control status changes to
    * or from 'DISABLED'. Depending on the status, it enables or disables the
    * appropriate DOM element.
    * @param isDisabled The disabled status to set on the element
    */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private calculateExchangeRateFromAmount(exchangeRate: number, amount: number) {
    if (!exchangeRate || exchangeRate === 0) {
      return amount;
    }
    const result = exchangeRate * amount;

    return parseFloat(parseFloat(result.toString()).toPrecision(15));
  }
}
