/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BudgetExcelExportSearchCondition { 
    readonly listSortDirection?: BudgetExcelExportSearchCondition.ListSortDirectionEnum;
    sortDirection?: string;
    sortProperty?: string;
    operationalFileId?: number;
    budgetConfig?: BudgetExcelExportSearchCondition.BudgetConfigEnum;
    displayedColumns?: Array<string>;
}
export namespace BudgetExcelExportSearchCondition {
    export type ListSortDirectionEnum = 0 | 1;
    export const ListSortDirectionEnum = {
        NUMBER_0: 0 as ListSortDirectionEnum,
        NUMBER_1: 1 as ListSortDirectionEnum
    };
    export type BudgetConfigEnum = 0 | 1 | 2;
    export const BudgetConfigEnum = {
        NUMBER_0: 0 as BudgetConfigEnum,
        NUMBER_1: 1 as BudgetConfigEnum,
        NUMBER_2: 2 as BudgetConfigEnum
    };
}