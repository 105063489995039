import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})

export class FormlyHelper {

  private generalFormSubmissionSubject: Subject<void> = new Subject();

  public generalFormSubmitted() {
    this.generalFormSubmissionSubject.next();
  }

  public getGeneralFormSubmission$(): Observable<void> {
    return this.generalFormSubmissionSubject.asObservable();
  }



}