import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class OperationalFileDocumentsRefreshService {
  private refreshLists$: Subject<void> = new Subject<void>();

  public refresh() {
    this.refreshLists$.next(null);
  }

  public getRefresh$(): Observable<void> {
    return this.refreshLists$.asObservable();
  }
}
