import { Component, Input } from "@angular/core";
import { AddressDetailsDTO } from "../../../../services/swagger-gen/fordesk";

@Component({
  selector: "address-label",
  templateUrl: "./address-label.component.html"
})
export class AddressLabelComponent {
  @Input() public item: AddressDetailsDTO;

  constructor() { }
}
