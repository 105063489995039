export const of_notes_locale = {
  ADD_NEW_NOTE: "Add a new note",
  EDIT_NOTE: "Edit a note",
  MESSAGES: {
    NOTE_UPDATED: "Note successfully updated.",
    NOTE_ADDED: "Note successfully added. "
  },
  TITLE: "Title",
  TEXT: "Text"
};
