import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";
import { OperationalFileService } from "../services/swagger-gen/fordesk";
import { OperationalFileIdentifierDTO } from "../services/swagger-gen/fordesk/model/operationalFileIdentifierDTO";

@Injectable({ providedIn: "root" })
export class OperationalFileIdentifiersHelper {
  constructor() { }

  public getIdentifiers(service: OperationalFileService, input: Subject<string>, onStart: Function, onComplete: Function): Observable<OperationalFileIdentifierDTO[]> {
    return input.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string) => {
        if (term && term.length > 2) {
          if (onStart) {
            onStart();
          }

          return service.searchOperationalFileIdentifiers(term)
            .pipe(
              map((res) => {
                return res ? res : [];
              }),
              catchError(() => of([])), // empty list on error
              tap(() => onComplete?.())
            );
        } else {
          onComplete?.();
          return of([]);
        }
      })
    );
  }
}
