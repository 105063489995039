import { Directive, HostListener, ElementRef, Input } from "@angular/core";

@Directive({
  selector: "[dateCharactersOnly]"
})
export class DateCharactersOnlyDirective {
  private readonly regexStr: string = "^[0-9\-]*$";

  @Input()
  public dateCharactersOnly: boolean;

  constructor(private el: ElementRef) { }

  @HostListener("keypress", ["$event"]) public onKeyPress(event: KeyboardEvent): boolean {
    if (this.dateCharactersOnly) {
      return new RegExp(this.regexStr).test(event.key);
    }
    return true;
  }

  @HostListener("paste", ["$event"]) public blockPaste(event: KeyboardEvent): void {
    if (this.dateCharactersOnly) {
      this.validateFields(event);
    }
  }

  public validateFields(event: any): void {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^0-9\-]/g, "");
      event.preventDefault();
    }, 100);
  }

}