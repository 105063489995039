import { sortAlphabetically } from "./sort-extensions";
import { NameValue } from "../models/name-value.model";

export class EnumEx {
    private constructor() { }

    /**
     * Deletes the provided item, if found, in the array of items.
     */
    public static getNamesAndValues<T extends number>(e: any, sortAlpha: boolean = true): NameValue<T>[] {
        return EnumEx.getNames(e, sortAlpha).map(n => <NameValue<T>>({ name: n, value: e[n] as T }));
    }

    public static getNames(e: any, sortAlpha: boolean = true): string[] {
        let result = Object.keys(e).filter(k => typeof e[k] === "number") as string[];
        if (sortAlpha) {
            result = result.sort(sortAlphabetically);
        }

        return result;
    }

    public static getValues<T extends number>(e: any): T[] {
        return Object.keys(e)
            .map(k => e[k])
            .filter(v => typeof v === "number") as T[];
    }
}
