export const of_transports_locale = {
  ADD_TRANSPORT: "Add transport",
  TRANSPORT_DETAILS: "Transport details",
  TRANSPORT_COMPANY: "Transport company",
  PICKUP_LOCATION: "Pickup location",
  TERMINAL: "Terminal",
  LOCATION: "Location",
  TOWN: "Town",
  ZIPCODE: "Zipcode",
  MULTISTOPS: "Multistops",
  ADD_MULTISTOP: "Add transport multistop",
  DEPOT: "Depot",
  COUNTRY: "Country",
  DATE: "Date",
  REFERENCE: "Reference",
  FULL_DELIVERY_LOCATION: "Full delivery location",
  EMPTY_DROP_OFF_LOCATION: "Empty drop off location",
  DELIVERY_LOCATION: "Delivery location",
  TRANSPORT_MODE: "Transport mode",
  REMARKS: "Remarks",
  PARTY_TYPE: "Party type",
  MSG_CONTAINER_REQUIRED: "At least one container should be assigned.",
  NO_SUITABLE_COSTS_FOUND: "No suitable operational file costs were found",
  NO_PINED_COSTS_FOUND: "No attached costs were found",
  MSG_GOODS_REQUIRED: "At least one good should be assigned.",
  ATTACHED_COSTS: "Attached costs",
  FOUND_COSTS: "Found costs",
  GOODS: "Goods",
  TRANSPORT_STOPS: {
    TITLE: "Transport stops",
    TYPE: "Stop type",
    LOCATION: "Location",
    ADDRESS: "Address",
    TIMESTAMP: "Date",
    ADD_TITLE: "Add a new transport stop",
    DETAILS: "Transport stop details",
    STOP_TYPE: "Stop type",
    DATE: "Date",
    REFERENCE: "Reference",
    TERMINAL_DEPOT_LOCATION: "Terminal / depot / location",
    STOP_LOCATION: "Stop location",
    REMARKS: "Remarks",
    STANDARD_TEXTS: "Standard texts"
  },
  MESSAGES: {
    UNPIN_ALL_COSTS_AND_CHAGE_COMPANY: "Do you want to unpin all costs and change the company?",
    PLEASE_SAVE_SELCTED_COMPANY_TO_ATTACH_COSTS: "Please save selected company to attach costs.",
  },
  WIZARD: {
    TITLE: "Create Transport Order",
    STEP: "Step",
    PERFORM_TRANSPORT_STOP_TITLE: "Perform loading at this location:",
    PLEASE_SAVE_TRANSPORT_STOP: "Please save transport stop",
    FINISHED: "You have completed all steps. Please press finish to complete the process",
    EMPTY_PICKUP_TITLE: "Empty Pickup",
    MULTI_STOP_TITLE: "Multi stop",
    FULL_PICKUP_TITLE: "Full Pickup",
    PICKUP_TITLE: "Pickup",
    PARTY_WAREHOUSE: "(Party) Warehouse",
    OTHER_PARTY: "Other party",
    OTHER: "Other",
    ADD_REMARKS: "Add remarks",
    SHOW_ALL_GOODS: "Show all goods",
    REMARKS: "Remarks",
    CLOSSING_DATE: "(Vessel closing date: {{date}})",
    ETD_CLOSSING_DATE: "(Estimated Time of Departure: {{date}})",
    ETA_CLOSSING_DATE: "(Estimated Time of Arrival: {{date}})",
    ON_DATE: "On date:",
    DISABLED_SELECT_BOX_PLACEHOLDER: "Please select current radio option to enable select box",
    LOADING_ADDRESS: "(Party) Loading Address",
    LOADING_TITLE: "Loading",
    CUSTOMER_ADDRESS: "Customer address",
    DELIVERY_TITLE: "Delivery",
    EMPTY_DROP_OFF_TITLE: "Empty drop off",
    DELIVERY_ADDRESS: "(Party) Delivery Address",
    TERMINAL: "Terminal",
    NO_TERMINAL: "No terminal",
    FULL_DELIVERY_TITLE: "Full Delivery",
    WAREHOUSE_TRANSPORT_TYPE: "Transport type",
    FROM_CUSTOMER_TO_WAREHOUSE: "From Customer to Warehouse",
    FROM_WAREHOUSE_TO_CUSTOMER: "From Warehouse to Customer",
    ERRORS: {
      REQUIRED_DATA_NOT_FILLED_FOR_FINISH: "Please fill in the required fields to finish."
    }
  }
};
