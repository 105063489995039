import { Component, Inject } from "@angular/core";
import { AlertDialogData } from "../../../core/models/dialogs/alert-dialog-data";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmBase } from "../abstractions/confirm-base.component";

@Component({
  selector: "alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"]
})
export class AlertDialog extends ConfirmBase {
  constructor(
    public dialogRef: MatDialogRef<AlertDialog>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData
  ) {
    super(dialogRef, data);
  }
}
