/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DateRange } from './dateRange';
import { NumberRange } from './numberRange';

export interface OutgoingInvoiceSearchCondition { 
    pageSize?: number;
    page?: number;
    readonly listSortDirection?: OutgoingInvoiceSearchCondition.ListSortDirectionEnum;
    sortDirection?: string;
    sortProperty?: string;
    customerName?: Array<string>;
    invoiceNumber?: Array<string>;
    status?: Array<OutgoingInvoiceSearchCondition.StatusEnum>;
    dueDate?: Array<DateRange>;
    invoiceDate?: Array<DateRange>;
    creationDate?: Array<DateRange>;
    type?: Array<string>;
    inclVat?: Array<NumberRange>;
    operationalFileNumber?: Array<string>;
    currency?: Array<number>;
    paid?: Array<OutgoingInvoiceSearchCondition.PaidEnum>;
}
export namespace OutgoingInvoiceSearchCondition {
    export type ListSortDirectionEnum = 0 | 1;
    export const ListSortDirectionEnum = {
        NUMBER_0: 0 as ListSortDirectionEnum,
        NUMBER_1: 1 as ListSortDirectionEnum
    };
    export type StatusEnum = '0' | '1' | '2' | '3';
    export const StatusEnum = {
        _0: '0' as StatusEnum,
        _1: '1' as StatusEnum,
        _2: '2' as StatusEnum,
        _3: '3' as StatusEnum
    };
    export type PaidEnum = '0' | '1';
    export const PaidEnum = {
        _0: '0' as PaidEnum,
        _1: '1' as PaidEnum
    };
}