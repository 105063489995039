/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { OperationalFIleStepDescriptionDTO } from '../model/operationalFIleStepDescriptionDTO';
import { OperationalFileStepDTO } from '../model/operationalFileStepDTO';
import { OperationalFileStepDetails } from '../model/operationalFileStepDetails';
import { Response } from '../model/response';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OperationalFileStepsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Assign unused
     * 
     * @param newPartyId 
     * @param operationalFileId 
     * @param createCopy 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assignUnused(newPartyId: number, operationalFileId: number, createCopy?: boolean, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public assignUnused(newPartyId: number, operationalFileId: number, createCopy?: boolean, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public assignUnused(newPartyId: number, operationalFileId: number, createCopy?: boolean, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public assignUnused(newPartyId: number, operationalFileId: number, createCopy?: boolean, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (newPartyId === null || newPartyId === undefined) {
            throw new Error('Required parameter newPartyId was null or undefined when calling assignUnused.');
        }

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling assignUnused.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (newPartyId !== undefined && newPartyId !== null) {
            queryParameters = queryParameters.set('newPartyId', <any>newPartyId);
        }
        if (createCopy !== undefined && createCopy !== null) {
            queryParameters = queryParameters.set('createCopy', <any>createCopy);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/operational-file-steps/assign-unused`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Check an attached file steps to party
     * 
     * @param operationalFileId 
     * @param partyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkAttachedFileStepsToParty(operationalFileId: number, partyId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<boolean>;
    public checkAttachedFileStepsToParty(operationalFileId: number, partyId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<boolean>>;
    public checkAttachedFileStepsToParty(operationalFileId: number, partyId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<boolean>>;
    public checkAttachedFileStepsToParty(operationalFileId: number, partyId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling checkAttachedFileStepsToParty.');
        }

        if (partyId === null || partyId === undefined) {
            throw new Error('Required parameter partyId was null or undefined when calling checkAttachedFileStepsToParty.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (partyId !== undefined && partyId !== null) {
            queryParameters = queryParameters.set('partyId', <any>partyId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<boolean>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/operational-file-steps/check-attached-steps-to-party`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Check steps with the same party type
     * 
     * @param operationalFileId 
     * @param partyTypeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkStepsWithSamePartyType(operationalFileId: number, partyTypeId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public checkStepsWithSamePartyType(operationalFileId: number, partyTypeId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public checkStepsWithSamePartyType(operationalFileId: number, partyTypeId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public checkStepsWithSamePartyType(operationalFileId: number, partyTypeId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling checkStepsWithSamePartyType.');
        }

        if (partyTypeId === null || partyTypeId === undefined) {
            throw new Error('Required parameter partyTypeId was null or undefined when calling checkStepsWithSamePartyType.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (partyTypeId !== undefined && partyTypeId !== null) {
            queryParameters = queryParameters.set('partyTypeId', <any>partyTypeId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/operational-file-steps/check-steps-with-the-same-party-type`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Create an operational file steps
     * 
     * @param operationalFileId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStepInOperationalFile(operationalFileId: number, body?: OperationalFileStepDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OperationalFileStepDetails>;
    public createStepInOperationalFile(operationalFileId: number, body?: OperationalFileStepDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OperationalFileStepDetails>>;
    public createStepInOperationalFile(operationalFileId: number, body?: OperationalFileStepDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OperationalFileStepDetails>>;
    public createStepInOperationalFile(operationalFileId: number, body?: OperationalFileStepDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling createStepInOperationalFile.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<OperationalFileStepDetails>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/operational-file-steps`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Delete an attached file steps
     * 
     * @param operationalFileId 
     * @param partyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAttachedFileSteps(operationalFileId: number, partyId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public deleteAttachedFileSteps(operationalFileId: number, partyId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public deleteAttachedFileSteps(operationalFileId: number, partyId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public deleteAttachedFileSteps(operationalFileId: number, partyId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling deleteAttachedFileSteps.');
        }

        if (partyId === null || partyId === undefined) {
            throw new Error('Required parameter partyId was null or undefined when calling deleteAttachedFileSteps.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (partyId !== undefined && partyId !== null) {
            queryParameters = queryParameters.set('partyId', <any>partyId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.delete<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/operational-file-steps/delete-attached`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Delete an operational file steps
     * 
     * @param operationalFileId 
     * @param stepId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOperationalFileStep(operationalFileId: number, stepId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public deleteOperationalFileStep(operationalFileId: number, stepId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public deleteOperationalFileStep(operationalFileId: number, stepId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public deleteOperationalFileStep(operationalFileId: number, stepId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling deleteOperationalFileStep.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling deleteOperationalFileStep.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.delete<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/operational-file-steps/${encodeURIComponent(String(stepId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get all operational file steps
     * 
     * @param operationalFileId 
     * @param isOrderedByDeadline 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOperationalFileSteps(operationalFileId: number, isOrderedByDeadline?: boolean, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<OperationalFileStepDetails>>;
    public getOperationalFileSteps(operationalFileId: number, isOrderedByDeadline?: boolean, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<OperationalFileStepDetails>>>;
    public getOperationalFileSteps(operationalFileId: number, isOrderedByDeadline?: boolean, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<OperationalFileStepDetails>>>;
    public getOperationalFileSteps(operationalFileId: number, isOrderedByDeadline?: boolean, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getOperationalFileSteps.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isOrderedByDeadline !== undefined && isOrderedByDeadline !== null) {
            queryParameters = queryParameters.set('isOrderedByDeadline', <any>isOrderedByDeadline);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<OperationalFileStepDetails>>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/operational-file-steps`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get steps with missed linked parties
     * 
     * @param operationalFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStepsWithMissedLinkedParties(operationalFileId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<OperationalFIleStepDescriptionDTO>>;
    public getStepsWithMissedLinkedParties(operationalFileId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<OperationalFIleStepDescriptionDTO>>>;
    public getStepsWithMissedLinkedParties(operationalFileId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<OperationalFIleStepDescriptionDTO>>>;
    public getStepsWithMissedLinkedParties(operationalFileId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getStepsWithMissedLinkedParties.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<OperationalFIleStepDescriptionDTO>>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/operational-file-steps/missed-party-linking`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Send an email when a step is completed
     * 
     * @param operationalFileId 
     * @param stepId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendEmail(operationalFileId: number, stepId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public sendEmail(operationalFileId: number, stepId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public sendEmail(operationalFileId: number, stepId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public sendEmail(operationalFileId: number, stepId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling sendEmail.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling sendEmail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/operational-file-steps/${encodeURIComponent(String(stepId))}/send-email`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * unpin an attached file steps by party id
     * 
     * @param operationalFileId 
     * @param partyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unpinAttachedFileStepsByPartyId(operationalFileId: number, partyId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public unpinAttachedFileStepsByPartyId(operationalFileId: number, partyId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public unpinAttachedFileStepsByPartyId(operationalFileId: number, partyId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public unpinAttachedFileStepsByPartyId(operationalFileId: number, partyId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling unpinAttachedFileStepsByPartyId.');
        }

        if (partyId === null || partyId === undefined) {
            throw new Error('Required parameter partyId was null or undefined when calling unpinAttachedFileStepsByPartyId.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (partyId !== undefined && partyId !== null) {
            queryParameters = queryParameters.set('partyId', <any>partyId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/operational-file-steps/unpin-attached`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Update an operational file step
     * 
     * @param operationalFileId 
     * @param stepId 
     * @param body 
     * @param isDone 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOperationalFileStep(operationalFileId: number, stepId: number, body?: OperationalFileStepDTO, isDone?: boolean, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OperationalFileStepDetails>;
    public updateOperationalFileStep(operationalFileId: number, stepId: number, body?: OperationalFileStepDTO, isDone?: boolean, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OperationalFileStepDetails>>;
    public updateOperationalFileStep(operationalFileId: number, stepId: number, body?: OperationalFileStepDTO, isDone?: boolean, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OperationalFileStepDetails>>;
    public updateOperationalFileStep(operationalFileId: number, stepId: number, body?: OperationalFileStepDTO, isDone?: boolean, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling updateOperationalFileStep.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling updateOperationalFileStep.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isDone !== undefined && isDone !== null) {
            queryParameters = queryParameters.set('isDone', <any>isDone);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.put<OperationalFileStepDetails>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/operational-file-steps/${encodeURIComponent(String(stepId))}`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Update an operational file steps
     * 
     * @param operationalFileId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOperationalFileStepsOrder(operationalFileId: number, body?: Array<number>, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public updateOperationalFileStepsOrder(operationalFileId: number, body?: Array<number>, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public updateOperationalFileStepsOrder(operationalFileId: number, body?: Array<number>, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public updateOperationalFileStepsOrder(operationalFileId: number, body?: Array<number>, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling updateOperationalFileStepsOrder.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.put<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/operational-file-steps/order`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Update steps with missed linked parties
     * 
     * @param operationalFileId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStepsWithMissedLinkedParties(operationalFileId: number, body?: Array<OperationalFIleStepDescriptionDTO>, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public updateStepsWithMissedLinkedParties(operationalFileId: number, body?: Array<OperationalFIleStepDescriptionDTO>, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public updateStepsWithMissedLinkedParties(operationalFileId: number, body?: Array<OperationalFIleStepDescriptionDTO>, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public updateStepsWithMissedLinkedParties(operationalFileId: number, body?: Array<OperationalFIleStepDescriptionDTO>, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling updateStepsWithMissedLinkedParties.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.put<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/operational-file-steps/missed-party-linking`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

}
