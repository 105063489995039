import { BehaviorSubject, forkJoin } from "rxjs";
import { Injectable } from "@angular/core";
import { filter, switchMap } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { LayoutConfigStorageService } from "./layout-config-storage.service";
import { LayoutConfig, LayoutConfigModel } from "../../config/layout";
import { TenantConfigurationService } from "./client-tenant-configuration/tenant-config.service";

@Injectable({ providedIn: "root" })
export class LayoutConfigService {
  public layoutConfig: LayoutConfig;
  public onLayoutConfigUpdated$: BehaviorSubject<LayoutConfig>;

  constructor(
    private router: Router,
    private layoutConfigStorageService: LayoutConfigStorageService,
    private clientTenantConfigService: TenantConfigurationService
  ) {
    // default config
    this.layoutConfig = new LayoutConfig();
    // register on config changed event and set default config
    this.onLayoutConfigUpdated$ = new BehaviorSubject(this.layoutConfig);

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        switchMap(() =>
          forkJoin({
            clientTenantConfig: this.clientTenantConfigService.initialize(),
            layoutConfig: this.layoutConfigStorageService.loadConfig()
          })))
      .subscribe(configs => {
        const layoutConfig: LayoutConfig = configs ? configs.layoutConfig : null;
        const logoFileName: string = configs && configs.clientTenantConfig && configs.clientTenantConfig.generalSettings ? configs.clientTenantConfig.generalSettings.logoFileName : null;
        if (logoFileName) {
          const model = layoutConfig.config as LayoutConfigModel;
          model.header.self.logo.light = logoFileName;
        }

        this.layoutConfig = layoutConfig;
        this.onLayoutConfigUpdated$.next(layoutConfig);
      });
  }

  /**
   * Reset existing configurations
   * NOTE: This method will remove older config and pass only new;
   * @param model
   * @param doNotSave
   */
  public setModel(model: any, doNotSave?: boolean): void {
    // merge and replace existing config object
    // deep merge for mutltidimentional arrays
    this.layoutConfig = Object.assign({}, this.layoutConfig, model);

    if (!doNotSave) {
      this.layoutConfigStorageService.saveConfig(this.layoutConfig);
    }

    // fire off an event that all subscribers will listen
    this.onLayoutConfigUpdated$.next(this.layoutConfig);
  }

  public reloadSavedConfig(): void {
    this.setModel(new LayoutConfig(this.getSavedConfig()), true);
  }

  /**
   * Set current config as default template.
   * This config is changeable via layout builder.
   * Useful when want to reset layout without clearing the config at layout
   */
  public getSavedConfig() {
    return this.layoutConfigStorageService.loadConfig();
  }
}
