/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CostInvoiceSummary { 
    incomingInvoiceId?: number;
    incomingInvoiceAccountingNumber?: string;
    incomingInvoiceDraftNumber?: string;
    taxPercentage?: number;
    taxSystem?: string;
    issueDate?: Date;
    invoiceStatus?: CostInvoiceSummary.InvoiceStatusEnum;
    incomingInvoiceNumber?: string;
}
export namespace CostInvoiceSummary {
    export type InvoiceStatusEnum = 1 | 2 | 3 | 4 | 5;
    export const InvoiceStatusEnum = {
        NUMBER_1: 1 as InvoiceStatusEnum,
        NUMBER_2: 2 as InvoiceStatusEnum,
        NUMBER_3: 3 as InvoiceStatusEnum,
        NUMBER_4: 4 as InvoiceStatusEnum,
        NUMBER_5: 5 as InvoiceStatusEnum
    };
}