/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PagedResponseFoundBudgetQuoteDetailsDTO } from '../model/pagedResponseFoundBudgetQuoteDetailsDTO';
import { PivotTableResponseReportBudgetDetailsPerQuoteDTO } from '../model/pivotTableResponseReportBudgetDetailsPerQuoteDTO';
import { QuoteBudgetDetailsSearchCondition } from '../model/quoteBudgetDetailsSearchCondition';
import { QuoteBudgetDetailsTotalsDTO } from '../model/quoteBudgetDetailsTotalsDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class QuoteReportingService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get the quote budget details for the pivot table.
     * 
     * @param minDate 
     * @param maxDate 
     * @param sortDirection 
     * @param sortProperty 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuoteBudgetDetails(minDate?: Date, maxDate?: Date, sortDirection?: string, sortProperty?: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PivotTableResponseReportBudgetDetailsPerQuoteDTO>;
    public getQuoteBudgetDetails(minDate?: Date, maxDate?: Date, sortDirection?: string, sortProperty?: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PivotTableResponseReportBudgetDetailsPerQuoteDTO>>;
    public getQuoteBudgetDetails(minDate?: Date, maxDate?: Date, sortDirection?: string, sortProperty?: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PivotTableResponseReportBudgetDetailsPerQuoteDTO>>;
    public getQuoteBudgetDetails(minDate?: Date, maxDate?: Date, sortDirection?: string, sortProperty?: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (minDate !== undefined && minDate !== null) {
            queryParameters = queryParameters.set('MinDate', <any>minDate.toISOString());
        }
        if (maxDate !== undefined && maxDate !== null) {
            queryParameters = queryParameters.set('MaxDate', <any>maxDate.toISOString());
        }
        if (sortDirection !== undefined && sortDirection !== null) {
            queryParameters = queryParameters.set('SortDirection', <any>sortDirection);
        }
        if (sortProperty !== undefined && sortProperty !== null) {
            queryParameters = queryParameters.set('SortProperty', <any>sortProperty);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<PivotTableResponseReportBudgetDetailsPerQuoteDTO>(`${this.basePath}/api/reporting/quotes/quote-budget-details`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get the quote budget details excel report.
     * 
     * @param minDate 
     * @param maxDate 
     * @param sortDirection 
     * @param sortProperty 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuoteBudgetDetailsExcelReport(minDate?: Date, maxDate?: Date, sortDirection?: string, sortProperty?: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public getQuoteBudgetDetailsExcelReport(minDate?: Date, maxDate?: Date, sortDirection?: string, sortProperty?: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public getQuoteBudgetDetailsExcelReport(minDate?: Date, maxDate?: Date, sortDirection?: string, sortProperty?: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public getQuoteBudgetDetailsExcelReport(minDate?: Date, maxDate?: Date, sortDirection?: string, sortProperty?: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (minDate !== undefined && minDate !== null) {
            queryParameters = queryParameters.set('MinDate', <any>minDate.toISOString());
        }
        if (maxDate !== undefined && maxDate !== null) {
            queryParameters = queryParameters.set('MaxDate', <any>maxDate.toISOString());
        }
        if (sortDirection !== undefined && sortDirection !== null) {
            queryParameters = queryParameters.set('SortDirection', <any>sortDirection);
        }
        if (sortProperty !== undefined && sortProperty !== null) {
            queryParameters = queryParameters.set('SortProperty', <any>sortProperty);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get(`${this.basePath}/api/reporting/quotes/quote-budget-details-report`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get the quote budget details totals.
     * 
     * @param minDate 
     * @param maxDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuoteBudgetDetailsTotals(minDate?: Date, maxDate?: Date, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<QuoteBudgetDetailsTotalsDTO>;
    public getQuoteBudgetDetailsTotals(minDate?: Date, maxDate?: Date, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<QuoteBudgetDetailsTotalsDTO>>;
    public getQuoteBudgetDetailsTotals(minDate?: Date, maxDate?: Date, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<QuoteBudgetDetailsTotalsDTO>>;
    public getQuoteBudgetDetailsTotals(minDate?: Date, maxDate?: Date, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (minDate !== undefined && minDate !== null) {
            queryParameters = queryParameters.set('minDate', <any>minDate.toISOString());
        }
        if (maxDate !== undefined && maxDate !== null) {
            queryParameters = queryParameters.set('maxDate', <any>maxDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<QuoteBudgetDetailsTotalsDTO>(`${this.basePath}/api/reporting/quotes/quote-budget-details-totals`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get the quote budget details paged.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchQuoteBudgetDetails(body?: QuoteBudgetDetailsSearchCondition, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PagedResponseFoundBudgetQuoteDetailsDTO>;
    public searchQuoteBudgetDetails(body?: QuoteBudgetDetailsSearchCondition, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PagedResponseFoundBudgetQuoteDetailsDTO>>;
    public searchQuoteBudgetDetails(body?: QuoteBudgetDetailsSearchCondition, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PagedResponseFoundBudgetQuoteDetailsDTO>>;
    public searchQuoteBudgetDetails(body?: QuoteBudgetDetailsSearchCondition, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<PagedResponseFoundBudgetQuoteDetailsDTO>(`${this.basePath}/api/reporting/quotes/quote-budget-details/search`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

}
