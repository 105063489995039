import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogData, ConfirmDialogResultData } from '../../../core/models/dialogs/confirm-dialog-data';
import { BudgetExcelExportSearchCondition } from '../../../core/services/swagger-gen/fordesk';
import { ConfirmBase } from '../abstractions/confirm-base.component';

export class ConfirmExcelExportDialogResultData extends ConfirmDialogResultData {
  public budgetType: BudgetExcelExportSearchCondition.BudgetConfigEnum;
}
@Component({
  selector: 'confirm-budget-excel-import',
  templateUrl: './confirm-budget-excel-export.component.html',
  styleUrls: ['./confirm-budget-excel-export.component.scss']
})
export class ConfirmBudgetExcelExportComponent extends ConfirmBase implements OnInit {
  constructor(public dialogRef: MatDialogRef<ConfirmBudgetExcelExportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
  }

  public confirmExcelExport(budgetType: BudgetExcelExportSearchCondition.BudgetConfigEnum) {
    const dialogResult: ConfirmExcelExportDialogResultData = { isConfirmed: true, budgetType };
    this.dialogRef.close(dialogResult);
  }
}
