/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AcceptedReleaseRightDTO } from '../model/acceptedReleaseRightDTO';
import { ContainerReleaseRightsDTO } from '../model/containerReleaseRightsDTO';
import { DeclinedReleaseRightDTO } from '../model/declinedReleaseRightDTO';
import { OkResult } from '../model/okResult';
import { RevokedReleaseRightDTO } from '../model/revokedReleaseRightDTO';
import { TransferReleaseRightDTO } from '../model/transferReleaseRightDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ReleaseRightsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Accepting a release right on a container
     * 
     * @param operationalFileId 
     * @param containerId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public acceptReleaseRight(operationalFileId: number, containerId: number, body?: AcceptedReleaseRightDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OkResult>;
    public acceptReleaseRight(operationalFileId: number, containerId: number, body?: AcceptedReleaseRightDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OkResult>>;
    public acceptReleaseRight(operationalFileId: number, containerId: number, body?: AcceptedReleaseRightDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OkResult>>;
    public acceptReleaseRight(operationalFileId: number, containerId: number, body?: AcceptedReleaseRightDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling acceptReleaseRight.');
        }

        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling acceptReleaseRight.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<OkResult>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/${encodeURIComponent(String(containerId))}/release-rights/acceptances`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Declining a release right on a container
     * 
     * @param operationalFileId 
     * @param containerId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public declineReleaseRight(operationalFileId: number, containerId: number, body?: DeclinedReleaseRightDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OkResult>;
    public declineReleaseRight(operationalFileId: number, containerId: number, body?: DeclinedReleaseRightDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OkResult>>;
    public declineReleaseRight(operationalFileId: number, containerId: number, body?: DeclinedReleaseRightDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OkResult>>;
    public declineReleaseRight(operationalFileId: number, containerId: number, body?: DeclinedReleaseRightDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling declineReleaseRight.');
        }

        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling declineReleaseRight.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<OkResult>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/${encodeURIComponent(String(containerId))}/release-rights/declines`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get a container&#x27;s release right
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReleaseRight(id: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<ContainerReleaseRightsDTO>;
    public getReleaseRight(id: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<ContainerReleaseRightsDTO>>;
    public getReleaseRight(id: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<ContainerReleaseRightsDTO>>;
    public getReleaseRight(id: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getReleaseRight.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<ContainerReleaseRightsDTO>(`${this.basePath}/api/operational/operational-files/containers/release-rights/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get info about a container&#x27;s release right
     * 
     * @param containerId 
     * @param operationalFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReleaseRightInfo(containerId: number, operationalFileId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<ContainerReleaseRightsDTO>;
    public getReleaseRightInfo(containerId: number, operationalFileId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<ContainerReleaseRightsDTO>>;
    public getReleaseRightInfo(containerId: number, operationalFileId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<ContainerReleaseRightsDTO>>;
    public getReleaseRightInfo(containerId: number, operationalFileId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling getReleaseRightInfo.');
        }

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getReleaseRightInfo.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<ContainerReleaseRightsDTO>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/${encodeURIComponent(String(containerId))}/release-rights`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Revoking a release right on a container
     * 
     * @param operationalFileId 
     * @param containerId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public revokeReleaseRight(operationalFileId: number, containerId: number, body?: RevokedReleaseRightDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OkResult>;
    public revokeReleaseRight(operationalFileId: number, containerId: number, body?: RevokedReleaseRightDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OkResult>>;
    public revokeReleaseRight(operationalFileId: number, containerId: number, body?: RevokedReleaseRightDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OkResult>>;
    public revokeReleaseRight(operationalFileId: number, containerId: number, body?: RevokedReleaseRightDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling revokeReleaseRight.');
        }

        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling revokeReleaseRight.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<OkResult>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/${encodeURIComponent(String(containerId))}/release-rights/revokes`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Transfer a release right on a container to the next party
     * 
     * @param operationalFileId 
     * @param containerId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public transferReleaseRight(operationalFileId: number, containerId: number, body?: TransferReleaseRightDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OkResult>;
    public transferReleaseRight(operationalFileId: number, containerId: number, body?: TransferReleaseRightDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OkResult>>;
    public transferReleaseRight(operationalFileId: number, containerId: number, body?: TransferReleaseRightDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OkResult>>;
    public transferReleaseRight(operationalFileId: number, containerId: number, body?: TransferReleaseRightDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling transferReleaseRight.');
        }

        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling transferReleaseRight.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<OkResult>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/${encodeURIComponent(String(containerId))}/release-rights/transfers`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

}
