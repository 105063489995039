import {Component, Input} from "@angular/core";
import {TerminalDTO} from "../../../../services/swagger-gen/fordesk";

@Component({
  selector: 'terminal-label',
  templateUrl: './terminal-label.component.html'
})
export class TerminalLabelComponent {
  @Input()
  public item: TerminalDTO;

  constructor() { }
}
