import { Directive, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Directive()
export abstract class ComponentBase implements OnDestroy {
  public loading: boolean = false;

  protected unsubscribe: Subject<void> = new Subject();

  public ngOnDestroy(): REQUIRED_SUPER_NGONDESTROY {
    if (!this.unsubscribe) {
      return;
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();

    return new REQUIRED_SUPER_NGONDESTROY;
  }

  public unsubscribeAsObservable: Observable<void> = this.unsubscribe.asObservable();
}

// Makes calling super.ngOnDestroy() required from derived components to prevent incorrect unsubscribe subject behavior.
class REQUIRED_SUPER_NGONDESTROY { }