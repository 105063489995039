import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { OperationalFileStatus } from "../../enumerations/operational-file-status.enum";

@Injectable({ providedIn: "root" })
export class EditableOperationalFileService {

  private isEditable$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor() {
  }

  public getNext(operationalFileStatus: OperationalFileStatus): void {
    if (operationalFileStatus === OperationalFileStatus.Active) {
      this.isEditable$.next(true);
    } else {
      this.isEditable$.next(false);
    }
  }

  public isFileEditable(): Observable<boolean> {
    return this.isEditable$.asObservable();
  }
}
