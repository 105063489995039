import { Injectable } from "@angular/core";
import { DecimalPipe } from "@angular/common";

@Injectable()
export class DecimalFormatterService {

  private exchangeRateFormat: string = "1.2-8";
  private numberFormat: string = "1.2-2";
  private regexMax8Digits: RegExp = /^[0-9]+(\.[0-9]{1,8})?$/;

  constructor(private decimalPipe: DecimalPipe) { }

  public getNumberFormat() {
    return this.numberFormat;
  }

  public format(value: number): string {
    return this.decimalPipe.transform(value, this.numberFormat);
  }

  public getExchangeRateFormat(): string {
    return this.exchangeRateFormat;
  }

  public getRequiredExchangeRateRegex(): RegExp {
    return this.regexMax8Digits;
  }

  public getMaxDecimalsRegex(numberOfDecimals: number): RegExp {
    return new RegExp(`^[0-9]+(\\.[0-9]{1,${numberOfDecimals}})?$`);
  }
}
