import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Injector, Input } from "@angular/core";
import { ComponentBase } from "../../../../../core/components/abstractions/component-base";
import { from, Observable, of } from "rxjs";
import { NamedBlob } from "../../../../../core/models/files/named-blob";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { LoadingOverlayService } from "../../../../../core/services/loading-overlay/loading-overlay.service";
import { SafeUrl } from "@angular/platform-browser";
import { SafePipe } from "../../../../../core/pipes/safe.pipe";
import { ImageViewerConfig } from "ngx-image-viewer";
import UTIF from 'utif';
import UPNG from 'upng-js';
import { getFileNameWithoutExtension } from "../../../../../core/helpers/filename-helper";

@Component({
  selector: "image-viewer-dialog",
  templateUrl: "./image-viewer-dialog.component.html"
})

// Bug in ngx-image-viewer: Produces "Document not active"-error in chrome console (known problem, occurs in official ngx-image-viewer demo as well)
export class ImageViewerDialogComponent extends ComponentBase implements OnInit, OnDestroy {
  public showImage: boolean = false;
  public onClose: Function = null;
  public extension: string;
  public fileName: string;
  public images: SafeUrl[] = [];
  public imageViewerConfig: ImageViewerConfig = {
    btnIcons: {
      fullscreen: "fa fa-expand",
      rotateClockwise: "fa fa-redo",
      rotateCounterClockwise: "fa fa-undo",
      zoomIn: "fa fa-search-plus",
      zoomOut: "fa fa-search-minus"
    },
    wheelZoom: true
  } as ImageViewerConfig;
  @ViewChild("downloadLink", { static: false })
  private downloadLink: ElementRef;

  private fileSource: Observable<NamedBlob>;
  private url: string;

  @Input()
  public displayFoter: boolean = true;

  constructor(
    private injector: Injector,
    public overlayService: LoadingOverlayService,
    private safePipe: SafePipe
  ) {
    super();
  }

  public ngOnInit() { }

  public ngOnDestroy() {
    window.URL.revokeObjectURL(this.url);
    return super.ngOnDestroy();
  }

  public tryLoadImage(source: Observable<NamedBlob>, onComplete: Function) {
    this.fileSource = source;

    if (!source) {
      this.closeDialog();
      return;
    }

    source
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(res => {
          if (res.blob.type === 'image/tiff') {
            // The TIFF image format is not supported by ngx-image-viewer, so we will convert this image to PNG for preview.
            return this.convertTiffToPng(res);
          }

          return of(res);
        })
      )
      .subscribe({
        next: (res: NamedBlob) => {
          this.url = window.URL.createObjectURL(res.blob);
          this.images = res ? [this.safePipe.transform(this.url, "url")] : [];
          this.showImage = this.images && this.images.length > 0;
          this.fileName = res.fileName;

          if (onComplete) {
            onComplete();
          }

          this.removeHover("download-btn");
        },
        error: err => this.closeDialog()
      });
  }

  public cancel(): void {
    this.closeDialog();
  }

  public closeDialog(): void {
    if (this.onClose) {
      this.onClose();
    }
    else {
      let dialogRef = this.injector.get(NgbActiveModal);
      dialogRef.close();
    }
  }

  public download(): void {
    this.removeHover("download-btn");
    this.fileSource
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: res => {
          const url: string = window.URL.createObjectURL(res.blob);
          const link = this.downloadLink.nativeElement;
          link.href = url;
          link.download = res.fileName;
          link.click();
          window.URL.revokeObjectURL(url);
        },
        error: () => this.overlayService.stopLoading(),
        complete: () => this.overlayService.stopLoading()
      });
  }

  private removeHover(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.blur();
    }
  }

  private convertTiffToPng(tiffImageBlob: NamedBlob) {
    return from(tiffImageBlob.blob.arrayBuffer())
      .pipe(
        map(tiffImageBuffer => {
          const [ifd] = UTIF.decode(tiffImageBuffer);
          UTIF.decodeImage(tiffImageBuffer, ifd);
          const tiffImageRGBA8 = UTIF.toRGBA8(ifd);
          const pngImageBuffer = UPNG.encode([tiffImageRGBA8.buffer], ifd.width, ifd.height);
          const pngImageBlob: NamedBlob = new NamedBlob();
          pngImageBlob.blob = new Blob([pngImageBuffer], { type: "image/png" });
          pngImageBlob.fileName = getFileNameWithoutExtension(tiffImageBlob.fileName);
          return pngImageBlob;
        })
      );
  }
}
