import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild
} from "@angular/core";
import { Observable } from "rxjs";
import * as objectPath from "object-path";

@Component({
	selector: "m-portlet",
	templateUrl: "./portlet.component.html",
  styleUrls: ["./portlet.component.scss"],
	changeDetection: ChangeDetectionStrategy.Default
})
export class PortletComponent implements OnInit, AfterViewInit {

  public isHeaderFixed: boolean = false;

	@Input() public loading$: Observable<boolean>;
	@Input() public options: any;

	@ViewChild("mPortlet", { static: false }) public elPortlet: ElementRef;
	@ViewChild("mPortletHead", { static: false }) public elHead: ElementRef;
	@ViewChild("mPortletBody", { static: false }) public elBody: ElementRef;
	@ViewChild("mPortletFooter", { static: false }) public elFooter: ElementRef;
	@ViewChild("mPortletHeadTools", { static: false }) public elHeadTools: ElementRef;

	constructor() {
	}

	public ngAfterViewInit(): void {
		// hide portlet footer if no content
		if (this.elFooter && this.elFooter.nativeElement.children.length === 0) {
			this.elFooter.nativeElement.style.display = "none";
			this.elPortlet.nativeElement.classList.add("m-portlet--full-height");
		}
		// add portlet responsive mobile for sticky portlet
		if (objectPath.get(this.options, "enableSticky")) {
			this.elPortlet.nativeElement.classList.add("m-portlet--responsive-mobile");
		}
		// hide portlet header tools if no content
		if (this.elHeadTools && this.elHeadTools.nativeElement.children.length === 0) {
			this.elHeadTools.nativeElement.style.display = "none";
		}
	}

	public ngOnInit() { }

  @HostListener("window:scroll", [])
  public onWindowScroll() {
	  this.isHeaderFixed = document.body.scrollTop > 70 || document.documentElement.scrollTop > 70;
  }
}

