import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Subscription } from "rxjs";

@Directive({
  selector: "[typeAheadHelper]"
})
export class TypeAheadHelperDirective implements OnDestroy, OnChanges {
  @Input() public typeahead: Subject<string>;
  @Input() public formControl: UntypedFormControl;
  @Input() public maxLength: number = 3950;

  private subscriptions: Subscription[] = [];
  private line: string = "";
  private validationErrors: ValidationErrors;

  public readonly handleTypeEventRef: () => void = this.handleTypeEvent.bind(this);
  public setup: Subject<void> = new Subject<void>();
  public hasSetup: boolean = false;

  constructor(private host: NgSelectComponent, private elemRef: ElementRef, private translate: TranslateService) {
    const subscription = this.setup.subscribe(_ => this.setUp());
    this.subscriptions.push(subscription);
  }

  public ngOnChanges(_: SimpleChanges): void {
    if (!this.hasSetup && this.typeahead && this.formControl) {
      this.setup.next();
    }
  }

  public TypeAheadValidator(): ValidatorFn {
    return this.validate.bind(this);
  }

  private setUp() {
    this.hasSetup = true;
    this.host.hideSelected = true;

    this.formControl.setValidators(this.formControl.validator
      ? [this.TypeAheadValidator(), this.formControl.validator]
      : [this.TypeAheadValidator()]);

    const subscription = this.typeahead.subscribe(input => {
      this.line = input;
      this.manuallySetErrors(input);
    });

    this.subscriptions.push(subscription);

    //  If we had any result in the search, for example, we found a result for 3 letters,
    //  and then we deleted 1 character, and we have only 2 characters and the search for them is not performed,
    //  then the block with the text that you need to enter characters will appear only after a while,
    //   and so we catch this event and change the text
    //  Simple input events are processed in the method independently
    this.elemRef.nativeElement.addEventListener("DOMNodeInserted", this.handleTypeEventRef);
  }

  private validate() {
    if (this.line && this.line.length > this.maxLength) {
      return { "maxLength": true };
    }
    return null;
  }

  private manuallySetErrors(input: string) {
    this.validationErrors = this.formControl.value ? null : this.formControl.validator?.({} as AbstractControl);

    // needs to stay because of address.component
    this.manuallySetMaxLengthError(input);

    this.formControl.setErrors(this.validationErrors);
  }

  private manuallySetMaxLengthError(input: string) {
    if (input && input.length > this.maxLength) {
      this.validationErrors = { "maxLength": true, ...this.validationErrors };
    }
  }

  private resetInputText() {
    this.typeahead.next(null);
  }

  @HostListener("blur")
  public Blur() {
    // adding timeout to avoid submit
    setTimeout(() => {
      this.resetInputText();
    }, 100);
  }
  @HostListener("change")
  public Change() {
    this.resetInputText();
  }

  @HostListener("input")
  public Input() {
    this.handleTypeEvent();
  }

  public handleTypeEvent() {
    const elemRef = this.elemRef.nativeElement as HTMLElement;
    const data = (elemRef.querySelector("div.ng-input > input[type=text]") as any)?.value;

    const placeholder = elemRef.getElementsByClassName("ng-placeholder")[0] as HTMLElement;
    const textElement = (this.elemRef.nativeElement as HTMLElement).getElementsByClassName("ng-option ng-option-disabled")[0] as HTMLElement;

    if (placeholder && placeholder.innerHTML === this.translate.instant("TYPEAHEAD_3_MIN") && !data || (data && data.length < 3)) {
      if (textElement && textElement.innerHTML !== this.translate.instant("TYPE_TO_SEARCH")) {
        textElement.innerHTML = this.translate.instant("TYPE_TO_SEARCH");
      }
    }
    else if (placeholder && placeholder.innerHTML === this.translate.instant("TYPEAHEAD_3_MIN") && !data || (data && data.length >= 3)) {
      if (textElement && textElement.innerHTML === this.translate.instant("TYPE_TO_SEARCH")) {
        textElement.innerHTML = this.translate.instant("NO_ITEMS_FOUND");
      }
    }
  }

  public ngOnDestroy(): void {
    this.elemRef?.nativeElement?.removeEventListener("DOMNodeInserted", this.handleTypeEventRef);
    this.subscriptions.forEach(x => x.unsubscribe);
  }
}
