import { Observable } from "rxjs";

export class UploadDocumentsDialog {
  public allowedFileTypes: string;
  public uploadMultiple: boolean;

  public title: string;
  public extraMessage: string;

}
