import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";
import { OperationalContainerIdentifierDTO, OperationalFileContainerService } from "../services/swagger-gen/fordesk";

@Injectable({ providedIn: "root" })
export class OperationalFileContainerIdentifiersHelper {
  constructor() { }

  public getOperationalFileContainerIdentifiers(service: OperationalFileContainerService, input: Subject<string>, operatioanlFileId: number, onStart: Function, onComplete: Function): Observable<OperationalContainerIdentifierDTO[]> {

    return input.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string) => {
        if (term && term.length > 2) {
          if (onStart) {
            onStart();
          }

          return service.searchOperationalContainerIdentifiers(operatioanlFileId, term)
            .pipe(
              map((res) => {
                return res ? res : [];
              }),
              catchError(() => of([])), // empty list on error
              tap(() => onComplete?.())
            );
        } else {
          onComplete?.();
          return of([]);
        }
      })
    );
  }

  public getContainerIdentifiers(service: OperationalFileContainerService, input: Subject<string>, onStart: Function, onComplete: Function): Observable<OperationalContainerIdentifierDTO[]> {
    return input.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string) => {
        if (term && term.length > 2) {
          if (onStart) {
            onStart();
          }

          return service.searchContainerIdentifiers(term)
            .pipe(
              map((res) => {
                return res ? res : [];
              }),
              catchError(() => of([])), // empty list on error
              tap(() => onComplete?.())
            );
        } else {
          onComplete?.();
          return of([]);
        }
      })
    );
  }
}
