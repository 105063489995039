export const export_finance_data_locale = {
  FROM: "From",
  TO: "To",
  EXPORT_SUCCESFUL: "Export was executed.",
  NOTHING_TO_EXPORT: "There is nothing to export for the given period/accounting numbers or everything has already been exported.",
  EXPORT_FAILED: "Export failed.",
  FILE_TYPE: "File type",
  Customers: "Customers",
  Suppliers: "Suppliers",
  OutgoingInvoices: "Outgoing invoices",
  IncomingInvoices: "Incoming invoices",
  ACCOUNTING_NUMBERS: "Accounting numbers",
  INCLUDE_ALREADY_EXPORTED_INVOICES_CHECKBOX: "Include invoices that have already been exported",
  INCLUDE_ALREADY_EXPORTED_INVOICES_WARNING_QUESTION: "By checking this option, selected invoices will be exported even if they have already been exported previously. This should only be used if something went wrong with the original invoice as this can create duplicates in the accounting software. Are you sure you want to enable this option?",
  ACTIONS: {
    CLOSE_PERIOD: "close accounting period"
  },
  ACCOUNTING_PERIOD_CLOSED: "Accounting period closed.",
  CLOSE_ACCOUNTING_PERIOD_CONFIRM: "Would you like to close this accounting period?",
  ACCOUNTING_PERIOD: "Accounting period",
  ACCOUNTING_PERIOD_END_DATE: "Accounting period end date",
  ACCOUNTING_PERIOD_LIST: {
    TITLE: "Closed accounting periods",
    START_DATE: "Start date",
    END_DATE: "End date"
  }
};
