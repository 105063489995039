import { Component, OnInit, Input } from "@angular/core";
import { QuoteStatus } from "../../../../core/enumerations/quote-status.enum";

@Component({
  selector: "quote-status-badge",
  templateUrl: "./quote-status-badge.component.html",
  styleUrls: ["./quote-status-badge.component.scss"]
})
export class QuoteStatusBadgeComponent implements OnInit {

  constructor() { }

  @Input() public quoteFileStatus: QuoteStatus;

  // tslint:disable-next-line: typedef
  public QuoteFileStatus = QuoteStatus;

  public ngOnInit() {
  }

}
