import { Component, HostBinding, Input, AfterViewInit, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { EMPTY, interval } from "rxjs";
import { catchError, concatMap, distinctUntilChanged, mergeMap, takeUntil } from "rxjs/operators";
import { environment } from "../../../../../../environments/environment";
import { ComponentBase } from "../../../../../core/components/abstractions/component-base";
import { NotificationEventsService } from "../../../../../core/services/notifications/notification-events.service";
import { NotificationsCountDTO, NotificationsService } from "../../../../../core/services/swagger-gen/fordesk";

@Component({
  selector: "m-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"]
})
export class NotificationComponent extends ComponentBase implements OnInit, AfterViewInit {
  @HostBinding("class")
  // tslint:disable-next-line:max-line-length
  public classes: string = "m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width";

  @HostBinding("attr.m-dropdown-toggle") public attrDropdownToggle: string = "click";
  @HostBinding("attr.m-dropdown-persistent") public attrDropdownPersisten: string = "true";

  @Input() public animateShake: any;

  public newNotification: boolean = false;
  public notificationCount: number = 0;

  private refreshRate: number = 60000; // Fallback to one minute if no config is found

  constructor(
    private notificationsService: NotificationsService,
    private notificationEventsService: NotificationEventsService,
    private router: Router) {
    super();
    // animate icon shake and dot blink
    setInterval(() => {
      this.animateShake = this.newNotification ? "m-animate-shake" : "";
    }, 3000);
    setInterval(() => { this.animateShake = ""; this.newNotification = false; }, 6000);
  }

  public ngOnInit(): void {
    this.fetchInitialNotificationCount();
  }

  public ngAfterViewInit(): void {
    const refreshRate: number = environment.notificationsRefreshRate;
    if (refreshRate && !isNaN(refreshRate)) {
      this.refreshRate = +refreshRate;
    } else {
      const defaultRefreshRate: number = 60000;
      this.refreshRate = defaultRefreshRate;
    }

    this.subscribeToArchiveEvent();
    this.fetchNotificationCountOnSchedule();
  }

  private subscribeToArchiveEvent(): void {
    this.notificationEventsService
      .hasArchived()
      .pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged(),
        concatMap(() => this.notificationsService.getNotificationsCount({ disableLoader: true }))
      ).subscribe((notificationsCount: NotificationsCountDTO) => {
        this.newNotification = notificationsCount.nrOfActive > this.notificationCount;
        this.notificationCount = notificationsCount.nrOfActive;
      });
  }

  private fetchInitialNotificationCount(): void {
    this.notificationsService
      .getNotificationsCount({ disableLoader: true })
      .pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged()
      )
      .subscribe((notificationsCount: NotificationsCountDTO) => {
        this.newNotification = notificationsCount.nrOfActive > this.notificationCount;
        this.notificationCount = notificationsCount.nrOfActive;
      });
  }

  private fetchNotificationCountOnSchedule(): void {
    interval(this.refreshRate)
      .pipe(
        mergeMap(() => this.notificationsService
          .getNotificationsCount({ disableLoader: true })
          .pipe(catchError(() => EMPTY),
            takeUntil(this.unsubscribe))),
        catchError(() => EMPTY),
        takeUntil(this.unsubscribe)
      ).subscribe((notificationsCount: NotificationsCountDTO) => {
        if (notificationsCount != null) {
          this.newNotification = notificationsCount.nrOfActive > this.notificationCount;
          this.notificationCount = notificationsCount.nrOfActive;
        }
      });
  }

  public navigateToNotifications(): void {
    this.router.navigateByUrl("notifications/list");
  }
}
