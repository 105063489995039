import {Component, Input} from "@angular/core";
import {DepotDTO} from "../../../../services/swagger-gen/fordesk";

@Component({
  selector: 'depot-label',
  templateUrl: './depot-label.component.html'
})
export class DepotLabelComponent  {

  @Input()
  public item: DepotDTO;

  constructor() { }
}
