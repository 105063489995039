export const of_containers_displayed_columns_locale = {
  containerNumber: "Container number",
  containerSize: "Size",
  noOfGoods: "# of goods",
  noOfColli: "# of colli",
  cargoWeight: "Cargo weight",
  containerTare: "Tare container",
  vgmWeight: "VGM weight",
  vgmStatus: "VGM Status",
  vgmTimestamp: "VGM Timestamp",
  sealNumbers: "Seal Numbers",
  releaseRightStatus: "Release right"
};
