import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { UtilsService } from "./utils.service";
import { ApiConfigService } from "./api-config.service";
import { NamedBlob } from "../models/files/named-blob";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class DocumentEditorService {
  constructor(protected http: HttpClient,
    protected util: UtilsService,
    protected apiConfig: ApiConfigService) {
  }

  public convertToSftd(fileName: string, document: Blob): Observable<string> {
    const url: string = this.apiConfig.createApiUrl(`/document-editor/convert-to-sfdt`);
    const params = this.util.urlParams({ fileName: fileName });
    const formData = new FormData();
    formData.append(fileName, document);
    return this.http.post<string>(url, formData, { params: params });
  }

  public downloadDocument(documentTemplateId: number, fileName: string, document: Blob): Observable<NamedBlob> {
    const url: string = this.apiConfig.createApiUrl(`/document-editor/convert-to-download`);
    const formData: FormData = new FormData();
    formData.append(fileName, document, fileName);

    if (documentTemplateId) {
      formData.append("documentTemplateId", documentTemplateId.toString());
    }

    return this.http.post<Blob>(url, formData, { responseType: "blob" as "json" })
      .pipe(map(res => {
        if (!res) {
          return null;
        }

        return <NamedBlob>{
          blob: res,
          fileName: fileName
        };
      }));
  }
}
