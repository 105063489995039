export const of_documents_locale = {
  ACTIONS: {
    CREATE_DOCUMENT: "create document",
    GENERATE: "Generate"
  },
  DOC_TEMPLATE: "Document template",
  PARTY: "Party",
  CUSTOMS_AGENT_PARTY: "Customs agent party",
  GOODS: "Goods",
  CONTAINERS: "Containers",
  ADD_NEW_DOCUMENT: "Add new document",
  TRANSPORT: "Transport",
  SHIPPING_INSTRUCTIONS: "Shipping instructions",
  BOOKINGS: "Bookings",
  BL_NUMBERS: "BL numbers",
  TRANSPORT_STOP: "Transport Stop",
  CUSTOMER: "Customer",
  CONTACT_PERSON: "Contact Person",
  LANGUAGE: "Language",
};
