export const of_courier_instructions_locale = {
  SEARCH_COURIER_INSTRUCTIONS: "Search courier instructions",
  DATE: "Date",
  THEIR_REFERENCE: "Their reference",
  YOUR_REFERENCE: "Your reference",
  DESTINATION: "Destination",
  SIGNATURE_REQUIREMENT: "Signature requirement",
  DESTINATIONS: "Destinations",
  INSTRUCTION_TYPE: "Instruction type",
  STATUS: "Status",
  CANCEL_MESSAGE: "Please contact the courier directly via phone or email to cancel the instructions.",
  CANCEL_TITLE: "Cancel courier instructions",
  INVOICE_REFERENCE: "Invoice reference",
  INSTRUCTION_DATE: "Instruction date",
  REFERENCE: "Reference",
  PICKUP_LOCATION: "Print",
  AT_CUSTOMER: "The documents will be collected from you by our couriers",
  PRINTED_BY_COURIER: "The attachments will be printed immediately by us",
  SENT_SUCCESSFULLY: "The courier instructions have been sent successfully.",
  AMOUNT: "Amount",
  PORT: "Port",
  VESSEL: "Vessel",
  CONTAINER_NUMBERS: "Container numbers",
  BL_NUMBER: "BL number",
  BL_NUMBERS: "BL numbers",
  REMARKS: "Remarks",
  MORNING: "Morning (till 12 PM)",
  AFTERNOON: "Afternoon (after 12 PM)",
  EXPRESS_PROCESSING: "Express processing",
  SIGNATURE_REQUIRED: "Signature required",
  DEADLINE: "Deadline",
  PAYMENT_REQUIRED: "Payment required",
  SELECT_DESTINATIONS: "Select destination(s)",
  SELECT_DESTINATION: "Select destination",
  SELECT_DOCUMENTS: "Select document(s)",
  CONTACT: "Contact",
  TEL: "Tel.",
  CHEQUE_NUMBER: "Cheque number",
  AMOUNT_OF_BLNUMBERS_AND_CONTAINERS_MORE_THAN_10: "Total amount of BL numbers and containers cannot be more than 10.",
  PRICE: "Price",
  EUR: "EUR",
  ORDER_DOCUMENT: "Order document",
  ORDER_TRACKING: "Order tracking",
  NOT_ALLOWED_INSTRUCTION_DATE: "Instruction date cannot be in the past.",
  MESSAGES: {
    STATUS_ACCEPTED: "The courier instructions were successfully accepted.",
    STATUS_REFUSED: "The courier instructions were refused.",
    STATUS_AWAITING: "The courier instructions are awaiting reply.",
    NOT_YET_SENT: "The courier instructions have not been sent yet.",
  },
  SIGNATURE_REQUIREMENTS: {
    NEEN: "No",
    JAOPOPDRACHT: "Yes, on instruction",
    JAOPCOPY: "Yes, on copy",
    JAOPOPDRACHTENCOPY: "Yes, on instruction AND copy"
  },
  INSTRUCTION_TYPES: {
    AFGEVENATTESTCERTIFICAAT: "Hand over certificate",
    LATENAPPROUVERENBL: "Have B/L approved",
    ANTWERPENSTADSKOERIEROPHALENENLEVEREN: "Antwerp city courier: pick up and deliver",
    AFHALENWAIVER: "Collect waiver",
    AFHALENLAATVOLGEN: "Collect release note",
    AFHALENCHEQUE: "Collect cheque",
    AFHALENVERZEKERINGSPOLIS: "Collect insurance policy",
    AFHALENBL: "Collect B/L",
    AFHALENATTESTCERTIFICAAT: "Collect certificate",
    AFGEVENPERMIS: "Hand over permit",
    AFGEVENLAATVOLGEN: "Hand over release note",
    AFGEVENBL: "Hand over B/L",
    AFHALENBLANCOBL: "Collect blank B/L"
  }
};
