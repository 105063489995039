import { Injectable } from "@angular/core";
import { ApiConfigService } from "../api-config.service";
import { UtilsService } from "../utils.service";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { SendEmailDto } from "../../models/utilities/send-email-dto.model";
import { Response } from "../../models/abstractions/response.model";
import { NamedBlob } from "../../models/files/named-blob";
import { UblConfiguration } from "../../models/dialogs/ubl-configuration";

@Injectable({ providedIn: "root" })
export class MailService {
  private resource: string = "mails";

  constructor(
    protected http: HttpClient,
    protected apiConfig: ApiConfigService,
    protected util: UtilsService
  ) { }


  public sendEmail(emailDto: SendEmailDto, namedAttachments?: NamedBlob[], ublConfig?: UblConfiguration, defaultBCCMails?: string[]): Observable<Response> {
    const url: string = this.apiConfig.createUtilitiesApiUrl(this.resource);
    const formData = new FormData();

    namedAttachments.forEach(file => {
      formData.append("attachments", file.blob, file.fileName);
    });
    if (defaultBCCMails) {
      for (var i = 0; i < defaultBCCMails.length; i++) {
        formData.append(`defaultBCCMails[${i}]`, defaultBCCMails[i]);
      }
    }

    Object.keys(emailDto).forEach(key => {
      const value = emailDto[key];
      if (value instanceof Array) {
        value.forEach(e => {
          formData.append(key, e);
        });
      } else {
        formData.set(key, value);
      }
    });

    if (ublConfig && ublConfig.isSendUblEnabled) {
      Object.keys(ublConfig).forEach(key => {
        const value = ublConfig[key];
        if (value instanceof Array) {
          value.forEach(e => {
            formData.append(key, e);
          });
        } else {
          formData.set(key, value);
        }
      });
    }

    return this.http.post<Response>(url, formData);
  }
}
