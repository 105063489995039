/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TransportStopLocationIdentifierDTO } from './transportStopLocationIdentifierDTO';

export interface OperationalFileTransportStopDetailsDTO { 
    addressLine1?: string;
    addressLine2?: string;
    stateProvinceRegion?: string;
    placeId?: number;
    countryId?: number;
    zipCode?: string;
    transportStopTypeId: number;
    timestamp?: Date;
    reference?: string;
    transportStopLocationId?: TransportStopLocationIdentifierDTO;
    portId?: number;
    remarks?: string;
    standardTextIds?: Array<number>;
    wizardType?: OperationalFileTransportStopDetailsDTO.WizardTypeEnum;
    stopIndex?: number;
    goodsIds?: Array<number>;
}
export namespace OperationalFileTransportStopDetailsDTO {
    export type WizardTypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const WizardTypeEnum = {
        NUMBER_0: 0 as WizardTypeEnum,
        NUMBER_1: 1 as WizardTypeEnum,
        NUMBER_2: 2 as WizardTypeEnum,
        NUMBER_3: 3 as WizardTypeEnum,
        NUMBER_4: 4 as WizardTypeEnum,
        NUMBER_5: 5 as WizardTypeEnum,
        NUMBER_6: 6 as WizardTypeEnum
    };
}