import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map, takeUntil, tap } from "rxjs/operators";
import { ComponentBase } from "../../../core/components/abstractions/component-base";

interface IdentifiableString {
  id: number;
  text: string;
}

@Component({
  selector: "text-to-label",
  templateUrl: "./text-to-label.component.html",
  styleUrls: ["./text-to-label.component.scss"]
})
export class TextToLabelComponent extends ComponentBase implements AfterViewInit {
  @Input() public control: UntypedFormControl;
  public lines: IdentifiableString[] = [];
  public showAll: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  public ngAfterViewInit(): void {
    this.lines = this.mapToIdentifiableStrings(this.control.value);

    this.cdr.detectChanges();

    this.control.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        debounceTime(200),
        distinctUntilChanged(),
        map(this.mapToIdentifiableStrings.bind(this)))
      .subscribe((lines: IdentifiableString[]) => {
        this.lines = lines ? lines : [];
        if (this.lines?.length < 4) {
          this.showAll = false;
        }
      });
  }

  public get disabled(): boolean {
    return this.control.disabled;
  }

  public clear(lineStr: IdentifiableString | null): void {
    if (this.disabled) {
      return;
    }

    this.control.markAsDirty();
    this.control.markAsTouched()

    const value = [...this.lines]?.filter(x => x.id !== lineStr?.id)
      .map(x => x.text).join("\n");

    this.control.setValue(value);
    this.control.markAsDirty()
    this.control.markAsTouched()
  }

  public getLines() {
    const lines = this.lines || [];
    return this.showAll ? lines.slice(0, lines.length - 1) : lines.slice(0, 3);
  }
  public toggleShowAll(): void {
    this.showAll = !this.showAll;
  }

  public getLastLine() {
    const lines = this.lines || [];
    return lines[lines.length - 1];
  }


  private mapToIdentifiableStrings(input: string): IdentifiableString[] {
    const arr = input.split("\n");
    let i = 0;
    return arr
      .filter((x: string) => x !== "")
      .map((x: string) => ({ id: ++i, text: x }));
  }
}
