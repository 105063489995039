/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OperationalFileOverviewDTO { 
    operationalFileId?: number;
    operationalFileNumber?: string;
    operationalFileDate?: Date;
    userName?: string;
    department?: string;
    currency?: string;
    exchangeRate?: number;
    invoiceCustomer?: string;
    invoiceCustomerNumber?: string;
    customer?: string;
    customerNumber?: string;
    customerReference?: string;
    bookingReference?: string;
    operationalFileType?: string;
    shipmentType?: string;
    transportType?: string;
    incoTerms?: string;
    carrier?: string;
    airFreight?: string;
    transporter?: string;
    vessel?: string;
    voyage?: string;
    flightNr?: string;
    truckPlate?: string;
    eta?: Date;
    etd?: Date;
    billOfLadingNr?: string;
    airwayBillNr?: string;
    cmrNr?: string;
    portOfLoading?: string;
    portOfDischarge?: string;
    placeOfReceipt?: string;
    placeOfDestination?: string;
    numberOfContainers?: number;
    numberOfTEU?: number;
    status?: OperationalFileOverviewDTO.StatusEnum;
}
export namespace OperationalFileOverviewDTO {
    export type StatusEnum = 0 | 1 | 2;
    export const StatusEnum = {
        NUMBER_0: 0 as StatusEnum,
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum
    };
}