import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from "@angular/core";
import {
  FoundGridLayoutDTO,
  GetAllResponseGridLayoutLookupDTO,
  GridLayoutLookupDTO,
  GridLayoutsService
} from "../../../services/swagger-gen/fordesk";
import { switchMap, takeUntil } from "rxjs/operators";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GridType } from "../../../types/grid-type.type";
import { GridLayoutCacheService } from "../../../services/utilities/grid-layout-cache.service";
import { Subject } from "rxjs";
import { ConfirmDialog } from "../../../../content/dialogs/confirm/confirm.component";
import { ConfirmDialogData, ConfirmDialogResultData } from "../../../models/dialogs/confirm-dialog-data";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "../../../../content/layout/toaster/toaster.service";
import UpsertGridDialogData from "../../../models/utilities/grid-layouts/add-grid-dialog-data.model";
import { ManageGridLayoutsDialogData } from "../../../models/utilities/grid-layouts/manage-grid-layout-dialog-data.model";
import { AddGridLayoutComponent } from "../add-grid-layout/add-grid-layout.component";
import { GridLayoutCommunicationService } from "../../../services/grid/grid-layout-communication.service";
import { GridLayoutUpserted } from "../../../models/utilities/grid-layouts/grid-layout-upserted.model";

@Component({
  selector: "manage-grid-layouts",
  templateUrl: "./manage-grid-layouts.component.html",
  styleUrls: ["./manage-grid-layouts.component.scss"]
})
export class ManageGridLayoutsComponent implements OnInit, OnDestroy {

  private unsubscribe: Subject<void> = new Subject();

  public hasAdminRole: boolean = false;
  public sharedViews: GridLayoutLookupDTO[];
  public personalViews: GridLayoutLookupDTO[];

  constructor(@Inject(MAT_DIALOG_DATA) private data: ManageGridLayoutsDialogData,
    private dialogRef: MatDialogRef<ManageGridLayoutsComponent>,
    private dialog: MatDialog,
    private toaster: ToasterService,
    private translate: TranslateService,
    private gridLayoutCommunicationService: GridLayoutCommunicationService,
    private gridLayoutCacheService: GridLayoutCacheService,
    private gridLayoutService: GridLayoutsService) {
  }

  public ngOnInit(): void {
    this.hasAdminRole = this.data.hasAdminRole;

    this.refreshViews();
  }

  public ngOnDestroy(): void {
    if (!this.unsubscribe || !this.unsubscribe.observers || this.unsubscribe.observers.length <= 0) {
      return;
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public removeView(dto: GridLayoutLookupDTO): void {
    if (!dto) {
      return;
    }

    const dialogData: ConfirmDialogData = {
      confirmationText: this.translate.instant("MESSAGES.CONFIRM_TO_CONTINUE"),
      dialogTitle: this.translate.instant("MESSAGES.WARNING")
    };

    this.dialog
      .open(ConfirmDialog, { disableClose: true, data: dialogData })
      .afterClosed()
      .subscribe((data: ConfirmDialogResultData) => {
        const isConfirmed = data && data.isConfirmed;

        if (isConfirmed) {
          this.gridLayoutService._delete(dto.id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(_ => {
              this.toaster.success(this.translate.instant("MESSAGES.DELETE_CONFIRMED_SINGULAR"));
              this.refreshViews();

              this.gridLayoutCommunicationService.delete(dto);
            });
        }
      });
  }

  public editView(dto: GridLayoutLookupDTO): void {
    if (!dto) {
      return;
    }

    const dialogData: UpsertGridDialogData = {
      id: dto.id,
      name: dto.name,
      isShared: dto.shared,
      gridType: this.data.gridType,
      hasAdminRole: this.data.hasAdminRole,
      isLoggedInUserCreator: dto.isLoggedInUserCreator
    };

    this.dialog
      .open(AddGridLayoutComponent, { disableClose: true, data: dialogData })
      .afterClosed()
      .subscribe((editedView: GridLayoutUpserted) => {
        if (editedView) {
          this.toaster.success(this.translate.instant("MESSAGES.UPDATE_CONFIRMED_SINGULAR"));
          this.refreshViews();

          this.gridLayoutCommunicationService.edit(editedView);
        }
      });

  }

  public cancel(): void {
    this.dialogRef.close(null);
  }

  private refreshViews(): void {
    const gridType = this.gridLayoutCacheService.mapGridTypeToEnum(this.data.gridType);

    this.gridLayoutService.getAllGridLayoutsByType(gridType)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ results }: GetAllResponseGridLayoutLookupDTO) => {
        this.sharedViews = results.filter(x => x.shared);
        this.personalViews = results.filter(x => !x.shared);
      });
  }

  public sharedViewsExist(): boolean {
    return this.sharedViews?.length > 0;
  }

  public personalViewsExist(): boolean {
    return this.personalViews?.length > 0;
  }
}
