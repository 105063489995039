import { Injectable } from "@angular/core";
import { NgbDateStruct, NgbDateNativeAdapter } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";

@Injectable({ providedIn: "root" })
export class NgbDateHelper {
  constructor(public nativeAdapter: NgbDateNativeAdapter) { }

  public fromModel(date: Date): NgbDateStruct {
    //return this.nativeAdapter.fromModel(date);

    // TODO commented line above didn't work properly (try using 31/01/2020 and 29/02/2020)
    // Update with new way of handling dates (see @Mo)
    return { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() } as NgbDateStruct;
  }

  public toModel(date: NgbDateStruct): Date {
    // L stands for only date
    // https://momentjs.com/
    // >> Multiple Locale Support
    // return moment(`${date.month}-${date.day + 1}-${date.year}`, "L").toDate();

    // TODO commented line above didn't work properly (try using 31/01/2020 and 29/02/2020)
    // Update with new way of handling dates (see @Mo)
    return moment.utc(`${date.month}-${date.day}-${date.year}`, "L").local().toDate();
  }

  public isBetween(date: any, minDate: any, maxDate: any) {
    return !this.isSmallerThan(date, minDate) && !this.isGreaterThan(date, maxDate);
  }

  private isSmallerThan(date: any, minDate: any) {
    if (date) {
      if (date.year < minDate.year) {
        return true;
      } else if (date.year === minDate.year && date.month < minDate.month) {
        return true;
      } else if (date.month === minDate.month && date.day < minDate.day) {
        return true;
      }
      return false;
    }

    return false;
  }

  private isGreaterThan(date: any, maxDate: any) {
    if (date) {
      if (date.year > maxDate.year) {
        return true;
      } else if (date.year === maxDate.year && date.month > maxDate.month) {
        return true;
      } else if (date.month === maxDate.month && date.day > maxDate.day) {
        return true;
      }
      return false;
    }

    return true;
  }
}