export const of_booking_locale = {
  TITLE: "Booking",
  TYPE_OF_BOOKING: "Type of booking",
  SELECT_TYPE_OF_BOOKING: "Select type of booking",
  LOADS: "Loads",
  BOOKING_SEND: "The booking has been sent successfully.",
  BOOKING_CANCELLED: "The booking has been cancelled successfully.",
  CHOOSE_TYPE: "Please choose the type of booking:",
  CHOOSE_TYPE_TITLE: "Booking type",
  FREIGHT_PAYABLE_AT: "Freight payable at",
  STATUS: "Status",
  STATUS_ACCEPTED: "The booking was successfully accepted on {{timestamp}}.",
  STATUS_REFUSED: "The booking was refused on {{timestamp}}.",
  STATUS_CANCELLED: "The booking was cancelled on {{timestamp}}.",
  STATUS_AWAITING: "The booking is awaiting reply since {{timestamp}}.",
  NOT_YET_SENT: "The booking has not been sent yet.",
  DECLINED: "The booking has been declined by the carrier.",
  CONFIRMED: "The booking has been confirmed by the carrier.",
  PENDING: "The booking is pending confirmation by the carrier.",
  CONDITIONALLY_ACCEPTED: "The booking has been accepted by the carrier, under conditions.",
  REPLACED: "The booking has been successfully replaced.",
  HAULAGE_ARRANGEMENT: "Haulage arrangement",
  CONTAINERS: "Containers",
  CONTAINERS_NOT_FOUND: "No containers were found for your query. To add them, go to the 'Containers' tab.",
  CONTAINER_REQUIRED: "At least one container is required.",
  GOODS: "Goods",
  GOODS_NOT_FOUND: "The selected containers do not contain such goods.",
  GOOD_REQUIRED: "At least one good is required.",
  CHARGE_TYPE: "Charge type",
  CHARGE_PAYMENT_TYPE: "Charge payment type",
  LINKED_CHARGE_CATEGORY_PARTIES:"Linked charge category parties",
  IMPORT: "import",
  EXPORT: "export",
  IMPORT_POD: "import (PoD)",
  EXPORT_POL: "export (PoL)",
  ERRORS: {
    NO_TEMPLATE_AVAILABLE: "There is no document template available for the given operational file type or shipment type."
  },
  CARRIER_AMS_FILING: "Carrier AMS filing?",
  CARRIER_AMS_FILING_COLUMN: "Carrier AMS filing",
  ID: "Booking ID",
  CONTRACT_NR: "Contract nr.",
  FREIGHT_TARIFF_NR: "Freight tariff nr.",
  BOOKING_OFFICE: "Booking office",
  CONFIRM_CANCEL: {
    TITLE: "Confirm cancel",
    CONFIRM_MESSAGE: "Are you sure you want to cancel this booking? This cannot be undone."
  },
  PRE_VALIDATION_DIALOG: {
    MESSAGE: "We've found that the following values are not applicable for the \"send\" operation. " +
      "That's why we have to cut them to the permitted length:" +
      "<br /><br />{{correctedProperties}}<br /><br />" +
      "If you agree with this - press \"ok\", otherwise press \"cancel\" and change the value manually."
  },
  CHANGED_DIALOG: {
    HAULAGE_ARRANGEMENT: "Haulage arrangement was prefilled. Please save to not lose the data."
  },
  CONTAINER_GOODS: {
    OUTER_PACKAGE_TYPE_CODE_DESCRIPTION: "Outer package type code - description",
    GOODS_DESCRIPTION: "Goods description",
    NUMBER_OF_COLLIS: "Number of collis",
    GROSS_WEIGHT: "Gross weight",
    CONTAINER_NUMBER: "Container number",
    SHOW_CONTAINER_GOODS: "Show selected container goods details",
    HIDE_CONTAINER_GOODS: "Hide selected container goods details"
  }
};
