import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmDialog } from "../confirm/confirm.component";
import { Inject, OnDestroy } from "@angular/core";
import { ConfirmDialogData, ConfirmDialogResultData } from "../../../core/models/dialogs/confirm-dialog-data";
import { Subject } from "rxjs";

export abstract class ConfirmBase {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) { }

  protected unsubscribe: Subject<void> = new Subject();

  public cancel() {
    const dialogResult: ConfirmDialogResultData = { isConfirmed: false };
    this.dialogRef.close(dialogResult);
  }

  public confirm() {
    const dialogResult: ConfirmDialogResultData = { isConfirmed: true };
    this.dialogRef.close(dialogResult);
  }
}
