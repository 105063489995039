import { ConfirmDialogData } from "../models/dialogs/confirm-dialog-data";
import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import { DialogResult } from "../enumerations/dialog-result";

/**
 * Provides functionality to build user feedback messages.
 */

@Injectable({ providedIn: "root" })
export class MessageHelper {
  constructor(private translateService: TranslateService) { }

  /**
   * Builds a ConfirmDialogData instance containing the correct confirmation text and title based on the amount of selected items.
   * @param selectedItems The items that have been selected by the user.
   */
  public getIdsArenotKnownMessage(parentEntity: string, entity: string): string {
    return this.translateService.instant("MESSAGES.CANCEL_IDS_ARE_NOT_KNOWN", {
      entity: entity,
      parentEntity: parentEntity
    });
  }

  /**
  * Builds a ConfirmDialogData instance containing the correct confirmation text and title based on the amount of selected items.
  * @param selectedItems The items that have been selected by the user.
  */
  public getConfirmUpdateData(selectedItems: any[]): ConfirmDialogData {
    return this.getConfirmData(selectedItems, "UPDATE");
  }

  /**
   * Builds a ConfirmDialogData instance containing the correct confirmation text and title based on the amount of selected items.
   * @param selectedItems The items that have been selected by the user.
   */
  public getConfirmDeleteData(selectedItems: any[]): ConfirmDialogData {
    return this.getConfirmData(selectedItems, "DELETE");
  }

  /**
   * Builds a ConfirmDialogData instance containing the correct confirmation text and title based on the amount of selected items.
   * @param selectedItems The items that have been selected by the user.
   */
  public getConfirmCancelData(selectedItems: any[]): ConfirmDialogData {
    return this.getConfirmData(selectedItems, "CANCEL");
  }

  /**
   * Returns the correct message based on the number of selected items when they have been successfully deleted.
   * @param selectedItems The items that have been selected by the user.
   */
  public getDeleteConfirmedText(selectedItems: any[]): string {
    return this.getConfirmedText(selectedItems, "DELETE");
  }

  /**
   * Returns a generic message based on the provided dialog result.
   * @param dialogResult The dialog's result.
   */
  public getGenericDialogResultMessage(dialogResult: DialogResult): string {
    if (dialogResult === DialogResult.Success) {
      return this.translateService.instant("MESSAGES.CHANGES_SAVED");
    } else if (dialogResult === DialogResult.Error) {
      return this.translateService.instant("MESSAGES.GENERAL_ERROR");
    }

    return null;
  }

  /**
   * Returns the correct message containing the name of the item added, based on the dialog result.
   * @param dialogResult The dialog's result.
   * @param name The name of the item that has been added.
   */
  public getNewItemAddedMessage(dialogResult: DialogResult, name: string): string {
    if (dialogResult === DialogResult.Success) {
      const key = "MESSAGES.NEW_ITEM_WITH_NAME_ADDED";
      return this.translateService.instant(key, { name: name });
    } else {
      return this.translateService.instant("MESSAGES.GENERAL_ERROR");
    }
  }

  /**
   * Returns the correct message based on the number of selected items when they have been successfully cancelled.
   * @param selectedItems The items that have been selected by the user.
   */
  public getCancelledConfirmedText(selectedItems: any[]): string {
    return this.getConfirmedText(selectedItems, "CANCEL");
  }

  public getCopyConfirmedText(selectedItems: any[]): string {
    return this.getConfirmedText(selectedItems, "COPY");
  }

  public getRecalculateConfirmedText(selectedItems: any[]): string {
    return this.getConfirmedText(selectedItems, "RECALCULATE");
  }

  public getConfirmCopy(selectedItems: any[]): ConfirmDialogData {
    return this.getConfirmData(selectedItems, "COPY");
  }

  public getConfirmRecalculate(selectedItems: any[]): ConfirmDialogData {
    return this.getConfirmData(selectedItems, "RECALCULATE");
  }

  private getConfirmedText(selectedItems: any[], actionKey: string) {
    if (!selectedItems) {
      return null;
    }

    if (selectedItems.length === 1) {
      return this.translateService.instant(`MESSAGES.${actionKey}_CONFIRMED_SINGULAR`);
    } else {
      return this.translateService.instant(`MESSAGES.${actionKey}_CONFIRMED_PLURAL`, { numberItems: selectedItems.length });
    }
  }

  private getConfirmData(selectedItems: any[], actionKey: string): ConfirmDialogData {
    if (!selectedItems) {
      return null;
    }

    let title: string;
    if (selectedItems.length === 1) {
      title = this.translateService.instant(`MESSAGES.CONFIRM_${actionKey}_SINGULAR`);
    } else if (selectedItems.length > 1) {
      title = this.translateService.instant(`MESSAGES.CONFIRM_${actionKey}_PLURAL`, { numberItems: selectedItems.length });
    }

    return {
      confirmationText: this.translateService.instant("MESSAGES.CONFIRM_TO_CONTINUE"),
      dialogTitle: title
    };
  }
}
