import { EventEmitter, Injectable } from "@angular/core";
import { AddedOutgoingInvoiceLineDTO, DeletedOutgoingInvoiceLineDTO } from "../swagger-gen/fordesk";
import { Observable } from "rxjs";
import { forIn, startsWith } from "lodash-es";

@Injectable({
  providedIn: "root"
})
export class OutgoingInvoiceRefreshService {
  public outgoingInvoiceUpdated: EventEmitter<void> = new EventEmitter();
  private lineAdded: EventEmitter<AddedOutgoingInvoiceLineDTO> = new EventEmitter<AddedOutgoingInvoiceLineDTO>();
  private lineRemoved: EventEmitter<DeletedOutgoingInvoiceLineDTO[]> = new EventEmitter<DeletedOutgoingInvoiceLineDTO[]>();
  private prefix: string = "outgoingInvoiceRefresh-";

  constructor() { }

  public check() {
    if (this.isReload()) {
      this.removeAllFromStorage();
    }
  }

  public saveOperationalFileNumberRefresh(outgoingInvoice: number, operationalFile: number) {
    this.removeAllFromStorage();
    localStorage.setItem(this.createKey(outgoingInvoice), operationalFile.toString());
  }

  public loadOperationalFileNumberRefresh(outgoingInvoice: number) {
    const operationalfileId = +localStorage.getItem(this.createKey(outgoingInvoice));
    this.removeAllFromStorage();

    return operationalfileId ? operationalfileId : null;
  }

  public removeAllFromStorage() {
    forIn(window.localStorage, (value: string, key: string) => {
      if (startsWith(key, this.prefix)) {
        localStorage.removeItem(key);
      }
    });
  }

  public emitOutgoingInvoiceUpdated(): void {
    if (this.outgoingInvoiceUpdated) {
      this.outgoingInvoiceUpdated.emit();
    }
  }

  public getLineAddedObservable(): Observable<AddedOutgoingInvoiceLineDTO> {
    return this.lineAdded.asObservable();
  }

  public getLinesRemovedObservable(): Observable<DeletedOutgoingInvoiceLineDTO[]> {
    return this.lineRemoved.asObservable();
  }

  public emitLineAdded(event: AddedOutgoingInvoiceLineDTO): void {
    if (this.lineAdded) {
      this.lineAdded.emit(event);
    }
  }

  public emitLinesRemoved(event: DeletedOutgoingInvoiceLineDTO[]): void {
    if (this.lineRemoved) {
      this.lineRemoved.emit(event);
    }
  }

  private isReload() {
    return performance.getEntriesByType("navigation")?.[0]?.["type"] !== "reload";
  }

  private createKey(id: number): string {
    return this.prefix + id;
  }
}
