import { AfterContentInit, Directive, ElementRef, Input, AfterViewInit } from "@angular/core";

@Directive({ selector: "[autoFocus]" })
export class AutoFocusDirective implements AfterViewInit {
  @Input()
  public appAutoFocus: boolean;

  public constructor(private el: ElementRef) {
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, 500);
  }
}