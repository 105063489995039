export class ConfirmWithReasonDialogData {
  public dialogTitle: string;
  public reasonEnabled: boolean;
  public confirmationText: string;
}

export class ConfirmWithReasonDialogResultData {
  public reason: string;
  public isConfirmed: boolean;
}
