import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "line-totals",
  templateUrl: "./line-totals.component.html",
  styleUrls: ["./line-totals.component.scss"]
})
export class LineTotalsComponent {
  @Input()
  public total: number;
  @Input()
  public totalCurrencyCode: string;
  @Input()
  public totalCurrencySymbol: string;
  @Input()
  public totalAppCurrency: number;
  @Input()
  public totalAppCurrencyCode: string;
  @Input()
  public totalAppCurrencySymbol: string;
}
