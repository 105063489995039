export const grid_layouts_locale = {
  PERSONAL: "Personal",
  SHARED: "Shared",
  NO_LAYOUTS_FOUND: "No layouts could be found.",
  VIEW_NAME: "View name",
  SHARED_LABEL: "Shared (other users can use this)",
  SHARED_VIEWS: "Shared views",
  PERSONAL_VIEWS: "Personal views",
  PERSONAL_VIEWS_NOT_FOUND: "Personal views not found",
  SHARED_VIEWS_NOT_FOUND: "Shared views not found"
};
