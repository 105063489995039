/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FoundBudgetQuoteDetailsDTO { 
    quoteFileId?: number;
    operationalFileId?: number;
    quoteFileNumber?: string;
    operationalFileNumber?: string;
    quotationDate?: Date;
    customerName?: string;
    customerNumber?: string;
    customerReference?: string;
    department?: string;
    userName?: string;
    userInitials?: string;
    fileType?: string;
    shipmentType?: string;
    transportType?: string;
    portOfLoading?: string;
    portOfDischarge?: string;
    placeOfDestination?: string;
    placeOfReceipt?: string;
    percentTO?: number;
    carrierName?: string;
    totalCost?: number;
    totalRevenue?: number;
    result?: number;
    period?: string;
    status?: FoundBudgetQuoteDetailsDTO.StatusEnum;
}
export namespace FoundBudgetQuoteDetailsDTO {
    export type StatusEnum = 0 | 1 | 2 | 3 | 4;
    export const StatusEnum = {
        NUMBER_0: 0 as StatusEnum,
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum,
        NUMBER_3: 3 as StatusEnum,
        NUMBER_4: 4 as StatusEnum
    };
}