import { Injectable } from "@angular/core";

export const OPERATIONAL_FILES_OVERVIEW: string = "/operational/files";
export const OPERATIONAL_DETAILS: string = "/operational/files";
export const DETAILS: string = "details";

@Injectable()
export class OperationalUrlHelper {
  public getOperationalDetailsUrl(id: number): string {
    return `/operational/files/${id}/details`;
  }

  public getOperationalUblSuggestionUrl(id: number): string {
    return `/operational/files/${id}/ubl-suggestions`;
  }
}
