import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ContainerGoodsDTO } from "../../../../services/swagger-gen/fordesk";

@Component({
  selector: "container-goods-label",
  templateUrl: "./container-goods-label.html"
})
// tslint:disable-next-line: component-class-suffix
export class ContainerGoodsLabel {
  @Input()
  public item: ContainerGoodsDTO;

  @Output()
  public clear: EventEmitter<ContainerGoodsDTO> = new EventEmitter();

  constructor() { }

  public clearItem(item: ContainerGoodsDTO): void {
    this.clear.emit(item);
  }
}
