export enum BookingExtraConfirmationSelectedOption {
  SendAsOriginal,
  SendAsAmmendment,
  CancelPendingAndSendNewOriginal,
  CancelPendingAndSendNewAmmendment
}


export enum BookingExtraConfirmationType {
  TriesToSendOriginalButAcceptedExists,
  TriesToSendOriginalButExistsPreviouslyPendingOriginal,
  TriesToSendAmmendmentButThereIsAnOriginalThatWasRejected,
  TriesToSendAmmendmentButThereIsAnOriginalThatIsPending,
  TriesToSendAmmendmentButThereIsPendingAmmendment
}
