/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AddShippingInstructionsDTO { 
    billOfLadingType?: AddShippingInstructionsDTO.BillOfLadingTypeEnum;
    freightPayable?: AddShippingInstructionsDTO.FreightPayableEnum;
    numberOfOriginals?: number;
    numberOfCopies?: number;
    nonPrintableRemarks?: string;
    printableRemarks?: string;
    portOfDischarge?: string;
    portOfLoading?: string;
    placeOfReceipt?: string;
    placeOfDestination?: string;
    isFreighted?: boolean;
    operationalFileContainerIds?: Array<number>;
    containerGoodsIds?: Array<number>;
    operationalFilePartyIds?: Array<number>;
    bookingReferences?: Array<string>;
}
export namespace AddShippingInstructionsDTO {
    export type BillOfLadingTypeEnum = 0 | 1;
    export const BillOfLadingTypeEnum = {
        NUMBER_0: 0 as BillOfLadingTypeEnum,
        NUMBER_1: 1 as BillOfLadingTypeEnum
    };
    export type FreightPayableEnum = 0 | 1 | 2 | 3;
    export const FreightPayableEnum = {
        NUMBER_0: 0 as FreightPayableEnum,
        NUMBER_1: 1 as FreightPayableEnum,
        NUMBER_2: 2 as FreightPayableEnum,
        NUMBER_3: 3 as FreightPayableEnum
    };
}