import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Directive({
  selector: "[trimStartWithoutFormControl]"
})

export class TrimStartWithoutFormControlDirective {


  constructor(private el: ElementRef,) { }

  @HostListener("input", ["$event"]) public onKeyUp(event: KeyboardEvent) {
    if (this.el && this.el.nativeElement && this.el.nativeElement.value) {
      this.el.nativeElement.value = this.el.nativeElement.value.trimStart();
    }
  }
}
