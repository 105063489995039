export const of_revenues_displayed_columns_locale = {
  activityCodeDescription: "Name",
  partyName: "Party",
  quantity: "Quantity",
  priceExclVat: "Price",
  totalExclVat: "Total with currency",
  totalExclVatOriginalCurrency: "Total",
  isInvoiced: "Status",
  vatAmount: "Vat",
  totalInclVat: "Total (incl. VAT) with currency",
  totalInclVatOriginalCurrency: "Total (incl. VAT)",
  invoiceOrProformaReference: "Invoice or Proforma reference",
  invoiceStatus: "Invoice status",
  invoiceDate: "Invoice date",
  taxSystem: "Tax system",
  taxPercentage: "Tax percentage"
};
