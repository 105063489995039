import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take, takeUntil } from 'rxjs/operators';
import { BookingsService, BookingTemplatesDTO } from '../../../core/services/swagger-gen/fordesk';
import { ComponentBase } from '../../../core/components/abstractions/component-base';

export class BookingTemplatesComponentData {
  public operationalFileId: number;
}

@Component({
  selector: 'booking-templates',
  templateUrl: './booking-templates.component.html',
  styleUrls: ['./booking-templates.component.scss']
})
export class BookingTemplatesComponent extends ComponentBase implements OnInit {

  public items: BookingTemplatesDTO[] = [];
  public detailsForm: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<BookingTemplatesComponent>,
    private fb: UntypedFormBuilder,
    private bookingsService: BookingsService,
    @Inject(MAT_DIALOG_DATA) public data: BookingTemplatesComponentData) {
    super();
  }

  ngOnInit(): void {

    this.createForm();

    this.bookingsService.getBookingTemplates(this.data.operationalFileId).pipe(take(1),
      takeUntil(this.unsubscribe)).subscribe(data => {
        if (data.length > 0) {
          this.detailsForm.controls.documentTemplateId.setValue(data[0].documentTemplateId);

        }

        if (data.length == 1) {
          this.confirm();
          return;
        }

        this.items = data;
      });
  }

  private createForm(): void {
    this.detailsForm = this.fb.group({
      documentTemplateId: [0]
    });
  }

  public cancel() {
    this.dialogRef.close();
  }

  public confirm() {
    this.dialogRef.close(this.detailsForm.controls.documentTemplateId.value);
  }

}
