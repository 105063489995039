import { AbstractControl, ValidatorFn } from "@angular/forms";


export function phoneRegexValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {

    const forbidden = control.value != null && control.value.length > 0 && !/^(\s*)?(\+)?([() +]?\d[- _():=+]?){1,}(\s*)?$/gm.test(control.value);

    return forbidden ? { phoneNumber: true } : null;
  };
}
