import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GuardsCheckStart, Router, RouterEvent } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { isEqual } from "lodash-es";
import { interval, Observable, of, Subject } from "rxjs";
import { catchError, delay, filter, map, mergeMap, take, takeUntil } from "rxjs/operators";
import { UnsavedChangesConfirmDialogComponent } from "../../../content/dialogs/unsaved-changes-confirm-dialog/unsaved-changes-confirm-dialog.component";
import { EntityDetailsComponent } from "../../components/abstractions/entity-details.component";
import { TabSetNavigationHelper } from "../../helpers/tabset-navigation-helper";
import { UnsavedChangesOptions } from "../../models/dialogs/enums/unsaved-changes-options.enum";
import { UnsavedChangesDialogData, UnsavedChangesDialogResultData } from "../../models/dialogs/unsaved-changes-dialog-data";
import { LoadingOverlayService } from "../loading-overlay/loading-overlay.service";
import { RequestStorageService } from "../request-storage-service";

@Injectable({
  providedIn: "root"
})
export class UnsavedChangesService {
  private dialogData: UnsavedChangesDialogData = {
    dialogTitle: this.translate.instant("MESSAGES.UNSAVED_CHANGES"),
    confirmationText: this.translate.instant("MESSAGES.PICK_ONE_OF_OPTIONS"),
    isSaveAllowed: true
  };

  private allowNewDialog: boolean = true;
  private currentComponents: UnsubscribableComponent[] = [];

  constructor(private router: Router,
    private requestStorageService: RequestStorageService,
    private loadingOverlayService: LoadingOverlayService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private tabsetNavigation: TabSetNavigationHelper) { }

  public setUp(component: EntityDetailsComponent, unsubscribe: Subject<void>) {
    this.currentComponents.push({ component, unsubscribe });
    this.setUpRouterEvents(component, unsubscribe);
  }

  public isAllowed(optionalMessage: string = null, additionalAction: Function = null): Observable<boolean> {
    const dirtyComponent: UnsubscribableComponent = this.currentComponents?.find(({ component }) => component?.isDirty);

    return of(dirtyComponent).pipe(
      take(1),
      mergeMap((dirty) => {
        if (dirty && this.allowNewDialog) {
          return this.openDialog(dirty.component, optionalMessage).pipe(take(1), map((res: UnsavedChangesOptions) => {

            if (res === UnsavedChangesOptions.STAY) {
              return false;
            } else if (res === UnsavedChangesOptions.DISCARD) {
              dirtyComponent.component.resetForm();
              if (additionalAction)
                additionalAction();
            } else if (res === UnsavedChangesOptions.SAVE) {
              try {
                dirtyComponent.component.save(null, false);
              }
              finally { }
            }
            return true;
          }));
        }
        return of(true);
      }));
  }

  private setUpRouterEvents(component: EntityDetailsComponent, unsubscribe: Subject<void>) {
    this.router.events.pipe(
      takeUntil(unsubscribe),
      filter(e => e instanceof GuardsCheckStart))
      .subscribe((e: RouterEvent) => {
        if (component.isDirty && this.allowNewDialog) {
          this.allowNewDialog = false;
          this.preventDefault();
          return this.openDialog(component)
            .pipe(takeUntil(unsubscribe))
            .subscribe((res: UnsavedChangesOptions) => {
              if (res === UnsavedChangesOptions.STAY) {
                this.tabsetNavigation.setActiveTabByUrl();
                this.makePageVisible();
              } else if (res === UnsavedChangesOptions.DISCARD) {
                component.resetForm();
                this.unsubscribeAllCurrentComponents();
                this.router.navigateByUrl(e.url, { skipLocationChange: true });
              } else if (res === UnsavedChangesOptions.SAVE) {
                try {
                  component.save();
                }
                finally {
                  if (!component.unsavedChangesMainlyUnsubscribe) {
                    const subscription = interval(500).pipe(filter(_ => this.requestStorageService.getCountRequests() === 0)).subscribe(() => {
                      this.router.navigateByUrl(e.url, { skipLocationChange: true });
                      this.unsubscribeAllCurrentComponents();
                      subscription.unsubscribe();
                    });
                  } else {
                    component.unsubscribeAsObservable.pipe(delay(50)).subscribe(() => {
                      this.router.navigateByUrl(e.url, { skipLocationChange: true });
                    });
                  }
                }
              }

              this.allowNewDialog = true;
            });
        }
      });

  }

  public backToPage(component: EntityDetailsComponent) {
    this.tabsetNavigation.setActiveTabByUrl();
    this.makePageVisible();
    if (component.valueBeforeSaving) {
      component.initialForm.next(component.valueBeforeSaving);
    }

  }
  public areFormValuesEqual(initial: any, details: any) {
    const [largest, smallest] = Object.keys(initial) > Object.keys(details)
      ? [{ ...initial }, { ...details }] : [{ ...details }, { ...initial }];

    for (const key in smallest) {
      if (Object.prototype.hasOwnProperty.call(smallest, key) &&
        Object.prototype.hasOwnProperty.call(largest, key)) {
        if (!isEqual(
          [[], null].includes(largest[key]) ? "" : largest[key],
          [[], null].includes(smallest[key]) ? "" : smallest[key])) {
          return false;
        }
      }
    }
    return true;
  }

  private openDialog(component: EntityDetailsComponent, optionalMessage: string = null) {
    const dialogData = optionalMessage
      ? { ... this.dialogData, dialogTitle: optionalMessage }
      : { ... this.dialogData };

    dialogData.isSaveAllowed = component.validate();

    return this.dialog
      .open(UnsavedChangesConfirmDialogComponent, { disableClose: true, data: dialogData })
      .afterClosed()
      .pipe(
        map(({ response }: UnsavedChangesDialogResultData) => response),
        catchError(() => of(false)));
  }

  public unsubscribeAllCurrentComponents() {
    this.currentComponents.forEach(({ unsubscribe }) => {
      unsubscribe?.next();
      unsubscribe?.complete();
    });
    this.currentComponents = [];
  }

  public unsubscribeComponent(component: EntityDetailsComponent) {
    const currentComponent = this.currentComponents.find(x => x.component === component);

    if (currentComponent) {
      currentComponent.unsubscribe?.next();
      currentComponent.unsubscribe?.complete();

      this.currentComponents = this.currentComponents.filter(x => x.component !== component);
    }
  }

  private makePageVisible() {
    const wrapper = document.getElementsByClassName("m-wrapper")[0] as HTMLElement;
    wrapper.style.display = "block";
  }

  private preventDefault() {
    this.router.navigateByUrl(this.router.routerState.snapshot.url, { skipLocationChange: true });
    this.loadingOverlayService.stopLoading();
  }

  public resetComponent(component: EntityDetailsComponent) {
    component?.resetForm();
  }

  public resetAllComponents() {
    this.currentComponents.forEach(c => {
      this.resetComponent(c.component);
    });
  }
}

interface UnsubscribableComponent {
  component: EntityDetailsComponent;
  unsubscribe: Subject<void>;
}

interface UnsubscribableComponent {
  component: EntityDetailsComponent;
  unsubscribe: Subject<void>;
}
