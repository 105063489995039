import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ExtendedFileCopyDialogData, ExtendedFileCopyDialogResultData } from "../../../../core/models/dialogs/extended-file-copy-dialog-data";
import { ConfirmBase } from "../../../dialogs/abstractions/confirm-base.component";
import { ShipmentType } from "../../../../core/enumerations/shipment-type.enum";

@Component({
  selector: "extended-file-copy",
  templateUrl: "./extended-file-copy.component.html",
  styleUrls: ["./extended-file-copy.component.scss"]
})
export class ExtendedFileCopyComponent extends ConfirmBase implements OnInit {
  public date: Date;
  public includeContainers: boolean = true;
  public includeCosts: boolean = true;
  public includeGeneral: boolean = true;
  public includeGoods: boolean = true;
  public includeParties: boolean = true;
  public includeRevenues: boolean = true;
  public includeSteps: boolean = true;
  public includeShippingInstructions: boolean = true;
  public includeBookings: boolean = true;
  public includeTransports: boolean = false;

  public selectedContainsFcl: boolean = false;
  public selectedFclOnly: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ExtendedFileCopyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExtendedFileCopyDialogData
  ) {
    super(dialogRef, data);
    this.date = data.date;
    if (this.data['shipmentTypes'].every(num => num === ShipmentType.FCL)) {
      this.selectedFclOnly = true
      this.selectedContainsFcl = true
    }
    else if (this.data['shipmentTypes'].some(x => x === ShipmentType.FCL)) {
      this.selectedFclOnly = false
      this.selectedContainsFcl = true
    }
  }

  public ngOnInit() {
    this.updateIncludeParties();
  }

  public updateIncludeParties() {
    if (this.includeCosts || this.includeRevenues || this.includeTransports) {
      this.includeParties = true;
    }
  }

  public onTransportsEnabel() {
    if (this.includeTransports) {
      this.includeParties = true;

      this.includeGoods = this.includeTransports && !this.selectedFclOnly
      this.includeContainers = this.includeTransports && this.selectedContainsFcl
    }
  }

  public confirm() {
    const dialogResult: ExtendedFileCopyDialogResultData = {
      isConfirmed: true,
      date: this.date,
      includeContainers: this.includeContainers,
      includeTransports: this.includeTransports,
      includeCosts: this.includeCosts,
      includeGeneral: this.includeGeneral,
      includeGoods: this.includeGoods,
      includeParties: this.includeParties,
      includeRevenues: this.includeRevenues,
      includeSteps: this.includeSteps,
      includeShippingInstructions: this.includeShippingInstructions,
      includeBookings: this.includeBookings,
    };

    this.dialogRef.close(dialogResult);
  }
}
