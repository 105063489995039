import { Directive, HostListener, ElementRef, Renderer2, AfterViewInit, OnInit, Input } from "@angular/core";
import { ClipboardService } from "../helpers/clipboard.service";
import { TranslateService } from "@ngx-translate/core";

@Directive({ selector: "[clipboard]" })
export class ClipboardDirective implements OnInit {
  @Input()
  public alwaysVisible: boolean = false;

  private copyIcon: any;

  constructor(
    private clipBoardService: ClipboardService,
    private el: ElementRef,
    private renderer: Renderer2,
    private translate: TranslateService
  ) { }

  public ngOnInit(): void {
    this.copyIcon = this.buildCopyIcon();
    this.renderer.appendChild(this.el.nativeElement, this.copyIcon);
  }

  @HostListener("mouseenter")
  public onMouseEnter() {
    if (!this.alwaysVisible) {
      this.renderer.removeClass(this.copyIcon, "hide");
    }
  }

  @HostListener("mouseleave")
  public onMouseLeave() {
    if (!this.alwaysVisible) {
      this.renderer.addClass(this.copyIcon, "hide");
    }
  }

  private copyToClipBoardFn = (event: Event) => {
    event.stopPropagation();
    const elementText: string = this.getElementText();
    const truncatedText = elementText ? elementText.trim() : "";
    this.clipBoardService.emitToClipboard(truncatedText);
  }

  private buildCopyIcon() {
    const icon = this.renderer.createElement("i");
    const classesToAdd = ["la", "la-copy", "copy-icon", "ml-1"];
    if (!this.alwaysVisible) {
      classesToAdd.push("hide");
    }
    classesToAdd.forEach(cl => this.renderer.addClass(icon, cl));

    this.renderer.setAttribute(icon, "title", this.translate.instant("ACTIONS.COPY_TO_CLIPBOARD"));
    this.renderer.listen(icon, "click", this.copyToClipBoardFn);

    return icon;
  }

  private getElementText(): string {
    let elementText: string;
    if (this.el && this.el.nativeElement && this.el.nativeElement.innerText) {
      elementText = this.el.nativeElement.innerText;
    } else {
      elementText = null;
    }

    return elementText;
  }
}
