import { takeUntil, map, concatMap, switchMap, tap } from "rxjs/operators";
import { NamedBlob } from "./../../../../../core/models/files/named-blob";
import { Component, ViewChild, ElementRef, AfterViewInit, Input } from "@angular/core";
import {
  ToolbarService,
  DocumentEditorContainer,
  FormatType
} from "@syncfusion/ej2-angular-documenteditor";
import { MessageHelper } from "../../../../../core/helpers/message-helper";
import { ToasterService } from "../../../../layout/toaster/toaster.service";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, from, Observable, of } from "rxjs";
import { ComponentBase } from "../../../../../core/components/abstractions/component-base";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WordEditorButtonOptions } from "../../../../../core/models/dialogs/word-editor-button-options";
import { DocumentEditorService as CustomDocumentEditorService } from "../../../../../core/services/document-editor.service";
import { DialogResult } from "../../../../../core/enumerations/dialog-result";
import { MailComponent } from "../mail/mail.component";
import { OperationalFileDocumentsCreateService } from "../../../../../core/services/operational/operational-file/operational-file-document-create.service";
import { DocumentEditorService, DocumentTemplateResponse, Response, QuoteFileService, CompanyRelatedEmailDTO } from "../../../../../core/services/swagger-gen/fordesk";
import { PostEmailSendDTO } from "../mail/models/post-email-send-dto";
import { environment } from "../../../../../../environments/environment";
import { HttpResponse } from "@angular/common/http";
import { DocumentFile } from "../../../../../core/models/documents/document-file";
import { IMailingSourceBehavior } from "../../../../../core/helpers/mailing/mailing-source-behavior";
import { AttachDocumentToSelectedOperationalFiles } from ".././../../../../core/services/operational/operational-file/attach-document-to-selected-operational-files.service"
import { TenantConfigurationService } from "../../../../../core/services/client-tenant-configuration/tenant-config.service";

@Component({
  selector: "word-editor",
  templateUrl: "./word-editor.component.html",
  styleUrls: ["./word-editor.component.scss"],
  providers: [ToolbarService]
})
export class WordEditorComponent extends ComponentBase implements AfterViewInit {
  @ViewChild("document_editor", { static: false })
  public documentEditorContainer: DocumentEditorContainer;
  public buttonOptions: WordEditorButtonOptions = { isPrintEnabled: true, isSaveEnabled: true, isDownloadEnabled: false, isMailEnabled: true, isSaveUnpaidInvoiceEnabled: false };
  public documentEditorServiceUrl = `${environment.forDeskApiConfig.host}/${environment.forDeskApiConfig.baseUrl}/document-editor/`
  public isClientPortalEnabled: boolean = false;

  private fileName: string;
  private file: Blob;
  private documentTemplateId: number;

  public attachments: Array<NamedBlob> = [];

  @ViewChild("downloadLink", { static: false })
  private downloadLink: ElementRef;

  public saveDocument: (document: Blob, path: string) => Observable<NamedBlob>;

  public uploadPdfFileAfterSendAction$: (arg: PostEmailSendDTO) => Observable<Response>;

  public getOperationalFileDocumentTemplateInfo: (fileName: string) => Observable<DocumentTemplateResponse>;

  public mailingSourceBehavior: IMailingSourceBehavior;

  @Input()
  public getDocuments: () => Observable<DocumentFile[]>;

  @Input()
  public mainFilePath: string;

  @Input()
  public isOperationalFile: boolean = false;

  @Input()
  public documentRelatedOfIds: number[];

  public getEmailsAutocompleteSuggestions: (text: string) => Observable<CompanyRelatedEmailDTO[]>;

  constructor(
    private attachDocumentToSelectedOperationalFile: AttachDocumentToSelectedOperationalFiles,
    public documentEditorService: DocumentEditorService,
    public customDocumentEditorService: CustomDocumentEditorService,
    public messageHelper: MessageHelper,
    public toaster: ToasterService,
    public translate: TranslateService,
    public modal: NgbActiveModal,
    public ngbModal: NgbModal,
    private clientTenantConfig: TenantConfigurationService,
    private operationalFileDocumentsCreateService: OperationalFileDocumentsCreateService,
    public quoteFileService: QuoteFileService,
  ) {
    super();
  }

  public ngAfterViewInit() {
    const accessToken: string = <string>localStorage.getItem("access_token");
    this.documentEditorContainer.headers = [{ "Authorization": `Bearer ${accessToken}` }];
    this.isClientPortalEnabled = this.clientTenantConfig.get().generalSettings.isClientPortalEnabled;
  }

  public loadTemplatedDocument(file: NamedBlob, documentTemplateId?: number, buttonOptions?: WordEditorButtonOptions): Observable<void> {
    this.documentTemplateId = documentTemplateId;
    return this.loadDocument(file, buttonOptions);
  }

  public loadDocument(file: NamedBlob, buttonOptions?: WordEditorButtonOptions): Observable<void> {
    if (buttonOptions) {
      this.buttonOptions = buttonOptions;
    }

    this.fileName = file.fileName;
    this.file = file ? file.blob : new Blob();

    return this.customDocumentEditorService.convertToSftd(this.fileName, this.file).pipe(
      takeUntil(this.unsubscribe),
      tap(res => {
        this.documentEditorContainer.documentEditor.open(res);
        this.addChangeMisoFontButton();
      }),
      // Map to void to signal to the subscriber that no meaningful data will be emitted
      map(() => undefined)
    );
  }

  // TODO remove: temporary solution because miso font is not selectable
  private addChangeMisoFontButton() {
    const changeFontDiv = document.createElement("DIV");
    changeFontDiv.className = "e-toolbar-item e-de-toolbar-btn-end e-de-lock-dropdownbutton";
    const button = document.createElement("BUTTON");
    const buttonLogo = document.createElement("SPAN");
    const buttonText = document.createElement("SPAN");
    button.className = "e-tbar-btn e-tbtn-txt e-control e-btn e-lib e-dropdown-btn e-de-toolbar-btn-first e-caret-hide";
    buttonLogo.className = "e-btn-icon e-de-ctnr-fontcolor e-icons e-icon-left";
    buttonText.className = "e-tbar-btn-text";
    buttonText.innerText = "Correct font";
    button.appendChild(buttonLogo);
    button.appendChild(buttonText);
    button.addEventListener("click", () => { this.changeFontToMiso(); });
    changeFontDiv.appendChild(button);
    document.querySelector(".e-de-toolbar .e-toolbar-items").appendChild(changeFontDiv);
  }

  public changeFontToMiso(): void {
    this.documentEditorContainer.documentEditor.selection.characterFormat.fontFamily = "miso";
  }

  public save(saveToCpFolder: Boolean = false): void {
    if (!this.saveDocument) {
      this.toaster.error(this.messageHelper.getGenericDialogResultMessage(DialogResult.Error));
    }
    let path = saveToCpFolder ? "/Client Portal Shared/" : null;

    this.documentEditorContainer.documentEditor.enableWordExport = true;

    const formatType: FormatType = "Docx";
    const saveOperation: Observable<Blob> = from(this.documentEditorContainer.documentEditor.saveAsBlob(formatType));

    saveOperation
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(document => {
        this.saveDocument(document, path)
          .subscribe(() => {
            this.operationalFileDocumentsCreateService.documentCreate(this.fileName);
            this.toaster.success(this.translate.instant("DIGITAL_ARCHIVE.MESSAGES.DOCUMENT_SAVED"));
            this.modal.close();
          });
      });
  }

  public cancel(): void {
    this.modal.close();
  }

  public download() {
    this.removeHover("download-btn");
    this.documentEditorContainer.documentEditor.enableWordExport = true;

    const formatType: FormatType = "Docx";
    const saveOperation: Observable<Blob> = from(this.documentEditorContainer.documentEditor.saveAsBlob(formatType));

    saveOperation
      .pipe(takeUntil(this.unsubscribe),
        concatMap(document => this.customDocumentEditorService.downloadDocument(this.documentTemplateId, this.fileName, document)),
        map(res => {
          const url: string = window.URL.createObjectURL(res.blob);
          const link = this.downloadLink.nativeElement;
          link.href = url;
          link.download = res.fileName;
          link.click();
          window.URL.revokeObjectURL(url);
        }))
      .subscribe();
  }

  public mail() {
    this.removeHover("mail-btn");
    const pdfBlob: NamedBlob = new NamedBlob();

    this.generatePdf()
      .pipe(map(res => {
        pdfBlob.blob = res.body;
        pdfBlob.fileName = `${this.mainFilePath && this.mainFilePath != "/"
          ? `${this.mainFilePath.replace(/\//g, '')}/`
          : ""}${res.headers.get("fileName")}`

        const files: NamedBlob[] = [pdfBlob];

        if (this.attachments) {
          files.push(...this.attachments);
        }
        const modalRef = this.ngbModal.open(MailComponent, {
          backdrop: 'static',
          keyboard: false,
          size: <any>"xl"
        });
        (<MailComponent>modalRef.componentInstance).files = files;
        (<MailComponent>modalRef.componentInstance).uploadPdfFileAfterSendAction$ = this.uploadPdfFileAfterSendAction$;
        (<MailComponent>modalRef.componentInstance).pdfFile = pdfBlob;
        (<MailComponent>modalRef.componentInstance).mailingSourceBehavior = this.mailingSourceBehavior;
        (<MailComponent>modalRef.componentInstance).getDocuments = this.getDocuments;
        (<MailComponent>modalRef.componentInstance).getEmailsAutocompleteSuggestions = this.getEmailsAutocompleteSuggestions;
      }), takeUntil(this.unsubscribe))
      .subscribe();
  }

  public print() {
    this.removeHover("print-btn");
    this.documentEditorContainer.documentEditor.enableWordExport = true;

    this.generatePdf()
      .subscribe(result => {
        this.removeHover("print-btn");
        const blobUrl = URL.createObjectURL(result.body);
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      });
  }

  public displaySaveUnpaidInvoiceDocumentButton() {
    var x = this.buttonOptions.isSaveUnpaidInvoiceEnabled && (this.documentRelatedOfIds?.length > 0 ?? false)
    return x;
  }

  public saveUnpaidInvoiceDocument() {
    this.attachDocumentToSelectedOperationalFile.save(this.documentRelatedOfIds, this.fileName, this.file).subscribe((res: boolean) => {
      if (res) {
        this.toaster.success(this.translate.instant("DIGITAL_ARCHIVE.MESSAGES.DOCUMENT_WAS_SUCCESFULLY_UPLOADED_TO_LINKED_OF"));
      }
    });
  }

  private generatePdf(): Observable<HttpResponse<Blob>> {
    const formatType: FormatType = "Docx";
    const saveOperation: Observable<Blob> = from(this.documentEditorContainer.documentEditor.saveAsBlob(formatType));
    const defaultDocumentTemplateInfoObject: DocumentTemplateResponse = {
      hasRequiresMasterTemplate: true,
      documentTemplateId: this.documentTemplateId
    };

    return forkJoin({
      blobFile: saveOperation,
      documentTemplateInfo: this.documentTemplateId || !this.getOperationalFileDocumentTemplateInfo ? of(defaultDocumentTemplateInfoObject) : this.getOperationalFileDocumentTemplateInfo(this.fileName)
    })
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(data => {
          if (data.documentTemplateInfo.hasRequiresMasterTemplate) {
            return this.customDocumentEditorService.downloadDocument(data.documentTemplateInfo.documentTemplateId, this.fileName, data.blobFile)
              .pipe(
                takeUntil(this.unsubscribe),
                switchMap((res: NamedBlob) => {
                  return this.documentEditorService.convertWordToPdfArray(res.fileName, res.blob ? res.blob : new Blob(), { observe: "response" })
                }))
          }
          else {
            return this.customDocumentEditorService.downloadDocument(0, this.fileName, data.blobFile).pipe(
              takeUntil(this.unsubscribe),
              switchMap((res: NamedBlob) => {
                return this.documentEditorService.convertWordToPdfArray(res.fileName, res.blob ? res.blob : new Blob(), { observe: "response" })
              }));
          }
        }));
  }

  private removeHover(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.blur();
    }
  }
}
