import {Directive, Input, OnInit} from "@angular/core";
import {UntypedFormGroup} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {ToasterService} from "../../content/layout/toaster/toaster.service";
import {NumberRangeInputActiveService} from "../services/utilities/number-range-input-active.service";
import {NumberRangeInputType} from "../enumerations/number-range-input-type.enum";

@Directive({
  selector: "[numberRange]"
})

export class NumberRangeDirective implements OnInit {

  private _minControl: any;
  private _maxControl: any;

  @Input("numberRangeFormGroup")
  public numberRangeFormGroup: UntypedFormGroup;

  @Input("unsubscribe")
  public unsubscribe: Subject<void>;

  constructor(public translate: TranslateService,
              public toaster: ToasterService,
              public numberRangeInputActiveService: NumberRangeInputActiveService) {
  }

  public ngOnInit() {

    const numberRangeControls = this.numberRangeFormGroup?.controls;
    this._minControl = numberRangeControls?.min;
    this._maxControl = numberRangeControls?.max;

    if (this._minControl && this._maxControl) {
      this.numberRangeInputActiveService
        .getInputActive$()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          if (!data.isActive) {
            this.handler(data.type);
          }
        });
    }
  }

  private handler(inputType: NumberRangeInputType): void {
    const maxValue = this._maxControl.value;
    const minValue = this._minControl.value;

    if (!maxValue || !minValue) {
      return;
    }

    if (inputType === NumberRangeInputType.max && maxValue < minValue) {
      this._maxControl.setValue(minValue);
      this._maxControl.updateValueAndValidity();
      this.toaster.error(this.translate.instant("ERRORS.MAX_VALUE_LESS_THAN_MIN"));
    } else if (inputType === NumberRangeInputType.min && minValue > maxValue) {
      this._minControl.setValue(maxValue);
      this._minControl.updateValueAndValidity();
      this.toaster.error(this.translate.instant("ERRORS.MIN_VALUE_MORE_THAN_MAX"));
    }
  }
}
