import {
  AjaxSettingsModel,
  BeforeSendEventArgs,
  ContextMenuSettingsModel,
  FailureEventArgs,
  FileSelectEventArgs,
  MenuClickEventArgs,
  NavigationPaneSettingsModel,
  ToolbarClickEventArgs,
  ToolbarSettingsModel,
  UploadSettingsModel
} from "@syncfusion/ej2-filemanager";
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UtilsService } from "../../../../../core/services/utils.service";
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { DocumentFile } from "../../../../../core/models/documents/document-file";
import { LoadingOverlayService } from "../../../../../core/services/loading-overlay/loading-overlay.service";
import { WordEditorComponent } from "../word-editor/word-editor.component";
import { ComponentBase } from "../../../../../core/components/abstractions/component-base";
import { ToasterService } from "../../../../layout/toaster/toaster.service";
import { TranslateService } from "@ngx-translate/core";
import { finalize, map, switchMap, take, takeUntil } from "rxjs/operators";
import { FileExtensions } from "../../../../../core/enumerations/file-extentions.enum";
import { PdfViewerDialogComponent } from "../pdf-viewer-dialog/pdf-viewer-dialog.component";
import { FileExtensionHelper } from "../../../../../core/services/helpers/file-extension.helper";
import { ImageViewerDialogComponent } from "../image-viewer-dialog/image-viewer-dialog.component";
import { PdfViewerButtonOptions } from "../../../../../core/models/dialogs/pdf-viewer-button-options";
import {
  BeforePopupOpenCloseEventArgs,
  FileManagerComponent,
  FileOpenEventArgs,
  PopupOpenCloseEventArgs,
  UploadListCreateArgs
} from "@syncfusion/ej2-angular-filemanager";
import { FileDownloadService } from "../../../../../core/services/file-download.service";
import { NamedBlob } from "../../../../../core/models/files/named-blob";
import { Observable, of } from "rxjs";
import { Dialog } from "@syncfusion/ej2-popups";
import { FileUploadFailModalDialogComponent } from "./dialogs/file-upload-fail-modal-dialog/file-upload-fail-modal-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { FileMoveFailDialogComponent } from "./dialogs/file-move-fail-dialog/file-move-fail-dialog.component";
import { XhrInterceptorService } from "../../../../../core/services/http/xhr-interceptor.service";
import { ConfirmDialog } from "../../../../dialogs/confirm/confirm.component";
import { ConfirmDialogData, ConfirmDialogResultData } from "../../../../../core/models/dialogs/confirm-dialog-data";
import { OperationalFileDocumentsCreateService } from "../../../../../core/services/operational/operational-file/operational-file-document-create.service";
import { LostInternetConnectionService } from "../../../../../core/services/http/lost-internet-connection.service";
import { DigitalArchiveService } from "../../../../../core/services/digital-archive.service";
import { CompanyRelatedEmailDTO, DocumentTemplateResponse, FormOperationalFile, FormOperationalFileGeneral, OperationalFileService, Response } from "../../../../../core/services/swagger-gen/fordesk";
import { PostEmailSendDTO } from "../mail/models/post-email-send-dto";
import { notAllowedDocumentNameCharactersRegex } from "../../../../../core/helpers/regex.helper";
import { replaceEncodedQuotes } from "../../../../../core/helpers/filename-helper";
import { IMailingSourceBehavior } from "../../../../../core/helpers/mailing/mailing-source-behavior";
import { UploadDocumensDialogComponent } from "../../../../dialogs/upload-documents-dialog/upload-documents-dialog-component";
import { OPERATIONAL_FILES_OVERVIEW, OperationalUrlHelper } from "../../../../pages/operational/operational-routes";
import { ActivatedRoute, Router } from "@angular/router";
import { OperationalFileEditIdHelper } from "../../../../../core/helpers/operational-file-edit-id-helper";
import { RoleService } from "../../../../../core/auth/roles/role.service";

@Component({
  selector: "digital-archive",
  templateUrl: "./digital-archive.component.html",
  styleUrls: ["./digital-archive.component.scss"]
})
export class DigitalArchiveComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
  @Input() public isCreateNewDocumentsEnabled: boolean = false;
  @Input() public fileRefreshed$: Observable<void>;
  @Input() public isFileEditable$: Observable<boolean>;
  @Input() public onCreateDocument: () => void;
  @Input() public saveDocument: (document: Blob, fileName: string) => Observable<NamedBlob>;
  @Input() public getOperationalFileDocumentTemplateInfo: (fileName: string) => Observable<DocumentTemplateResponse>;
  @Input() uploadPdfFileAfterSendAction$: (arg: PostEmailSendDTO) => Observable<Response>;
  @Input() public getEmailsAutocompleteSuggestions: (text: string) => Observable<CompanyRelatedEmailDTO[]>;
  @Input() public getDocuments: () => Observable<DocumentFile[]>;
  @Input() public mailingSourceBehavior: IMailingSourceBehavior;

  public userHasOCRUserRole: boolean = false;

  private isManuallyCancelPopup: boolean = false;
  public ajaxSettings: AjaxSettingsModel;
  public toolbarSettings: ToolbarSettingsModel;
  public uploadSettings: UploadSettingsModel;
  public navigationPaneSettings: NavigationPaneSettingsModel;
  public contextMenuSettings: ContextMenuSettingsModel;
  public selectedFile: DocumentFile;
  public isClosed: boolean = false;
  private toolbar: string[] = ["NewFolder", "Upload", "Refresh", "Selection", "View", "SortBy", "Delete", "Open"];
  private _resourceUrl: string;
  private popup: Dialog;
  private openFileIcon: string = "<span class='e-menu-icon e-icons e-fe-open ml-2'></span>";

  private uploadedFileName: string = null;
  private shouldSelectFile: boolean = false;

  public operationalFileId: number;

  // Define the possible actions that can be intercepted.
  private readonly read: string = "read";
  private readonly renameAction: string = "rename";
  private readonly remove: string = "delete";
  // private readonly getDetails: string = "details"; //uncomment if you want to implement details
  private readonly search: string = "search";
  private readonly create: string = "create";
  private readonly upload: string = "upload";
  private readonly move: string = "move";
  private readonly failureTypes: string[] = [".e-validation-fails", ".e-upload-fails"];
  private readonly maxFileNameLength: number = 228;
  private readonly notAllowedCharacters = ["/:*?\"\<\>\|\\"];

  @ViewChild("downloadLink", { static: false })
  private downloadLink: ElementRef;

  @ViewChild("file_manager", { static: false })
  private fileManager: FileManagerComponent;

  public get isFileManagerSelectedItemsEmpty(): boolean {
    return this.fileManager?.selectedItems?.length === 0;
  }

  constructor(
    private roleService: RoleService,
    private dialog: MatDialog,
    private urlHelper: OperationalUrlHelper,
    private util: UtilsService,
    public router: Router,
    private modal: NgbModal,
    public loadingOverlay: LoadingOverlayService,
    private activatedRoute: ActivatedRoute,
    private toaster: ToasterService,
    public operationalFileService: OperationalFileService,
    private translate: TranslateService,
    public fileExtensionHelper: FileExtensionHelper,
    private operationalFileEditHelper: OperationalFileEditIdHelper,
    private fileDownloadService: FileDownloadService,
    private xhrInterceptorService: XhrInterceptorService,
    private operationalFileDocumentsCreateService: OperationalFileDocumentsCreateService,
    private lostInternetConnectionService: LostInternetConnectionService,
    private digitalArchiveService: DigitalArchiveService
  ) {
    super();
  }

  private isUserHasOCRUserRole() {
    this.roleService.userHasOCRUserRole()
      .pipe(map(x => this.userHasOCRUserRole = x),
        takeUntil(this.unsubscribe)).subscribe();
  }

  public ngOnInit() {
    this.operationalFileId = this.operationalFileEditHelper.getOperationalFileId(
      this.activatedRoute
    );

    this.operationalFileDocumentsCreateService.getDocumentCreate$().subscribe(fileName => {
      this.setSelectedFileName(fileName);
    });

    this.xhrInterceptorService.setupXHRInterceptor();

    // See https://ej2.syncfusion.com/angular/documentation/file-manager/customization/ for more info
    this.toolbarSettings = {
      items: this.toolbar,
      visible: true
    };

    // "Upload", "Delete", "Download", "Refresh", "Selection", "View"

    this.uploadSettings = {
      autoUpload: true,
      maxFileSize: 20 * 1024 * 1024, // 20Mb
      allowedExtensions: this.fileExtensionHelper.getAllowExtensions()
    };

    this.navigationPaneSettings = {
      visible: false
    };

    // Add 'Details' to layout if you want to add it to the menu.
    // Deleting the entire layout property is also ok, because it will use a default which includes 'Details'
    this.contextMenuSettings = {
      file: ["Open", "|", "Delete", "Rename"],
      folder: ["Open", "|", "Delete", "Rename"],
      layout: ["SortBy", "View", "Refresh", "|", "NewFolder", "Upload"],
      visible: true
    };


    if (this.isFileEditable$) {
      this.isFileEditable$
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(res => this.isClosed = !res);
    }

    if (this.fileRefreshed$) {
      this.fileRefreshed$
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          if (this.fileManager && this.fileManager.element) {
            this.fileManager.refresh();
          }
        });
    }

    setTimeout(() => {
      this.setupOpenButton();
    }, 200);

    this.digitalArchiveService.observeCloseModal()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.modal?.dismissAll());

    this.digitalArchiveService.observeRefresh()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.fileManager?.refresh());

    this.isUserHasOCRUserRole();
  }

  public uploadOCR() {

    this.openOCRDialog();
  }

  public openOCRDialog() {
    this.dialog.open(UploadDocumensDialogComponent, {
      disableClose: true, data:
      {
        allowedFileTypes: '.pdf, .jpg, .jpeg, .tif, .tiff, .png',
        uploadMultiple: false,
        title: "OPERATIONAL.EDIT.UPLOAD_FILE",
        extraMessage: "OPERATIONAL.EDIT.FEATURE_UNDER_DEVELOPMENT"
      }
    })
      .afterClosed()
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap((files: File[]) => {
          if (files && files.length > 0) {
            const file = files[0];

            return this.operationalFileService.extractOperationalFileUsingOCR(this.operationalFileId, file)
              .pipe(
                switchMap((ocrOperationalFile: FormOperationalFile) => {
                  if (ocrOperationalFile) {
                    if (this.isFormOperationalFileEmpty(ocrOperationalFile)) {
                      this.toaster.warning(this.translate.instant("OPERATIONAL.EDIT.MESSAGES.NO_DATA_RECOGNIZED_TOSUGGEST"))
                      return of(null)
                    }
                    return of({ ocrOperationalFile, file });
                  }
                  return of(null);
                })
              );
          }
          return of(null);
        })
      )
      .subscribe(({ ocrOperationalFile, file }) => {
        if (ocrOperationalFile) {
          this.router.navigate([this.urlHelper.getOperationalUblSuggestionUrl(this.operationalFileId)], {
            state: { ocrOperationalFile, file }
          });
        }
      });

  }

  private isFormOperationalFileEmpty(file: FormOperationalFile): boolean {
    return this.isEmpty(file.operationalFileGeneral) && this.isEmpty(file.operationalFileContainers);
  }

  private isEmpty = (value: any): boolean => {
    if (value == null) return true; // Check for null or undefined
    if (Array.isArray(value) && value.length === 0) return true; // Check for empty arrays
    if (typeof value === 'object') {
      return Object.values(value).every(this.isEmpty); // Recursively check all properties
    }
    return false;
  }

  private setAjaxSettings(resourceUrl: string) {
    this.ajaxSettings = {
      url: resourceUrl,
      uploadUrl: `${resourceUrl}/upload`,
      downloadUrl: `${resourceUrl}/download`
    };
  }

  public ngAfterViewInit(): void {
    this.handleSearchInput();
    this.handleSyncfusionModalClose();
  }

  @Input("resourceUrl")
  public set resourceUrl(value: string) {
    this.setAjaxSettings(value);
    this._resourceUrl = value;
  }

  public onSuccess(event: SuccessEvent) {
    // TODO: implement logging?

    if (event.action === "Upload") {
      const allEvent: any = event;
      const fileName = replaceEncodedQuotes(allEvent.result?.file?.name)
        ?.replace(notAllowedDocumentNameCharactersRegex, ' ')
        .replace(/\s\s+/g, ' ');
      this.setSelectedFileName(fileName);
    }

    if (event.action === "read") {
      if (this.shouldSelectFile) {
        this.fileManager.clearSelection();

        const uploadedFileName = this.uploadedFileName;

        setTimeout(() => {
          if (uploadedFileName) {
            this.fileManager.selectedItems = [uploadedFileName];

            const anyFileManagerFeFiles: any = this.fileManager.feFiles;
            const file = anyFileManagerFeFiles?.fe_tree?.find(x => x.name === uploadedFileName);

            if (file) {
              this.selectedFile = { ...file };
            }

            this.setSelectedFileName(null, false);
          }
        }, 400);
      }
    }

    if (event.action === "delete") {
      this.selectedFile = null;
      this.configToolbarMenu(false);

      this.hidePopup();
    }

    if (event.action === "move") {
      this.fileManager.clearSelection();
      this.fileManager.refreshFiles();
    }


    if (event.action === "Upload" || event.action === "move" || event.action === this.renameAction) {
      this.hidePopup();
    }
  }

  public ngOnDestroy() {
    this.xhrInterceptorService.unsubscribe();
    return super.ngOnDestroy();
  }

  // TODO Find a proper way of error handling / intercepting exceptions from AJAX requests.
  public onFailure(event: FailureEventArgs) {
    this.fileManager.clearSelection();
    setTimeout(() => {
      const action: string = event.action ? event.action.toLowerCase() : null;
      const xhrError = this.hasXhrError();
      this.lostInternetConnectionService
        .isOffline()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(isOffline => {
          if (isOffline) {
            this.lostInternetConnectionService.notifyUser();
            return;
          } else {
            if (xhrError) {
              return;
            }
            if (action === this.upload || action === this.renameAction || action === this.move) {

              this.onFileExistError(event);
              return;
            }
          }
        });
    });

    this.hidePopup(false);
  }

  private hasXhrError() {
    return this.xhrInterceptorService.isXHRError() &&
      (this.xhrInterceptorService.folderOrFileNameMustBeDifferentFromInitial() || this.xhrInterceptorService.folderNameConnotContainsExtension() || this.xhrInterceptorService.operationFileIsClosed() || this.xhrInterceptorService.incorrectQuoteStatus());
  }

  private formatBadRequestOnFileExistError(fileName: string) {
    const fileNames = Array.from(document.getElementsByClassName("e-file-name"));

    for (const item of fileNames) {
      const itemAsHtmlElement: HTMLElement = item as HTMLElement;

      if (itemAsHtmlElement.getAttribute("title") === fileName) {
        const uploadFails = Array.from(itemAsHtmlElement.parentElement.getElementsByClassName("e-file-status e-upload-fails"));

        for (const uploadFail of uploadFails) {
          uploadFail.innerHTML = this.translate.instant("FILE_UPLOAD_FAIL.FILE_EXIST");
        }
      }

    }
  }

  private handleSearchInput() {
    setTimeout(() => {
      const searchInput = document.getElementById("documentsManager_search") as HTMLInputElement;
      if (searchInput) {
        searchInput.maxLength = 2000;
      }
    }, 1000);

  }

  private onFileExistError(event: FailureEventArgs) {
    const action: string = event.action ? event.action.toLowerCase() : null;
    let dialogRef;

    if (action === this.upload) {
      dialogRef = this.dialog.open(FileUploadFailModalDialogComponent, {
        disableClose: true
      });

      const error: any = event.error;
      const file = error.file.rawFile;

      dialogRef.componentInstance.data = {
        path: this.fileManager.path,
        file: file,
        requestUrl: this.ajaxSettings.uploadUrl,
        isUpload: true
      };

      this.formatBadRequestOnFileExistError(file.name);

    } else {
      dialogRef = this.dialog.open(FileMoveFailDialogComponent, {
        disableClose: true
      });

      if (action === this.renameAction) {
        dialogRef.componentInstance.data = {
          requestUrl: `${this._resourceUrl}/${this.fileManager.currentItemText}`,
          currentItemText: this.fileManager.renameText,
          dropPath: this.fileManager.path,
          dragPath: this.fileManager.path,
          initialItemName: this.fileManager.currentItemText
        };
      } else {
        dialogRef.componentInstance.data = {
          requestUrl: `${this._resourceUrl}/${this.fileManager.currentItemText}`,
          currentItemText: this.fileManager.currentItemText,
          dropPath: this.fileManager.dropPath,
          dragPath: this.fileManager.dragPath,
          initialItemName: this.fileManager.currentItemText
        };
      }
    }


    if (dialogRef) {
      dialogRef.afterClosed().subscribe((data) => {
        if (action === this.upload) {
          if (data.success) {
            const validationMessages = Array.from(document.getElementsByClassName("e-file-name"));

            this.setSelectedFileName(dialogRef.componentInstance.fullFileName);

            for (const item of validationMessages) {
              const itemAsHtmlElement: HTMLElement = item as HTMLElement;
              if (itemAsHtmlElement.getAttribute("title") === dialogRef.componentInstance.data.file.name) {
                itemAsHtmlElement.parentElement.parentElement.remove();

                this.hidePopup(true, 100);

              }
            }
          }

        }
        this.fileManager.refreshFiles();
      });
    }
  }

  private setSelectedFileName(fileName: string, shouldSelect: boolean = true) {
    this.uploadedFileName = fileName;
    this.shouldSelectFile = shouldSelect;
  }

  private hidePopup(useTimeout: boolean = true, defaultTimeout: number = 1000) {

    if (useTimeout) {
      setTimeout(() => {
        if (this.popup) {
          this.popup.hide();
        }
      }, defaultTimeout);
    } else {
      if (this.popup) {
        this.popup.hide();
      }
    }

  }
  public beforeFileManagerRequestSend(e: BeforeSendEventArgs) {
    if (!e || !e.action) {
      return;
    }

    // This handler intercepts any AJAX requests made by the FileManager and redirects them to the correct
    // REST endpoints on the Documents API.
    // We can't use HttpInterceptors here - the FileManager executes XmlHttpRequests directly.

    // e.ajaxSettings.data is a JSON string containing all relevant request data,
    // parse it into an 'any' instance and retrieve the file name.
    const actualAjaxSettings: AjaxSettings = e.ajaxSettings as AjaxSettings;
    const ajaxSettingsData = JSON.parse(actualAjaxSettings.data) as AjaxSettingsData;

    const fileNames = ajaxSettingsData.names;

    const fileName = fileNames ? fileNames[0] : "";

    actualAjaxSettings.beforeSend = function (args: any) {
      // Setting authorization header
      const accessToken: string = <string>localStorage.getItem("access_token");
      args.httpRequest.setRequestHeader("Authorization", `Bearer ${accessToken}`);

    };

    // Switch on the requested action and adjust as required:
    // - HTTP verb
    // - query string parameters
    // - route parameters
    const ajaxSettings: AjaxSettings = e.ajaxSettings as AjaxSettings;

    switch (e.action.toLowerCase()) {
      case this.search: {
        ajaxSettings.type = "GET";
        ajaxSettings.url += `?${this.util.urlParam(ajaxSettingsData)}`;
        this.configToolbarMenu(false);
        break;
      }
      case this.read: {
        ajaxSettings.type = "GET";
        ajaxSettings.url += `?${this.util.urlParam(ajaxSettingsData)}`;
        break;
      }
      // Uncomment if you want to implement details
      // case this.getDetails: {
      //   ajaxSettings.type = "GET";
      //   ajaxSettings.url += `/${fileName}`;
      //   break;
      // }
      case this.renameAction: {
        if (!this.isFileNamePatternValid()) {
          this.addError("file-pattern");
          e.cancel = true;
          break;
        }

        if (this.fileContainsTooManyWhitSpacesInARow("rename")) {
          this.addError("white-spaces");
          e.cancel = true;
          break;
        }

        const path = ajaxSettingsData.path;


        const oldName = ajaxSettingsData.name;
        const newName = ajaxSettingsData.newName;

        if (this.rename(oldName, `${path}${newName}`, path, ajaxSettings) === -1) {
          e.cancel = true;
        }
        break;
      }
      case this.remove: {
        ajaxSettings.type = "DELETE";
        ajaxSettings.url += `/${fileName}`;
        ajaxSettings.data = JSON.stringify(ajaxSettingsData.path);

        break;
      }
      case this.create: {
        if (!this.isFolderNameValid()) {
          this.addError("folder");
          e.cancel = true;
          break;
        }

        if (this.fileContainsTooManyWhitSpacesInARow("newname")) {
          this.addError("white-spaces");
          e.cancel = true;
          break;
        }

        const path = `${ajaxSettingsData.path}${ajaxSettingsData.name}`;

        ajaxSettings.type = "POST";
        ajaxSettings.url += `/folders`;
        ajaxSettings.data = JSON.stringify({ path: path });
        break;
      }
      case this.upload: {
        ajaxSettings.url += `?${this.util.urlParam(ajaxSettingsData[0])}`;
        break;
      }
      case this.move: {
        const path = ajaxSettingsData.path;
        const targetPath = ajaxSettingsData.targetPath;
        this.rename(fileName, `${targetPath}${fileName}`, path, ajaxSettings);
        break;
      }

    }
  }

  private fileContainsTooManyWhitSpacesInARow(type: "rename" | "newname"): boolean {
    const input = document.getElementById(type) as HTMLInputElement;
    const value = input?.value ?? "";

    return value.match(/\s\s\s*/g)?.length > 0;
  }

  public beforePopupOpen(event: BeforePopupOpenCloseEventArgs): any {
    const openEvent = event.popupModule.content;
    if (typeof openEvent === "string" && openEvent.includes("The destination folder is the subfolder of the source folder")) {
      event.cancel = true;
    }

    if (event.popupName === "Retry Upload") {
      event.cancel = true;
    }

    if (event.popupName === "Upload" || event.popupName === "Error") {

      this.popup = event.popupModule;
    }
    return event;
  }

  public beforePopupClose(event: BeforePopupOpenCloseEventArgs): any {
    const uploadFileList = (Array.from(document.getElementsByClassName("e-upload-files"))[0]) as HTMLElement;

    if (uploadFileList) {
      // Handle close popup not by user click
      if (event.popupName === "Upload" && (this.hasItems(this.failureTypes, uploadFileList) ||
        this.hasItems([".e-upload-progress-bar.e-upload-progress"], uploadFileList)) && !this.isManuallyCancelPopup) {
        event.cancel = true;
      }

      // Handle close popup  by user click
      if (event.popupName === "Upload" && this.isManuallyCancelPopup && this.hasItems([".e-upload-progress-bar.e-upload-progress"], uploadFileList)) {
        this.toaster.error(this.translate.instant("DIGITAL_ARCHIVE.MESSAGES.CANCEL_UPLOADED_FILES"));
        event.cancel = true;
      }
    }

    this.isManuallyCancelPopup = false;
  }

  private setCorrectDisplayErrors(popupName: string) {

    setTimeout(() => {
      const errors = Array.from(document.getElementsByClassName("e-fe-error"));

      if (popupName === "Create Folder") {
        this.handleCreateFolderErrors(errors);
      } else if (popupName === "Rename") {
        this.handleRenameFileErrors(errors);
      }

      for (const item of errors) {
        const itemAsHtmlElement: HTMLElement = item as HTMLElement;
        itemAsHtmlElement.addEventListener("DOMSubtreeModified", () => {
          this.handleInvalidCharactersError(itemAsHtmlElement);

          if (popupName === "Rename") {
            this.handlerEmptyFileName(itemAsHtmlElement);
          } else if (popupName === "Create Folder") {
            this.handleEmptyFolderNameError(itemAsHtmlElement);
          }
        });
      }
    }, 0);
  }

  private handleRenameFileErrors(errors: Array<Element>) {

    const input = document.getElementById("rename");

    const inputAsHtml: HTMLInputElement = input as HTMLInputElement;

    const saveBtn = document.getElementsByClassName("e-control e-btn e-lib e-primary e-flat")[0] as HTMLButtonElement;

    inputAsHtml.addEventListener("input", (event: InputEvent) => {

      this.renameMaxLengthErrorHandler(inputAsHtml, errors, saveBtn);
      this.handleInvalidCharactersError(inputAsHtml);

      if (!this.isFileNamePatternValid(inputAsHtml)) {
        this.addError("file-pattern", errors);
      }

      if (this.fileContainsTooManyWhitSpacesInARow("rename")) {
        this.addError("white-spaces");
      }

      if (!this.isFileNamePatternValid()) {
        this.addError("file-pattern");
      }

      if (this.isDigitalArchiveGonnaThrowError("rename")) {
        this.addError("digital-archive-error");
      }
    });
  }

  private handleCreateFolderErrors(errors: Array<Element>) {
    const input = document.getElementById("newname");

    const inputAsHtml: HTMLElement = input as HTMLElement;

    inputAsHtml.addEventListener("input", (event: InputEvent) => {
      this.handleInvalidCharactersError(inputAsHtml);
      if (!this.isFolderNameValid()) {
        this.addError("folder");
      }

      if (this.fileContainsTooManyWhitSpacesInARow("newname")) {
        this.addError("white-spaces");
      }

      if (this.isDigitalArchiveGonnaThrowError("newname")) {
        this.addError("digital-archive-error");
      }
    });
  }

  private renameMaxLengthErrorHandler(input?: HTMLInputElement, errors?: any, saveBtn?: HTMLButtonElement): void {
    if (!input || !errors || !saveBtn) {
      return;
    }
    saveBtn.disabled = false;

    if (input.value.length === input.maxLength) {
      const errorBlock = errors[0] as HTMLElement;

      if (errorBlock) {
        errorBlock.innerText = this.translate.instant("VALIDATION.MAX_LENGTH", {
          name: this.translate.instant("DIGITAL_ARCHIVE.NAME"),
          max: this.maxFileNameLength
        });

        if (!saveBtn.classList.contains("save-btn")) {
          saveBtn.classList.add("save-btn");
        }

        saveBtn.disabled = true;
      }
    }
  }

  private addError(type: "file-pattern" | "folder" | "white-spaces" | "digital-archive-error", errors?: any) {
    errors = errors ?? Array.from(document.getElementsByClassName("e-fe-error"));
    const message = this.getErrorMessage(type);

    for (const error of errors) {
      const errorAsHtmlElement: HTMLElement = error as HTMLElement;
      if (!errorAsHtmlElement.innerText.includes(this.translate.instant(message))) {
        errorAsHtmlElement.innerText = this.translate.instant(message);
      }
    }
  }

  private getErrorMessage(type: "file-pattern" | "folder" | "white-spaces" | "digital-archive-error") {
    switch (type) {
      case "white-spaces":
        return "DIGITAL_ARCHIVE.MESSAGES.FILE_TOO_MANY_WHITESPACES";
      case "folder":
        return "DIGITAL_ARCHIVE.MESSAGES.DIRECTORY_CANNOT_CONTAINS_EXTENSION";
      case "digital-archive-error":
        return "DIGITAL_ARCHIVE.MESSAGES.FILE_NAME_CONTAINS_INVALID_CHARACTERS";
      default:
        return "DIGITAL_ARCHIVE.MESSAGES.INVALID_FILE_NAME_PATTERN";
    }
  }

  private isFolderNameValid(input?: HTMLElement) {
    input = input ?? document.getElementById("newname");
    const inputAsHtml: HTMLElement = input as HTMLElement;

    const fullInputNode: any = inputAsHtml;
    if (fullInputNode && fullInputNode.value && !fullInputNode.value.endsWith(".") && this.hasAnyFileExtension(fullInputNode.value)) {
      return false;
    }

    return true;
  }

  private isFileNamePatternValid(input?: HTMLElement) {
    input = input ?? document.getElementById("rename");

    const inputAsHtml: HTMLElement = input as HTMLElement;

    const fullInputNode: any = inputAsHtml;

    if (fullInputNode && fullInputNode.value) {
      const dotsCount = (fullInputNode.value.split(".").length - 1);

      if (fullInputNode.value.startsWith(".") && dotsCount === 1) {
        return false;
      }
    }

    return true;
  }

  private isDigitalArchiveGonnaThrowError(type: "rename" | "newname", input?: HTMLElement) {
    input = input ?? document.getElementById(type);

    const inputAsHtml: HTMLElement = input as HTMLElement;

    const fullInputNode: any = inputAsHtml;

    if (fullInputNode?.value && fullInputNode?.value.length > 0) {
      const valueArr: string[] = [...fullInputNode?.value];
      if (valueArr.some(x => this.notAllowedCharacters.includes(x))) {
        return true;
      }

      if ([" ", "."].includes(valueArr[valueArr.length - 1])) {
        return true;
      }
    }

    return false;
  }

  private hasAnyFileExtension(fileName: string): boolean {
    const regex = /^.*\.[^\\]+$/;

    return regex.test(fileName);
  }

  private handlerEmptyFileName(element: HTMLElement): void {
    if (element.innerText === this.translate.instant("DIGITAL_ARCHIVE.MESSAGES.FILE_NAME_CANNOT_BE_EMPTY")) {
      return;
    }

    if (element.innerText && element.innerText.includes("The file or folder name cannot be empty.")) {
      element.innerText = this.translate.instant("DIGITAL_ARCHIVE.MESSAGES.FILE_NAME_CANNOT_BE_EMPTY");
    }
  }

  private handleEmptyFolderNameError(element: HTMLElement): void {
    if (element.innerText === this.translate.instant("DIGITAL_ARCHIVE.MESSAGES.FOLDER_NAME_CANNOT_BE_EMPTY")) {
      return;
    }

    if (element.innerText && element.innerText.includes("The file or folder name cannot be empty.")) {
      element.innerText = this.translate.instant("DIGITAL_ARCHIVE.MESSAGES.FOLDER_NAME_CANNOT_BE_EMPTY");
    }
  }

  private handleInvalidCharactersError(element: HTMLElement): void {
    if (element.innerText === this.translate.instant("DIGITAL_ARCHIVE.MESSAGES.FILE_NAME_CONTAINS_INVALID_CHARACTERS")) {
      return;
    }

    if (element.innerText && element.innerText.includes("The file or folder name \"")) {
      element.innerText = this.translate.instant("DIGITAL_ARCHIVE.MESSAGES.FILE_NAME_CONTAINS_INVALID_CHARACTERS");
    }
  }

  public onPopupOpen(event: PopupOpenCloseEventArgs) {
    this.alterFileSizeErrorMessage();
    this.setCorrectDisplayErrors(event.popupName);

    this.isManuallyCancelPopup = false;

    setTimeout(() => {
      const closeMainModalButton = Array.from(document.getElementsByClassName("e-btn-icon e-icon-dlg-close e-icons"));

      closeMainModalButton.forEach((item, i) => {
        const itemAsHtmlElement: HTMLElement = item as HTMLElement;
        itemAsHtmlElement.onmousedown = (e) => this.isManuallyCancelPopup = true;
      });

    }, 0);

    const eventName = event.popupName === "Rename" ? "rename" : event.popupName === "Create Folder" ? "newname" : "";

    if (event.popupName !== "") {
      setTimeout(() => {

        const renameInput = document.getElementById(eventName) as HTMLInputElement;

        if (renameInput) {
          renameInput.maxLength = this.maxFileNameLength + 1;
        }

        renameInput?.addEventListener("input", (changeEvent: any) => {
          const selectionStart = changeEvent.target.selectionStart;
          const selectionEnd = changeEvent.target.selectionEnd;

          const formattedFileName = this.getFormattedFileName(changeEvent.target.value);

          changeEvent.target.value = formattedFileName;

          changeEvent.target.setSelectionRange(selectionStart, selectionEnd);
        });

      }, 10);
    }

  }

  private alterFileSizeErrorMessage(): void {
    // Wrap in setTimeout to ensure full creation of pop up component.
    // Without this, the list of uploaded files in the popup is considered empty at the time of execution.
    // This makes it impossible to manipulate the File size error message of Syncfusion.
    setTimeout(() => {
      const validationMessages = Array.from(document.getElementsByClassName("e-file-status e-validation-fails"));

      for (const item of validationMessages) {
        const itemAsHtmlElement: HTMLElement = item as HTMLElement;
        if (itemAsHtmlElement.innerText && itemAsHtmlElement.innerText === "File size is too large") {
          itemAsHtmlElement.innerText = this.translate.instant("DIGITAL_ARCHIVE.MESSAGES.FILE_SIZE_TOO_LARGE");
        }
      }
    }, 0);
  }

  private generateClickHandler(fileName: string, itemAsHtmlElement: HTMLElement) {
    const handler = (event) => {
      this.handleRemoveFileClick(event, {
        confirmationText: this.translate.instant("DIGITAL_ARCHIVE.MESSAGES.ABORT_UPLOAD", { name: fileName }),
        dialogTitle: this.translate.instant("DIGITAL_ARCHIVE.MESSAGES.CONFIRM_ABORT_UPLOAD"),
      }, itemAsHtmlElement);
    };

    return handler;
  }

  public uploadListCreate(uploadeEvent: UploadListCreateArgs) {
    setTimeout(() => {
      const fileNames = Array.from(document.getElementsByClassName("e-file-name"));
      const closeButtons = Array.from(document.getElementsByClassName("e-icons e-file-remove-btn"));
      closeButtons.forEach((item, i) => {
        const itemAsHtmlElement: HTMLElement = item as HTMLElement;

        if (!itemAsHtmlElement.onmousedown) {
          itemAsHtmlElement.onmousedown = this.generateClickHandler(fileNames[i].getAttribute("title"), itemAsHtmlElement);
        }
      });

      fileNames.forEach((fileNameItem) => {
        const fileNameHtmlElement: HTMLElement = fileNameItem as HTMLElement;
        const fileName = replaceEncodedQuotes(fileNameHtmlElement.innerText);
        if (notAllowedDocumentNameCharactersRegex.test(fileName)) {
          const newFileName = fileName.replace(notAllowedDocumentNameCharactersRegex, ' ');
          fileNameHtmlElement.innerText = newFileName;
        }
      });

    }, 0);
  }

  private handleRemoveFileClick(event: any, data: ConfirmDialogData, itemAsHtmlElement: HTMLElement) {
    event.stopPropagation();
    event.preventDefault();

    this.dialog
      .open(ConfirmDialog, {
        data,
        disableClose: true
      })
      .afterClosed()
      .subscribe((confirmData: ConfirmDialogResultData) => {
        const isConfirmed = confirmData && confirmData.isConfirmed;
        if (isConfirmed) {
          itemAsHtmlElement.onmousedown = null;
          itemAsHtmlElement.click();

          // It is necessary so that we can automatically close the uploaded list window if we click on closing an already canceled file and it was the last one in the uploaded list
          itemAsHtmlElement.onclick = () => {
            this.hidePopup(true, 200);
          };

          setTimeout(() => {
            const uploadFileList = (Array.from(document.getElementsByClassName("e-upload-files"))[0]) as HTMLElement;
            if (!uploadFileList) {
              this.hidePopup(false);
              return;
            }

            // Handle only file the uploading of which has not even started, needs to be handled here because the onSuccess event with cancelevent will not be called because its upload was not started
            if (uploadFileList && !this.hasItems(this.failureTypes, uploadFileList)) {
              this.hidePopup(false);
              return;
            }
          }, 100);
        }
      });
  }

  private hasItems(queryParams: string[], itemAsHtmlElement: HTMLElement) {
    return this.getHtmlItems(queryParams, itemAsHtmlElement).length > 0;
  }

  private getHtmlItems(queryParams: string[], itemAsHtmlElement: HTMLElement) {
    return queryParams.map(type => itemAsHtmlElement.querySelectorAll(type)).filter(x => x.length > 0);
  }

  private rename(oldName: string, newFileNamePath: string, path: string, ajaxSettings: AjaxSettings) {
    newFileNamePath = newFileNamePath.replace(/\s\s+/g, ' ');
    const newFileExtension = this.fileExtensionHelper.getFileNameExtension(newFileNamePath, oldName);
    if (!newFileExtension) {
      this.toaster.error(this.translate.instant("DIGITAL_ARCHIVE.MESSAGES.INVALID_FILE_EXTENSION"));
      return -1;
    }

    ajaxSettings.type = "PUT";
    ajaxSettings.url += `/${encodeURIComponent(oldName)}`;
    ajaxSettings.data = JSON.stringify({ newFileNamePath: newFileNamePath, path: path });
  }

  public createDocument(): void {
    // Call external handler function if set
    if (this.onCreateDocument) {
      this.onCreateDocument();
    }
  }

  public downloadFile(): void {
    if (!this.selectedFile || !this.selectedFile.name) {
      return;
    }

    this.loadingOverlay.startLoading();

    const fileName: string = this.selectedFile.name;
    this.fileDownloadService
      .downloadFile(this._resourceUrl, fileName, this.selectedFile.filterPath)
      .subscribe({
        next: res => {
          const url: string = window.URL.createObjectURL(res.blob);
          const link = this.downloadLink.nativeElement;
          link.href = url;
          link.download = res.fileName;
          link.click();
          window.URL.revokeObjectURL(url);
        },
        error: () => this.loadingOverlay.stopLoading(),
        complete: () => this.loadingOverlay.stopLoading()
      });
  }

  public selectFile(args: FileSelectEventArgs): void {
    if (!args.fileDetails) {
      return;
    }

    const anyFileDetails: any = args.fileDetails;
    if (anyFileDetails as Array<DocumentFile> && anyFileDetails.length > 0) {
      args.fileDetails = anyFileDetails[0];
    }


    this.selectedFile = args.fileDetails as DocumentFile;

    const fileIsSelected = args.action === "select";
    this.configToolbarMenu(fileIsSelected, this.selectedFile.isFile);
  }

  public fileManagerToolbarClick(args: ToolbarClickEventArgs) {
    this.handleToolbarClick(args);

    if (args.item.text !== "Open") {
      return;
    }

    this.openFile(args);
    this.handleNgbModalClose();
  }

  public fileManagerMenuClick(args: MenuClickEventArgs) {
    if (args.item.text !== "Open") {
      return;
    }
    this.openFile(args);
  }

  public configToolbarMenu(shouldBeVisible: boolean, isFile: boolean = true) {
    const openButton: HTMLElement = this.findToolbarButton("Open");
    const closeButton: HTMLElement = this.findToolbarButton("Delete");

    if (shouldBeVisible) {
      if (isFile) {
        openButton?.classList.remove("hide");
      }
      closeButton?.classList.remove("hide");
    } else {
      openButton?.classList.add("hide");
      closeButton?.classList.add("hide");
    }
  }

  private findToolbarButton(buttonName: string) {
    const toolbarItems = <HTMLElement[]><any>document.querySelectorAll(".e-tbar-btn-text");
    let openButton: HTMLElement;

    toolbarItems.forEach(element => {
      if (element.innerHTML === buttonName) {
        openButton = element;
      }
    });

    return openButton?.parentElement?.parentElement;
  }

  private setupOpenButton() {
    const openButton: HTMLElement = this.findToolbarButton("Open");

    const child = openButton?.firstChild?.firstChild as HTMLElement;
    child?.insertAdjacentHTML("beforebegin",
      this.openFileIcon);

    openButton?.classList?.add("hide");
  }

  private openFile(args: ToolbarClickEventArgs | MenuClickEventArgs): void {
    const fileName: string = this.fileExtensionHelper.getFileNameFromToolbarClickEvent(args);
    if (!fileName) {
      this.toaster.error(this.translate.instant("DIGITAL_ARCHIVE.MESSAGES.NO_DOCUMENT_SELECTED"));
      return;
    }

    const extension = this.fileExtensionHelper.getFileNameExtension(fileName);
    this.loadingOverlay.startLoading();

    switch (extension) {
      case FileExtensions.doc:
      case FileExtensions.docx: {
        const modalRef: NgbModalRef = this.modal.open(WordEditorComponent, {
          backdrop: "static",
          keyboard: false,
          size: <any>"xl"
        });
        (<WordEditorComponent>modalRef.componentInstance).getEmailsAutocompleteSuggestions = this.getEmailsAutocompleteSuggestions;
        (<WordEditorComponent>modalRef.componentInstance).mailingSourceBehavior = this.mailingSourceBehavior;
        this.fileDownloadService
          .downloadFile(this._resourceUrl, fileName, args.fileDetails[0]["filterPath"], false)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe({
            next: (namedBlob) => {
              const editor: WordEditorComponent = (<WordEditorComponent>modalRef.componentInstance);
              editor.saveDocument = (document: Blob) => this.saveDocument(document, fileName);
              editor.uploadPdfFileAfterSendAction$ = this.uploadPdfFileAfterSendAction$;
              editor.getDocuments = this.getDocuments;
              editor.mainFilePath = this.fileManager.path;

              if (this.getOperationalFileDocumentTemplateInfo) {
                editor.getOperationalFileDocumentTemplateInfo = (fileName: string) => this.getOperationalFileDocumentTemplateInfo(fileName);
              }

              editor.loadDocument(namedBlob).pipe(
                finalize(() => this.loadingOverlay.stopLoading())
              ).subscribe();
            },
            error: () => {
              this.modal.dismissAll();
            }
          });
        break;
      }
      case FileExtensions.pdf: {
        const modalRef = this.modal.open(PdfViewerDialogComponent, {
          backdrop: "static",
          keyboard: false,
          size: <any>"xl"
        });
        const pdfViewerComponent: PdfViewerDialogComponent = (<PdfViewerDialogComponent>modalRef.componentInstance);
        pdfViewerComponent.getDocuments = this.getDocuments;
        pdfViewerComponent.mainFilePath = this.fileManager.path;
        const pdfOperation = this.fileDownloadService.downloadFile(this._resourceUrl, fileName, args.fileDetails[0]["filterPath"], false);
        const buttonOptions = {
          isDownloadEnabled: true,
          isMailEnabled: true,
          isPrintEnabled: true
        } as PdfViewerButtonOptions;
        pdfViewerComponent.getEmailsAutocompleteSuggestions = this.getEmailsAutocompleteSuggestions;
        pdfViewerComponent.mailingSourceBehavior = this.mailingSourceBehavior;
        pdfViewerComponent.tryLoadPdf(pdfOperation, buttonOptions, () => this.loadingOverlay.stopLoading());
        break;
      }
      case FileExtensions.jpg:
      case FileExtensions.jpeg:
      case FileExtensions.png:
      case FileExtensions.gif: {
        const modalRef = this.modal.open(ImageViewerDialogComponent, <NgbModalOptions>{
          backdrop: "static",
          keyboard: false,
          size: "lg"
        });
        const imageViewerComponent: ImageViewerDialogComponent = (<ImageViewerDialogComponent>modalRef.componentInstance);
        imageViewerComponent.extension = extension;

        const imageOperation = this.fileDownloadService.downloadFile(this._resourceUrl, fileName, args.fileDetails[0]["filterPath"]);
        imageViewerComponent.tryLoadImage(imageOperation, () => this.loadingOverlay.stopLoading());
        break;
      }
      case FileExtensions.folder: {
        this.loadingOverlay.stopLoading();
        break;
      }
      default: {
        this.fileDownloadService
          .downloadFile(this._resourceUrl, fileName, this.selectedFile.filterPath)
          .subscribe({
            next: res => {
              const url: string = window.URL.createObjectURL(res.blob);
              const link = this.downloadLink.nativeElement;
              link.href = url;
              link.download = res.fileName;
              link.click();
              window.URL.revokeObjectURL(url);
            },
            error: () => this.loadingOverlay.stopLoading(),
            complete: () => this.loadingOverlay.stopLoading()
          });
        break;
      }
    }
  }

  public onFileOpen($event: FileOpenEventArgs) {
    if ($event.fileDetails["name"].includes(".")) {
      $event.cancel = true;

      if (this.modal.hasOpenModals()) {
        return;
      }

      const event: OpenFileEventArgs = {
        fileDetails: [$event.fileDetails]
      };

      this.openFile(event);
    }
  }

  private handleSyncfusionModalClose() {
    setTimeout(() => {
      const attrObserver = new MutationObserver((mutations) => {
        mutations.forEach(mu => {
          if (mu.type !== "attributes" && mu.attributeName !== "class") {
            return;
          }

          const toolbarItems = Array.from(document.getElementsByClassName("e-tbar-btn e-tbtn-txt e-control e-btn e-lib"));

          toolbarItems.forEach((item, i) => {
            const itemAsHtmlElement: HTMLElement = item as HTMLElement;

            if (itemAsHtmlElement != null) {
              this.makeElementInactive(itemAsHtmlElement);
            }

          });
        });
      });

      const fileManager = Array.from(document.getElementsByClassName("e-filemanager"));
      fileManager.forEach(el => attrObserver.observe(el, { attributes: true }));
    }, 1000);
  }

  private handleNgbModalClose() {

    setTimeout(() => {
      const fileManager = Array.from(document.getElementsByTagName("ngb-modal-window"));

      fileManager.forEach(el => el.removeEventListener("DOMNodeRemoved", this.unselectToolbarButtons.bind(this)));

      fileManager.forEach(el => el.addEventListener("DOMNodeRemoved", this.unselectToolbarButtons.bind(this)));
    }, 100);

  }

  private unselectToolbarButtons() {
    setTimeout(() => {
      const toolbarItems = Array.from(document.getElementsByClassName("e-tbar-btn e-tbtn-txt e-control e-btn e-lib"));

      toolbarItems.forEach((item) => {
        const itemAsHtmlElement: HTMLElement = item as HTMLElement;

        if (itemAsHtmlElement != null) {
          this.makeElementInactive(itemAsHtmlElement);
        }

      });
    }, 0);
  }

  private handleToolbarClick(args: ToolbarClickEventArgs) {
    let toolbarItems: Element[];
    setTimeout(() => {
      if (args.item.tooltipText === "Sort by" || args.item.tooltipText === "View") {
        setTimeout(() => {
          toolbarItems = Array.from(document.getElementsByClassName("e-item"));

          toolbarItems.forEach((item, i) => {
            const itemAsHtmlElement: HTMLElement = item as HTMLElement;

            if (itemAsHtmlElement != null) {
              itemAsHtmlElement.addEventListener("click", () => {
                const activeSelectBox: HTMLElement = document.querySelector(".e-active");

                if (activeSelectBox != null) {
                  setTimeout(() => {
                    this.makeElementInactive(activeSelectBox);
                  }, 100);
                }
              });
            }
          });
        }, 0);
        return;
      }

      toolbarItems = Array.from(document.getElementsByClassName("e-tbar-btn e-tbtn-txt e-control e-btn e-lib"));

      toolbarItems.forEach((item, i) => {
        const itemAsHtmlElement: HTMLElement = item as HTMLElement;

        if (itemAsHtmlElement != null) {
          this.makeElementInactive(itemAsHtmlElement);
        }

      });
    }, 200);
  }

  private makeElementInactive(element: HTMLElement): void {
    if (element) {
      element.blur();
      element.classList.remove("e-active");
    }
  }

  private getFormattedFileName(fullFileName: string): string {
    // Format an empty space
    fullFileName = fullFileName.replace(/\s\s\s*/g, "  ").trimLeft();
    return this.formatFileNameExtension(fullFileName);
  }

  private formatFileNameExtension(fullFileName: string): string {
    // Get extension
    const regex = /\.[^/.]+$/g;
    const found = fullFileName.match(regex);

    // Format extension
    if (found && found.length >= 1) {
      const allButLast = found[0].slice(0, found[0].length - 1);
      const extension = allButLast.replace(/\s/g, "");
      fullFileName = fullFileName.replace(allButLast, extension);
    }

    return fullFileName;
  }

}

interface AjaxSettings {
  type: string;
  url: string;
  beforeSend: Function;
  data: string;
  dataType: string;
  mode: boolean;
}

interface AjaxSettingsData {
  action: string;
  path: string;
  dateModified: Date;
  dateCreated: Date;
  size: number;
  hasChild: boolean;
  isFile: boolean;
  names: string[];
  name: string;
  newName: string;
  targetPath: string;
}

interface SuccessEvent {
  name: string;
  action: string;
}


interface OpenFileEventArgs {
  fileDetails?: Object[];
}

