import { Injectable } from "@angular/core";
import { OAuthService, UserInfo } from "angular-oauth2-oidc";
import { from, Observable, of } from "rxjs";
import { map, share, tap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class UserProfileService {
  private userProfile: UserInfo = null;
  private userProfile$: Observable<UserInfo> = null;

  constructor(public authService: OAuthService) { }

  public getUserProfile$(): Observable<UserInfo> {
    if (this.userProfile) {
      return of(this.userProfile);
    }

    if (!this.userProfile$) {
      this.userProfile$ = from(this.authService.loadUserProfile())
        .pipe(
          share(),
          tap(profile => this.cacheUserProfile(profile)),
          map(profile => profile)
        );
    }

    return this.userProfile$;
  }

  public cacheUserProfile(profile: UserInfo): void {
    this.userProfile = profile;
  }
}
