import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { GridLayoutLookupDTO } from "../swagger-gen/fordesk";
import { GridLayoutUpserted } from "../../models/utilities/grid-layouts/grid-layout-upserted.model";

@Injectable({ providedIn: "root" })
export class GridLayoutCommunicationService {

  private layoutDeleted$: Subject<GridLayoutLookupDTO> = new Subject<GridLayoutLookupDTO>();
  private layoutEdited$: Subject<GridLayoutUpserted> = new Subject<GridLayoutUpserted>();

  public getLayoutDeleted$(): Observable<GridLayoutLookupDTO> {
    return this.layoutDeleted$.asObservable();
  }

  public getLayoutEdited$(): Observable<GridLayoutUpserted> {
    return this.layoutEdited$.asObservable();
  }

  public delete(dto: GridLayoutLookupDTO): void {
    this.layoutDeleted$.next(dto);
  }

  public edit(dto: GridLayoutUpserted): void {
    this.layoutEdited$.next(dto);
  }
}
