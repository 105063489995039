import { Directive, HostListener, ElementRef, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Directive({
  selector: "[numbersOnly]"
})
export class NumbersOnlyDirective {
  private readonly regexStr: string = "^(?!$)[\\d,.]+$";

  @Input("formControl")
  public formControl: UntypedFormControl;

  constructor(private el: ElementRef) { }

  @HostListener("keypress", ["$event"]) public onKeyPress(event: KeyboardEvent): boolean {
    return new RegExp(this.regexStr).test(event.key);
  }

  @HostListener("paste", ["$event"]) public blockPaste(event: KeyboardEvent): void {
    this.validateFields(event);
  }

  public validateFields(event: any): void {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^0-9.,]/g, "");

      if (this.formControl) {
        this.formControl.setValue(this.el.nativeElement.value);
      }

      event.preventDefault();
    }, 100);
  }

}
