import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { EntityDetailsComponent } from '../../../core/components/abstractions/entity-details.component';
import propertyOf from "../../../core/models/utilities/name-of.type";
import { UnsavedChangesService } from '../../../core/services/helpers/unsaved-changes.service';
import { CompaniesService, CompanyAddressDTO, CompanyAddressesService, ContactPersonDetailsDTO, ContactPersonDTO, ContactPersonsService, ContactPersonTypesService, LanguagesService, LookupDTO } from '../../../core/services/swagger-gen/fordesk';
import { singleEmailValidator } from '../../../core/validators/email.validator';
import { requireOneCheckboxToBeCheckedValidator } from '../../../core/validators/minimum-checked.validator';
import { phoneRegexValidator } from '../../../core/validators/phone-validator';

export class AddContactPersonDialogData {
  companyId: number;
}

@Component({
  selector: 'add-contact-person-dialog',
  templateUrl: './add-contact-person-dialog.component.html',
  styleUrls: ['./add-contact-person-dialog.component.scss']
})
export class AddContactPersonDialogComponent extends EntityDetailsComponent implements OnInit, AfterViewInit {

  public contactPersonTypes: LookupDTO[] = [];
  public languages: LookupDTO[] = [];

  public cachableTypes: string[] = ["contactPersonTypeId"];

  constructor(
    private languageService: LanguagesService,
    private contactPersonTypeService: ContactPersonTypesService,
    private companyAddressService: CompanyAddressesService,
    private companyService: CompaniesService,
    private contactPersonService: ContactPersonsService,
    private fb: UntypedFormBuilder,
    unsavedChangedService: UnsavedChangesService,
    route: ActivatedRoute,
    public cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<AddContactPersonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddContactPersonDialogData) {

    super(route, unsavedChangedService);
  }

  public ngOnInit(): void {
    this.createForm();
    this.loadFormData();
  }

  public ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  private createForm(): void {
    this.detailsForm = this.fb.group({
      contactPersonTypeId: [null, Validators.required],
      firstName: [null, [Validators.required, Validators.maxLength(128)]],
      name: [null, [Validators.required, Validators.maxLength(128)]],
      phone: [null, [phoneRegexValidator(), Validators.required, Validators.maxLength(64)]],
      mobile: [null, [Validators.maxLength(64), phoneRegexValidator()]],
      email: [null, [Validators.required, singleEmailValidator(), Validators.maxLength(256)]],
      languageId: [null, Validators.required],
      addresses: this.fb.array([])
    });
  }

  public saveInternal() {
    const selectedAddresses = this.detailsForm
      .get("addresses")
      .value.filter((address: any) => address && address.isChecked)
      .map((address: any) => address.id);

    const contactPerson: ContactPersonDTO = {
      contactPersonTypeId: this.getFormValue("contactPersonTypeId"),
      firstName: this.getFormValue("firstName"),
      name: this.getFormValue("name"),
      phone: this.getFormValue("phone"),
      mobile: this.getFormValue("mobile"),
      email: this.getFormValue("email"),
      languageId: this.getFormValue("languageId"),
      addressIds: selectedAddresses
    };
    // TODO: find out what's wrong with type conversion
    // contactPerson = this.detailsForm.getRawValue();

    this.contactPersonService.addContactPerson(contactPerson).subscribe((data) => {
      this.dialogRef.close(data);
    });
  }

  private loadFormData(): void {

    forkJoin({
      loadedLanguages: this.languageService.getAllLanguages(),
      loadedContactPersonTypes: this.contactPersonTypeService.getAllContactPersonTypes(),
      companyAddresses: this.companyAddressService.getAllCompanyAddressesByCompanyId(this.data.companyId),
      companyDetails: this.companyService.getCompanyDetails(this.data.companyId)
    }).subscribe(
      ({ loadedLanguages,
        loadedContactPersonTypes,
        companyAddresses,
        companyDetails
      }) => {

        this.languages = loadedLanguages ? loadedLanguages.results : [];
        const contactPersonTypes = loadedLanguages ? loadedContactPersonTypes.results : [];

        this.loadAddresses(companyAddresses, []);
        if (companyDetails && companyDetails.languageId) {
          this.f.languageId.setValue(companyDetails.languageId);
        }

        this.refreshByCache(propertyOf<AddContactPersonDialogComponent>("contactPersonTypes"), contactPersonTypes, "contactPersonTypeId");

        this.cdRef.detectChanges();
      }
    );
  }

  private loadAddresses(companyAddresses: CompanyAddressDTO[], selectedAddresses: LookupDTO[]): void {
    const addresses: UntypedFormArray = this.detailsForm.get("addresses") as UntypedFormArray;

    let onlyOneAddress = companyAddresses.length === 1;

    companyAddresses.forEach(ca => {
      const isSelected = selectedAddresses.some(sa => ca.id === sa.id);
      const address = {
        isChecked: isSelected || onlyOneAddress,
        ...ca
      };

      addresses.push(this.fb.group(address));
    });

    this.detailsForm.controls["addresses"].setValidators(
      requireOneCheckboxToBeCheckedValidator()
    );
  }

  public cancel() {
    this.dialogRef.close(null);
  }

}
