/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateFolderRequest } from '../model/createFolderRequest';
import { RenameFileRequest } from '../model/renameFileRequest';
import { Response } from '../model/response';
import { RootObject } from '../model/rootObject';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class QuoteFileDocumentsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Delete a quote file document
     * 
     * @param quoteFileId 
     * @param fileName 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(quoteFileId: number, fileName: string, body?: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<RootObject>;
    public _delete(quoteFileId: number, fileName: string, body?: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<RootObject>>;
    public _delete(quoteFileId: number, fileName: string, body?: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<RootObject>>;
    public _delete(quoteFileId: number, fileName: string, body?: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (quoteFileId === null || quoteFileId === undefined) {
            throw new Error('Required parameter quoteFileId was null or undefined when calling _delete.');
        }

        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling _delete.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.delete<RootObject>(`${this.basePath}/api/quotes/quote-files/${encodeURIComponent(String(quoteFileId))}/documents/${encodeURIComponent(String(fileName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Add a quote file folder
     * 
     * @param body 
     * @param quoteFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createFolder(body: CreateFolderRequest, quoteFileId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<RootObject>;
    public createFolder(body: CreateFolderRequest, quoteFileId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<RootObject>>;
    public createFolder(body: CreateFolderRequest, quoteFileId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<RootObject>>;
    public createFolder(body: CreateFolderRequest, quoteFileId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createFolder.');
        }

        if (quoteFileId === null || quoteFileId === undefined) {
            throw new Error('Required parameter quoteFileId was null or undefined when calling createFolder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<RootObject>(`${this.basePath}/api/quotes/quote-files/${encodeURIComponent(String(quoteFileId))}/documents/folders`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Download a quote file document
     * 
     * @param quoteFileId 
     * @param fileName 
     * @param path 
     * @param useMasterTemplate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public download(quoteFileId: number, fileName: string, path?: string, useMasterTemplate?: boolean, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public download(quoteFileId: number, fileName: string, path?: string, useMasterTemplate?: boolean, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public download(quoteFileId: number, fileName: string, path?: string, useMasterTemplate?: boolean, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public download(quoteFileId: number, fileName: string, path?: string, useMasterTemplate?: boolean, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (quoteFileId === null || quoteFileId === undefined) {
            throw new Error('Required parameter quoteFileId was null or undefined when calling download.');
        }

        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling download.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (useMasterTemplate !== undefined && useMasterTemplate !== null) {
            queryParameters = queryParameters.set('useMasterTemplate', <any>useMasterTemplate);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (path !== undefined) {
            formParams = formParams.append('path', <any>path) || formParams;
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Blob>(`${this.basePath}/api/quotes/quote-files/${encodeURIComponent(String(quoteFileId))}/documents/${encodeURIComponent(String(fileName))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Download a quote file document
     * 
     * @param quoteFileId 
     * @param fileName 
     * @param path 
     * @param useMasterTemplate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public download_1(quoteFileId: number, fileName: string, path?: string, useMasterTemplate?: boolean, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public download_1(quoteFileId: number, fileName: string, path?: string, useMasterTemplate?: boolean, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public download_1(quoteFileId: number, fileName: string, path?: string, useMasterTemplate?: boolean, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public download_1(quoteFileId: number, fileName: string, path?: string, useMasterTemplate?: boolean, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (quoteFileId === null || quoteFileId === undefined) {
            throw new Error('Required parameter quoteFileId was null or undefined when calling download_1.');
        }

        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling download_1.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (useMasterTemplate !== undefined && useMasterTemplate !== null) {
            queryParameters = queryParameters.set('useMasterTemplate', <any>useMasterTemplate);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (path !== undefined) {
            formParams = formParams.append('path', <any>path) || formParams;
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Blob>(`${this.basePath}/api/quotes/quote-files/${encodeURIComponent(String(quoteFileId))}/documents/download`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get quote file documents
     * 
     * @param quoteFileId 
     * @param searchString 
     * @param path 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAll(quoteFileId: number, searchString?: string, path?: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<RootObject>;
    public getAll(quoteFileId: number, searchString?: string, path?: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<RootObject>>;
    public getAll(quoteFileId: number, searchString?: string, path?: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<RootObject>>;
    public getAll(quoteFileId: number, searchString?: string, path?: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (quoteFileId === null || quoteFileId === undefined) {
            throw new Error('Required parameter quoteFileId was null or undefined when calling getAll.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchString !== undefined && searchString !== null) {
            queryParameters = queryParameters.set('searchString', <any>searchString);
        }
        if (path !== undefined && path !== null) {
            queryParameters = queryParameters.set('path', <any>path);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<RootObject>(`${this.basePath}/api/quotes/quote-files/${encodeURIComponent(String(quoteFileId))}/documents`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get all quote file documents with subdirectories
     * 
     * @param quoteFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllQuoteFileDocumentsWithSubdirectories(quoteFileId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<any>>;
    public getAllQuoteFileDocumentsWithSubdirectories(quoteFileId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<any>>>;
    public getAllQuoteFileDocumentsWithSubdirectories(quoteFileId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<any>>>;
    public getAllQuoteFileDocumentsWithSubdirectories(quoteFileId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (quoteFileId === null || quoteFileId === undefined) {
            throw new Error('Required parameter quoteFileId was null or undefined when calling getAllQuoteFileDocumentsWithSubdirectories.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<any>>(`${this.basePath}/api/quotes/quote-files/${encodeURIComponent(String(quoteFileId))}/documents/all-quote-files-documents-with-subdirectories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Rename a quote file document
     * 
     * @param body 
     * @param quoteFileId 
     * @param fileName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rename(body: RenameFileRequest, quoteFileId: number, fileName: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<RootObject>;
    public rename(body: RenameFileRequest, quoteFileId: number, fileName: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<RootObject>>;
    public rename(body: RenameFileRequest, quoteFileId: number, fileName: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<RootObject>>;
    public rename(body: RenameFileRequest, quoteFileId: number, fileName: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling rename.');
        }

        if (quoteFileId === null || quoteFileId === undefined) {
            throw new Error('Required parameter quoteFileId was null or undefined when calling rename.');
        }

        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling rename.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.put<RootObject>(`${this.basePath}/api/quotes/quote-files/${encodeURIComponent(String(quoteFileId))}/documents/${encodeURIComponent(String(fileName))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Save a quote file document
     * 
     * @param quoteFileId 
     * @param documentTemplateId 
     * @param overwrite 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public save(quoteFileId: number, documentTemplateId?: number, overwrite?: boolean, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<RootObject>;
    public save(quoteFileId: number, documentTemplateId?: number, overwrite?: boolean, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<RootObject>>;
    public save(quoteFileId: number, documentTemplateId?: number, overwrite?: boolean, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<RootObject>>;
    public save(quoteFileId: number, documentTemplateId?: number, overwrite?: boolean, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (quoteFileId === null || quoteFileId === undefined) {
            throw new Error('Required parameter quoteFileId was null or undefined when calling save.');
        }



        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (documentTemplateId !== undefined) {
            formParams = formParams.append('documentTemplateId', <any>documentTemplateId) || formParams;
        }
        if (overwrite !== undefined) {
            formParams = formParams.append('overwrite', <any>overwrite) || formParams;
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<RootObject>(`${this.basePath}/api/quotes/quote-files/${encodeURIComponent(String(quoteFileId))}/documents`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Upload a quote file document
     * 
     * @param quoteFileId 
     * @param uploadFiles 
     * @param path 
     * @param overwrite 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public upload(quoteFileId: number, uploadFiles?: Array<Blob>, path?: string, overwrite?: boolean, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<RootObject>;
    public upload(quoteFileId: number, uploadFiles?: Array<Blob>, path?: string, overwrite?: boolean, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<RootObject>>;
    public upload(quoteFileId: number, uploadFiles?: Array<Blob>, path?: string, overwrite?: boolean, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<RootObject>>;
    public upload(quoteFileId: number, uploadFiles?: Array<Blob>, path?: string, overwrite?: boolean, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (quoteFileId === null || quoteFileId === undefined) {
            throw new Error('Required parameter quoteFileId was null or undefined when calling upload.');
        }




        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (uploadFiles) {
            uploadFiles.forEach((element) => {
                formParams = formParams.append('uploadFiles', <any>element) || formParams;
            })
        }
        if (path !== undefined) {
            formParams = formParams.append('path', <any>path) || formParams;
        }
        if (overwrite !== undefined) {
            formParams = formParams.append('overwrite', <any>overwrite) || formParams;
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<RootObject>(`${this.basePath}/api/quotes/quote-files/${encodeURIComponent(String(quoteFileId))}/documents/upload`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Save an operational file document
     * 
     * @param quoteFileId 
     * @param fileName 
     * @param file 
     * @param emails 
     * @param subject 
     * @param message 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadAfterEmail(quoteFileId: number, fileName?: string, file?: Blob, emails?: Array<string>, subject?: string, message?: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public uploadAfterEmail(quoteFileId: number, fileName?: string, file?: Blob, emails?: Array<string>, subject?: string, message?: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public uploadAfterEmail(quoteFileId: number, fileName?: string, file?: Blob, emails?: Array<string>, subject?: string, message?: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public uploadAfterEmail(quoteFileId: number, fileName?: string, file?: Blob, emails?: Array<string>, subject?: string, message?: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (quoteFileId === null || quoteFileId === undefined) {
            throw new Error('Required parameter quoteFileId was null or undefined when calling uploadAfterEmail.');
        }






        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (fileName !== undefined) {
            formParams = formParams.append('FileName', <any>fileName) || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('File', <any>file) || formParams;
        }
        if (emails) {
            emails.forEach((element) => {
                formParams = formParams.append('Emails', <any>element) || formParams;
            })
        }
        if (subject !== undefined) {
            formParams = formParams.append('Subject', <any>subject) || formParams;
        }
        if (message !== undefined) {
            formParams = formParams.append('Message', <any>message) || formParams;
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Response>(`${this.basePath}/api/quotes/quote-files/${encodeURIComponent(String(quoteFileId))}/documents/upload-after-email`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

}
