import { Directive, Input, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[decimalFormatter]'
})
export class DecimalFormatterDirective {
  @Input() decimalPlaces: number = 2; // Default to 2 decimal places

  constructor(private ngControl: NgControl) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    if (!value) return;

    const stringValue = value.toString();
    const decimalSplit = stringValue.includes('.') ? stringValue.split('.')[1] : '';
    const commaSplit = stringValue.includes(',') ? stringValue.split(',')[1] : '';

    const needsFormatting =
      (decimalSplit.length > this.decimalPlaces) ||
      (commaSplit.length > this.decimalPlaces);

    if (needsFormatting) {
      // Normalize the input by replacing commas and restricting to the necessary decimal places
      const normalizedValue = stringValue.replace(/,/g, '');
      const formatted = parseFloat(normalizedValue).toFixed(this.decimalPlaces);
      this.ngControl.valueAccessor.writeValue(formatted);
    }
  }
}