import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter, take, takeUntil } from "rxjs/operators";
import * as objectPath from "object-path";
import { MenuHorizontalOffcanvasDirective } from "../../../../core/directives/menu-horizontal-offcanvas.directive";
import { MenuHorizontalDirective } from "../../../../core/directives/menu-horizontal.directive";
import { MenuHorizontalService } from "../../../../core/services/layout/menu-horizontal.service";
import { MenuConfigService } from "../../../../core/services/menu-config.service";
import { ClassInitService } from "../../../../core/services/class-init.service";
import { UPLOAD_UBL_DIALOG } from "../../../../core/services/helpers/dialog-identifiers";
import { MatDialog } from "@angular/material/dialog";
import { UploadUblDialog } from "../../../dialogs/upload-ubl-dialog/upload-ubl-dialog.component";
import { ToasterService } from "../../toaster/toaster.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UnsavedChangesService } from "../../../../core/services/helpers/unsaved-changes.service";
import { ComponentBase } from "../../../../core/components/abstractions/component-base";

@Component({
  selector: "m-menu-horizontal",
  templateUrl: "./menu-horizontal.component.html",
  changeDetection: ChangeDetectionStrategy.Default
})
export class MenuHorizontalComponent extends ComponentBase implements OnInit, AfterViewInit {
  @HostBinding("class") public classes: string = "";
  @HostBinding("id") public id: string = "m_header_menu";

  @HostBinding("attr.mMenuHorizontalOffcanvas")
  public mMenuHorOffcanvas: MenuHorizontalOffcanvasDirective;
  @HostBinding("attr.mMenuHorizontal")
  public mMenuHorizontal: MenuHorizontalDirective;

  public currentRouteUrl: any = "";
  public activeItem: any;
  public itemsWithAsides: any[] = [];

  constructor(
    private el: ElementRef,
    public classInitService: ClassInitService,
    public menuHorService: MenuHorizontalService,
    private menuConfigService: MenuConfigService,
    private router: Router,
    private modal: NgbModal,
    private toaster: ToasterService,
    private unsavedChangesService: UnsavedChangesService
  ) {
    super();
    this.classes = this.menuHorService.menuClasses;
  }

  public ngAfterViewInit(): void {
    Promise.resolve(null).then(() => {
      this.mMenuHorOffcanvas = new MenuHorizontalOffcanvasDirective(this.el);
      this.mMenuHorOffcanvas.ngAfterViewInit();

      this.mMenuHorizontal = new MenuHorizontalDirective(this.el);
      this.mMenuHorizontal.ngAfterViewInit();
    });
  }

  public ngOnInit(): void {
    this.currentRouteUrl = this.router.url;
    this.menuHorService.menuList$.subscribe(menuItems => this.fillAsides(menuItems));

    this.shouldOverrideAsides();

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe))
      .subscribe(event => {
        this.currentRouteUrl = this.router.url;
        this.shouldOverrideAsides();
      });
  }

  public shouldOverrideAsides() {
    const aside = this.getActiveItemAside();
    if (aside) {
      // override aside menu as secondary menu of current header menu
      this.menuConfigService.configModel.config.aside = aside;
      this.menuConfigService.setModel(this.menuConfigService.configModel);
    }
  }

  public fillAsides(menuItems: any[]) {
    for (const menuItem of menuItems) {
      if (menuItem.aside) {
        this.itemsWithAsides.push(menuItem);
      }

      if (menuItem.submenu && menuItem.submenu.items) {
        this.fillAsides(menuItem.submenu.items);
      }
    }
  }

  public getActiveItemAside() {
    if (this.currentRouteUrl === "") {
      return null;
    }

    for (const item of this.itemsWithAsides) {
      if (item.page && item.page === this.currentRouteUrl) {
        return item.aside;
      }
    }
  }

  public getItemCssClasses(item: any) {
    let cssClasses = "m-menu__item";

    if (objectPath.get(item, "submenu")) {
      cssClasses += " m-menu__item--submenu";
    }

    if (objectPath.get(item, "resizer")) {
      cssClasses += " m-menu__item--resize";
    }

    if (
      (objectPath.get(item, "root") &&
        objectPath.get(item, "submenu.type") === "classic") ||
      parseInt(objectPath.get(item, "submenu.width"), 2) > 0
    ) {
      cssClasses += " m-menu__item--rel";
    }

    const customClass = objectPath.get(item, "custom-class");
    if (customClass) {
      cssClasses += " " + customClass;
    }

    if (objectPath.get(item, "icon-only")) {
      cssClasses += " m-menu__item--icon-only";
    }

    if (item.submenu && this.isMenuItemIsActive(item)) {
      cssClasses += " m-menu__item--active";
    }

    return cssClasses;
  }

  public getItemAttrLinkRedirect(menuItem: any): any {
    if (objectPath.get(menuItem, "redirect")) {
      return "1";
    }

    return null;
  }

  public getItemAttrResizeDesktopBreakpoint(menuItem: any) {
    if (objectPath.get(menuItem, "resizer")) {
      return objectPath.get(menuItem, "resize-breakpoint");
    }

    return null;
  }

  public getItemAttrSubmenuToggle(menuItem: any) {
    let attrSubmenuToggle = "hover";
    if (objectPath.get(menuItem, "toggle") === "click") {
      attrSubmenuToggle = "click";
    } else if (objectPath.get(menuItem, "submenu.type") === "tabs") {
      attrSubmenuToggle = "tabs";
    } else {
      // submenu toggle default to 'hover'
    }

    return attrSubmenuToggle;
  }

  public getItemAttrSubmenuMode(menuItem: any) {
    return null;
  }

  public getItemMenuSubmenuClass(menuItem: any) {
    let subClass = "";

    const subAlignment = objectPath.get(menuItem, "submenu.alignment");
    if (subAlignment) {
      subClass += " m-menu__submenu--" + subAlignment;
    }

    if (objectPath.get(menuItem, "submenu.type") === "classic") {
      subClass += " m-menu__submenu--classic";
    }

    if (objectPath.get(menuItem, "submenu.type") === "tabs") {
      subClass += " m-menu__submenu--tabs";
    }

    if (objectPath.get(menuItem, "submenu.type") === "mega") {
      if (objectPath.get(menuItem, "submenu.width")) {
        subClass += " m-menu__submenu--fixed";
      }
    }

    if (objectPath.get(menuItem, "submenu.pull")) {
      subClass += " m-menu__submenu--pull";
    }

    return subClass;
  }

  public isMenuItemIsActive(item: any): boolean {
    if (item.submenu) {
      return this.isMenuRootItemIsActive(item);
    }

    if (!item.page) {
      return false;
    }

    return item.page === this.currentRouteUrl;
  }

  public isMenuRootItemIsActive(item: any): boolean {
    if (item.submenu.columns) {
      for (const column of item.submenu.columns) {
        for (const subItem of column.items) {
          if (this.isMenuItemIsActive(subItem)) {
            return true;
          }
        }
      }
    } else if (item.submenu.items) {
      for (const subItem of item.submenu.items) {
        if (this.isMenuItemIsActive(subItem)) {
          return true;
        }
      }
    }

    return false;
  }

  public openDialog(dialogIdentifier: string) {
    if (dialogIdentifier === UPLOAD_UBL_DIALOG) {
      this.unsavedChangesService.isAllowed().subscribe(allowed => {
        if (allowed) {
          this.modal.open(UploadUblDialog, {
            backdrop: 'static',
            keyboard: false
          });
        }
      });
    }
  }
}
