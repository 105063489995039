/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CompanyRelatedEmailDTO } from '../model/companyRelatedEmailDTO';
import { CopyOperationalFileDto } from '../model/copyOperationalFileDto';
import { EmailTemplateTypeDTO } from '../model/emailTemplateTypeDTO';
import { FormOperationalFile } from '../model/formOperationalFile';
import { FormattedEmailTemplateDTO } from '../model/formattedEmailTemplateDTO';
import { NewOperationalFileDTO } from '../model/newOperationalFileDTO';
import { OCRModifiedOperationalFile } from '../model/oCRModifiedOperationalFile';
import { OperationalFileCompactDetailsDTO } from '../model/operationalFileCompactDetailsDTO';
import { OperationalFileExcelExportSearchCondition } from '../model/operationalFileExcelExportSearchCondition';
import { OperationalFileFullDetailsDTO } from '../model/operationalFileFullDetailsDTO';
import { OperationalFileIdentifierDTO } from '../model/operationalFileIdentifierDTO';
import { OperationalFilePortsDTO } from '../model/operationalFilePortsDTO';
import { OperationalFileSearchCondition } from '../model/operationalFileSearchCondition';
import { OperationalFileSummaryDTO } from '../model/operationalFileSummaryDTO';
import { OperationalFileTariffDetailsDTO } from '../model/operationalFileTariffDetailsDTO';
import { OperationalFileTransportWizardDetailsDTO } from '../model/operationalFileTransportWizardDetailsDTO';
import { OperationalFileVatDetails } from '../model/operationalFileVatDetails';
import { PagedResponseFoundOperationalFileDTO } from '../model/pagedResponseFoundOperationalFileDTO';
import { Response } from '../model/response';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OperationalFileService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add an operational file
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOperationalFile(body?: NewOperationalFileDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OperationalFileFullDetailsDTO>;
    public addOperationalFile(body?: NewOperationalFileDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OperationalFileFullDetailsDTO>>;
    public addOperationalFile(body?: NewOperationalFileDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OperationalFileFullDetailsDTO>>;
    public addOperationalFile(body?: NewOperationalFileDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<OperationalFileFullDetailsDTO>(`${this.basePath}/api/operational/operational-files`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Cancel an operational file
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancel(id: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public cancel(id: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public cancel(id: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public cancel(id: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling cancel.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.delete<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Change a budget status
     * 
     * @param id 
     * @param openBudget 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeBudgetStatus(id: number, openBudget?: boolean, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public changeBudgetStatus(id: number, openBudget?: boolean, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public changeBudgetStatus(id: number, openBudget?: boolean, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public changeBudgetStatus(id: number, openBudget?: boolean, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling changeBudgetStatus.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (openBudget !== undefined && openBudget !== null) {
            queryParameters = queryParameters.set('openBudget', <any>openBudget);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.patch<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(id))}`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Check that can create outgoing invoice using this operations file
     * 
     * @param operationalFileId 
     * @param selectedRevenueIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkValidOperationalFileForOutgoingInvoice(operationalFileId: number, selectedRevenueIds: Array<number>, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<boolean>;
    public checkValidOperationalFileForOutgoingInvoice(operationalFileId: number, selectedRevenueIds: Array<number>, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<boolean>>;
    public checkValidOperationalFileForOutgoingInvoice(operationalFileId: number, selectedRevenueIds: Array<number>, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<boolean>>;
    public checkValidOperationalFileForOutgoingInvoice(operationalFileId: number, selectedRevenueIds: Array<number>, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling checkValidOperationalFileForOutgoingInvoice.');
        }

        if (selectedRevenueIds === null || selectedRevenueIds === undefined) {
            throw new Error('Required parameter selectedRevenueIds was null or undefined when calling checkValidOperationalFileForOutgoingInvoice.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (selectedRevenueIds) {
            selectedRevenueIds.forEach((element) => {
                queryParameters = queryParameters.append('selectedRevenueIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<boolean>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/validate-invoice-customers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Close an operational file
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public close(id: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public close(id: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public close(id: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public close(id: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling close.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.patch<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(id))}/close`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Copy an operational file.
     * 
     * @param operationalFileId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copyOperationalFile(operationalFileId: number, body?: CopyOperationalFileDto, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<number>;
    public copyOperationalFile(operationalFileId: number, body?: CopyOperationalFileDto, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<number>>;
    public copyOperationalFile(operationalFileId: number, body?: CopyOperationalFileDto, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<number>>;
    public copyOperationalFile(operationalFileId: number, body?: CopyOperationalFileDto, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling copyOperationalFile.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<number>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/copy`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Download a report
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadReport(body?: OperationalFileExcelExportSearchCondition, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public downloadReport(body?: OperationalFileExcelExportSearchCondition, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public downloadReport(body?: OperationalFileExcelExportSearchCondition, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public downloadReport(body?: OperationalFileExcelExportSearchCondition, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post(`${this.basePath}/api/operational/operational-files/download`,
            body,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Extract operationalFile using OCR
     * 
     * @param operationalFileId 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public extractOperationalFileUsingOCR(operationalFileId: number, file?: Blob, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<FormOperationalFile>;
    public extractOperationalFileUsingOCR(operationalFileId: number, file?: Blob, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<FormOperationalFile>>;
    public extractOperationalFileUsingOCR(operationalFileId: number, file?: Blob, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<FormOperationalFile>>;
    public extractOperationalFileUsingOCR(operationalFileId: number, file?: Blob, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling extractOperationalFileUsingOCR.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<FormOperationalFile>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/extract-using-ocr`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get operational file invoice customers
     * 
     * @param operationalFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllInvoiceCustomers(operationalFileId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<number>>;
    public getAllInvoiceCustomers(operationalFileId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<number>>>;
    public getAllInvoiceCustomers(operationalFileId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<number>>>;
    public getAllInvoiceCustomers(operationalFileId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getAllInvoiceCustomers.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<number>>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/invoice-customers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get operational file by file number.
     * 
     * @param fileNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByFileNumber(fileNumber: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OperationalFileCompactDetailsDTO>;
    public getByFileNumber(fileNumber: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OperationalFileCompactDetailsDTO>>;
    public getByFileNumber(fileNumber: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OperationalFileCompactDetailsDTO>>;
    public getByFileNumber(fileNumber: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (fileNumber === null || fileNumber === undefined) {
            throw new Error('Required parameter fileNumber was null or undefined when calling getByFileNumber.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fileNumber !== undefined && fileNumber !== null) {
            queryParameters = queryParameters.set('fileNumber', <any>fileNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<OperationalFileCompactDetailsDTO>(`${this.basePath}/api/operational/operational-files`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get an operational file compact details
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompactDetails(id: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OperationalFileCompactDetailsDTO>;
    public getCompactDetails(id: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OperationalFileCompactDetailsDTO>>;
    public getCompactDetails(id: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OperationalFileCompactDetailsDTO>>;
    public getCompactDetails(id: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getCompactDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<OperationalFileCompactDetailsDTO>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(id))}/compact`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get an operational file details
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDetails(id: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OperationalFileFullDetailsDTO>;
    public getDetails(id: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OperationalFileFullDetailsDTO>>;
    public getDetails(id: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OperationalFileFullDetailsDTO>>;
    public getDetails(id: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<OperationalFileFullDetailsDTO>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(id))}/full`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get a list of email addresses for mailing autocomplete for operation file.
     * 
     * @param operationalFileId 
     * @param searchTerm 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEmailSuggestions(operationalFileId: number, searchTerm?: string, pageSize?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<CompanyRelatedEmailDTO>>;
    public getEmailSuggestions(operationalFileId: number, searchTerm?: string, pageSize?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<CompanyRelatedEmailDTO>>>;
    public getEmailSuggestions(operationalFileId: number, searchTerm?: string, pageSize?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<CompanyRelatedEmailDTO>>>;
    public getEmailSuggestions(operationalFileId: number, searchTerm?: string, pageSize?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getEmailSuggestions.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchTerm !== undefined && searchTerm !== null) {
            queryParameters = queryParameters.set('searchTerm', <any>searchTerm);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<CompanyRelatedEmailDTO>>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/mails/autocomplete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get operational file details for transport wizard
     * 
     * @param operationalFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getForTransportWizardDetails(operationalFileId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OperationalFileTransportWizardDetailsDTO>;
    public getForTransportWizardDetails(operationalFileId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OperationalFileTransportWizardDetailsDTO>>;
    public getForTransportWizardDetails(operationalFileId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OperationalFileTransportWizardDetailsDTO>>;
    public getForTransportWizardDetails(operationalFileId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getForTransportWizardDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<OperationalFileTransportWizardDetailsDTO>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/transport-wizard-details`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get operational file email template types
     * 
     * @param operationalFileId 
     * @param emailTemplateCategory 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOperationalFileEmailTemplateTypes(operationalFileId: number, emailTemplateCategory?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<EmailTemplateTypeDTO>>;
    public getOperationalFileEmailTemplateTypes(operationalFileId: number, emailTemplateCategory?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<EmailTemplateTypeDTO>>>;
    public getOperationalFileEmailTemplateTypes(operationalFileId: number, emailTemplateCategory?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<EmailTemplateTypeDTO>>>;
    public getOperationalFileEmailTemplateTypes(operationalFileId: number, emailTemplateCategory?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getOperationalFileEmailTemplateTypes.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (emailTemplateCategory !== undefined && emailTemplateCategory !== null) {
            queryParameters = queryParameters.set('emailTemplateCategory', <any>emailTemplateCategory);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<EmailTemplateTypeDTO>>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/email-template-types`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get operational file formatted email template
     * 
     * @param operationalFileId 
     * @param emailTemplateTypeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOperationalFileFormattedEmailTemplate(operationalFileId: number, emailTemplateTypeId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<FormattedEmailTemplateDTO>;
    public getOperationalFileFormattedEmailTemplate(operationalFileId: number, emailTemplateTypeId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<FormattedEmailTemplateDTO>>;
    public getOperationalFileFormattedEmailTemplate(operationalFileId: number, emailTemplateTypeId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<FormattedEmailTemplateDTO>>;
    public getOperationalFileFormattedEmailTemplate(operationalFileId: number, emailTemplateTypeId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getOperationalFileFormattedEmailTemplate.');
        }

        if (emailTemplateTypeId === null || emailTemplateTypeId === undefined) {
            throw new Error('Required parameter emailTemplateTypeId was null or undefined when calling getOperationalFileFormattedEmailTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<FormattedEmailTemplateDTO>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/email-template-types/${encodeURIComponent(String(emailTemplateTypeId))}/formatted`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get all ports for an operational file
     * 
     * @param operationalFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOperationalFilePorts(operationalFileId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OperationalFilePortsDTO>;
    public getOperationalFilePorts(operationalFileId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OperationalFilePortsDTO>>;
    public getOperationalFilePorts(operationalFileId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OperationalFilePortsDTO>>;
    public getOperationalFilePorts(operationalFileId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getOperationalFilePorts.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<OperationalFilePortsDTO>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/ports`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Change a shipment type
     * 
     * @param operationalFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getShipmentType(operationalFileId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<number>;
    public getShipmentType(operationalFileId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<number>>;
    public getShipmentType(operationalFileId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<number>>;
    public getShipmentType(operationalFileId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getShipmentType.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<number>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/shipment-type`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Change a status
     * 
     * @param operationalFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStatus(operationalFileId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<number>;
    public getStatus(operationalFileId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<number>>;
    public getStatus(operationalFileId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<number>>;
    public getStatus(operationalFileId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getStatus.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<number>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/status`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Change a summary
     * 
     * @param operationalFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSummary(operationalFileId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OperationalFileSummaryDTO>;
    public getSummary(operationalFileId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OperationalFileSummaryDTO>>;
    public getSummary(operationalFileId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OperationalFileSummaryDTO>>;
    public getSummary(operationalFileId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<OperationalFileSummaryDTO>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/summary`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Change a tariff details
     * 
     * @param operationalFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTariffDetails(operationalFileId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OperationalFileTariffDetailsDTO>;
    public getTariffDetails(operationalFileId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OperationalFileTariffDetailsDTO>>;
    public getTariffDetails(operationalFileId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OperationalFileTariffDetailsDTO>>;
    public getTariffDetails(operationalFileId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getTariffDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<OperationalFileTariffDetailsDTO>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/tariff-details`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get vat details from operational file.
     * 
     * @param operationalFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVatDetails(operationalFileId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OperationalFileVatDetails>;
    public getVatDetails(operationalFileId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OperationalFileVatDetails>>;
    public getVatDetails(operationalFileId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OperationalFileVatDetails>>;
    public getVatDetails(operationalFileId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getVatDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<OperationalFileVatDetails>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/vat-details`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Open an operational file
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public open(id: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public open(id: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public open(id: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public open(id: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling open.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.patch<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(id))}/open`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Search an operational file
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public search(body?: OperationalFileSearchCondition, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PagedResponseFoundOperationalFileDTO>;
    public search(body?: OperationalFileSearchCondition, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PagedResponseFoundOperationalFileDTO>>;
    public search(body?: OperationalFileSearchCondition, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PagedResponseFoundOperationalFileDTO>>;
    public search(body?: OperationalFileSearchCondition, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<PagedResponseFoundOperationalFileDTO>(`${this.basePath}/api/operational/operational-files/search`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get all operational file identifiers based on an operational file number
     * 
     * @param operationalFileNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchOperationalFileIdentifiers(operationalFileNumber?: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<OperationalFileIdentifierDTO>>;
    public searchOperationalFileIdentifiers(operationalFileNumber?: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<OperationalFileIdentifierDTO>>>;
    public searchOperationalFileIdentifiers(operationalFileNumber?: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<OperationalFileIdentifierDTO>>>;
    public searchOperationalFileIdentifiers(operationalFileNumber?: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (operationalFileNumber !== undefined && operationalFileNumber !== null) {
            queryParameters = queryParameters.set('operationalFileNumber', <any>operationalFileNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<OperationalFileIdentifierDTO>>(`${this.basePath}/api/operational/operational-files/identifiers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Update operationalFile using OCR
     * 
     * @param operationalFileId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOperationalFileUsingOCR(operationalFileId: number, body?: OCRModifiedOperationalFile, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public updateOperationalFileUsingOCR(operationalFileId: number, body?: OCRModifiedOperationalFile, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public updateOperationalFileUsingOCR(operationalFileId: number, body?: OCRModifiedOperationalFile, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public updateOperationalFileUsingOCR(operationalFileId: number, body?: OCRModifiedOperationalFile, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling updateOperationalFileUsingOCR.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/update-using-ocr`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

}
