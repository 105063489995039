import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { PartyLookupDTO } from "../../../core/services/swagger-gen/fordesk";

@Component({
  selector: "destination-details-card",
  templateUrl: "./destination-details-card.component.html",
  styleUrls: ['./destination-details-card.component.scss']
})
export class DestinationDetailsCardComponent implements OnInit {
  @Input() public party: PartyLookupDTO;
  @Input() public index: number;
  @Input() public isReadonly: boolean = false;
  @Input() public displayCustomerName: boolean = true;

  @Output() public remove: EventEmitter<number> = new EventEmitter();

  constructor() { }

  public ngOnInit() {
  }

  public emitRemoved() {
    this.remove.emit(this.party.id);
  }
}
