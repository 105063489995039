/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AddBookingDTO { 
    remarks?: string;
    contractNumber?: string;
    freightTariffNumber?: string;
    chargePaymentType?: AddBookingDTO.ChargePaymentTypeEnum;
    chargeType?: AddBookingDTO.ChargeTypeEnum;
    haulageArrangementId?: number;
    isHaulageArrangementManuallyChanged?: boolean;
    hasCarrierAMSFiling?: boolean;
    bookingOfficeId?: number;
    operationalFileContainerIds?: Array<number>;
    containerGoodsIds?: Array<number>;
    linkedChargeCategoryParties?: Array<number>;
}
export namespace AddBookingDTO {
    export type ChargePaymentTypeEnum = 0 | 1 | 2;
    export const ChargePaymentTypeEnum = {
        NUMBER_0: 0 as ChargePaymentTypeEnum,
        NUMBER_1: 1 as ChargePaymentTypeEnum,
        NUMBER_2: 2 as ChargePaymentTypeEnum
    };
    export type ChargeTypeEnum = 0 | 1 | 2 | 3 | 4 | 5;
    export const ChargeTypeEnum = {
        NUMBER_0: 0 as ChargeTypeEnum,
        NUMBER_1: 1 as ChargeTypeEnum,
        NUMBER_2: 2 as ChargeTypeEnum,
        NUMBER_3: 3 as ChargeTypeEnum,
        NUMBER_4: 4 as ChargeTypeEnum,
        NUMBER_5: 5 as ChargeTypeEnum
    };
}