/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TerminalDTO } from './terminalDTO';

export interface OperationalFileTransportWizardDetailsDTO { 
    operationalFileType?: OperationalFileTransportWizardDetailsDTO.OperationalFileTypeEnum;
    closingVessel?: Date;
    etd?: Date;
    eta?: Date;
    terminal?: TerminalDTO;
}
export namespace OperationalFileTransportWizardDetailsDTO {
    export type OperationalFileTypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const OperationalFileTypeEnum = {
        NUMBER_0: 0 as OperationalFileTypeEnum,
        NUMBER_1: 1 as OperationalFileTypeEnum,
        NUMBER_2: 2 as OperationalFileTypeEnum,
        NUMBER_3: 3 as OperationalFileTypeEnum,
        NUMBER_4: 4 as OperationalFileTypeEnum,
        NUMBER_5: 5 as OperationalFileTypeEnum,
        NUMBER_6: 6 as OperationalFileTypeEnum
    };
}