import { ImageViewerModule } from "ngx-image-viewer";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ScrollTopComponent } from "./layout/scroll-top/scroll-top.component";
import { NoticeComponent } from "./content/general/notice/notice.component";
import { PortletModule } from "./content/general/portlet/portlet.module";
import { SpinnerButtonModule } from "./content/general/spinner-button/spinner-button.module";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { ListTimelineModule } from "./layout/list-timeline/list-timeline.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { DocumentEditorContainerModule, SfdtExportService, WordExportService } from "@syncfusion/ej2-angular-documenteditor";
import { MailComponent } from "./content/general/mail/mail.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ImageViewerDialogComponent } from "./content/general/image-viewer-dialog/image-viewer-dialog.component";
import { TagInputModule } from "ngx-chips";
import { NavigatorComponent } from "./layout/navigator/navigator.component";
import { DoubleSidedSliderComponent } from "./content/double-sided-slider/double-sided-slider.component";
import { LineTotalsComponent } from "./content/line-totals/line-totals.component";
import { DigitalArchiveComponent } from "./content/general/digital-archive/digital-archive.component";
import { FileManagerAllModule } from "@syncfusion/ej2-angular-filemanager";
import { ErrorComponent } from "../dialogs/error/error.component";
import { DestinationDetailsCardComponent } from "../shared/destination-details-card/destination-details-card.component";
import { WordEditorComponent } from "./content/general/word-editor/word-editor.component";
import { QuoteStatusBadgeComponent } from "./content/quote-status-badge/quote-status-badge.component";
import { ExtendedFileCopyComponent } from "./content/extended-file-copy/extended-file-copy.component";
import { ForwardingComponentModule } from "../../core/components/forwarding-component.module";
import { PdfViewerDialogComponent } from "./content/general/pdf-viewer-dialog/pdf-viewer-dialog.component";
import { FileUploadFailModalDialogComponent } from "./content/general/digital-archive/dialogs/file-upload-fail-modal-dialog/file-upload-fail-modal-dialog.component";
import { FileRenameDialogComponent } from "./content/general/digital-archive/dialogs/file-rename-dialog/file-rename-dialog.component";
import { FileMoveFailDialogComponent } from "./content/general/digital-archive/dialogs/file-move-fail-dialog/file-move-fail-dialog.component";
import { ReleaseRightActionComponent } from "./content/release-right-action/release-right-action.component";
import { AcceptDeclineReleaseRightComponent } from "./content/release-right-action/accept-decline-release-right/accept-decline-release-right.component";
import { TransferReleaseRightComponent } from "./content/release-right-action/transfer-release-right/transfer-release-right.component";
import { RevokeReleaseRightComponent } from "./content/release-right-action/revoke-release-right/revoke-release-right.component";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  declarations: [
    ScrollTopComponent,
    NoticeComponent,
    ImageViewerDialogComponent,
    MailComponent,
    NavigatorComponent,
    DoubleSidedSliderComponent,
    LineTotalsComponent,
    DigitalArchiveComponent,
    ErrorComponent,
    DestinationDetailsCardComponent,
    WordEditorComponent,
    QuoteStatusBadgeComponent,
    ExtendedFileCopyComponent,
    PdfViewerDialogComponent,
    FileUploadFailModalDialogComponent,
    FileRenameDialogComponent,
    FileMoveFailDialogComponent,
    ReleaseRightActionComponent,
    AcceptDeclineReleaseRightComponent,
    TransferReleaseRightComponent,
    RevokeReleaseRightComponent
  ],
  exports: [
    FileMoveFailDialogComponent,
    FileRenameDialogComponent,
    FileUploadFailModalDialogComponent,
    ScrollTopComponent,
    NoticeComponent,
    PortletModule,
    SpinnerButtonModule,
    ListTimelineModule,
    NgSelectModule,
    ImageViewerModule,
    NavigatorComponent,
    DoubleSidedSliderComponent,
    LineTotalsComponent,
    DigitalArchiveComponent,
    ErrorComponent,
    DestinationDetailsCardComponent,
    QuoteStatusBadgeComponent,
    ExtendedFileCopyComponent,
    FormsModule,
    ReactiveFormsModule,
    ReleaseRightActionComponent,
    PdfViewerDialogComponent
  ],
  imports: [
    CommonModule,
    FileManagerAllModule,
    AngularEditorModule,
    TagInputModule,
    DocumentEditorContainerModule,
    ForwardingComponentModule,
    SharedModule
  ],
  providers: [
    WordExportService,
    SfdtExportService
  ]
})
export class PartialsModule { }
