/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FoundImoClassificationDTO { 
    id?: number;
    isDeleted?: boolean;
    isBlocked?: boolean;
    unCode?: string;
    _class?: string;
    technicalName?: string;
    properShippingName?: string;
    packageGroup?: FoundImoClassificationDTO.PackageGroupEnum;
}
export namespace FoundImoClassificationDTO {
    export type PackageGroupEnum = 0 | 1 | 2;
    export const PackageGroupEnum = {
        NUMBER_0: 0 as PackageGroupEnum,
        NUMBER_1: 1 as PackageGroupEnum,
        NUMBER_2: 2 as PackageGroupEnum
    };
}