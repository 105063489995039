export const file_upload_fail_component_locale = {
  FILE_EXIST: "A file or folder with the given name is already present.",
  WHAT_TO_DO: "What would you like to do?",
  NEW_FILE_NAME: "New file name",
  NEW_FOLDER_NAME: "New folder name",
  HEADER:
  {
    FILE_EXIST: "A file or folder with the given name already exists",
    FILE_RENAME: "Rename file",
    FOLDER_RENAME: "Rename folder"
  },
  VALIDATION: {
    NAMES_ARE_SAME: "The new name matches the old one.",
    INVALID_NAME: "Invalid name.",
    EQUALS_INITIAL_FILE_NAME: "The new name matches the initial one."
  }
};
