export const mail_component_locale = {
  ENTER_EMAIL: "Enter email",
  ADD_ATTACHMENTS: "Add attachment(s)",
  SEND_MAIL: "Send mail",
  ATTACHMENTS: "Attachments",
  BODY: "Body",
  SUBJECT: "Subject",
  ENTER_SUBJECT: "Enter subject",
  ENTER_BODY: "Enter body",
  TO: "To",
  RECIPIENTS: "Recipients",
  CONFIRM_MAIL: "Are you sure you want to send this e-mail?",
  MAIL_SEND_SUCCESS: "Your e-mail has been send successfully.",
  MAIL_SEND_ERROR: "An error occurred while attempting to send your e-mail. Please try again.",
  SEND_UBL: "Include Universal Business Language (UBL) file",
  USE_TEMPLATE: "Use Template",
  EXCISTING_DOCUMENTS: "Existing document(s)",
  NO_DOCUMENT_IS_CHOSEN: 'No document is chosen'
};
