import { environment } from "./../../../../../environments/environment";
import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input, OnInit } from "@angular/core";
import { ClassInitService } from "../../../../core/services/class-init.service";
import { LayoutConfigService } from "../../../../core/services/layout-config.service";
import * as objectPath from "object-path";
import { DOCUMENT } from "@angular/common";

@Component({
	selector: "m-brand",
	templateUrl: "./brand.component.html",
	changeDetection: ChangeDetectionStrategy.Default
})
export class BrandComponent implements OnInit {
	@HostBinding("class") public classes: string = "m-stack__item m-brand";
	@Input() public menuAsideLeftSkin: any = "";
	@Input() public menuAsideMinimizeDefault: any = false;
	@Input() public menuAsideMinimizToggle: any = false;
	@Input() public menuAsideDisplay: any = false;
	@Input() public menuHeaderDisplay: any = true;
	@Input() public headerLogo: any = "";
	public environmentName: string = environment && environment.name ? environment.name : "";

	constructor(
		private classInitService: ClassInitService,
		private layoutConfigService: LayoutConfigService,
		@Inject(DOCUMENT) private document: Document
	) {
		// subscribe to class update event
		this.classInitService.onClassesUpdated$.subscribe(classes => {
			this.classes = "m-stack__item m-brand " + classes.brand.join(" ");
		});

		this.layoutConfigService.onLayoutConfigUpdated$.subscribe(model => {
			this.menuAsideLeftSkin = objectPath.get(model, "config.aside.left.skin");

			this.menuAsideMinimizeDefault = objectPath.get(model, "config.aside.left.minimize.default");

			this.menuAsideMinimizToggle = objectPath.get(model, "config.aside.left.minimize.toggle");

			this.menuAsideDisplay = objectPath.get(model, "config.menu.aside.display");

			this.menuHeaderDisplay = objectPath.get(model, "config.menu.header.display");

			const headerLogo = objectPath.get(model, "config.header.self.logo");
			if (typeof headerLogo === "object") {
				this.headerLogo = objectPath.get(headerLogo, this.menuAsideLeftSkin);
			} else {
				this.headerLogo = headerLogo;
			}
		});
	}

	public ngOnInit(): void { }

	/**
	 * Toggle class topbar show/hide
	 * @param event
	 */
	public clickTopbarToggle(event: Event): void {
		this.document.body.classList.toggle("m-topbar--on");
	}
}
