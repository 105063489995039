import { Component, Input } from "@angular/core";

@Component({
  selector: "type-ahead-error",
  templateUrl: "./type-ahead-error.component.html",
  styleUrls: ["./type-ahead-error.component.scss"]
})
export class TypeAheadErrorComponent {
  @Input("maxLength")
  public maxLength: number = 3950;

  @Input("name")
  public name: string = "Input";

  constructor() { }
}
