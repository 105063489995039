/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UpsertProspectDTO { 
    companyName?: string;
    status?: UpsertProspectDTO.StatusEnum;
    contactName?: string;
    contactTelephoneNr?: string;
    contactEmail?: string;
    firstContactTimestamp?: Date;
    lastContactTimestamp?: Date;
    nextContactTimestamp?: Date;
    freeText?: string;
}
export namespace UpsertProspectDTO {
    export type StatusEnum = 0 | 1 | 2 | 3;
    export const StatusEnum = {
        NUMBER_0: 0 as StatusEnum,
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum,
        NUMBER_3: 3 as StatusEnum
    };
}