/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DeactivateClientPortalInvitation } from '../model/deactivateClientPortalInvitation';
import { NewClientPortalUser } from '../model/newClientPortalUser';
import { PagedResponseFoundClientPortalUserDTO } from '../model/pagedResponseFoundClientPortalUserDTO';
import { ReactivateClientPortalInvitation } from '../model/reactivateClientPortalInvitation';
import { Response } from '../model/response';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ClientPortalUsersService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * deactivate
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deactivate(body: DeactivateClientPortalInvitation, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public deactivate(body: DeactivateClientPortalInvitation, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public deactivate(body: DeactivateClientPortalInvitation, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public deactivate(body: DeactivateClientPortalInvitation, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling deactivate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Response>(`${this.basePath}/api/masterdata/client-portal-users/deactivate`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Invite new client portal user
     * 
     * @param body 
     * @param companyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inviteClientPortalUser(body: NewClientPortalUser, companyId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public inviteClientPortalUser(body: NewClientPortalUser, companyId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public inviteClientPortalUser(body: NewClientPortalUser, companyId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public inviteClientPortalUser(body: NewClientPortalUser, companyId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling inviteClientPortalUser.');
        }

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling inviteClientPortalUser.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (companyId !== undefined && companyId !== null) {
            queryParameters = queryParameters.set('companyId', <any>companyId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Response>(`${this.basePath}/api/masterdata/client-portal-users/invite`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * reactivate
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reactivate(body: ReactivateClientPortalInvitation, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public reactivate(body: ReactivateClientPortalInvitation, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public reactivate(body: ReactivateClientPortalInvitation, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public reactivate(body: ReactivateClientPortalInvitation, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling reactivate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Response>(`${this.basePath}/api/masterdata/client-portal-users/reactivate`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * search client portal users
     * 
     * @param companyId 
     * @param searchConditionSearchTerm 
     * @param searchConditionSortDirection 
     * @param searchConditionSortProperty 
     * @param searchConditionPageSize 
     * @param searchConditionPage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchClientPortalUsers(companyId: number, searchConditionSearchTerm?: string, searchConditionSortDirection?: string, searchConditionSortProperty?: string, searchConditionPageSize?: number, searchConditionPage?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PagedResponseFoundClientPortalUserDTO>;
    public searchClientPortalUsers(companyId: number, searchConditionSearchTerm?: string, searchConditionSortDirection?: string, searchConditionSortProperty?: string, searchConditionPageSize?: number, searchConditionPage?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PagedResponseFoundClientPortalUserDTO>>;
    public searchClientPortalUsers(companyId: number, searchConditionSearchTerm?: string, searchConditionSortDirection?: string, searchConditionSortProperty?: string, searchConditionPageSize?: number, searchConditionPage?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PagedResponseFoundClientPortalUserDTO>>;
    public searchClientPortalUsers(companyId: number, searchConditionSearchTerm?: string, searchConditionSortDirection?: string, searchConditionSortProperty?: string, searchConditionPageSize?: number, searchConditionPage?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling searchClientPortalUsers.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (companyId !== undefined && companyId !== null) {
            queryParameters = queryParameters.set('CompanyId', <any>companyId);
        }
        if (searchConditionSearchTerm !== undefined && searchConditionSearchTerm !== null) {
            queryParameters = queryParameters.set('SearchCondition.SearchTerm', <any>searchConditionSearchTerm);
        }
        if (searchConditionSortDirection !== undefined && searchConditionSortDirection !== null) {
            queryParameters = queryParameters.set('SearchCondition.SortDirection', <any>searchConditionSortDirection);
        }
        if (searchConditionSortProperty !== undefined && searchConditionSortProperty !== null) {
            queryParameters = queryParameters.set('SearchCondition.SortProperty', <any>searchConditionSortProperty);
        }
        if (searchConditionPageSize !== undefined && searchConditionPageSize !== null) {
            queryParameters = queryParameters.set('SearchCondition.PageSize', <any>searchConditionPageSize);
        }
        if (searchConditionPage !== undefined && searchConditionPage !== null) {
            queryParameters = queryParameters.set('SearchCondition.Page', <any>searchConditionPage);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<PagedResponseFoundClientPortalUserDTO>(`${this.basePath}/api/masterdata/client-portal-users/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

}
