export const extra_booking_confirm = {
  TITLE: "Extra confirmation",
  TRIES_TO_SEND_ORIGINAL_BUT_ACCEPTED_EXISTS: "An original booking has already been sent and was accepted. Sending another original will most likely generate an error from INTTRA. It is recommended to send an ammendmend instead.",
  TRIES_TO_SEND_ORIGINAL_BUT_EXISTS_PREVIOUSLY_PENDING_ORIGINAL: "An original booking has already been sent and is currently awaiting a response from the carrier. Proper procedure would be to cancel the pending booking before sending a new original or to wait for the original to be accepted or rejected and then send an ammendment or new original. Are you sure you want to send another original booking at this time?",
  TRIES_TO_SEND_AMMENDMENT_BUT_THERE_IS_AN_ORIGINAL_THAT_WAS_REJECTED: "The original booking was rejected by inttra or the carrier. You should probably send a new original instead of an ammendment. Are you sure you want to send this booking as an ammendment?",
  TRIES_TO_SEND_AMMENDMENT_BUT_THERE_IS_AN_ORIGINAL_THAT_IS_PENDING: "The original booking is currently awaiting response from the carrier. Sending an ammendment at this time would likely generate an error from INTTRA. Proper procedure would be to cancel the pending original and send a new original instead, or to wait for the original to be accepted or rejected and then send either an ammendment or a new original. Are you sure you want to send an ammendment at this time?",
  TRIES_TO_SEND_AMMENDMENT_BUT_THERE_IS_PENDING_AMMENDMENT: "There is already a booking ammendment awaiting response from the carrier. Sending another ammendment at this time will likely generate an error from inttra. You should either wait for that ammendment to be either accepted or rejected and send the new ammendment then, or cancel the pending ammendment to send a new one.",
  BUTTONS: {
    SEND_NEW_ORIGINAL_NOW: "Send new original now",
    CANCEL_PENDING_BOOKING_AND_SEND_NEW_ORIGINAL: "Cancel pending booking and send new original",
    SEND_AS_AMMENDMENT: "Send as ammendment",
    SEND_AS_AMMENDMENT_INSTEAD: "Send as ammendment instead",
    SEND_AS_ORIGINAL: "Send as original",
    SEND_AMMENDMENT: "Send ammendment",
    CANCEL_PENDING_ORIGINAL_AND_SEND_NEW_ORIGINAL: "Cancel pending original and send new original",
    SEND_NEW_AMMENDMENT_NOW: "Send new ammendment now",
    CANCEL_PENDING_AMMENDMENT_AND_SEND_NEW_AMMENDMENT: "Cancel pending ammendment and send new ammendment",
    CLOSE_EXTRA_CONFIRMATION: "close extra confirmation"
  }
};
