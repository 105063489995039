import { Component, Input } from "@angular/core";

@Component({
  selector: "operational-file-cost-status-badge",
  templateUrl: "./operational-file-cost-status-badge.component.html"
})
export class OperationalFileCostStatusBadgeComponent {
  @Input()
  public isBooked: boolean;

  constructor() { }
}
