import { Component, OnInit } from "@angular/core";
import { FormlyHelper } from "../../../helpers/formly-helper";
import { FieldType } from "@ngx-formly/core";
import { Validators } from "@angular/forms";

@Component({
  selector: "formly-voyage",
  templateUrl: "./formly-voyage.component.html",
  styleUrls: ["./formly-voyage.component.scss"]
})
export class FormlyVoyageComponent extends FieldType implements OnInit {
  public submitted: boolean;

  constructor(
    private formlyHelper: FormlyHelper
  ) {
    super();
  }

  public ngOnInit() {
    this.formlyHelper
      .getGeneralFormSubmission$()
      .subscribe(() => this.submitted = true);

    if (this.field.templateOptions.required) {
      this.formControl.setValidators([Validators.required, Validators.maxLength(4096)]);
    } else {
      this.formControl.setValidators([Validators.maxLength(4096)]);
    }
  }
}
