import { Component, Input } from "@angular/core";
import { AddressDetailsDTO } from "../../../../services/swagger-gen/fordesk";

@Component({
  selector: "address-option",
  templateUrl: "./address-option.component.html"
})
export class AddressOptionComponent {
  @Input() public item: AddressDetailsDTO;

  constructor() { }
}
