import { Component, Input } from "@angular/core";
import { CompanyAddressDTO } from "../../../../services/swagger-gen/fordesk";

@Component({
  selector: "company-address-label",
  templateUrl: "./company-address-label.html"
})
// tslint:disable-next-line: component-class-suffix
export class CompanyAddressLabel {
  @Input()
  public item: CompanyAddressDTO;

  @Input()
  public showType: boolean = false;

  constructor() { }
}
