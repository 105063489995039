import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, from, of } from "rxjs";
import { concatMap, switchMap, takeUntil } from "rxjs/operators";
import { ConfirmDialogData, ConfirmDialogResultData } from "../../../core/models/dialogs/confirm-dialog-data";
import { UnsavedChangesService } from "../../../core/services/helpers/unsaved-changes.service";
import { IncomingInvoicesService, UblResultDTO } from "../../../core/services/swagger-gen/fordesk";
import { ToasterService } from "../../layout/toaster/toaster.service";
import { ConfirmDialog } from "../confirm/confirm.component";

@Component({
  selector: "upload-ubl-dialog",
  templateUrl: "./upload-ubl-dialog.component.html",
  styleUrls: ["./upload-ubl-dialog.component.scss"]
})
// tslint:disable-next-line: component-class-suffix
export class UploadUblDialog implements OnInit, OnDestroy {
  public filesToUpload: File[] = [];
  @ViewChild("uploader", { static: false }) private uploaderInput: ElementRef;

  private unsubscribe: Subject<void> = new Subject();
  public hasFileName: boolean = false;

  constructor(
    public dialog: MatDialog,
    public modal: NgbActiveModal,
    private toaster: ToasterService,
    private translate: TranslateService,
    private router: Router,
    private incomingInvoicesService: IncomingInvoicesService,
    private unsavedChangesService: UnsavedChangesService) {
  }

  public ngOnInit(): void {
  }

  public addFileToUploader(event) {
    if (event && event[0]) {
      this.filesToUpload = [];

      const files = event as any[];
      for (let i = 0; i < files.length; i++) {
        if (!(files[i].type === "application/pdf" || files[i].type === "text/xml" || files[i].type === "image/png" || files[i].type === "image/jpg" || files[i].type === "image/jpeg" || files[i].type === "image/tiff")) {
          this.toaster.error(this.translate.instant("FINANCE.INCOMING_INVOICES_DETAILS.UPLOAD_PDF_OR_IMAGE"));
          return;
        }

        this.filesToUpload.push(files[i]);
      }
      this.hasFileName = true;
    }
  }

  public deleteAttachment(fileName) {
    this.filesToUpload = this.filesToUpload.filter(x => x.name != fileName)
    if (this.filesToUpload.length === 0) {
      this.hasFileName = false;
    }
    this.uploaderInput.nativeElement.value = null;
  }

  public uploadFile(): void {
    this.modal.close()//close selected documents modal window
    if (this.filesToUpload.length > 0) {
      from(this.filesToUpload)
        .pipe(
          concatMap(file =>//send request to create incoming invoice we use concat map to send request pairs (createInvoiceFromUBLForm, confirmDuplicate) one by one
            this.incomingInvoicesService.createInvoiceFromUBL(file).pipe(
              takeUntil(this.unsubscribe),
              concatMap((response: UblResultDTO) => {
                if (response.isDuplicate) {
                  return this.confirmDuplicate(file.name).pipe(
                    takeUntil(this.unsubscribe),
                    switchMap((result: ConfirmDialogResultData) => {//send request to check is invoice duplicate
                      if (result.isConfirmed) {
                        return this.incomingInvoicesService.unDeleteIncomingInvoice(response.incomingInvoiceId).pipe(
                          takeUntil(this.unsubscribe),
                          switchMap(() => {
                            this.handleSuccessfulUpload(response.incomingInvoiceId);
                            return of(null);
                          })
                        );
                      } else {
                        return of(null);
                      }
                    })
                  );
                } else {
                  this.handleSuccessfulUpload(response.incomingInvoiceId);
                  return of(null);
                }
              })
            )
          ),
          takeUntil(this.unsubscribe)
        )
        .subscribe({
          complete: () => this.modal.close()
        });
    }
  }

  private confirmDuplicate(documentName: string): Observable<ConfirmDialogResultData> {
    const dialogData: ConfirmDialogData = {
      confirmationText: this.translate.instant("FINANCE.INCOMING_INVOICES_DETAILS.MESSAGES.ALREADY_EXISTS", { documentName }),
      dialogTitle: this.translate.instant("MESSAGES.CONFIRM_TO_CONTINUE")
    };

    return this.dialog
      .open(ConfirmDialog, { disableClose: true, data: dialogData })
      .afterClosed();
  }

  private handleSuccessfulUpload(incomingInvoiceId: number): void {
    this.unsavedChangesService.unsubscribeAllCurrentComponents();
    this.toaster.success(this.translate.instant("MESSAGES.SUCCESSFULLY_UPLOADED"));
    window.open(`finance/incoming-invoices/${incomingInvoiceId}/details?showSidebar=true`)
  }

  public ngOnDestroy(): void {
    if (!this.unsubscribe || !this.unsubscribe.observers || this.unsubscribe.observers.length <= 0) {
      return;
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
