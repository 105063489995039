/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OperationalFileStepDetails } from './operationalFileStepDetails';

export interface NewOperationalFileDTO { 
    operationalFileType: NewOperationalFileDTO.OperationalFileTypeEnum;
    transportType: NewOperationalFileDTO.TransportTypeEnum;
    shipmentType: NewOperationalFileDTO.ShipmentTypeEnum;
    customerReference?: string;
    fileNumber?: string;
    currencyId: number;
    isBudgetClosed: boolean;
    bookingReferences?: Array<string>;
    billOfLadingNumbers?: Array<string>;
    warehousingNeeded?: boolean;
    customerAddressId: number;
    carrierAddressId?: number;
    invoiceCompanyAddressId: number;
    transportCompanyAddressId?: number;
    departmentId: number;
    customsAddressId?: number;
    steps?: Array<OperationalFileStepDetails>;
    fileDate?: Date;
    customerContactPersonId?: number;
    invoiceCustomerContactPersonId?: number;
    isInvoiceCustomerGoodsOwner?: boolean;
}
export namespace NewOperationalFileDTO {
    export type OperationalFileTypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const OperationalFileTypeEnum = {
        NUMBER_0: 0 as OperationalFileTypeEnum,
        NUMBER_1: 1 as OperationalFileTypeEnum,
        NUMBER_2: 2 as OperationalFileTypeEnum,
        NUMBER_3: 3 as OperationalFileTypeEnum,
        NUMBER_4: 4 as OperationalFileTypeEnum,
        NUMBER_5: 5 as OperationalFileTypeEnum,
        NUMBER_6: 6 as OperationalFileTypeEnum
    };
    export type TransportTypeEnum = 0 | 1 | 2 | 3;
    export const TransportTypeEnum = {
        NUMBER_0: 0 as TransportTypeEnum,
        NUMBER_1: 1 as TransportTypeEnum,
        NUMBER_2: 2 as TransportTypeEnum,
        NUMBER_3: 3 as TransportTypeEnum
    };
    export type ShipmentTypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const ShipmentTypeEnum = {
        NUMBER_0: 0 as ShipmentTypeEnum,
        NUMBER_1: 1 as ShipmentTypeEnum,
        NUMBER_2: 2 as ShipmentTypeEnum,
        NUMBER_3: 3 as ShipmentTypeEnum,
        NUMBER_4: 4 as ShipmentTypeEnum,
        NUMBER_5: 5 as ShipmentTypeEnum,
        NUMBER_6: 6 as ShipmentTypeEnum
    };
}