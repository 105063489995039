import { Component, Input, Output, EventEmitter } from "@angular/core";

enum Direction {
  LEFT = 0,
  RIGHT = 1
}

@Component({
  selector: "navigator",
  templateUrl: "./navigator.component.html"
})
export class NavigatorComponent {
  @Input()
  public ids: number[] = [];
  @Input()
  public translationKey: string;
  @Input()
  public currentId: number = 0;

  @Output()
  public navigateEvent: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  public navigate(direction: Direction) {
    let index = this.ids.indexOf(this.currentId);
    direction === Direction.LEFT ? index-- : index++;
    if (index < 0) {
      index = this.ids.length - 1;
    }
    if (index === this.ids.length) {
      index = 0;
    }
    this.navigateEvent.emit(this.ids[index]);
  }

}
