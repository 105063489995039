import { Directive, HostListener, Input } from "@angular/core";
import { EntityDetailsComponent } from "../components/abstractions/entity-details.component";

@Directive({
  selector: "[unsavedChanges]"
})
export class UnsavedChangesDirective {
  @Input("unsavedChanges")
  private component: EntityDetailsComponent;

  constructor() {
  }

  @HostListener("click")
  public click() {
    this.component.resetForm();
  }

}
