import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { GridTypeDictionary } from "../../types/grid-type-dictionary.type";
import { GridType } from "../../types/grid-type.type";
import {
  INCOMING_INVOICE_COLUMNS_STORAGE,
  INCOMING_INVOICE_FOR_CODA_COLUMNS_STORAGE,
  INCOMING_INVOICE_FOR_CODA_SELECTED_LAYOUT_STORAGE,
  INCOMING_INVOICE_FOR_CODA_STORAGE,
  INCOMING_INVOICE_SELECTED_LAYOUT_STORAGE,
  INCOMING_INVOICE_STORAGE,
  OPERATIONAL_COLUMNS_STORAGE,
  OPERATIONAL_SELECTED_LAYOUT_STORAGE,
  OPERATIONAL_STORAGE,
  OUTGOING_INVOICE_COLUMNS_STORAGE,
  OUTGOING_INVOICE_FOR_CODA_COLUMNS_STORAGE,
  OUTGOING_INVOICE_FOR_CODA_SELECTED_LAYOUT_STORAGE,
  OUTGOING_INVOICE_FOR_CODA_STORAGE,
  OUTGOING_INVOICE_SELECTED_LAYOUT_STORAGE,
  OUTGOING_INVOICE_STORAGE,
  QUOTE_COLUMNS_STORAGE,
  QUOTE_SELECTED_LAYOUT_STORAGE,
  QUOTE_STORAGE
} from "../helpers/local-storage-keys";
import { NewGridLayoutDTO } from "../swagger-gen/fordesk";

@Injectable({ providedIn: "root" })
export class GridLayoutCacheService {

  private gridChanged: Subject<GridType> = new Subject<GridType>();
  public gridChanged$: Observable<GridType> = this.gridChanged.asObservable();

  constructor() {
  }

  public notifyGridChanged(type: string) {
    const gridType = this.mapKeysToGridType(type);
    if (gridType) {
      this.gridChanged.next(gridType);
    }
  }

  public mapKeysToGridType(type: string): GridType | null {
    switch (type.toLocaleUpperCase()) {
      case OPERATIONAL_STORAGE:
      case OPERATIONAL_COLUMNS_STORAGE:
        return "OperationalFile";
      case OUTGOING_INVOICE_STORAGE:
      case OUTGOING_INVOICE_COLUMNS_STORAGE:
        return "OutgoingInvoice";
      case INCOMING_INVOICE_STORAGE:
      case INCOMING_INVOICE_COLUMNS_STORAGE:
        return "IncomingInvoice";
      case QUOTE_STORAGE:
      case QUOTE_COLUMNS_STORAGE:
        return "Quotes";
      case OUTGOING_INVOICE_FOR_CODA_STORAGE:
      case OUTGOING_INVOICE_FOR_CODA_COLUMNS_STORAGE:
        return "OutgoingInvoiceForCoda";
      case INCOMING_INVOICE_FOR_CODA_STORAGE:
      case INCOMING_INVOICE_FOR_CODA_COLUMNS_STORAGE:
        return "IncomingInvoiceForCoda";
      default:
        return null;
    }
  }

  public mapGridTypeToFilterKeys(type: GridType): string | null {
    switch (type) {
      case "OperationalFile":
        return OPERATIONAL_STORAGE;
      case "OutgoingInvoice":
        return OUTGOING_INVOICE_STORAGE;
      case "IncomingInvoice":
        return INCOMING_INVOICE_STORAGE;
      case "Quotes":
        return QUOTE_STORAGE;
      case "OutgoingInvoiceForCoda":
        return OUTGOING_INVOICE_FOR_CODA_STORAGE;
      case "IncomingInvoiceForCoda":
        return INCOMING_INVOICE_FOR_CODA_STORAGE;
      default:
        return null;
    }
  }

  public mapGridTypeToColumnKeys(type: GridType): string | null {
    switch (type) {
      case "OperationalFile":
        return OPERATIONAL_COLUMNS_STORAGE;
      case "OutgoingInvoice":
        return OUTGOING_INVOICE_COLUMNS_STORAGE;
      case "IncomingInvoice":
        return INCOMING_INVOICE_COLUMNS_STORAGE;
      case "Quotes":
        return QUOTE_COLUMNS_STORAGE;
      case "OutgoingInvoiceForCoda":
        return OUTGOING_INVOICE_FOR_CODA_COLUMNS_STORAGE;
      case "IncomingInvoiceForCoda":
        return INCOMING_INVOICE_FOR_CODA_COLUMNS_STORAGE;
      default:
        return null;
    }
  }

  public mapGridTypeToCurrentlySelectedKeys(type: GridType): string | null {
    switch (type) {
      case "OperationalFile":
        return OPERATIONAL_SELECTED_LAYOUT_STORAGE;
      case "OutgoingInvoice":
        return OUTGOING_INVOICE_SELECTED_LAYOUT_STORAGE;
      case "IncomingInvoice":
        return INCOMING_INVOICE_SELECTED_LAYOUT_STORAGE;
      case "Quotes":
        return QUOTE_SELECTED_LAYOUT_STORAGE;
      case "OutgoingInvoiceForCoda":
        return OUTGOING_INVOICE_FOR_CODA_SELECTED_LAYOUT_STORAGE;
      case "IncomingInvoiceForCoda":
        return INCOMING_INVOICE_FOR_CODA_SELECTED_LAYOUT_STORAGE;
      default:
        return null;
    }
  }

  public mapGridTypeToEnum(gridType: GridType): NewGridLayoutDTO.TypeEnum {
    const mapper: GridTypeDictionary = {
      "OperationalFile": NewGridLayoutDTO.TypeEnum.NUMBER_0,
      "OutgoingInvoice": NewGridLayoutDTO.TypeEnum.NUMBER_1,
      "IncomingInvoice": NewGridLayoutDTO.TypeEnum.NUMBER_2,
      "Quotes": NewGridLayoutDTO.TypeEnum.NUMBER_3,
      "Prospects": NewGridLayoutDTO.TypeEnum.NUMBER_4,
      "OperationalSummaryPerUser": NewGridLayoutDTO.TypeEnum.NUMBER_5,
      "OpenBudgetPerOperationalFile": NewGridLayoutDTO.TypeEnum.NUMBER_6,
      "BudgetDetailsPerOperationalFile": NewGridLayoutDTO.TypeEnum.NUMBER_7,
      "BudgetDetailsPerQuote": NewGridLayoutDTO.TypeEnum.NUMBER_8,
      "YearlyBudgetOverview": NewGridLayoutDTO.TypeEnum.NUMBER_9,
      "OutgoingInvoicePerOperationalFile": NewGridLayoutDTO.TypeEnum.NUMBER_10,
      "OperationalFilesOverview": NewGridLayoutDTO.TypeEnum.NUMBER_11,
      "BudgetPerActivityCodes": NewGridLayoutDTO.TypeEnum.NUMBER_12,
      "OutgoingInvoiceForCoda": NewGridLayoutDTO.TypeEnum.NUMBER_13,
      "IncomingInvoiceForCoda": NewGridLayoutDTO.TypeEnum.NUMBER_14,
    };

    return mapper[gridType];
  }
}
