import { Component, Inject } from "@angular/core";
import { ConfirmBase } from "../abstractions/confirm-base.component";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmDialog } from "../confirm/confirm.component";
import { ConfirmOutgoingInvoiceLinesDialogData } from "../../../core/models/dialogs/confirm-dialog-data";

@Component({
  selector: "confirm-outgoing-invoice-lines",
  templateUrl: "./confirm-outgoing-invoice-lines.component.html",
  styleUrls: ["./confirm-outgoing-invoice-lines.component.scss"]
})
export class ConfirmOutgoingInvoiceLinesComponent extends ConfirmBase {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmOutgoingInvoiceLinesDialogData) {
    super(dialogRef, data);
  }
}
