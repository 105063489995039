import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class OperationalFileDocumentsCreateService {
  private documentCreate$: Subject<string> = new Subject<string>();

  public documentCreate(documentName: string) {
    this.documentCreate$.next(documentName);
  }

  public getDocumentCreate$(): Observable<string> {
    return this.documentCreate$.asObservable();
  }
}
