/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FoundBookingDTO { 
    id?: number;
    contractNumber?: string;
    chargePaymentType?: FoundBookingDTO.ChargePaymentTypeEnum;
    chargeType?: FoundBookingDTO.ChargeTypeEnum;
    freightTariffNumber?: string;
    hasCarrierAMSFiling?: boolean;
    status?: FoundBookingDTO.StatusEnum;
    bookingConfirmationStatus?: FoundBookingDTO.BookingConfirmationStatusEnum;
    lastBookingStatus?: FoundBookingDTO.LastBookingStatusEnum;
}
export namespace FoundBookingDTO {
    export type ChargePaymentTypeEnum = 0 | 1 | 2;
    export const ChargePaymentTypeEnum = {
        NUMBER_0: 0 as ChargePaymentTypeEnum,
        NUMBER_1: 1 as ChargePaymentTypeEnum,
        NUMBER_2: 2 as ChargePaymentTypeEnum
    };
    export type ChargeTypeEnum = 0 | 1 | 2 | 3 | 4 | 5;
    export const ChargeTypeEnum = {
        NUMBER_0: 0 as ChargeTypeEnum,
        NUMBER_1: 1 as ChargeTypeEnum,
        NUMBER_2: 2 as ChargeTypeEnum,
        NUMBER_3: 3 as ChargeTypeEnum,
        NUMBER_4: 4 as ChargeTypeEnum,
        NUMBER_5: 5 as ChargeTypeEnum
    };
    export type StatusEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const StatusEnum = {
        NUMBER_0: 0 as StatusEnum,
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum,
        NUMBER_3: 3 as StatusEnum,
        NUMBER_4: 4 as StatusEnum,
        NUMBER_5: 5 as StatusEnum,
        NUMBER_6: 6 as StatusEnum
    };
    export type BookingConfirmationStatusEnum = 0 | 1 | 2 | 3 | 4;
    export const BookingConfirmationStatusEnum = {
        NUMBER_0: 0 as BookingConfirmationStatusEnum,
        NUMBER_1: 1 as BookingConfirmationStatusEnum,
        NUMBER_2: 2 as BookingConfirmationStatusEnum,
        NUMBER_3: 3 as BookingConfirmationStatusEnum,
        NUMBER_4: 4 as BookingConfirmationStatusEnum
    };
    export type LastBookingStatusEnum = 0 | 1;
    export const LastBookingStatusEnum = {
        NUMBER_0: 0 as LastBookingStatusEnum,
        NUMBER_1: 1 as LastBookingStatusEnum
    };
}