import { NgOption } from "@ng-select/ng-select";
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { SelectOptionsService } from "./select-options/select-options.service";
import { ToasterService } from "../../../../content/layout/toaster/toaster.service";
import { MessageHelper } from "../../../helpers/message-helper";
import { DialogResult } from "../../../enumerations/dialog-result";
import { SearchObjectHelper } from "../../../helpers/search-object-helper";
import {
  AirportsService,
  CompanyAddressesService,
  IncotermsService,
  OperationalFileFullDetailsDTO,
  PortsService,
  TerminalsService,
  VesselsService
} from "../../../services/swagger-gen/fordesk";
import {
  PortChangedEvent,
  PortChangedService
} from "../../../services/operational/operational-file/port-changed.service";
import { map, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

export const FormlyNgSelectComponentSelector: string = "formly-ng-select";

@Component({
  selector: FormlyNgSelectComponentSelector,
  templateUrl: "./formly-ng-select.component.html",
  styleUrls: ["./formly-ng-select.component.scss"]
})
export class FormlyNgSelectComponent extends FieldType implements OnInit, AfterViewInit, OnDestroy {
  public loading: boolean;
  public mappedOptions: NgOption[] = [];
  public isAirport: boolean;
  constructor(
    public selectService: SelectOptionsService,
    public searchObjectHelper: SearchObjectHelper,
    private toaster: ToasterService,
    private messageHelper: MessageHelper,
    private terminalService: TerminalsService,
    public cdRef: ChangeDetectorRef,
    private vesselsService: VesselsService,
    private portService: PortsService,
    private incotermService: IncotermsService,
    private companyAddressService: CompanyAddressesService,
    private airPortsService: AirportsService,
    private portChangedService: PortChangedService
  ) {
    super();
  }

  private unsubscribe: Subject<void> = new Subject();
  public ngOnDestroy() {
    if (!this.unsubscribe) {
      return;
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  public ngOnInit() {
    this.isAirport = (typeof this.key === "string" || Array.isArray(this.key)) && this.key.includes("Airport");

    this.loading = true;

    // TODO: temporary workaround
    if (!this.selectService) {
      this.selectService = new SelectOptionsService(this.terminalService, this.vesselsService, this.portService, this.airPortsService, this.incotermService, this.companyAddressService);
    }

    if (!this.selectService) {
      console.error(`No service found for key '${this.key}'`);
      this.mappedOptions = [];
      this.loading = false;

      this.toaster.error(this.messageHelper.getGenericDialogResultMessage(DialogResult.Error));
    } else if (typeof this.key === "string") {

      this.getOptions(this.key, +this.model[this.key], this.model, this.model.operationalFile);

      if (this.key === "TerminalId") {
        this.portChangedService.isPortChanged()
          .pipe(
            map((data: PortChangedEvent) => {
              this.model[data.controlName] = data.id;
              return this.model;
            }), takeUntil(this.unsubscribe)
          )
          .subscribe((model) => this.getOptions(this.key.toString(), +model[this.key.toString()], model, model.operationalFile));
      }
    }
  }

  public ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  private getOptions(key: string, value: any, general?: any, operationalFile?: OperationalFileFullDetailsDTO): void {
    const isAirportModel = Object.keys(this.model).some(k => k.includes("Airport"));

    this.selectService.getOptions(key, +value, general, operationalFile, isAirportModel)
      .subscribe({
        next: res => {
          this.mappedOptions = res;
          // Cast number of string to number via "+" operator
          const selectedValue: number = +this.model[this.key as string];
          if (selectedValue && !isNaN(selectedValue)) {
            this.formControl.setValue(selectedValue);
          } else {
            this.formControl.reset();
          }
        },
        error: () => this.toaster.error(this.messageHelper.getGenericDialogResultMessage(DialogResult.Error)),
        complete: () => this.loading = false
      });
  }
}
