import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import numeral from "numeral";
import { ToasterService } from "../../layout/toaster/toaster.service";

const numberInputPattern = /[0-9.,]/;
const allowedSeparatorsPattern = /[.,]/;

/**
 * Custom numeric input component.
 * Implemented to support all decimal separators for any locale.
 */
@Component({
  selector: "number-input",
  templateUrl: "./number-input.component.html",
  styleUrls: ["./number-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NumberInputComponent),
    }
  ],
})
export class NumberInputComponent implements ControlValueAccessor {
  @Input()
  public set min(v: any) {
    const value = parseFloat(v);
    this.minValue = !isNaN(value) ? value : undefined;
  };

  @Input()
  public set max(v: any) {
    const value = parseFloat(v);
    this.maxValue = !isNaN(value) ? value : undefined;
  };

  @Input()
  public set step(v: any) {
    const value = parseFloat(v);
    this.stepValue = !isNaN(value) ? value : 1;
    this.stepValueDecimalsCount = this.getDecimalsCount(this.stepValue);
  };

  @Input()
  public name: string;

  @Input()
  public placeholder: string = "";

  @Input()
  public readonly: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Input()
  public positiveonly: boolean = false;

  /**
   * Sets input number precision. For example "10:6".
   * Also accepts '+' for whole numbers, which stands for 'infinite': "+:6".
   */
  @Input()
  public set precision(v: string) {
    this.parsePrecision(v);
  }
  private wholeNumbersCount: number;
  private decimalNumbersCount: number;
  private precisionPattern: RegExp;
  private defaultPrecision = "10:6";

  private onChange: (_: any) => void = (_: any) => { };
  private onTouched: () => void = () => { };

  public controlDisabled: boolean = false;

  public inputValue: string = "";
  private minValue: number;
  private maxValue: number;
  private stepValue: number;
  private stepValueDecimalsCount: number = 0;
  private typedDecimalSeparator: string;

  constructor(private toaster: ToasterService, private translate: TranslateService) { }

  public input(event) {
    let newValue = event.target.value;

    if (!newValue || (!this.positiveonly && newValue == "-")) {
      this.changeInputValue(undefined);
    } else if (this.isValudNumber(newValue)) {
      this.detectTypedDecimalSeparator(newValue);

      newValue = this.processPrecision(newValue);
      event.target.value = newValue;
      this.changeInputValue(newValue);
    } else {
      event.target.value = this.inputValue;
    }
  }

  public keyPress(event: KeyboardEvent) {
    if (!numberInputPattern.test(event.key) && !(!this.positiveonly && event.key == "-")) {
      // Invalid character.
      event.preventDefault();
    }
  }

  public paste(event: ClipboardEvent) {
    const text = event.clipboardData.getData('text/plain');
    let sanitizedText = "";

    if (text) {
      let alreadyContainSeparator = false;
      for (let i = 0; i < text.length; i++) {
        if (i == 0 && !this.positiveonly && text[i] == "-") {
          sanitizedText += text[i];
          continue;
        }

        if (numberInputPattern.test(text[i])) {
          if (allowedSeparatorsPattern.test(text[i])) {
            if (alreadyContainSeparator) {
              continue;
            }
            alreadyContainSeparator = true;
          }

          sanitizedText += text[i];
        }
      }

      if (this.isValudNumber(sanitizedText)) {
        this.detectTypedDecimalSeparator(sanitizedText);
        sanitizedText = this.processPrecision(sanitizedText);
      } else {
        sanitizedText = "";
      }
    }

    event.preventDefault();
    this.changeInputValue(sanitizedText);
  }

  public valueUpClick() {
    const currentValue = this.convertToNumberValue(this.inputValue);
    let newValueBuilder = numeral(currentValue || this.minValue || 0);

    if (this.getDecimalsCount(newValueBuilder.value()) > this.stepValueDecimalsCount) {
      newValueBuilder = numeral(newValueBuilder.value().toLocaleString("en-US", { maximumFractionDigits: this.stepValueDecimalsCount }));
    }

    newValueBuilder = newValueBuilder.add(this.stepValue);

    if (this.maxValue != undefined && newValueBuilder.value() > this.maxValue) {
      newValueBuilder = numeral(this.maxValue);
    }

    let newValue = newValueBuilder.value();
    newValue = this.processPrecision(newValue);
    this.changeInputValue(this.convertToStringValue(newValue));
  }

  public valueDownClick() {
    const currentValue = this.convertToNumberValue(this.inputValue);
    let newValueBuilder = numeral(currentValue || this.minValue || 0);

    if (this.getDecimalsCount(newValueBuilder.value()) > this.stepValueDecimalsCount) {
      newValueBuilder = numeral(newValueBuilder.value().toLocaleString("en-US", { maximumFractionDigits: this.stepValueDecimalsCount }));
    } else {
      newValueBuilder = newValueBuilder.subtract(this.stepValue);
    }

    if (this.minValue != undefined && newValueBuilder.value() < this.minValue) {
      newValueBuilder = numeral(this.minValue);
    }

    let newValue = newValueBuilder.value();
    newValue = this.processPrecision(newValue);
    this.changeInputValue(this.convertToStringValue(newValue));
  }

  public writeValue(value: number): void {
    this.inputValue = this.convertToStringValue(value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.controlDisabled = isDisabled;
  }

  private changeInputValue(newInputValue: string) {
    if (this.inputValue != newInputValue) {
      this.inputValue = newInputValue;
      this.onChange(this.convertToNumberValue(newInputValue));
    }
  }

  private isValudNumber(value: string) {
    value = value?.replace(allowedSeparatorsPattern, ".");
    return parseFloat(value) as any == value;
  }

  private convertToNumberValue(stringValue: string) {
    stringValue = stringValue?.replace(allowedSeparatorsPattern, ".");
    const numberValue = parseFloat(stringValue);
    return !isNaN(numberValue) ? numberValue : undefined;
  }

  private convertToStringValue(numberValue: number) {
    let stringValue = numberValue?.toString();
    if (this.typedDecimalSeparator) {
      stringValue = stringValue?.replace(allowedSeparatorsPattern, this.typedDecimalSeparator);
    }
    return stringValue;
  }

  private detectTypedDecimalSeparator(value: string) {
    if (!value) {
      return;
    }

    if (value.includes(",")) {
      this.typedDecimalSeparator = ",";
    } else if (value.includes(".")) {
      this.typedDecimalSeparator = ".";
    }
  }

  private getDecimalsCount(value: number) {
    const valueParts = value.toString().split('.');
    return valueParts.length == 2 ? valueParts[1].length : 0;
  }

  private processPrecision(value: string) {
    if (!this.precisionPattern) {
      this.parsePrecision(this.defaultPrecision);
    }

    if (!this.precisionPattern.test(value)) {
      this.showPrecisionOverflowWarning();
      return this.findBestPrecisionMatch(value);
    }

    return value;
  }

  private parsePrecision(precision: string) {
    const precisionParts = precision?.split(":");
    const [wholeNumbersPart, decimalsNumbersPart] = [...precisionParts];
    this.wholeNumbersCount = wholeNumbersPart !== "+" ? +wholeNumbersPart : Number.MAX_SAFE_INTEGER;
    this.decimalNumbersCount = +decimalsNumbersPart;
    this.precisionPattern = new RegExp(`^-?(\\d{0,${this.wholeNumbersCount}}([.,]\\d{0,${this.decimalNumbersCount}})?)$`);
  }

  private findBestPrecisionMatch(value: string) {
    let [convertedWhole, convertedDecimals, seperator] = ["", "", ""];
    const minus = value.includes("-") ? "-" : "";
    value = value.replace("-", "");

    if (value.match(allowedSeparatorsPattern)) {
      const [whole, decimals] = value.split(allowedSeparatorsPattern);
      convertedWhole = whole?.length > this.wholeNumbersCount ? whole.substring(0, this.wholeNumbersCount) : whole ? whole : "0";
      convertedDecimals = decimals?.length > this.decimalNumbersCount ? decimals.substring(0, this.decimalNumbersCount) : decimals;
      seperator = decimals ? (this.typedDecimalSeparator || ".") : "";
    } else {
      convertedWhole = value.length > this.wholeNumbersCount ? value.substring(0, this.wholeNumbersCount) : value;
    }

    return `${minus}${convertedWhole}${seperator}${convertedDecimals}`;
  }

  private showPrecisionOverflowWarning() {
    if (this.wholeNumbersCount !== Number.MAX_SAFE_INTEGER) {
      this.toaster.warning(
        this.translate.instant("VALIDATION.MAXIMUM_WHOLES_DECIMALS", { name: "This field", decimals: this.decimalNumbersCount, wholes: this.wholeNumbersCount }));
    } else {
      this.toaster.warning(
        this.translate.instant("VALIDATION.MAXIMUM_DECIMALS", { name: "This field", decimals: this.decimalNumbersCount }));
    }
  }
}
