import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { PaneType } from "../../../../core/types/pane-type";

@Component({
  selector: "double-sided-slider",
  templateUrl: "./double-sided-slider.component.html",
  styleUrls: ["./double-sided-slider.component.scss"],
  animations: [
    trigger("slide", [
      state("left", style({ transform: "translateX(0)" })),
      state("right", style({ transform: "translateX(-50%)" })),
      transition("* => *", animate("0.3s  ease-in-out"))
    ])
  ]
})
export class DoubleSidedSliderComponent implements AfterViewChecked {
  private _activePane: PaneType = "left";

  @ViewChild("leftPane", { static: false }) private leftPane: ElementRef;
  @ViewChild("rightPane", { static: false }) private rightPane: ElementRef;
  @ViewChild("parent", { static: false }) private parent: ElementRef;

  @Input()
  public set activePane(activePane: PaneType) {
    this._activePane = activePane;
  }

  public get activePane(): PaneType {
    return this._activePane;
  }


  constructor() {
  }

  public ngAfterViewChecked(): void {
    this.maxHeightHandler();
  }

  public maxHeightHandler() {
    if (!this.leftPane || !this.rightPane || !this.parent) {
      return;
    }

    const currentPaneElement = this._activePane === "left"
      ? this.leftPane
      : this.rightPane;

    const currentPaneHeight = this.getElementHeight(currentPaneElement, this._activePane);

    if (!currentPaneHeight) {
      return;
    }

    this.parent.nativeElement.style.maxHeight = currentPaneHeight + "px";
  }

  private getElementHeight(element: ElementRef, paneType: PaneType): number | null {
    const htmlElement = element.nativeElement as HTMLElement;

    const child = htmlElement.querySelector(`[${paneType}Pane]`) as HTMLElement;

    if (!child) {
      return null;
    }

    return child.offsetHeight;
  }
}
