import { Injectable } from "@angular/core";
import { ForwardingRole } from "./roles.enum";
import { Observable, of } from "rxjs";
import { map, concatMap, tap } from "rxjs/operators";
import { UserProfileService } from "../user-profile.service";

@Injectable({ providedIn: "root" })
export class RoleService {
  private userRoles: string[] = null;

  constructor(private userProfileService: UserProfileService) { }

  public getRolesFromUserProfile$(): Observable<any> {
    const roles = this.getRoles();
    if (roles) {
      return of(roles);
    }

    return this.userProfileService.getUserProfile$().pipe(
      tap(profile => this.cacheRoles(profile["role"])),
      map(profile => profile["role"])
    );
  }

  public userHasRole(roles: string[], role: ForwardingRole): boolean {
    return roles.includes(role);
  }

  public userHasAdminRole(): Observable<boolean> {
    return this.getRolesFromUserProfile$()
      .pipe(
        concatMap(x => of(this.userHasRole(x, ForwardingRole.ForwardingAdmin)))
      );
  }

  public userHasFordeskClientPortalConfig(): Observable<boolean> {
    return this.getRolesFromUserProfile$()
      .pipe(
        concatMap(x => of(this.userHasRole(x, ForwardingRole.FordeskClientPortalConfig)))
      );
  }

  public userHasDiflexmoAdminRole(): Observable<boolean> {
    return this.getRolesFromUserProfile$()
      .pipe(
        concatMap(x => of(this.userHasRole(x, ForwardingRole.DiflexmoAdmin)))
      );
  }

  public userHasFinanceExtensionRole(): Observable<boolean> {
    return this.getRolesFromUserProfile$()
      .pipe(
        concatMap(x => of(this.userHasRole(x, ForwardingRole.FinanceExtension)))
      );
  }

  public userHasOCRUserRole(): Observable<boolean> {
    return this.getRolesFromUserProfile$()
      .pipe(
        concatMap(x => of(this.userHasRole(x, ForwardingRole.OCRUser)))
      );
  }

  public cacheRoles(roles: string[]): void {
    this.userRoles = roles;
  }

  public getRoles(): string[] {
    return this.userRoles;
  }
}
