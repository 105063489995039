import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'invoice-type-status[invoiceType]',
  templateUrl: './invoice-type-status.component.html',
  styleUrls: ['./invoice-type-status.component.scss']
})
export class InvoiceTypeStatusComponent implements OnInit {

  @Input()
  public invoiceType: string;

  public get isCredited(): boolean {
    return this.invoiceType === "CN";
  }

  constructor() { }

  ngOnInit(): void {
  }

}
