import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { ConfirmBase } from "../abstractions/confirm-base.component";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmDialog } from "../confirm/confirm.component";
import { ConfirmPartyDropDownDialogData, ConfirmPartyDropDownResultData } from "../../../core/models/dialogs/confirm-dialog-data";
import { map, takeUntil } from "rxjs/operators";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { PartyLookupDTO } from "../../../core/services/swagger-gen/fordesk";
import { Subject } from "rxjs";

@Component({
  selector: "confirm-party-drop-down",
  templateUrl: "./confirm-party-drop-down.component.html",
  styleUrls: ["./confirm-party-drop-down.component.scss"]
})
export class ConfirmPartyDropDownComponent extends ConfirmBase implements OnInit {

  public submitted: boolean = false;
  public options: PartyLookupDTO[];
  public confirmForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmPartyDropDownDialogData,
    private fb: UntypedFormBuilder
  ) {
    super(dialogRef, data);
  }

  public ngOnInit(): void {
    this.createForm();

    this.data.partyLookups$.
      pipe(
        map(x => this.options = x),
        map(() => {
          let selectedOption: PartyLookupDTO;
          if (this.data.selectedValue && this.options) {
            selectedOption = this.options.find(o => o.id === this.data.selectedValue);
          } else {
            selectedOption = this.options && this.options.length > 0 ? this.options[0] : null;
          }

          this.confirmForm.patchValue({ selectedOption: selectedOption });
        })
      ).subscribe();
  }

  public confirm() {
    this.submitted = true;
    if (!this.confirmForm.valid) {
      return;
    }

    const dialogResult: ConfirmPartyDropDownResultData = {
      isConfirmed: true,
      option: this.confirmForm.get("selectedOption").value as PartyLookupDTO
    };

    this.dialogRef.close(dialogResult);
  }

  private createForm(): void {
    this.confirmForm = this.fb.group({
      selectedOption: [null, [Validators.required]]
    });
  }

  public partySearch(search: string, item: PartyLookupDTO): boolean {
    search = search.toLocaleLowerCase();
    const propertiesToBeSearched: string = `${item.description} ${item.alias} ${item.partyTypeCode}`;

    return propertiesToBeSearched.toLocaleLowerCase().includes(search);
  }
}
