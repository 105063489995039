export const operational_file_overview_locale = {
  FILE_NUMBER: "Operational file number",
  FILE_DATE: "Operational file date",
  USER: "User",
  DEPARTMENT: "Department",
  CURRENCY: "Currency",
  EXCHANGE_RATE: "Exchange rate",
  INVOICE_CUSTOMER: "Invoice customer",
  CUSTOMER: "Customer",
  INVOICE_CUSTOMER_NUMBER: "Invoice customer number",
  CUSTOMER_NUMBER: "Customer number",
  CUSTOMER_REFERENCE: "Customer reference",
  BOOKING_REFS: "Booking reference(s)",
  FILE_TYPE: "File type",
  SHIPMENT_TYPE: "Shipment type",
  TRANSPORT_TYPE: "Transport type",
  INCOTERMS: "Incoterms",
  CARRIER: "Carrier",
  AIRFREIGHT: "Airfreight",
  TRANSPORTER: "Transporter",
  VESSEL: "Vessel",
  VOYAGE: "Voyage",
  FLIGHT_NR: "Flight nr",
  TRUCK_PlATE: "Truck plate",
  ETA: "ETA",
  ETD: "ETD",
  BILL_OF_LADING_NRS: "Bill of lading nr(s)",
  AIRWAY_BILL: "Airway bill",
  CMR_NR: "Cmr nr",
  POL: "Port of loading",
  POD: "Port of discharge",
  PLOR: "Place of receipt",
  PLOD: "Place of destination",
  NR_OF_CONTAINERS: "# of containers",
  NR_OF_TEU: "# of TEU",
  STATUS: "Status"
};
