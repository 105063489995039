import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { concat, Observable, Subject } from "rxjs";
import { skip, switchMap, takeUntil } from "rxjs/operators";
import { EntityDetailsComponent } from "../../../../../core/components/abstractions/entity-details.component";
import { ReleaseRightEnum } from "../../../../../core/enumerations/release-right.enum";
import { OperationalFileContainerIdentifiersHelper } from "../../../../../core/helpers/operational-file-container-identifiers-helper";
import { OperationalFileIdentifiersHelper } from "../../../../../core/helpers/operational-file-identifiers-helper";
import { UnsavedChangesService } from "../../../../../core/services/helpers/unsaved-changes.service";
import { ContainerIsoCodeDTO, ContainerIsoCodesService, ContainerReleaseRightsDTO, OperationalContainerIdentifierDTO, OperationalFileCompactDetailsDTO, OperationalFileContainerDTO, OperationalFileContainerService, OperationalFileIdentifierDTO, OperationalFileService, ReleaseRightsService } from "../../../../../core/services/swagger-gen/fordesk";
import { ToasterService } from "../../../../layout/toaster/toaster.service";

@Component({
  selector: "accept-decline-release-right",
  templateUrl: "./accept-decline-release-right.component.html",
  styleUrls: ["./accept-decline-release-right.component.scss"]
})
export class AcceptDeclineReleaseRightComponent extends EntityDetailsComponent implements OnInit, AfterViewInit {
  @Input()
  public operationalFileId: number;
  @Input()
  public containerId: number;
  @Input()
  public releaseRightContainer: ContainerReleaseRightsDTO = {};
  @Input()
  public sendEvent: Observable<void>;
  @Input()
  public fileAndContainerSelectionDisabled: boolean = false;

  @Output()
  public changeContainerIsoCode: EventEmitter<ContainerIsoCodeDTO> = new EventEmitter<ContainerIsoCodeDTO>();
  @Output()
  public changeContainer: EventEmitter<{ container, operationalFileId }> = new EventEmitter<{ container, operationalFileId }>();
  @Output()
  public closeMainModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  public eventTypes: { value: ReleaseRightEnum, description: string }[] = [{ value: ReleaseRightEnum.Accept, description: this.translate.instant("OPERATIONAL.CONTAINERS.RELEASE_RIGHT.ACCEPT") }, { value: ReleaseRightEnum.Reject, description: this.translate.instant("OPERATIONAL.CONTAINERS.RELEASE_RIGHT.DECLINE") }];

  public foundOperationalFileIdentifiers: OperationalFileIdentifierDTO[] = [];
  public operationalFileIdentifiersLoading: boolean;
  public operationalFileIdentifiersInput$: Subject<string> = new Subject<string>();

  public foundOperationalFileContainerIdentifiers: OperationalContainerIdentifierDTO[] = [];
  public operationalFileContainerIdentifiersLoading: boolean;
  public operationalFileContainerIdentifiersInput$: Subject<string> = new Subject<string>();

  private container: OperationalFileContainerDTO;

  constructor(
    protected route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    public cdRef: ChangeDetectorRef,
    private operationalFileIdentifiersHelper: OperationalFileIdentifiersHelper,
    private operationalFileService: OperationalFileService,
    private operationalFileContainerIdentifiersHelper: OperationalFileContainerIdentifiersHelper,
    private operationalFileContainerService: OperationalFileContainerService,
    private containerIsoService: ContainerIsoCodesService,
    private releaseRightService: ReleaseRightsService,
    private toaster: ToasterService,
    unsavedChangedService: UnsavedChangesService
  ) {
    super(route, unsavedChangedService);
  }

  public ngOnInit() {
    this.createForm();

    this.setupOperationalFileTypeAhead();
    this.setupOperationalFileContainerTypeAhead();

    if (this.operationalFileId) {
      this.selectOperationalFile();
    }

    this.selectOperationalFileContainer();

    this.sendEvent.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(() => this.handleSend());
  }

  public ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  protected saveInternal() { }

  private createForm() {
    this.detailsForm = this.fb.group({
      event: [ReleaseRightEnum.Accept, Validators.required],
      operationalFileIdentifier: [{ value: null, disabled: this.fileAndContainerSelectionDisabled }, Validators.required],
      operationalFileContainerIdentifier: [{ value: null, disabled: this.fileAndContainerSelectionDisabled }, Validators.required],
      reason: [null]
    });

    this.detailsForm.controls.operationalFileIdentifier.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      if (this.operationalFileId === data) {
        this.foundOperationalFileContainerIdentifiers = [];
        return;
      }

      this.operationalFileId = data;

      if (!data) {
        this.detailsForm.controls.operationalFileContainerIdentifier.disable();
      } else {
        this.detailsForm.controls.operationalFileContainerIdentifier.enable();
        this.selectOperationalFile();
      }

      this.detailsForm.controls.operationalFileContainerIdentifier.setValue(null);

      // Unsubscribe to avoid getting old data. In case the operational file has been changed, you need to unsubscribe from the old operational files.
      this.operationalFileContainerIdentifiersInput$.unsubscribe();
      this.operationalFileContainerIdentifiersInput$ = new Subject<string>();

      this.foundOperationalFileContainerIdentifiers = [];
      this.setupOperationalFileContainerTypeAhead();
    });

    this.detailsForm.controls.operationalFileContainerIdentifier.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      if (this.containerId === data) {
        return;
      }

      this.containerId = data;

      if (data) {
        this.selectOperationalFileContainer();
      } else {
        this.clearContainerData();
      }
    });
  }

  private clearContainerData() {
    this.container = null;
    this.changeContainer.emit({ container: null, operationalFileId: null });
    this.changeContainerIsoCode.emit(null);
  }

  private selectOperationalFile() {
    this.operationalFileService.getCompactDetails(this.operationalFileId)
      .pipe(switchMap((data: OperationalFileCompactDetailsDTO) => this.operationalFileService
        .searchOperationalFileIdentifiers(data.fileNumber)), takeUntil(this.unsubscribe)).subscribe(data => {

          this.foundOperationalFileIdentifiers = data;
          this.operationalFileIdentifiersLoading = false;

          if (data.length > 0) {
            this.detailsForm.controls.operationalFileIdentifier.setValue(data[0].operationalFileId);
          }

          this.cdRef.detectChanges();
        });
  }

  private selectOperationalFileContainer() {

    if (this.containerId && this.operationalFileId) {
      this.operationalFileContainerService.getOperationalFileContainerDetails(this.operationalFileId, this.containerId)
        .pipe(
          switchMap((data: OperationalFileContainerDTO) => {
            this.container = data;
            this.changeContainer.emit({ container: data, operationalFileId: this.operationalFileId });

            return this.operationalFileContainerService
              .searchOperationalContainerIdentifiers(this.operationalFileId, data.containerNumber);
          }),
          switchMap((data: OperationalContainerIdentifierDTO[]) => {
            this.foundOperationalFileContainerIdentifiers = data;
            this.operationalFileContainerIdentifiersLoading = false;

            if (data.length > 0) {
              this.detailsForm.controls.operationalFileContainerIdentifier.setValue(data[0].operationalFileContainerId);
            }

            return this.containerIsoService.getContainerIsoCodeById(this.container.sizeTypeId);
          }), takeUntil(this.unsubscribe)
        )
        .subscribe(data => {
          this.changeContainerIsoCode.emit(data);

          this.cdRef.detectChanges();
        });
    }
  }

  private setupOperationalFileTypeAhead(): void {
    const onStart: Function = () => {
      this.operationalFileIdentifiersLoading = true;
      this.cdRef.detectChanges();
    };

    const onComplete: Function = () => {
      this.operationalFileIdentifiersLoading = false;
      this.cdRef.detectChanges();
    };

    const fullLookup: Observable<OperationalFileIdentifierDTO[]> = this.operationalFileIdentifiersHelper.getIdentifiers(
      this.operationalFileService,
      this.operationalFileIdentifiersInput$,
      onStart,
      onComplete
    );

    concat(fullLookup).subscribe(
      foundOperationalFileIdentifiers => {
        this.foundOperationalFileIdentifiers = foundOperationalFileIdentifiers;
        this.operationalFileIdentifiersLoading = false;
        this.cdRef.detectChanges();
      }
    );
  }

  private setupOperationalFileContainerTypeAhead(): void {
    const onStart: Function = () => {
      this.operationalFileContainerIdentifiersLoading = true;
      this.cdRef.detectChanges();
    };

    const onComplete: Function = () => {
      this.operationalFileContainerIdentifiersLoading = false;
      this.cdRef.detectChanges();
    };

    let fullLookup: Observable<OperationalFileIdentifierDTO[]>;
    if (this.operationalFileId) {
      fullLookup = this.operationalFileContainerIdentifiersHelper.getOperationalFileContainerIdentifiers(
        this.operationalFileContainerService,
        this.operationalFileContainerIdentifiersInput$,
        this.operationalFileId,
        onStart,
        onComplete
      );
    } else {
      fullLookup = this.operationalFileContainerIdentifiersHelper.getContainerIdentifiers(
        this.operationalFileContainerService,
        this.operationalFileContainerIdentifiersInput$,
        onStart,
        onComplete
      );
    }

    concat(fullLookup).subscribe(
      foundOperationalFileContainerIdentifiers => {
        this.foundOperationalFileContainerIdentifiers = foundOperationalFileContainerIdentifiers;
        this.operationalFileContainerIdentifiersLoading = false;
        this.cdRef.detectChanges();
      }
    );
  }

  public handleSend() {
    this.submitted = true;
    if (!this.detailsForm.valid || !this.releaseRightContainer) { return; }

    const status: ReleaseRightEnum = this.detailsForm.controls.event.value;

    if (status === ReleaseRightEnum.Accept) {
      this.releaseRightService.acceptReleaseRight(this.operationalFileId, this.containerId, { releaseRightId: this.releaseRightContainer.id })
        .subscribe(data => {
          this.toaster.success(this.translate.instant("OPERATIONAL.CONTAINERS.RELEASE_RIGHT.ACCEPT_SUCCESS"));

          this.closeMainModal.emit(true);
        });
    } else if (status === ReleaseRightEnum.Reject) {
      const reason: string = this.detailsForm.controls.reason.value;
      this.releaseRightService.declineReleaseRight(this.operationalFileId, this.containerId,
        {
          releaseRightId: this.releaseRightContainer.id,
          reason: reason
        }).subscribe(() => {
          this.toaster.success(this.translate.instant("OPERATIONAL.CONTAINERS.RELEASE_RIGHT.DECLINE_SUCCESS"));

          this.closeMainModal.emit(true);
        });
    }
  }

}
