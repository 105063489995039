export const of_budget_locale = {
  RESULT: "Result",
  ACTUAL_RESULT: "Actualised result",
  CURRENT_RESULT: "Current result",
  BUDGET_RESULT: "Budget result",
  EXCL_VAT: "excl. VAT",
  ACTUAL_RESULT_HINT: "All invoiced revenue totals (excl. VAT) - all booked cost totals (excl. VAT)",
  CURRENT_RESULT_HINT: "All revenue totals (excl. VAT) - all cost totals (excl. VAT)",
  BUDGET_RESULT_HINT: "All in budget revenue totals (excl. VAT) - all in budget cost totals (excl. VAT)",
  ACTIONS: {
    CREATE_OUTGOING_INVOICE: "create outgoing invoice",
    CREATE_INCOMING_INVOICE: "create incoming invoice",
  },
  CHOOSE_PARTY: "Please choose an operational file party for the new budget line(s).",
  CHOOSE_PARTY_TITLE: "Party",
  NO_REVENUE_INVOICE_SUMMARY: "No invoice information available or not invoiced yet.",
  NO_COST_INVOICE_SUMMARY: "No invoice information available or not booked yet.",
  REVENUE_LINES_CONTAIN_BLOCKED_PARTY: "The revenues lines contain a party that is blocked.",
  MODAL: {
    TYPE_OF_BUDGET: "What type of budget do you want to export in the report?",
    TITLE_TYPE_OF_BUDGET: "Select type of budget",
    ACTIONS: {
      ONLY_IN_BUDGET: "show only in budget",
      ONLY_OUT_OF_BUDGET: "show only out of budget",
      SHOW_BOTH: "show both"
    }
  }
};
