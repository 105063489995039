/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddedCreditNoteDTO } from '../model/addedCreditNoteDTO';
import { CompanyRelatedEmailDTO } from '../model/companyRelatedEmailDTO';
import { CreateCreditNoteDTO } from '../model/createCreditNoteDTO';
import { CreditNoteDatesDTO } from '../model/creditNoteDatesDTO';
import { EmailTemplateTypeDTO } from '../model/emailTemplateTypeDTO';
import { FinalizedOutgoingInvoiceDTO } from '../model/finalizedOutgoingInvoiceDTO';
import { FormattedEmailTemplateDTO } from '../model/formattedEmailTemplateDTO';
import { FoundLinkedCreditNoteDTO } from '../model/foundLinkedCreditNoteDTO';
import { FoundOutgoingInvoiceCompactDTO } from '../model/foundOutgoingInvoiceCompactDTO';
import { LinkedCreditNoteDTO } from '../model/linkedCreditNoteDTO';
import { NewOutgoingInvoiceDefaultsDTO } from '../model/newOutgoingInvoiceDefaultsDTO';
import { OutgoingInvoiceCreditNotesTotalDTO } from '../model/outgoingInvoiceCreditNotesTotalDTO';
import { OutgoingInvoiceDetailsDTO } from '../model/outgoingInvoiceDetailsDTO';
import { OutgoingInvoiceExcelExportSearchCondition } from '../model/outgoingInvoiceExcelExportSearchCondition';
import { OutgoingInvoiceLineTotalsDTO } from '../model/outgoingInvoiceLineTotalsDTO';
import { OutgoingInvoiceLinkedOperationalFileDTO } from '../model/outgoingInvoiceLinkedOperationalFileDTO';
import { OutgoingInvoiceSearchCondition } from '../model/outgoingInvoiceSearchCondition';
import { PagedResponseFoundLinkedCreditNoteDTO } from '../model/pagedResponseFoundLinkedCreditNoteDTO';
import { PagedResponseFoundOutgoingInvoiceDTO } from '../model/pagedResponseFoundOutgoingInvoiceDTO';
import { PreviewCreditNoteDTO } from '../model/previewCreditNoteDTO';
import { Response } from '../model/response';
import { SearchCondition } from '../model/searchCondition';
import { UpdateOutgoingInvoiceDTO } from '../model/updateOutgoingInvoiceDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OutgoingInvoicesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Calculate Expiration Date
     * 
     * @param invoiceDate 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public calculateExpirationDate(invoiceDate?: Date, customerId?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Date>;
    public calculateExpirationDate(invoiceDate?: Date, customerId?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Date>>;
    public calculateExpirationDate(invoiceDate?: Date, customerId?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Date>>;
    public calculateExpirationDate(invoiceDate?: Date, customerId?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invoiceDate !== undefined && invoiceDate !== null) {
            queryParameters = queryParameters.set('invoiceDate', <any>invoiceDate.toISOString());
        }
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Date>(`${this.basePath}/api/finance/outgoing-invoices/expiration-date`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Create a credit note
     * 
     * @param outgoingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCreditNote(outgoingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<AddedCreditNoteDTO>;
    public createCreditNote(outgoingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<AddedCreditNoteDTO>>;
    public createCreditNote(outgoingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<AddedCreditNoteDTO>>;
    public createCreditNote(outgoingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling createCreditNote.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<AddedCreditNoteDTO>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/credit-notes`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Create a credit note with lines
     * 
     * @param outgoingInvoiceId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCreditNoteWithLines(outgoingInvoiceId: number, body?: CreateCreditNoteDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<AddedCreditNoteDTO>;
    public createCreditNoteWithLines(outgoingInvoiceId: number, body?: CreateCreditNoteDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<AddedCreditNoteDTO>>;
    public createCreditNoteWithLines(outgoingInvoiceId: number, body?: CreateCreditNoteDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<AddedCreditNoteDTO>>;
    public createCreditNoteWithLines(outgoingInvoiceId: number, body?: CreateCreditNoteDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling createCreditNoteWithLines.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<AddedCreditNoteDTO>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/credit-lines`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Excel export
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public excelExport(body?: OutgoingInvoiceExcelExportSearchCondition, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public excelExport(body?: OutgoingInvoiceExcelExportSearchCondition, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public excelExport(body?: OutgoingInvoiceExcelExportSearchCondition, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public excelExport(body?: OutgoingInvoiceExcelExportSearchCondition, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post(`${this.basePath}/api/finance/outgoing-invoices/excel-export`,
            body,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Finalize an outgoing invoice
     * 
     * @param outgoingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public finalizeOutgoingInvoice(outgoingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<FinalizedOutgoingInvoiceDTO>;
    public finalizeOutgoingInvoice(outgoingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<FinalizedOutgoingInvoiceDTO>>;
    public finalizeOutgoingInvoice(outgoingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<FinalizedOutgoingInvoiceDTO>>;
    public finalizeOutgoingInvoice(outgoingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling finalizeOutgoingInvoice.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.put<FinalizedOutgoingInvoiceDTO>(`${this.basePath}/api/finance/outgoing-invoices/finalized/${encodeURIComponent(String(outgoingInvoiceId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get all linked credit notes by invoice id
     * 
     * @param outgoingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllLinkedCreditNotes(outgoingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<FoundLinkedCreditNoteDTO>>;
    public getAllLinkedCreditNotes(outgoingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<FoundLinkedCreditNoteDTO>>>;
    public getAllLinkedCreditNotes(outgoingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<FoundLinkedCreditNoteDTO>>>;
    public getAllLinkedCreditNotes(outgoingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling getAllLinkedCreditNotes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Array<FoundLinkedCreditNoteDTO>>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/search-linked-credit-notes`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get credit note dates
     * 
     * @param outgoingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCreditNoteDates(outgoingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<CreditNoteDatesDTO>;
    public getCreditNoteDates(outgoingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<CreditNoteDatesDTO>>;
    public getCreditNoteDates(outgoingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<CreditNoteDatesDTO>>;
    public getCreditNoteDates(outgoingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling getCreditNoteDates.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<CreditNoteDatesDTO>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/credit-note-dates`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get a details
     * 
     * @param outgoingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDetails(outgoingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OutgoingInvoiceDetailsDTO>;
    public getDetails(outgoingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OutgoingInvoiceDetailsDTO>>;
    public getDetails(outgoingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OutgoingInvoiceDetailsDTO>>;
    public getDetails(outgoingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling getDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<OutgoingInvoiceDetailsDTO>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get a list of email addresses for mailing autocomplete for outgoing invoice.
     * 
     * @param outgoingInvoiceId 
     * @param searchTerm 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEmailSuggestions(outgoingInvoiceId: number, searchTerm?: string, pageSize?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<CompanyRelatedEmailDTO>>;
    public getEmailSuggestions(outgoingInvoiceId: number, searchTerm?: string, pageSize?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<CompanyRelatedEmailDTO>>>;
    public getEmailSuggestions(outgoingInvoiceId: number, searchTerm?: string, pageSize?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<CompanyRelatedEmailDTO>>>;
    public getEmailSuggestions(outgoingInvoiceId: number, searchTerm?: string, pageSize?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling getEmailSuggestions.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchTerm !== undefined && searchTerm !== null) {
            queryParameters = queryParameters.set('searchTerm', <any>searchTerm);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<CompanyRelatedEmailDTO>>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/mails/autocomplete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get the linked credit note by invoice line
     * 
     * @param outgoingInvoiceLineId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLinkedCreditNoteByInvoiceLineId(outgoingInvoiceLineId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<LinkedCreditNoteDTO>;
    public getLinkedCreditNoteByInvoiceLineId(outgoingInvoiceLineId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<LinkedCreditNoteDTO>>;
    public getLinkedCreditNoteByInvoiceLineId(outgoingInvoiceLineId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<LinkedCreditNoteDTO>>;
    public getLinkedCreditNoteByInvoiceLineId(outgoingInvoiceLineId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceLineId === null || outgoingInvoiceLineId === undefined) {
            throw new Error('Required parameter outgoingInvoiceLineId was null or undefined when calling getLinkedCreditNoteByInvoiceLineId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<LinkedCreditNoteDTO>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceLineId))}/linked-credit-note`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get info about linked operational files
     * 
     * @param outgoingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLinkedOperationalFiles(outgoingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<OutgoingInvoiceLinkedOperationalFileDTO>>;
    public getLinkedOperationalFiles(outgoingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<OutgoingInvoiceLinkedOperationalFileDTO>>>;
    public getLinkedOperationalFiles(outgoingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<OutgoingInvoiceLinkedOperationalFileDTO>>>;
    public getLinkedOperationalFiles(outgoingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling getLinkedOperationalFiles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<OutgoingInvoiceLinkedOperationalFileDTO>>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/linked-operational-files`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get an new outgoing mail recipients
     * 
     * @param outgoingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMailRecipients(outgoingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<CompanyRelatedEmailDTO>>;
    public getMailRecipients(outgoingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<CompanyRelatedEmailDTO>>>;
    public getMailRecipients(outgoingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<CompanyRelatedEmailDTO>>>;
    public getMailRecipients(outgoingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling getMailRecipients.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<CompanyRelatedEmailDTO>>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/mail-recipients`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get an new outgoing invoice defaults
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNewOutgoingInvoiceDefaults(options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<NewOutgoingInvoiceDefaultsDTO>;
    public getNewOutgoingInvoiceDefaults(options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<NewOutgoingInvoiceDefaultsDTO>>;
    public getNewOutgoingInvoiceDefaults(options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<NewOutgoingInvoiceDefaultsDTO>>;
    public getNewOutgoingInvoiceDefaults(options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<NewOutgoingInvoiceDefaultsDTO>(`${this.basePath}/api/finance/outgoing-invoices/new-defaults`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get an outGoing invoice
     * 
     * @param outgoingInvoiceId 
     * @param outgoingInvoiceNumber 
     * @param invoiceDate 
     * @param invoiceExpirationDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOutGoingInvoice(outgoingInvoiceId: number, outgoingInvoiceNumber?: string, invoiceDate?: Date, invoiceExpirationDate?: Date, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public getOutGoingInvoice(outgoingInvoiceId: number, outgoingInvoiceNumber?: string, invoiceDate?: Date, invoiceExpirationDate?: Date, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public getOutGoingInvoice(outgoingInvoiceId: number, outgoingInvoiceNumber?: string, invoiceDate?: Date, invoiceExpirationDate?: Date, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public getOutGoingInvoice(outgoingInvoiceId: number, outgoingInvoiceNumber?: string, invoiceDate?: Date, invoiceExpirationDate?: Date, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling getOutGoingInvoice.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (outgoingInvoiceNumber !== undefined && outgoingInvoiceNumber !== null) {
            queryParameters = queryParameters.set('outgoingInvoiceNumber', <any>outgoingInvoiceNumber);
        }
        if (invoiceDate !== undefined && invoiceDate !== null) {
            queryParameters = queryParameters.set('invoiceDate', <any>invoiceDate.toISOString());
        }
        if (invoiceExpirationDate !== undefined && invoiceExpirationDate !== null) {
            queryParameters = queryParameters.set('invoiceExpirationDate', <any>invoiceExpirationDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/documents`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get an outgoing invoice copy
     * 
     * @param outgoingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOutgoingInvoiceCopy(outgoingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public getOutgoingInvoiceCopy(outgoingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public getOutgoingInvoiceCopy(outgoingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public getOutgoingInvoiceCopy(outgoingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling getOutgoingInvoiceCopy.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/documents/copy`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get a totals credited info
     * 
     * @param outgoingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOutgoingInvoiceCreditNotesTotal(outgoingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OutgoingInvoiceCreditNotesTotalDTO>;
    public getOutgoingInvoiceCreditNotesTotal(outgoingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OutgoingInvoiceCreditNotesTotalDTO>>;
    public getOutgoingInvoiceCreditNotesTotal(outgoingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OutgoingInvoiceCreditNotesTotalDTO>>;
    public getOutgoingInvoiceCreditNotesTotal(outgoingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling getOutgoingInvoiceCreditNotesTotal.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<OutgoingInvoiceCreditNotesTotalDTO>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/get-all-linked-credit-notes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get outgoing invoice reminder email template types
     * 
     * @param outgoingInvoiceId 
     * @param emailTemplateCategory 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOutgoingInvoiceEmailTemplateTypes(outgoingInvoiceId: number, emailTemplateCategory?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<EmailTemplateTypeDTO>>;
    public getOutgoingInvoiceEmailTemplateTypes(outgoingInvoiceId: number, emailTemplateCategory?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<EmailTemplateTypeDTO>>>;
    public getOutgoingInvoiceEmailTemplateTypes(outgoingInvoiceId: number, emailTemplateCategory?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<EmailTemplateTypeDTO>>>;
    public getOutgoingInvoiceEmailTemplateTypes(outgoingInvoiceId: number, emailTemplateCategory?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling getOutgoingInvoiceEmailTemplateTypes.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (emailTemplateCategory !== undefined && emailTemplateCategory !== null) {
            queryParameters = queryParameters.set('emailTemplateCategory', <any>emailTemplateCategory);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<EmailTemplateTypeDTO>>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/email-template-types`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get outgoing invoice reminder email template
     * 
     * @param outgoingInvoiceId 
     * @param emailTemplateTypeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOutgoingInvoiceFormattedEmailTemplate(outgoingInvoiceId: number, emailTemplateTypeId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<FormattedEmailTemplateDTO>;
    public getOutgoingInvoiceFormattedEmailTemplate(outgoingInvoiceId: number, emailTemplateTypeId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<FormattedEmailTemplateDTO>>;
    public getOutgoingInvoiceFormattedEmailTemplate(outgoingInvoiceId: number, emailTemplateTypeId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<FormattedEmailTemplateDTO>>;
    public getOutgoingInvoiceFormattedEmailTemplate(outgoingInvoiceId: number, emailTemplateTypeId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling getOutgoingInvoiceFormattedEmailTemplate.');
        }

        if (emailTemplateTypeId === null || emailTemplateTypeId === undefined) {
            throw new Error('Required parameter emailTemplateTypeId was null or undefined when calling getOutgoingInvoiceFormattedEmailTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<FormattedEmailTemplateDTO>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/email-template-types/${encodeURIComponent(String(emailTemplateTypeId))}/formatted`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get an outgoing invoice original
     * 
     * @param outgoingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOutgoingInvoiceOriginal(outgoingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public getOutgoingInvoiceOriginal(outgoingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public getOutgoingInvoiceOriginal(outgoingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public getOutgoingInvoiceOriginal(outgoingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling getOutgoingInvoiceOriginal.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/documents/original`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get a totals
     * 
     * @param outgoingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTotals(outgoingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OutgoingInvoiceLineTotalsDTO>;
    public getTotals(outgoingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OutgoingInvoiceLineTotalsDTO>>;
    public getTotals(outgoingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OutgoingInvoiceLineTotalsDTO>>;
    public getTotals(outgoingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling getTotals.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<OutgoingInvoiceLineTotalsDTO>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/total`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Preview a credit note
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public previewCreditNote(body?: PreviewCreditNoteDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public previewCreditNote(body?: PreviewCreditNoteDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public previewCreditNote(body?: PreviewCreditNoteDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public previewCreditNote(body?: PreviewCreditNoteDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post(`${this.basePath}/api/finance/outgoing-invoices/preview-credit-note`,
            body,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Remove an outgoing invoice
     * 
     * @param outgoingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeOutgoingInvoice(outgoingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public removeOutgoingInvoice(outgoingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public removeOutgoingInvoice(outgoingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public removeOutgoingInvoice(outgoingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling removeOutgoingInvoice.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.delete<Response>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Search for outgoing invoice
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public search(body?: OutgoingInvoiceSearchCondition, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PagedResponseFoundOutgoingInvoiceDTO>;
    public search(body?: OutgoingInvoiceSearchCondition, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PagedResponseFoundOutgoingInvoiceDTO>>;
    public search(body?: OutgoingInvoiceSearchCondition, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PagedResponseFoundOutgoingInvoiceDTO>>;
    public search(body?: OutgoingInvoiceSearchCondition, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<PagedResponseFoundOutgoingInvoiceDTO>(`${this.basePath}/api/finance/outgoing-invoices/search`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Search linked credit notes by invoice id
     * 
     * @param outgoingInvoiceId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchLinkedCreditNotes(outgoingInvoiceId: number, body?: SearchCondition, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PagedResponseFoundLinkedCreditNoteDTO>;
    public searchLinkedCreditNotes(outgoingInvoiceId: number, body?: SearchCondition, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PagedResponseFoundLinkedCreditNoteDTO>>;
    public searchLinkedCreditNotes(outgoingInvoiceId: number, body?: SearchCondition, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PagedResponseFoundLinkedCreditNoteDTO>>;
    public searchLinkedCreditNotes(outgoingInvoiceId: number, body?: SearchCondition, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling searchLinkedCreditNotes.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<PagedResponseFoundLinkedCreditNoteDTO>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/search-linked-credit-note`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Unpaid the payment date of an outgoing invoice.
     * 
     * @param outgoingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unpaidOutgoingInvoice(outgoingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public unpaidOutgoingInvoice(outgoingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public unpaidOutgoingInvoice(outgoingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public unpaidOutgoingInvoice(outgoingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling unpaidOutgoingInvoice.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.put<Response>(`${this.basePath}/api/finance/outgoing-invoices/unpaid/${encodeURIComponent(String(outgoingInvoiceId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Update an outgoing invoice
     * 
     * @param outgoingInvoiceId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOutgoingInvoice(outgoingInvoiceId: number, body?: UpdateOutgoingInvoiceDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OutgoingInvoiceDetailsDTO>;
    public updateOutgoingInvoice(outgoingInvoiceId: number, body?: UpdateOutgoingInvoiceDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OutgoingInvoiceDetailsDTO>>;
    public updateOutgoingInvoice(outgoingInvoiceId: number, body?: UpdateOutgoingInvoiceDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OutgoingInvoiceDetailsDTO>>;
    public updateOutgoingInvoice(outgoingInvoiceId: number, body?: UpdateOutgoingInvoiceDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling updateOutgoingInvoice.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.put<OutgoingInvoiceDetailsDTO>(`${this.basePath}/api/finance/outgoing-invoices/updated/${encodeURIComponent(String(outgoingInvoiceId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Update outgoing invoice last reminder sent date
     * 
     * @param outgoingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOutgoingInvoiceLastReminderSentDate(outgoingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Date>;
    public updateOutgoingInvoiceLastReminderSentDate(outgoingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Date>>;
    public updateOutgoingInvoiceLastReminderSentDate(outgoingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Date>>;
    public updateOutgoingInvoiceLastReminderSentDate(outgoingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling updateOutgoingInvoiceLastReminderSentDate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.put<Date>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/last-reminder-sent`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Update the payment date of an outgoing invoice.
     * 
     * @param outgoingInvoiceId 
     * @param paidDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOutgoingInvoicePaidDate(outgoingInvoiceId: number, paidDate?: Date, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Date>;
    public updateOutgoingInvoicePaidDate(outgoingInvoiceId: number, paidDate?: Date, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Date>>;
    public updateOutgoingInvoicePaidDate(outgoingInvoiceId: number, paidDate?: Date, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Date>>;
    public updateOutgoingInvoicePaidDate(outgoingInvoiceId: number, paidDate?: Date, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling updateOutgoingInvoicePaidDate.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (paidDate !== undefined && paidDate !== null) {
            queryParameters = queryParameters.set('paidDate', <any>paidDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.put<Date>(`${this.basePath}/api/finance/outgoing-invoices/paid/${encodeURIComponent(String(outgoingInvoiceId))}`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Validate an outgoing invoice lines
     * 
     * @param outgoingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateOutgoingInvoiceLines(outgoingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<FoundOutgoingInvoiceCompactDTO>>;
    public validateOutgoingInvoiceLines(outgoingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<FoundOutgoingInvoiceCompactDTO>>>;
    public validateOutgoingInvoiceLines(outgoingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<FoundOutgoingInvoiceCompactDTO>>>;
    public validateOutgoingInvoiceLines(outgoingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling validateOutgoingInvoiceLines.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<FoundOutgoingInvoiceCompactDTO>>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/validations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

}
