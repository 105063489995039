/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { HourMinute } from './hourMinute';

export interface ExportFinanceDataDTO { 
    to: string;
    from: string;
    exportOptions?: ExportFinanceDataDTO.ExportOptionsEnum;
    fromHour?: HourMinute;
    toHour?: HourMinute;
    accountingNumbers?: string;
    includeAlreadyExportedInvoices?: boolean;
}
export namespace ExportFinanceDataDTO {
    export type ExportOptionsEnum = 0 | 1 | 2 | 3;
    export const ExportOptionsEnum = {
        NUMBER_0: 0 as ExportOptionsEnum,
        NUMBER_1: 1 as ExportOptionsEnum,
        NUMBER_2: 2 as ExportOptionsEnum,
        NUMBER_3: 3 as ExportOptionsEnum
    };
}