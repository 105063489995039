import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { replaceEncodedQuotes } from "../../../../../../../core/helpers/filename-helper";
import { notAllowedDocumentNameCharactersRegex } from "../../../../../../../core/helpers/regex.helper";
import { FileRenameDialogComponent } from "../file-rename-dialog/file-rename-dialog.component";

export class FileUploadFailData {
  public requestUrl: string;
  public file: any;
  public path: string;
}

@Component({
  selector: "file-upload-fail-modal-dialog",
  templateUrl: "./file-upload-fail-modal-dialog.component.html",
  styleUrls: ["./file-upload-fail-modal-dialog.component.scss"]
})

export class FileUploadFailModalDialogComponent implements OnInit {

  @Input()
  public data: FileUploadFailData;

  @Input()
  public fullFileName: string;

  constructor(
    private http: HttpClient,
    public dialogRef: MatDialogRef<FileUploadFailData>,
    private modal: MatDialog,
  ) {
  }

  public ngOnInit() {
    this.fullFileName = replaceEncodedQuotes(this.data.file.name)
      .replace(notAllowedDocumentNameCharactersRegex, ' ')
      .replace(/\s\s+/g, ' ');
  }

  public cancel() {
    this.dialogRef.close({ success: false });
  }

  public overwrite() {
    const formData: FormData = new FormData();

    formData.append("uploadFiles", this.generateFile());
    formData.append("path", this.data.path);
    formData.append("overwrite", JSON.stringify(true));

    this.http.post<Blob>(this.data.requestUrl, formData, { responseType: "blob" as "json" })
      .subscribe({
        next: () => {
          this.dialogRef.close({ success: true });
        },

      });
  }

  private generateFile(newFileName?: string): Blob {

    const blob = this.data.file.slice(0, this.data.file.size, this.data.file);
    const newFile = new File([blob], newFileName ? newFileName : this.fullFileName, { type: this.data.file.type });

    return newFile;
  }

  public rename() {

    const renameDialogComponent = this.modal.open(FileRenameDialogComponent, {
      disableClose: true,
      maxWidth: "25vw",
      width: "25vw"
    });

    renameDialogComponent.componentInstance.data = {
      fileName: this.fullFileName,
    };

    renameDialogComponent.afterClosed().subscribe((newFileName) => {

      if (newFileName) {
        // Need to set it to empty so that if the new file name also exists,
        // set the new name and redraw the component.
        // If the component is not redrawn the old name will always be used
        //Because the translation does not redraw the component when the passed value changes
        this.fullFileName = "";

        const formData: FormData = new FormData();

        formData.append("uploadFiles", this.generateFile(newFileName));
        formData.append("path", this.data.path);

        this.http.post<Blob>(this.data.requestUrl, formData, { responseType: "blob" as "json" })
          .subscribe({
            next: () => {
              this.fullFileName = newFileName;
              this.dialogRef.close({ success: true });
            },
            error: () => {
              this.fullFileName = newFileName;
            }
          });
      }
    });
  }
}
