export const incoming_invoices_list_locale = {
  TYPE: "Type",
  CURRENCY: "Currency",
  CREDIT_NOTE_SHORT: "CN",
  INVOICE_SHORT: "INV",
  TITLE: "Incoming invoices",
  DOC_REFERENCE: "Doc. reference",
  FILE_REFERENCE: "Operational file nr.",
  INVOICE_REFERENCE: "Invoice nr.",
  SUPPLIER_NAME: "Supplier name",
  AMOUNT_EXCL_VAT: "Amount excl. VAT",
  AMOUNT_VAT: "VAT amount",
  AMOUNT_INCL_VAT: "Amount (incl. VAT)",
  CURRENCY_CODE: "Currency code",
  COMMENT: "Comment",
  COMMENT_CODE: "Comment code",
  ACCOUNTING_NUMBER: "Accounting nr.",
  DUPLICATE: "Duplicate",
  NEW: "New",
  PENDING: "Pending",
  PARTIALLY_BOOKED: "Partially booked",
  BOOKED: "Booked",
  PROTESTED: "Protested",
  INVOICE_NUMBER: "Invoice nr.",
  VAT_SYSTEM: "VAT system",
  DRAFT_NUMBER: "Draft nr.",
  ACCOUNTING_REF: "Accounting ref.",
  BOOKED_OPERATIONAL_FILE_NUMBER: "Booked operational file nr.",
  PAID: "Paid",
  PAID_YES: "Yes",
  PAID_NO: "No",
  PROTEST_SINGLE_INCOMING_INVOICE: "Protest incoming invoice {{invoiceNumber}}",
  PROTEST_MULTIPLE_INCOMING_INVOICE: "Protest incoming invoices",
  PROTEST_REASON: "Protest reason",
  INVOICES_MUST_HAVE_CONSISTENT_PAYEMENT_STATE: "Both paid and unpaid invoices selected. Please choose either all paid or all unpaid invoices",
  EXPANDED: {
    FILE_NUMBER: "File number",
    FILE_TYPE: "File type",
    FROM: "From",
    TO: "To",
    NO_RESULTS_FOUND: "No linked operational files."
  },
  MODALS: {
    SET_PAID_DATE_TITLE: "Payment date",
    SET_PAID_DATE: "Choose a payment date"
  },
  CONFIRM_UNPAID_EXPORT_TITLE: "Confirm operation",
  CONFIRM_UNPAID_EXPORT_TEXT: "Selected invoices have already been paid. Do you want to proceed with export?",
};
