import { Injectable } from "@angular/core";
import { Observable, Subject} from "rxjs";

export class PortChangedEvent {
  public id: string | number;
  public controlName: string;
}

@Injectable({ providedIn: "root" })
export class PortChangedService {

  private isPortChanged$: Subject<PortChangedEvent> = new Subject();

  constructor() {
  }

  public getNext(event: PortChangedEvent): void {
    this.isPortChanged$.next(event);
  }

  public isPortChanged(): Observable<PortChangedEvent> {
    return this.isPortChanged$.asObservable();
  }
}

