import { Component, Inject } from "@angular/core";
import { ConfirmDialogData } from "../../../core/models/dialogs/confirm-dialog-data";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmBase } from "../abstractions/confirm-base.component";

@Component({
  selector: "confirm",
  templateUrl: "./confirm.component.html",
  styleUrls: ["./confirm.component.scss"]
})
export class ConfirmDialog extends ConfirmBase {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {
    super(dialogRef, data);
  }
}
