import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { TenantConfigurationService } from "../../services/client-tenant-configuration/tenant-config.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class DashboardSectionGuard implements CanActivate {
  constructor(
    private router: Router,
    private tenantConfig: TenantConfigurationService) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.tenantConfig.initialize()
      .pipe(
        map(res => res?.moduleSettings?.dashboards ?? false),
        map(dashboardsEnabled => dashboardsEnabled ? true : this.router.parseUrl("/")));
  }
}
