import { NamedLookup } from "../masterdata/lookup";
import { Observable } from "rxjs";
import { FoundOutgoingInvoiceCompactDTO, InttraMessageStateDTO, OperationalFileCompactDetailsDTO, PartyLookupDTO } from "../../services/swagger-gen/fordesk";

export class ConfirmDialogData {
  public confirmationText: string;
  public dialogTitle: string;
  public actionYes?: string;
  public actionNo?: string;
  public htmlText?: boolean;
}

export class ConfirmDialogWithDatesData {
  public confirmationText: string;
  public dialogTitle: string;
  public minDate?: Date;
  public maxDate?: Date;
}

export class ConfirmDialogTextValueData {
  public confirmationText: string;
  public dialogTitle: string;
  public defaultValue?: string;
  public maxLength: number;
}

export class ConfirmDialogResultData {
  public isConfirmed: boolean;
}

export class CopyConfirmDialogResultData extends ConfirmDialogResultData {
  public copyCount?: number;
}

export class ConfirmOutgoingInvoiceLinesDialogData extends ConfirmDialogData {
  public proformaReferences: FoundOutgoingInvoiceCompactDTO[] = [];
}

export class ConfirmDescriptionDropDownDialogData extends ConfirmDialogData {
  public namedLookups$: Observable<NamedLookup[]>;
  public reasonEnabled: boolean;
  public selectedValue?: number;
}

export class ConfirmDescriptionDropDownResultData extends ConfirmDialogResultData {
  public option: NamedLookup;
  public reason?: string;
}

export class ConfirmVGMDialogData extends ConfirmDialogData {
  public intraStateMessages$: Observable<InttraMessageStateDTO[]>;
  public vmgWeighingMethods$?: Observable<NamedLookup[]>;
  public operationalFileCompact$: Observable<OperationalFileCompactDetailsDTO>;
}

export class ConfirmVGMDialogDataResultData extends ConfirmDialogResultData {
  public inttraStateMessageId: number;
  public vmgWeighingMethodId?: number;
  public authorizedContactPerson?: string;
  public phoneNumber?: string;
  public submissionDate?: Date;
  public billOfLadingNumber: string;
  public bookingReference: string;
}

export class ConfirmPartyDropDownDialogData extends ConfirmDialogData {
  public partyLookups$: Observable<PartyLookupDTO[]>;
  public selectedValue?: number;
}

export class ConfirmPartyDropDownResultData extends ConfirmDialogResultData {
  public option: PartyLookupDTO;
}
