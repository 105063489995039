/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreatedIncomingInvoiceDTO } from '../model/createdIncomingInvoiceDTO';
import { ExportDefaultsDTO } from '../model/exportDefaultsDTO';
import { FoundIncomingInvoiceDTO } from '../model/foundIncomingInvoiceDTO';
import { IncomingInvoiceDetailsDTO } from '../model/incomingInvoiceDetailsDTO';
import { IncomingInvoiceDetailsSummaryDTO } from '../model/incomingInvoiceDetailsSummaryDTO';
import { IncomingInvoiceExcelExportSearchCondition } from '../model/incomingInvoiceExcelExportSearchCondition';
import { IncomingInvoiceLinkedOperationalFileDTO } from '../model/incomingInvoiceLinkedOperationalFileDTO';
import { IncomingInvoiceSearchCondition } from '../model/incomingInvoiceSearchCondition';
import { IncomingInvoiceSepaExportDTO } from '../model/incomingInvoiceSepaExportDTO';
import { NewIncomingInvoiceDTO } from '../model/newIncomingInvoiceDTO';
import { NewIncomingInvoiceDefaultsDTO } from '../model/newIncomingInvoiceDefaultsDTO';
import { NewIncomingInvoiceFromBudgetDTO } from '../model/newIncomingInvoiceFromBudgetDTO';
import { PagedResponseFoundIncomingInvoiceDTO } from '../model/pagedResponseFoundIncomingInvoiceDTO';
import { ProtestIncomingInvoiceDTO } from '../model/protestIncomingInvoiceDTO';
import { ProtestedIncomingInvoiceDTO } from '../model/protestedIncomingInvoiceDTO';
import { Response } from '../model/response';
import { UblResultDTO } from '../model/ublResultDTO';
import { UnprotestedIncomingInvoiceDTO } from '../model/unprotestedIncomingInvoiceDTO';
import { UpdatedIncomingInvoiceDTO } from '../model/updatedIncomingInvoiceDTO';
import { ValidateIncomingInvoiceDTO } from '../model/validateIncomingInvoiceDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class IncomingInvoicesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Check if an incoming invoice already exists
     * 
     * @param issueDate 
     * @param supplierId 
     * @param invoiceNumber 
     * @param amountIncludingVat 
     * @param excludeInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkIfIncomingInvoiceAlreadyExists(issueDate?: Date, supplierId?: number, invoiceNumber?: string, amountIncludingVat?: number, excludeInvoiceId?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<boolean>;
    public checkIfIncomingInvoiceAlreadyExists(issueDate?: Date, supplierId?: number, invoiceNumber?: string, amountIncludingVat?: number, excludeInvoiceId?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<boolean>>;
    public checkIfIncomingInvoiceAlreadyExists(issueDate?: Date, supplierId?: number, invoiceNumber?: string, amountIncludingVat?: number, excludeInvoiceId?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<boolean>>;
    public checkIfIncomingInvoiceAlreadyExists(issueDate?: Date, supplierId?: number, invoiceNumber?: string, amountIncludingVat?: number, excludeInvoiceId?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (issueDate !== undefined && issueDate !== null) {
            queryParameters = queryParameters.set('issueDate', <any>issueDate.toISOString());
        }
        if (supplierId !== undefined && supplierId !== null) {
            queryParameters = queryParameters.set('supplierId', <any>supplierId);
        }
        if (invoiceNumber !== undefined && invoiceNumber !== null) {
            queryParameters = queryParameters.set('invoiceNumber', <any>invoiceNumber);
        }
        if (amountIncludingVat !== undefined && amountIncludingVat !== null) {
            queryParameters = queryParameters.set('amountIncludingVat', <any>amountIncludingVat);
        }
        if (excludeInvoiceId !== undefined && excludeInvoiceId !== null) {
            queryParameters = queryParameters.set('excludeInvoiceId', <any>excludeInvoiceId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<boolean>(`${this.basePath}/api/finance/incoming-invoices/duplicate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Create an incoming invoice
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createIncomingInvoice(body?: NewIncomingInvoiceDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<CreatedIncomingInvoiceDTO>;
    public createIncomingInvoice(body?: NewIncomingInvoiceDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<CreatedIncomingInvoiceDTO>>;
    public createIncomingInvoice(body?: NewIncomingInvoiceDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<CreatedIncomingInvoiceDTO>>;
    public createIncomingInvoice(body?: NewIncomingInvoiceDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<CreatedIncomingInvoiceDTO>(`${this.basePath}/api/finance/incoming-invoices`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Create an incoming invoice from budget
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createIncomingInvoiceFromBudget(body?: NewIncomingInvoiceFromBudgetDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<CreatedIncomingInvoiceDTO>;
    public createIncomingInvoiceFromBudget(body?: NewIncomingInvoiceFromBudgetDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<CreatedIncomingInvoiceDTO>>;
    public createIncomingInvoiceFromBudget(body?: NewIncomingInvoiceFromBudgetDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<CreatedIncomingInvoiceDTO>>;
    public createIncomingInvoiceFromBudget(body?: NewIncomingInvoiceFromBudgetDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<CreatedIncomingInvoiceDTO>(`${this.basePath}/api/finance/incoming-invoices/from-budget`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Create an incoming invoice from a UBL file
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createInvoiceFromUBL(file?: Blob, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<UblResultDTO>;
    public createInvoiceFromUBL(file?: Blob, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<UblResultDTO>>;
    public createInvoiceFromUBL(file?: Blob, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<UblResultDTO>>;
    public createInvoiceFromUBL(file?: Blob, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<UblResultDTO>(`${this.basePath}/api/finance/incoming-invoices/upload-ubl`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Delete an incoming invoice 
     * 
     * @param incomingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteIncomingInvoice(incomingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public deleteIncomingInvoice(incomingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public deleteIncomingInvoice(incomingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public deleteIncomingInvoice(incomingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (incomingInvoiceId === null || incomingInvoiceId === undefined) {
            throw new Error('Required parameter incomingInvoiceId was null or undefined when calling deleteIncomingInvoice.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.delete<Response>(`${this.basePath}/api/finance/incoming-invoices/delete/${encodeURIComponent(String(incomingInvoiceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Delete incoming invoice document
     * 
     * @param incomingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteIncomingInvoiceDocument(incomingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public deleteIncomingInvoiceDocument(incomingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public deleteIncomingInvoiceDocument(incomingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public deleteIncomingInvoiceDocument(incomingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (incomingInvoiceId === null || incomingInvoiceId === undefined) {
            throw new Error('Required parameter incomingInvoiceId was null or undefined when calling deleteIncomingInvoiceDocument.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.delete<Response>(`${this.basePath}/api/finance/incoming-invoices/${encodeURIComponent(String(incomingInvoiceId))}/documents`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Excel export
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public excelExport(body?: IncomingInvoiceExcelExportSearchCondition, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public excelExport(body?: IncomingInvoiceExcelExportSearchCondition, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public excelExport(body?: IncomingInvoiceExcelExportSearchCondition, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public excelExport(body?: IncomingInvoiceExcelExportSearchCondition, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post(`${this.basePath}/api/finance/incoming-invoices/excel-export`,
            body,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Export incoming invoice to SEPA file
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportToSepa(body?: IncomingInvoiceSepaExportDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public exportToSepa(body?: IncomingInvoiceSepaExportDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public exportToSepa(body?: IncomingInvoiceSepaExportDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public exportToSepa(body?: IncomingInvoiceSepaExportDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post(`${this.basePath}/api/finance/incoming-invoices/export/sepa`,
            body,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get a details
     * 
     * @param incomingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDetails(incomingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<IncomingInvoiceDetailsDTO>;
    public getDetails(incomingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<IncomingInvoiceDetailsDTO>>;
    public getDetails(incomingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<IncomingInvoiceDetailsDTO>>;
    public getDetails(incomingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (incomingInvoiceId === null || incomingInvoiceId === undefined) {
            throw new Error('Required parameter incomingInvoiceId was null or undefined when calling getDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<IncomingInvoiceDetailsDTO>(`${this.basePath}/api/finance/incoming-invoices/${encodeURIComponent(String(incomingInvoiceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get default finance values for incoming invocie export
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExportDefaults(options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<ExportDefaultsDTO>;
    public getExportDefaults(options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<ExportDefaultsDTO>>;
    public getExportDefaults(options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<ExportDefaultsDTO>>;
    public getExportDefaults(options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<ExportDefaultsDTO>(`${this.basePath}/api/finance/incoming-invoices/export/defaults`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Import tariffs
     * 
     * @param incomingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncomingInvoiceDocument(incomingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public getIncomingInvoiceDocument(incomingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public getIncomingInvoiceDocument(incomingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public getIncomingInvoiceDocument(incomingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (incomingInvoiceId === null || incomingInvoiceId === undefined) {
            throw new Error('Required parameter incomingInvoiceId was null or undefined when calling getIncomingInvoiceDocument.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get(`${this.basePath}/api/finance/incoming-invoices/${encodeURIComponent(String(incomingInvoiceId))}/documents`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get an incoming invoice summary details
     * 
     * @param incomingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncomingInvoiceSummaryDetails(incomingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<IncomingInvoiceDetailsSummaryDTO>;
    public getIncomingInvoiceSummaryDetails(incomingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<IncomingInvoiceDetailsSummaryDTO>>;
    public getIncomingInvoiceSummaryDetails(incomingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<IncomingInvoiceDetailsSummaryDTO>>;
    public getIncomingInvoiceSummaryDetails(incomingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (incomingInvoiceId === null || incomingInvoiceId === undefined) {
            throw new Error('Required parameter incomingInvoiceId was null or undefined when calling getIncomingInvoiceSummaryDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<IncomingInvoiceDetailsSummaryDTO>(`${this.basePath}/api/finance/incoming-invoices/${encodeURIComponent(String(incomingInvoiceId))}/validation-summary`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get linked operational files
     * 
     * @param incomingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLinkedOperationalFiles(incomingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<IncomingInvoiceLinkedOperationalFileDTO>>;
    public getLinkedOperationalFiles(incomingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<IncomingInvoiceLinkedOperationalFileDTO>>>;
    public getLinkedOperationalFiles(incomingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<IncomingInvoiceLinkedOperationalFileDTO>>>;
    public getLinkedOperationalFiles(incomingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (incomingInvoiceId === null || incomingInvoiceId === undefined) {
            throw new Error('Required parameter incomingInvoiceId was null or undefined when calling getLinkedOperationalFiles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<IncomingInvoiceLinkedOperationalFileDTO>>(`${this.basePath}/api/finance/incoming-invoices/${encodeURIComponent(String(incomingInvoiceId))}/linked-operational-files`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get an new incoming invoice details
     * 
     * @param supplierId 
     * @param issueDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNewIncomingInvoiceDetails(supplierId: number, issueDate?: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<NewIncomingInvoiceDefaultsDTO>;
    public getNewIncomingInvoiceDetails(supplierId: number, issueDate?: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<NewIncomingInvoiceDefaultsDTO>>;
    public getNewIncomingInvoiceDetails(supplierId: number, issueDate?: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<NewIncomingInvoiceDefaultsDTO>>;
    public getNewIncomingInvoiceDetails(supplierId: number, issueDate?: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getNewIncomingInvoiceDetails.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (issueDate !== undefined && issueDate !== null) {
            queryParameters = queryParameters.set('issueDate', <any>issueDate);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<NewIncomingInvoiceDefaultsDTO>(`${this.basePath}/api/finance/incoming-invoices/new-defaults/${encodeURIComponent(String(supplierId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Protest an incoming invoice
     * 
     * @param incomingInvoiceId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public protestIncomingInvoice(incomingInvoiceId: number, body?: ProtestIncomingInvoiceDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<ProtestedIncomingInvoiceDTO>;
    public protestIncomingInvoice(incomingInvoiceId: number, body?: ProtestIncomingInvoiceDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<ProtestedIncomingInvoiceDTO>>;
    public protestIncomingInvoice(incomingInvoiceId: number, body?: ProtestIncomingInvoiceDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<ProtestedIncomingInvoiceDTO>>;
    public protestIncomingInvoice(incomingInvoiceId: number, body?: ProtestIncomingInvoiceDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (incomingInvoiceId === null || incomingInvoiceId === undefined) {
            throw new Error('Required parameter incomingInvoiceId was null or undefined when calling protestIncomingInvoice.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<ProtestedIncomingInvoiceDTO>(`${this.basePath}/api/finance/incoming-invoices/${encodeURIComponent(String(incomingInvoiceId))}/protested`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Search for incoming invoice
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public search(body?: IncomingInvoiceSearchCondition, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PagedResponseFoundIncomingInvoiceDTO>;
    public search(body?: IncomingInvoiceSearchCondition, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PagedResponseFoundIncomingInvoiceDTO>>;
    public search(body?: IncomingInvoiceSearchCondition, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PagedResponseFoundIncomingInvoiceDTO>>;
    public search(body?: IncomingInvoiceSearchCondition, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<PagedResponseFoundIncomingInvoiceDTO>(`${this.basePath}/api/finance/incoming-invoices/search`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Undelete an incoming invoice 
     * 
     * @param incomingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unDeleteIncomingInvoice(incomingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public unDeleteIncomingInvoice(incomingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public unDeleteIncomingInvoice(incomingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public unDeleteIncomingInvoice(incomingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (incomingInvoiceId === null || incomingInvoiceId === undefined) {
            throw new Error('Required parameter incomingInvoiceId was null or undefined when calling unDeleteIncomingInvoice.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Response>(`${this.basePath}/api/finance/incoming-invoices/undelete/${encodeURIComponent(String(incomingInvoiceId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Unprotest an incoming invoice
     * 
     * @param incomingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unProtestIncomingInvoice(incomingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<UnprotestedIncomingInvoiceDTO>;
    public unProtestIncomingInvoice(incomingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<UnprotestedIncomingInvoiceDTO>>;
    public unProtestIncomingInvoice(incomingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<UnprotestedIncomingInvoiceDTO>>;
    public unProtestIncomingInvoice(incomingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (incomingInvoiceId === null || incomingInvoiceId === undefined) {
            throw new Error('Required parameter incomingInvoiceId was null or undefined when calling unProtestIncomingInvoice.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<UnprotestedIncomingInvoiceDTO>(`${this.basePath}/api/finance/incoming-invoices/${encodeURIComponent(String(incomingInvoiceId))}/unprotested`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Unbook an incoming invoice
     * 
     * @param incomingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unbookIncomingInvoice(incomingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<FoundIncomingInvoiceDTO>;
    public unbookIncomingInvoice(incomingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<FoundIncomingInvoiceDTO>>;
    public unbookIncomingInvoice(incomingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<FoundIncomingInvoiceDTO>>;
    public unbookIncomingInvoice(incomingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (incomingInvoiceId === null || incomingInvoiceId === undefined) {
            throw new Error('Required parameter incomingInvoiceId was null or undefined when calling unbookIncomingInvoice.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<FoundIncomingInvoiceDTO>(`${this.basePath}/api/finance/incoming-invoices/${encodeURIComponent(String(incomingInvoiceId))}/unbook`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Unpaid the payment date of an incoming invoice.
     * 
     * @param incomingInvoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unpaidIncomingInvoice(incomingInvoiceId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public unpaidIncomingInvoice(incomingInvoiceId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public unpaidIncomingInvoice(incomingInvoiceId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public unpaidIncomingInvoice(incomingInvoiceId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (incomingInvoiceId === null || incomingInvoiceId === undefined) {
            throw new Error('Required parameter incomingInvoiceId was null or undefined when calling unpaidIncomingInvoice.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.put<Response>(`${this.basePath}/api/finance/incoming-invoices/unpaid/${encodeURIComponent(String(incomingInvoiceId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Update an incoming invoice
     * 
     * @param incomingInvoiceId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateIncomingInvoice(incomingInvoiceId: number, body?: UpdatedIncomingInvoiceDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public updateIncomingInvoice(incomingInvoiceId: number, body?: UpdatedIncomingInvoiceDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public updateIncomingInvoice(incomingInvoiceId: number, body?: UpdatedIncomingInvoiceDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public updateIncomingInvoice(incomingInvoiceId: number, body?: UpdatedIncomingInvoiceDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (incomingInvoiceId === null || incomingInvoiceId === undefined) {
            throw new Error('Required parameter incomingInvoiceId was null or undefined when calling updateIncomingInvoice.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.put<Response>(`${this.basePath}/api/finance/incoming-invoices/${encodeURIComponent(String(incomingInvoiceId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Update the payment date of an incoming invoice.
     * 
     * @param incomingInvoiceId 
     * @param paidDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateIncomingInvoicePaidDate(incomingInvoiceId: number, paidDate?: Date, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Date>;
    public updateIncomingInvoicePaidDate(incomingInvoiceId: number, paidDate?: Date, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Date>>;
    public updateIncomingInvoicePaidDate(incomingInvoiceId: number, paidDate?: Date, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Date>>;
    public updateIncomingInvoicePaidDate(incomingInvoiceId: number, paidDate?: Date, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (incomingInvoiceId === null || incomingInvoiceId === undefined) {
            throw new Error('Required parameter incomingInvoiceId was null or undefined when calling updateIncomingInvoicePaidDate.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (paidDate !== undefined && paidDate !== null) {
            queryParameters = queryParameters.set('paidDate', <any>paidDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.put<Date>(`${this.basePath}/api/finance/incoming-invoices/paid/${encodeURIComponent(String(incomingInvoiceId))}`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Upload incoming invoice document
     * 
     * @param incomingInvoiceId 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadIncomingInvoiceDocument(incomingInvoiceId: number, file?: Blob, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<string>;
    public uploadIncomingInvoiceDocument(incomingInvoiceId: number, file?: Blob, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<string>>;
    public uploadIncomingInvoiceDocument(incomingInvoiceId: number, file?: Blob, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<string>>;
    public uploadIncomingInvoiceDocument(incomingInvoiceId: number, file?: Blob, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (incomingInvoiceId === null || incomingInvoiceId === undefined) {
            throw new Error('Required parameter incomingInvoiceId was null or undefined when calling uploadIncomingInvoiceDocument.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<string>(`${this.basePath}/api/finance/incoming-invoices/${encodeURIComponent(String(incomingInvoiceId))}/documents`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Validate an incoming invoice
     * 
     * @param incomingInvoiceId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateIncomingInvoice(incomingInvoiceId: number, body?: ValidateIncomingInvoiceDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<string>;
    public validateIncomingInvoice(incomingInvoiceId: number, body?: ValidateIncomingInvoiceDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<string>>;
    public validateIncomingInvoice(incomingInvoiceId: number, body?: ValidateIncomingInvoiceDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<string>>;
    public validateIncomingInvoice(incomingInvoiceId: number, body?: ValidateIncomingInvoiceDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (incomingInvoiceId === null || incomingInvoiceId === undefined) {
            throw new Error('Required parameter incomingInvoiceId was null or undefined when calling validateIncomingInvoice.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<string>(`${this.basePath}/api/finance/incoming-invoices/${encodeURIComponent(String(incomingInvoiceId))}/validated-incoming-invoices`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

}
