import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { OAuthService } from "angular-oauth2-oidc";
import { UserProfileService } from "../../../../../core/auth/user-profile.service";

@Component({
  selector: "m-user-profile",
  templateUrl: "./user-profile.component.html",
  changeDetection: ChangeDetectionStrategy.Default
})
export class UserProfileComponent implements OnInit {
  @HostBinding("class")
  // tslint:disable-next-line:max-line-length
  public classes: string = "m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light";

  @HostBinding("attr.m-dropdown-toggle")
  public attrDropdownToggle: string = "click";

  @Input() public avatar: string = "./assets/app/media/img/users/user4.jpg";
  @Input() public avatarBg: SafeStyle = "";

  @ViewChild("mProfileDropdown", { static: false }) public mProfileDropdown: ElementRef;

  public firstname: string;
  public lastname: string;
  public email: string;
  constructor(
    private sanitizer: DomSanitizer,
    private auth: OAuthService,
    public cd: ChangeDetectorRef,
    private userProfileService: UserProfileService
  ) { }

  public ngOnInit(): void {
    if (!this.avatarBg) {
      this.avatarBg = this.sanitizer.bypassSecurityTrustStyle("url(./assets/app/media/img/misc/user_profile_bg.jpg)");
    }

    this.userProfileService.getUserProfile$().subscribe(res => {
      this.email = res["preferred_username"];
      this.firstname = res["given_name"];
      this.lastname = res["family_name"];
      this.cd.detectChanges();
    });
  }

  public logout() {
    this.auth.logOut(false);
  }
}
