/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OperationalFileCustomsDeclarationDetailsDTO { 
    id?: number;
    operationalFileId?: number;
    customsDocumentTypeId?: number;
    clearancePartyId?: number;
    consignorPartyId?: number;
    consigneePartyId?: number;
    customsAgentId?: number;
    transportModeId?: number;
    customsDeclarationTypeId?: number;
    combinedCustomsDeclarationTypeId?: number;
    customsDeclarationPartOneTypeId?: number;
    customsDeclarationPartTwoTypeId?: number;
    customsDeclarationProcedureTypeId?: number;
    remark?: string;
    assignmentDate?: Date;
    includedCustomsDocumentTypes?: Array<number>;
    chosenOperationalFileCustomsDocuments?: Array<string>;
    customsOfficeConsigneeId?: number;
    customsOfficeConsignorId?: number;
    customsInvoiceTypeId?: number;
    customsInvoiceDate?: Date;
    customsInvoiceReference?: string;
    customsInvoiceAmount?: number;
    previousArrangementTypeId?: number;
    articleNumber?: string;
    agentCode?: string;
    itemNumber?: string;
    stayNumber?: string;
    customsLocationCode?: string;
    invoicePartyName?: string;
    vatNumber?: string;
    previousArrangementDocumentArt?: number;
    previousArrangementDocumentDate?: Date;
    invoiceCurrencyId?: number;
    customsDeclarationType?: OperationalFileCustomsDeclarationDetailsDTO.CustomsDeclarationTypeEnum;
    operationalFileContainerIds?: Array<number>;
    operationalFileGoodsIds?: Array<number>;
}
export namespace OperationalFileCustomsDeclarationDetailsDTO {
    export type CustomsDeclarationTypeEnum = 0 | 1;
    export const CustomsDeclarationTypeEnum = {
        NUMBER_0: 0 as CustomsDeclarationTypeEnum,
        NUMBER_1: 1 as CustomsDeclarationTypeEnum
    };
}