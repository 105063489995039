import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  NgbAlertModule,
  NgbTabsetModule,
  NgbTooltipModule,
  NgbButtonsModule,
  NgbDatepickerModule,
  NgbTimepickerModule,
  NgbTypeaheadModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbPopoverModule,
  NgbAccordionModule
} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgbAlertModule,
    NgbTabsetModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbButtonsModule,
    NgbTypeaheadModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbPopoverModule,
    NgbAccordionModule
  ],
  exports: [
    NgbAlertModule,
    NgbTabsetModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbTypeaheadModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbPopoverModule,
    NgbAccordionModule
  ]
})
export class BootstrapModule { }
