export const incoming_invoices_new_locale = {
  MESSAGES: {
    AT_LEAST_ONE_COST_ALREADY_BOOKED: "At least one selected line is already on a finalized invoice and cannot be added to a new invoice. Do you want to proceed with just the open lines, or cancel the operation?",
    ALL_COSTS_BOOKED: "All of the selected lines are already on finalized invoices and cannot be placed on a new invoice. Do you want to proceed to create an empty incoming invoice, or cancel the operation?"
  },
  MODALS: {
    TITLE_CREATE: "Create new incoming invoice.",
    MULTIPLE_SUPPLIERS_MESSAGE: "The costs you have selected are from multiple suppliers. Please select budget lines from a single supplier only."
  },
  DEFAULT_INVOICE_NUMBER: "Please enter invoice number"
};
