import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { UtilsService } from "../../utils.service";
import { ApiConfigService } from "../../api-config.service";
import { NamedBlob } from "../../../models/files/named-blob";
import { map, take, takeUntil } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AttachDocumentToSelectedOperationalFiles {
  constructor(protected http: HttpClient,
    protected util: UtilsService,
    protected apiConfig: ApiConfigService) {
  }

  public save(operationalFileIds: number[], fileName: string, file: Blob, documentTemplateId?: number): Observable<boolean> {
    const url: string = this.apiConfig.createFinanceApiUrl("outgoing-invoices/documents/attachdocumenttoselectedofs");
    const formData: FormData = new FormData();
    formData.append(fileName, file, fileName);
    operationalFileIds.forEach(id => {
      formData.append("operationalFileIds", id.toString());
    });
    formData.append("overwrite", JSON.stringify(true));
    if (documentTemplateId) {
      formData.append("documentTemplateId", documentTemplateId.toString());
    }
    formData.append("uploadFiles", file, fileName);
    formData.append("path", "/");

    return this.http.post<boolean>(url, formData, { responseType: "blob" as "json" })
      .pipe(take(1));
  }
}
