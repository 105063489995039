import { environment } from "./../../../environments/environment";
import { Observable, Subject, from, throwError, of } from "rxjs";
import { map, catchError, tap, switchMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { AuthService } from "ngx-auth";
import { TokenStorage } from "./token-storage.service";
import { UtilsService } from "../services/utils.service";
import { AccessData } from "./access-data";
import { Credential } from "./credential";
import * as HttpStatusCode from "http-status-codes";
import { ApiConfigService } from "../services/api-config.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ForwardingRole } from "./roles/roles.enum";
import { OAuthService } from "angular-oauth2-oidc";
import { RoleService } from "./roles/role.service";

@Injectable()
export class AuthenticationService implements AuthService {
  private API_ENDPOINT_LOGIN: string = this.apiConfigService.createApiUrl("/users/login");
  private API_ENDPOINT_REFRESH: string = this.apiConfigService.createApiUrl("/users/refreshaccesstoken");

  public onCredentialUpdated$: Subject<AccessData>;

  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorage,
    private util: UtilsService,
    private apiConfigService: ApiConfigService,
    private roleService: RoleService,
    public oAuthService: OAuthService
  ) {
    this.onCredentialUpdated$ = new Subject();
  }

  /**
   * Check, if user already authorized.
   * @description Should return Observable with true or false values
   * @returns {Observable<boolean>}
   * @memberOf AuthService
   */
  public isAuthorized(): Observable<boolean> {
    return this.tokenStorage.getAccessToken()
      .pipe(map(token => !!token));
  }

  public isAuthorizedSync(): boolean {
    const helper = new JwtHelperService();

    const token = this.tokenStorage.getAccessTokenAsString();
    const isValid = !!token && !helper.isTokenExpired(token);

    return isValid;
  }


  /**
   * Get access token
   * @description Should return access token in Observable from e.g. localStorage
   * @returns {Observable<string>}
   */
  public getAccessToken(): Observable<string> {
    return this.tokenStorage.getAccessToken();
  }

  /**
   * Get user roles
   * @returns {Observable<any>}
   */
  public getUserRoles(): Observable<any> {
    return this.tokenStorage.getUserRoles();
  }

  /**
   * Function, that should perform refresh token verifyTokenRequest
   * @description Should be successfully completed so interceptor
   * can execute pending requests or retry original one
   * @returns {Observable<any>}
   */
  public refreshToken(): Observable<any> {
    return this.tokenStorage.getRefreshToken().pipe(
      switchMap((refreshToken: string) => {
        const accessToken = this.tokenStorage.getAccessTokenAsString();
        return this.http.post<any>(this.API_ENDPOINT_REFRESH, { "AccessToken": accessToken, "RefreshToken": refreshToken });
      }),
      tap(this.saveAccessData.bind(this)),
      catchError(err => {
        this.logout(true);
        return throwError(err);
      })
    );
  }

  /**
   * Function, checks response of failed request to determine,
   * whether token be refreshed or not.
   * @description Essentialy checks status
   * @param {Response} response
   * @returns {boolean}
   */
  public refreshShouldHappen(response: HttpErrorResponse): boolean {
    return response.status === HttpStatusCode.UNAUTHORIZED;
  }

  /**
   * Verify that outgoing request is refresh-token,
   * so interceptor won't intercept this request
   * @param {string} url
   * @returns {boolean}
   */
  public verifyTokenRequest(url: string): boolean {
    return url.endsWith(this.API_ENDPOINT_REFRESH);
  }

  /**
   * Submit login request
   * @param {Credential} credential
   * @returns {Observable<any>}
   */
  public login(credential: Credential): Observable<any> {
    // Expecting response from API
    // {"id":1,"username":"admin","password":"demo","email":"admin@demo.com","accessToken":"access-token-0.022563452858263444","refreshToken":"access-token-0.9348573301432961","roles":["ADMIN"],"pic":"./assets/app/media/img/users/user4.jpg","fullname":"Mark Andre"}
    return this.http.post<AccessData>(this.API_ENDPOINT_LOGIN, credential).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      tap(this.saveAccessData.bind(this)),
      catchError(error => {
        if (!environment.production) {
          console.error(error);
        }
        return throwError(error);
      })
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation: string = "operation", result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      if (!environment.production) {
        console.error(error);
      }

      // Let the app keep running by returning an empty result.
      return from(result);
    };
  }

  /**
   * Logout
   */
  public logout(refresh?: boolean): void {
    this.tokenStorage.clear();
    if (refresh) {
      location.reload();
    }
  }

  /**
   * Save access data in the storage
   * @private
   * @param {AccessData} data
   */
  private saveAccessData(accessData: AccessData) {
    if (typeof accessData !== "undefined") {
      this.tokenStorage
        .setAccessToken(accessData.accessToken)
        .setRefreshToken(accessData.refreshToken)
        .setUserRoles(accessData.roles);
      this.onCredentialUpdated$.next(accessData);
    }
  }

  /**
   * Submit forgot password request
   * @param {Credential} credential
   * @returns {Observable<any>}
   */
  public requestPassword(credential: Credential): Observable<any> {
    return this.http.get(this.API_ENDPOINT_LOGIN + "?" + this.util.urlParam(credential))
      .pipe(catchError(this.handleError("forgot-password", []))
      );
  }

  public hasRoleInToken$(forwardingRole: ForwardingRole): Observable<boolean> {
    return from(this.roleService.getRolesFromUserProfile$())
      .pipe(
        switchMap(roles => {
          return of(roles.includes(forwardingRole));
        })
      );
  }
}
