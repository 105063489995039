import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { OAuthErrorEvent } from "angular-oauth2-oidc";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense(environment.syncfusionAspNetCoreLicenseKey)

if (environment.production) {
  enableProdMode();
} else {
  console.log("environment:");
  console.log(environment);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => {
    if (err instanceof OAuthErrorEvent && err.type === "invalid_nonce_in_state") {
      window.location.href = environment.authentication.redirectUri;
    } else {
      console.log(err);
    }
  });
