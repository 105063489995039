import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ConfirmDateDialogResultData } from "../../../core/models/dialogs/confirm-with-date-dialog-result-data";
import { ConfirmBase } from "../abstractions/confirm-base.component";
import { ConfirmDialogWithDatesData } from "../../../core/models/dialogs/confirm-dialog-data";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { DateInputsHelperService } from "../../../core/helpers/date-inputs-helper.service";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { minDateValidator } from "../../../core/validators/min-date-validator";
import { CustomValidators } from "ngx-custom-validators";
import { maxDateValidator } from "../../../core/validators/max-date-validator";

@Component({
  selector: "confirm-date-picker",
  templateUrl: "./confirm-date-picker.component.html",
  styleUrls: ["./confirm-date-picker.component.scss"]
})
export class ConfirmDatePickerComponent extends ConfirmBase implements OnInit {

  public date: Date;
  public minDate: NgbDate;
  public minDateInDateForm: Date;
  public maxDate: NgbDate;
  public maxDateInDateForm: Date;

  public get minDateAsString() {
    return this.dateInputsHelperService.transformDateInput(this.minDateInDateForm);
  }

  public get maxDateAsString() {
    return this.dateInputsHelperService.transformDateInput(this.maxDateInDateForm);
  }

  public datesForm: UntypedFormGroup;
  public submitted: boolean = false;

  get f() {
    return this.datesForm.controls;
  }

  constructor(
    public dialogRef: MatDialogRef<ConfirmDatePickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogWithDatesData, private dateInputsHelperService: DateInputsHelperService, private fb: UntypedFormBuilder) {
    super(dialogRef, data);

    this.trySetMinDate(data);
    this.trySetMaxDate(data);
  }

  private trySetMinDate(data: ConfirmDialogWithDatesData) {
    const minDate = data.minDate;
    if (minDate) {
      this.minDateInDateForm = minDate;
      this.minDate = this.dateInputsHelperService.toNgbDate(minDate);
    }
  }

  private trySetMaxDate(data: ConfirmDialogWithDatesData) {
    const maxDate = data.maxDate;
    if (maxDate) {
      this.maxDateInDateForm = maxDate;
      this.maxDate = this.dateInputsHelperService.toNgbDate(maxDate);
    }
  }

  public ngOnInit(): void {
    this.date = new Date();

    this.datesForm = this.fb.group({
      selectedDate: [this.date, [minDateValidator(this.minDateInDateForm), maxDateValidator(this.maxDateInDateForm), CustomValidators.date]]
    });
  }

  public confirm() {
    this.submitted = true;
    if (!this.datesForm.valid) {
      return;
    }

    const dialogResult: ConfirmDateDialogResultData = {
      isConfirmed: true,
      date: this.date
    };

    this.dialogRef.close(dialogResult);
  }
}
