import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class DigitalArchiveService {
  private closeModal: Subject<void> = new Subject<void>();
  private refresh$: Subject<void> = new Subject<void>();

  constructor() { }

  public notifyCloseModal() {
    this.closeModal.next();
  }

  public observeCloseModal() {
    return this.closeModal.asObservable();
  }

  public notifyRefresh() {
    this.refresh$.next();
  }

  public observeRefresh() {
    return this.refresh$.asObservable();
  }
}
