export const of_displayed_columns = {
  filenr: "File number",
  customer: "Customer",
  filetype: "File type",
  steps: "Steps",
  "nrOfContainers-nrOfGoods": "Containers / Goods",
  "carrier-vessel": "Carrier / Vessel",
  "pol-pod": "P.o.L / P.o.D",
  "etd-eta": "ETD / ETA",
  creationdate: "Creation date",
  filedate: "File date",
  incoterms: "Incoterms",
  status: "Status",
  "placeOfReceipt-placeOfDestination": "Place of Receipt / Place of Destination",
  terminal: "Terminal",
  berthDate: "Berth date",
  "airwayBillNr-flightNr": "Airway Bill Nr. / Flight nr."
};
