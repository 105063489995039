import { ElementRef, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { NamedBlob } from "../models/files/named-blob";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class FileDownloadService {

  constructor(protected http: HttpClient) {
  }

  public download(downloadLink: ElementRef, file: Blob, fileName: string): void {
    const url: string = window.URL.createObjectURL(file);

    const link = downloadLink.nativeElement;
    link.href = url;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  public downloadFile(resourceUrl: string, fileName: string, path: string, useMasterTemplate?: boolean): Observable<NamedBlob> {
    let url = `${resourceUrl}/${encodeURIComponent(fileName)}`;
    if (useMasterTemplate !== null && useMasterTemplate !== undefined) {
      url += `?useMasterTemplate=${useMasterTemplate}`;
    }

    const formData: FormData = new FormData();
    formData.append("path", path);

    return this.http.post<Blob>(url, formData, { responseType: "blob" as "json", observe: "response" }
    ).pipe(
      map(response => {
        if (!response) {
          return null;
        }

        const namedBlob = new NamedBlob();
        namedBlob.initFromHttpResponse(response, fileName);
        namedBlob.fileName = decodeURIComponent(namedBlob.fileName)

        return namedBlob;
      }));
  }
}
