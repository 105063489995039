import { Directive, HostListener, ElementRef } from "@angular/core";

@Directive({
  selector: "[alphaOnly]"
})
export class AlphaOnlyDirective {
  private readonly regexStr: string = "^[a-zA-Z]*$";

  constructor(private el: ElementRef) { }

  @HostListener("keypress", ["$event"]) public onKeyPress(event: KeyboardEvent): boolean {
    return new RegExp(this.regexStr).test(event.key);
  }

  @HostListener("paste", ["$event"]) public blockPaste(event: KeyboardEvent): void {
    this.validateFields(event);
  }

  public validateFields(event: any): void {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^a-zA-Z]/g, "");
      event.preventDefault();
    }, 100);
  }

}
