/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OperationalFileTariffDetailsDTO { 
    portOfLoadingId?: number;
    portOfDischargeId?: number;
    placeOfDestinationId?: number;
    placeOfReceiptId?: number;
    transportTypeId?: OperationalFileTariffDetailsDTO.TransportTypeIdEnum;
    shipmentTypeId?: OperationalFileTariffDetailsDTO.ShipmentTypeIdEnum;
    fileTypeId?: OperationalFileTariffDetailsDTO.FileTypeIdEnum;
}
export namespace OperationalFileTariffDetailsDTO {
    export type TransportTypeIdEnum = 0 | 1 | 2 | 3;
    export const TransportTypeIdEnum = {
        NUMBER_0: 0 as TransportTypeIdEnum,
        NUMBER_1: 1 as TransportTypeIdEnum,
        NUMBER_2: 2 as TransportTypeIdEnum,
        NUMBER_3: 3 as TransportTypeIdEnum
    };
    export type ShipmentTypeIdEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const ShipmentTypeIdEnum = {
        NUMBER_0: 0 as ShipmentTypeIdEnum,
        NUMBER_1: 1 as ShipmentTypeIdEnum,
        NUMBER_2: 2 as ShipmentTypeIdEnum,
        NUMBER_3: 3 as ShipmentTypeIdEnum,
        NUMBER_4: 4 as ShipmentTypeIdEnum,
        NUMBER_5: 5 as ShipmentTypeIdEnum,
        NUMBER_6: 6 as ShipmentTypeIdEnum
    };
    export type FileTypeIdEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const FileTypeIdEnum = {
        NUMBER_0: 0 as FileTypeIdEnum,
        NUMBER_1: 1 as FileTypeIdEnum,
        NUMBER_2: 2 as FileTypeIdEnum,
        NUMBER_3: 3 as FileTypeIdEnum,
        NUMBER_4: 4 as FileTypeIdEnum,
        NUMBER_5: 5 as FileTypeIdEnum,
        NUMBER_6: 6 as FileTypeIdEnum
    };
}