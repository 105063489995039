import { Injectable } from "@angular/core";
import { NumberRangeInputData } from "../../models/utilities/number-range-input-data.model";
import { NumberRangeInputType } from "../../enumerations/number-range-input-type.enum";
import { Subject, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class NumberRangeInputActiveService {

  private inputActive$: Subject<NumberRangeInputData> = new Subject<NumberRangeInputData>();

  public getInputActive$(): Observable<NumberRangeInputData> {
    return this.inputActive$.asObservable();
  }

  public focusin(): void {
    this.inputActive$.next({
      isActive: true
    });
  }

  public focusout(inputType: NumberRangeInputType): void {
    this.inputActive$.next({
      isActive: false,
      type: inputType
    });
  }
}
