import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Injectable({ providedIn: "root" })
export class ToasterService {
  constructor(
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }

  public showSuccess(title: string, message: string) {
    this.toastr.success(message, title);
  }

  public showError(title: string, message: string) {
    this.toastr.error(message, title);
  }

  public showWarning(title: string, message: string) {
    this.toastr.warning(message, title);
  }

  public showInfo(title: string, message: string) {
    this.toastr.info(message, title);
  }

  /**
   * Displays a message indicating success, with a generic title.
   * @param message The message to be displayed.
   */
  public success(message: string) {
    const title = this.translate.instant("MESSAGES.SUCCESS");
    this.toastr.success(message, title);
  }

  /**
   * Displays a message indicating an error, with a generic title.
   * @param message The message to be displayed.
   */
  public error(message: string) {
    const title = this.translate.instant("MESSAGES.ERROR");
    this.toastr.error(message, title);
  }

  /**
   * Displays a message indicating a warning, with a generic title.
   * @param message The message to be displayed.
   */
  public warning(message: string) {
    const title = this.translate.instant("MESSAGES.WARNING");
    this.toastr.warning(message, title);
  }

  /**
   * Displays an informational message, with a generic title.
   * @param message The message to be displayed.
   */
  public info(message: string) {
    const title = this.translate.instant("MESSAGES.INFO");
    this.toastr.info(message, title);
  }
}
