export const of_revenues_locale = {
  INVOICED: "Invoiced",
  REVENUES: "Revenues",
  RESULT: "Result",
  ACTUAL_RESULT: "Actual result",
  COSTS: "Costs",
  ADD_NEW_COST: "Add new cost",
  COST_DETAILS: "Cost details",
  ACTIVITY_CODE: "Activity code",
  PARTY: "Party",
  WE_FOUND_OTHER_SUPPLIERS_WITH_SAME_TARIFF: "We have found other customers having tariffs that can be applied for this selection, do you want to continue?",
  NO_OTHER_SUPPLIERS_WITH_SAME_TARIFF_WERE_FOUND: "No other suppliers have tariffs that can be applied for this selection.",
  COMPANIES_SUGGESTIONS: "Existing companies with suitable tariffs",
  CURRENTLY_SELECTED_COMPANY: "Currently selected company",
  REVENUE_PRICE: "Revenue price",
  COST_PRICE: "Cost price",
  NO_TARIFF: "no tarrif",
  CURRENCY: "Currency",
  EXCHANGE_RATE: "Exchange rate",
  EXCHANGE_RATE_VALIDATION: "The exchange rate is not set for the selected currency. Use either the operational-file's currency or add an exchange rate in the general section.",
  QUANTITY: "Quantity",
  PRICE: "Price",
  PRICE_CONVERTED: "Price",
  VAT: "VAT",
  TOTAL: "Total",
  TOTAL_CONVERTED: "Total",
  VAT_DETAILS: "VAT details",
  ISINVOICED_DETAIL: "This revenue is invoiced and cannot be changed.",
  REVENUE_PRE_VALIDATION: "Port of loading and place of discharge or place of receipt and port of discharge has to be known. Please input those first.",
  NO_INVOICE_CUSTOMERS: "This operational-file has no customers to invoice yet. Please input those first.",
  COPY_TO_COSTS: "Copy to costs",
  NOT_INVOICED: "New",
  REMARKS: "Remarks",
  INVOICE_NUMBER: "Invoice nr",
  INVOICE_REFERENCE: "Invoice ref",
  TAX_PERCENTAGE: "Tax percentage",
  TAX_SYSTEM: "Tax system",
  ISSUE_DATE: "Issue date",
  INVOICE_STATUS: "Invoice status",
  INVOICE_SUMMARY: "Invoice summary",
  PROFORMA: "Proforma",
  PROFORMA_NUMBER: "Proforma nr.",
  TOTAL_INCL_VAT: "Total (incl. VAT)",
  INVOICE_OR_PROFORMA_REFERENCE: "Inv. / Proforma ref.",
  INVOICE_DATE: "Invoice date",
  MODAL: {
    REVENUES_CHANGED: "Revenue lines have been changed in the meantime and need to be reloaded",
    TITLE_REVENUES_CHANGED: "Revenue lines have been changed",
    RELOAD_REVENUES: "Reload revenues"
  }
};
