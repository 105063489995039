import { AfterContentInit, AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit } from "@angular/core";
import { MatSort, Sort } from "@angular/material/sort";
import { Subscription } from "rxjs";
import { OPERATIONAL_STORAGE } from "../../../core/services/helpers/local-storage-keys";
import { FilterCacheService } from "../../../core/services/utilities/filter-cache.service";

/**
 * Applies sorting behavior and styles of mat-sort-header to a table header with combined columns values.
 * Must be provided with the list of ids to apply sorting, for example '["firstField", "secondField"]'.
 * Combined header titles must be wrapper in header-combined-part elements.
 */
@Component({
  selector: "[mat-sort-header-combined]",
  templateUrl: './sort-header-combined.component.html',
  styleUrls: ["./sort-header-combined.component.scss"],
})
export class SortHeaderCombinedComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
  private combinedHeaderElement = null;
  private matSortHeadersElements = [];
  private matSortHeadersClickListeners = [];
  private matSortHeadersMouseOverListeners = [];
  private matSortHeadersMouseLeaveListeners = [];
  private matSortSubscription: Subscription;

  public ids: string[] = [];
  public headerPartsTexts: string[] = [];

  public arrowPreviewed: boolean = false;
  public sortingApplied: boolean = false;
  public currentSortProperty: string = '';
  public currentSortPropertyIndex: number = -1;
  public nextSortDirection: string = 'asc';

  @Input('mat-sort-header-combined')
  public set setIds(value: string) {
    this.ids = JSON.parse(value);
  }

  @Input('matTableSort')
  public matTableSort: MatSort;

  constructor(
    private elementRef: ElementRef,
    private filterCacheService: FilterCacheService<string>) {
  }

  ngOnInit() {
    const [sortProperty, sortDirection] = this.getCachedSortState();
    this.updateSortingState(sortProperty, sortDirection);

    this.matSortSubscription = this.matTableSort.sortChange.subscribe((sort: Sort) => {
      this.updateSortingState(sort.active, sort.direction);
      this.arrowPreviewed = false;
    });
  }

  ngAfterViewInit() {
    this.combinedHeaderElement = this.elementRef.nativeElement.getElementsByClassName('mat-sort-header-combined')[0];
    this.matSortHeadersElements = [...this.elementRef.nativeElement.getElementsByClassName('mat-sort-header')];

    this.subscribeHeadersClickEvents();
    this.subscribeHeadersMouseOverEvents();
    this.subscribeHeadersMouseLeaveEvents();
  }

  ngAfterContentInit() {
    const headerPartsElements = [...this.elementRef.nativeElement.querySelectorAll("header-combined-part")];
    this.headerPartsTexts = headerPartsElements
      .map(headerPart => headerPart.innerText);
  }

  ngOnDestroy() {
    this.matSortSubscription.unsubscribe();
    this.unsubscribeHeadersEvents();
  }

  @HostListener('click', ['$event'])
  handleClick(event) {
    const [targetPropertyIndex, targetSortDirection] = this.getNextSortParams();
    this.filterCacheService.storeSorting(OPERATIONAL_STORAGE, this.ids[targetPropertyIndex], targetSortDirection);

    this.matTableSort.sort({
      id: this.ids[targetPropertyIndex],
      start: targetSortDirection as ('asc' | 'desc'),
      disableClear: false
    });
  }

  private subscribeHeadersClickEvents(): void {
    this.matSortHeadersClickListeners = this.matSortHeadersElements
      .map((matSortHeader) => {
        const listener = (event) => {
          // Do not allow direct mouse clicks to prevent default mat-sort-header behavior. We will emulate them from parent combined column component.
          if (event.isTrusted && !event.target.classList.contains('mat-sort-header-combined')) {
            event.stopPropagation();
            this.combinedHeaderElement.click();
          }
        };
        matSortHeader.addEventListener('click', listener, true);
        return listener;
      });
  }

  private subscribeHeadersMouseOverEvents(): void {
    this.matSortHeadersMouseOverListeners = this.matSortHeadersElements
      .map((matSortHeader, index) => {
        const listener = () => {
          if (this.currentSortProperty !== this.ids[index]) {
            this.arrowPreviewed = true;
          }
        };
        matSortHeader.addEventListener('mouseover', listener);
        return listener;
      });
  }

  private subscribeHeadersMouseLeaveEvents(): void {
    this.matSortHeadersMouseLeaveListeners = this.matSortHeadersElements
      .map((matSortHeader, index) => {
        const listener = () => {
          if (this.currentSortProperty !== this.ids[index]) {
            this.arrowPreviewed = false;
          }
        };
        matSortHeader.addEventListener('mouseleave', listener);
        return listener;
      });
  }

  private unsubscribeHeadersEvents(): void {
    for (let i = 0; i < this.matSortHeadersElements.length; i++) {
      this.matSortHeadersElements[i].removeEventListener('click', this.matSortHeadersClickListeners[i], true);
      this.matSortHeadersElements[i].removeEventListener('mouseover', this.matSortHeadersMouseOverListeners[i]);
      this.matSortHeadersElements[i].removeEventListener('mouseleave', this.matSortHeadersMouseLeaveListeners[i]);
    }
  }

  private getNextSortParams(): [string, string] {
    const [sortProperty, sortDirection] = this.getCachedSortState();

    let targetPropertyIndex;
    let targetSortDirection = sortDirection

    if (sortProperty === '' || this.ids.indexOf(sortProperty) === -1) {
      targetPropertyIndex = 0;
      targetSortDirection = 'asc';
    } else {
      targetPropertyIndex = this.ids.indexOf(sortProperty) + 1;
      if (targetPropertyIndex === this.ids.length) {
        targetPropertyIndex = 0;
        targetSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
      }
    }

    return [targetPropertyIndex, targetSortDirection];
  }

  private getCachedSortState(): [string, string] {
    const cachedFilter = this.filterCacheService.get(OPERATIONAL_STORAGE);
    const sortProperty = cachedFilter ? cachedFilter.sortProperty : '';
    const sortDirection = cachedFilter ? cachedFilter.sortDirection : '';

    return [sortProperty, sortDirection];
  }

  private updateSortingState(sortProperty: string, sortDirection: string): void {
    this.currentSortProperty = sortProperty;
    this.currentSortPropertyIndex = this.ids.indexOf(sortProperty);
    this.sortingApplied = this.currentSortPropertyIndex != -1;
    if (this.currentSortPropertyIndex === this.ids.length - 1) {
      this.nextSortDirection = (sortDirection === 'asc') ? 'desc' : 'asc';
    } else {
      this.nextSortDirection = sortDirection;
    }
  }
}
