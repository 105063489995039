export function fileNameHasExtension(fileName: string, extensionToCheck: string): boolean {
  const extension = getFileNameExtension(fileName);
  return extension && extensionToCheck.toLowerCase().includes(extension);
}

export function getFileNameExtension(fileName: string): string {
  const fileNameParts = fileName.split('.');

  if (fileNameParts.length > 1) {
    return fileNameParts.pop().toLowerCase();
  }
}

export function getFileNameWithoutExtension(fileName: string): string {
  return fileName.replace(/\.[^/.]+$/, "");
}

// Replaces endoded by ej2-angular-documenteditor library quote character to whitespace.
export function replaceEncodedQuotes(fileName: string) {
  return fileName?.replace(/%22/g, ' ');
}
