import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[toUpperCase]"
})
export class ToUpperCaseDirective {

  constructor() { }

  @HostListener("input", ["$event"]) public onKeyUp(event: KeyboardEvent) {
    event.target["value"] = event.target["value"].toUpperCase();
  }
}
