/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FoundOperationalFileCost { 
    id?: number;
    lastRowModificationTimestamp?: Date;
    isBooked?: boolean;
    partyName?: string;
    activityCodeDescription?: string;
    quantity?: number;
    priceExclVat?: number;
    totalExclVat?: number;
    totalExclVatOriginalCurrency?: number;
    currencyCode?: string;
    isOutOfBudget?: boolean;
    operationalFileId?: number;
    sortValue?: number;
    partyId?: number;
    vatAmount?: number;
    totalInclVat?: number;
    totalInclVatOriginalCurrency?: number;
    incomingInvoiceId?: number;
    incomingInvoiceAccountingNumber?: string;
    incomingInvoiceDraftNumber?: string;
    taxPercentage?: number;
    taxSystem?: string;
    issueDate?: Date;
    invoiceStatus?: FoundOperationalFileCost.InvoiceStatusEnum;
    incomingInvoiceNumber?: string;
    remarks?: string;
    activityCodesCode?: string;
    operationalFileTransportId?: number;
}
export namespace FoundOperationalFileCost {
    export type InvoiceStatusEnum = 1 | 2 | 3 | 4 | 5;
    export const InvoiceStatusEnum = {
        NUMBER_1: 1 as InvoiceStatusEnum,
        NUMBER_2: 2 as InvoiceStatusEnum,
        NUMBER_3: 3 as InvoiceStatusEnum,
        NUMBER_4: 4 as InvoiceStatusEnum,
        NUMBER_5: 5 as InvoiceStatusEnum
    };
}