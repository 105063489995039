/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddOutgoingInvoiceLine } from '../model/addOutgoingInvoiceLine';
import { AddedOutgoingInvoiceLineDTO } from '../model/addedOutgoingInvoiceLineDTO';
import { DeletedOutgoingInvoiceLineDTO } from '../model/deletedOutgoingInvoiceLineDTO';
import { ExcelExportSearchCondition } from '../model/excelExportSearchCondition';
import { OutgoingInvoiceLineDetailsDTO } from '../model/outgoingInvoiceLineDetailsDTO';
import { PagedResponseFoundOutgoingInvoiceLineDTO } from '../model/pagedResponseFoundOutgoingInvoiceLineDTO';
import { Response } from '../model/response';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OutgoingInvoiceLinesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add an outgoing invoice line
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOutgoingInvoiceLine(body?: AddOutgoingInvoiceLine, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<AddedOutgoingInvoiceLineDTO>;
    public addOutgoingInvoiceLine(body?: AddOutgoingInvoiceLine, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<AddedOutgoingInvoiceLineDTO>>;
    public addOutgoingInvoiceLine(body?: AddOutgoingInvoiceLine, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<AddedOutgoingInvoiceLineDTO>>;
    public addOutgoingInvoiceLine(body?: AddOutgoingInvoiceLine, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<AddedOutgoingInvoiceLineDTO>(`${this.basePath}/api/finance/outgoing-invoices/line`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Add an outgoing invoice line
     * 
     * @param outgoingInvoiceId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOutgoingInvoiceLine_1(outgoingInvoiceId: number, body?: AddOutgoingInvoiceLine, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<AddedOutgoingInvoiceLineDTO>;
    public addOutgoingInvoiceLine_1(outgoingInvoiceId: number, body?: AddOutgoingInvoiceLine, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<AddedOutgoingInvoiceLineDTO>>;
    public addOutgoingInvoiceLine_1(outgoingInvoiceId: number, body?: AddOutgoingInvoiceLine, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<AddedOutgoingInvoiceLineDTO>>;
    public addOutgoingInvoiceLine_1(outgoingInvoiceId: number, body?: AddOutgoingInvoiceLine, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling addOutgoingInvoiceLine_1.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.put<AddedOutgoingInvoiceLineDTO>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/line`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Excel export
     * 
     * @param outgoingInvoiceId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public excelExport(outgoingInvoiceId: number, body?: ExcelExportSearchCondition, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public excelExport(outgoingInvoiceId: number, body?: ExcelExportSearchCondition, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public excelExport(outgoingInvoiceId: number, body?: ExcelExportSearchCondition, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public excelExport(outgoingInvoiceId: number, body?: ExcelExportSearchCondition, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling excelExport.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/excel-export`,
            body,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get a details
     * 
     * @param outgoingInvoiceId 
     * @param outgoingInvoiceLineId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDetails(outgoingInvoiceId: number, outgoingInvoiceLineId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OutgoingInvoiceLineDetailsDTO>;
    public getDetails(outgoingInvoiceId: number, outgoingInvoiceLineId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OutgoingInvoiceLineDetailsDTO>>;
    public getDetails(outgoingInvoiceId: number, outgoingInvoiceLineId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OutgoingInvoiceLineDetailsDTO>>;
    public getDetails(outgoingInvoiceId: number, outgoingInvoiceLineId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling getDetails.');
        }

        if (outgoingInvoiceLineId === null || outgoingInvoiceLineId === undefined) {
            throw new Error('Required parameter outgoingInvoiceLineId was null or undefined when calling getDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<OutgoingInvoiceLineDetailsDTO>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/line/${encodeURIComponent(String(outgoingInvoiceLineId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Remove outgoing invoice lines
     * 
     * @param outgoingInvoiceId 
     * @param outgoingInvoiceLineIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeOutgoingInvoiceLines(outgoingInvoiceId: number, outgoingInvoiceLineIds: Array<number>, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<DeletedOutgoingInvoiceLineDTO>>;
    public removeOutgoingInvoiceLines(outgoingInvoiceId: number, outgoingInvoiceLineIds: Array<number>, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<DeletedOutgoingInvoiceLineDTO>>>;
    public removeOutgoingInvoiceLines(outgoingInvoiceId: number, outgoingInvoiceLineIds: Array<number>, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<DeletedOutgoingInvoiceLineDTO>>>;
    public removeOutgoingInvoiceLines(outgoingInvoiceId: number, outgoingInvoiceLineIds: Array<number>, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling removeOutgoingInvoiceLines.');
        }

        if (outgoingInvoiceLineIds === null || outgoingInvoiceLineIds === undefined) {
            throw new Error('Required parameter outgoingInvoiceLineIds was null or undefined when calling removeOutgoingInvoiceLines.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (outgoingInvoiceLineIds) {
            outgoingInvoiceLineIds.forEach((element) => {
                queryParameters = queryParameters.append('outgoingInvoiceLineIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.delete<Array<DeletedOutgoingInvoiceLineDTO>>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/lines`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Search for outgoing invoice lines
     * 
     * @param outgoingInvoiceId 
     * @param searchTerm 
     * @param sortDirection 
     * @param sortProperty 
     * @param pageSize 
     * @param page 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchOutgoingInvoiceLines(outgoingInvoiceId: number, searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PagedResponseFoundOutgoingInvoiceLineDTO>;
    public searchOutgoingInvoiceLines(outgoingInvoiceId: number, searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PagedResponseFoundOutgoingInvoiceLineDTO>>;
    public searchOutgoingInvoiceLines(outgoingInvoiceId: number, searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PagedResponseFoundOutgoingInvoiceLineDTO>>;
    public searchOutgoingInvoiceLines(outgoingInvoiceId: number, searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling searchOutgoingInvoiceLines.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchTerm !== undefined && searchTerm !== null) {
            queryParameters = queryParameters.set('SearchTerm', <any>searchTerm);
        }
        if (sortDirection !== undefined && sortDirection !== null) {
            queryParameters = queryParameters.set('SortDirection', <any>sortDirection);
        }
        if (sortProperty !== undefined && sortProperty !== null) {
            queryParameters = queryParameters.set('SortProperty', <any>sortProperty);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<PagedResponseFoundOutgoingInvoiceLineDTO>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/line/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Update a vat details on outgoing invoice line
     * 
     * @param outgoingInvoiceId 
     * @param outgoingInvoiceLineId 
     * @param outgoingTaxCodeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateVatDetailsOnOutgoingInvoiceLine(outgoingInvoiceId: number, outgoingInvoiceLineId: number, outgoingTaxCodeId?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public updateVatDetailsOnOutgoingInvoiceLine(outgoingInvoiceId: number, outgoingInvoiceLineId: number, outgoingTaxCodeId?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public updateVatDetailsOnOutgoingInvoiceLine(outgoingInvoiceId: number, outgoingInvoiceLineId: number, outgoingTaxCodeId?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public updateVatDetailsOnOutgoingInvoiceLine(outgoingInvoiceId: number, outgoingInvoiceLineId: number, outgoingTaxCodeId?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (outgoingInvoiceId === null || outgoingInvoiceId === undefined) {
            throw new Error('Required parameter outgoingInvoiceId was null or undefined when calling updateVatDetailsOnOutgoingInvoiceLine.');
        }

        if (outgoingInvoiceLineId === null || outgoingInvoiceLineId === undefined) {
            throw new Error('Required parameter outgoingInvoiceLineId was null or undefined when calling updateVatDetailsOnOutgoingInvoiceLine.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (outgoingTaxCodeId !== undefined && outgoingTaxCodeId !== null) {
            queryParameters = queryParameters.set('outgoingTaxCodeId', <any>outgoingTaxCodeId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.patch<Response>(`${this.basePath}/api/finance/outgoing-invoices/${encodeURIComponent(String(outgoingInvoiceId))}/line/${encodeURIComponent(String(outgoingInvoiceLineId))}`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

}
