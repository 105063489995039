import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccountingGroupsService } from './api/accountingGroups.service';
import { AccountingPeriodService } from './api/accountingPeriod.service';
import { ActivityCodeGroupsService } from './api/activityCodeGroups.service';
import { ActivityCodesService } from './api/activityCodes.service';
import { AddressesService } from './api/addresses.service';
import { AirportsService } from './api/airports.service';
import { ArchivedNotificationsService } from './api/archivedNotifications.service';
import { BookingsService } from './api/bookings.service';
import { BudgetService } from './api/budget.service';
import { ClientPortalUsersService } from './api/clientPortalUsers.service';
import { ClientTenantConfigurationService } from './api/clientTenantConfiguration.service';
import { CombinedCustomsDeclarationTypesService } from './api/combinedCustomsDeclarationTypes.service';
import { CompaniesService } from './api/companies.service';
import { CompanyAddressesService } from './api/companyAddresses.service';
import { ContactPersonTypesService } from './api/contactPersonTypes.service';
import { ContactPersonsService } from './api/contactPersons.service';
import { ContainerIsoCodesService } from './api/containerIsoCodes.service';
import { CountriesService } from './api/countries.service';
import { CourierInstructionsService } from './api/courierInstructions.service';
import { CurrenciesService } from './api/currencies.service';
import { CustomersService } from './api/customers.service';
import { CustomsDocumentTypesService } from './api/customsDocumentTypes.service';
import { CustomsInvcoiceTypesService } from './api/customsInvcoiceTypes.service';
import { CustomsOfficesService } from './api/customsOffices.service';
import { CustomsPreviousArrangementTypeService } from './api/customsPreviousArrangementType.service';
import { DashboardsService } from './api/dashboards.service';
import { DeclarationProcedureTypesService } from './api/declarationProcedureTypes.service';
import { DeclarationTypesService } from './api/declarationTypes.service';
import { DeclarationTypesOneService } from './api/declarationTypesOne.service';
import { DeclarationTypesTwoService } from './api/declarationTypesTwo.service';
import { DepotsService } from './api/depots.service';
import { DocumentEditorService } from './api/documentEditor.service';
import { DocumentTemplateService } from './api/documentTemplate.service';
import { EmailTemplateTypesService } from './api/emailTemplateTypes.service';
import { EmailTemplatesService } from './api/emailTemplates.service';
import { ExampleDocumentsService } from './api/exampleDocuments.service';
import { ExportFinanceDataService } from './api/exportFinanceData.service';
import { ExternalContainerImportService } from './api/externalContainerImport.service';
import { GridLayoutsService } from './api/gridLayouts.service';
import { HaulageArrangementsService } from './api/haulageArrangements.service';
import { HsCodesService } from './api/hsCodes.service';
import { ImoClassificationsService } from './api/imoClassifications.service';
import { ImportFinanceDataService } from './api/importFinanceData.service';
import { IncludedCustomsDocumentTypesService } from './api/includedCustomsDocumentTypes.service';
import { IncomingInvoicesService } from './api/incomingInvoices.service';
import { IncomingTaxCodesService } from './api/incomingTaxCodes.service';
import { IncomingTaxSystemsService } from './api/incomingTaxSystems.service';
import { IncotermsService } from './api/incoterms.service';
import { IntegrationsService } from './api/integrations.service';
import { InttraMessageStatesService } from './api/inttraMessageStates.service';
import { InvoiceCommentsService } from './api/invoiceComments.service';
import { InvoiceTypesService } from './api/invoiceTypes.service';
import { LanguagesService } from './api/languages.service';
import { LocationsService } from './api/locations.service';
import { MailService } from './api/mail.service';
import { MasterDataService } from './api/masterData.service';
import { MessageTypesService } from './api/messageTypes.service';
import { NotificationsService } from './api/notifications.service';
import { OTLService } from './api/oTL.service';
import { OcrKeysService } from './api/ocrKeys.service';
import { OcrSettingsService } from './api/ocrSettings.service';
import { OperationalFileService } from './api/operationalFile.service';
import { OperationalFileContainerService } from './api/operationalFileContainer.service';
import { OperationalFileContainerGoodsService } from './api/operationalFileContainerGoods.service';
import { OperationalFileContainerSealNumbersService } from './api/operationalFileContainerSealNumbers.service';
import { OperationalFileCostService } from './api/operationalFileCost.service';
import { OperationalFileCustomsDocumentService } from './api/operationalFileCustomsDocument.service';
import { OperationalFileDocumentsService } from './api/operationalFileDocuments.service';
import { OperationalFileFormService } from './api/operationalFileForm.service';
import { OperationalFileFreeRemarksService } from './api/operationalFileFreeRemarks.service';
import { OperationalFileGeneralService } from './api/operationalFileGeneral.service';
import { OperationalFileGoodsService } from './api/operationalFileGoods.service';
import { OperationalFilePartyService } from './api/operationalFileParty.service';
import { OperationalFileRevenueService } from './api/operationalFileRevenue.service';
import { OperationalFileStepsService } from './api/operationalFileSteps.service';
import { OperationalFileTransportStopsService } from './api/operationalFileTransportStops.service';
import { OperationalFileTransportsService } from './api/operationalFileTransports.service';
import { OperationalFileUsersService } from './api/operationalFileUsers.service';
import { OperationalFilesSynchronizationService } from './api/operationalFilesSynchronization.service';
import { OutgoingInvoiceDocumentsService } from './api/outgoingInvoiceDocuments.service';
import { OutgoingInvoiceLinesService } from './api/outgoingInvoiceLines.service';
import { OutgoingInvoicesService } from './api/outgoingInvoices.service';
import { OutgoingTaxCodesService } from './api/outgoingTaxCodes.service';
import { PackageTypesService } from './api/packageTypes.service';
import { PartyTypesService } from './api/partyTypes.service';
import { PaymentTermsService } from './api/paymentTerms.service';
import { PlacesService } from './api/places.service';
import { PortsService } from './api/ports.service';
import { ProspectsService } from './api/prospects.service';
import { QuoteFileService } from './api/quoteFile.service';
import { QuoteFileBudgetService } from './api/quoteFileBudget.service';
import { QuoteFileContainerService } from './api/quoteFileContainer.service';
import { QuoteFileCostService } from './api/quoteFileCost.service';
import { QuoteFileDocumentsService } from './api/quoteFileDocuments.service';
import { QuoteFileGoodsService } from './api/quoteFileGoods.service';
import { QuoteFilePartyService } from './api/quoteFileParty.service';
import { QuoteFileRevenueService } from './api/quoteFileRevenue.service';
import { QuoteReportingService } from './api/quoteReporting.service';
import { ReleaseNoteService } from './api/releaseNote.service';
import { ReleaseRightsService } from './api/releaseRights.service';
import { ReportingService } from './api/reporting.service';
import { SealNumberTypesService } from './api/sealNumberTypes.service';
import { ShippingInstructionService } from './api/shippingInstruction.service';
import { StandardTextsService } from './api/standardTexts.service';
import { StepTemplatesService } from './api/stepTemplates.service';
import { StepsService } from './api/steps.service';
import { SupplierIntegrationService } from './api/supplierIntegration.service';
import { SuppliersService } from './api/suppliers.service';
import { TariffService } from './api/tariff.service';
import { TerminalsService } from './api/terminals.service';
import { TransportModesService } from './api/transportModes.service';
import { TransportStopLocationService } from './api/transportStopLocation.service';
import { TransportStopTypeService } from './api/transportStopType.service';
import { UnitsOfMeasurementService } from './api/unitsOfMeasurement.service';
import { ValidatedCostsService } from './api/validatedCosts.service';
import { VesselsService } from './api/vessels.service';
import { VgmMessagesService } from './api/vgmMessages.service';
import { VgmWeighingMethodsService } from './api/vgmWeighingMethods.service';
import { YearlyBudgetReportService } from './api/yearlyBudgetReport.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AccountingGroupsService,
    AccountingPeriodService,
    ActivityCodeGroupsService,
    ActivityCodesService,
    AddressesService,
    AirportsService,
    ArchivedNotificationsService,
    BookingsService,
    BudgetService,
    ClientPortalUsersService,
    ClientTenantConfigurationService,
    CombinedCustomsDeclarationTypesService,
    CompaniesService,
    CompanyAddressesService,
    ContactPersonTypesService,
    ContactPersonsService,
    ContainerIsoCodesService,
    CountriesService,
    CourierInstructionsService,
    CurrenciesService,
    CustomersService,
    CustomsDocumentTypesService,
    CustomsInvcoiceTypesService,
    CustomsOfficesService,
    CustomsPreviousArrangementTypeService,
    DashboardsService,
    DeclarationProcedureTypesService,
    DeclarationTypesService,
    DeclarationTypesOneService,
    DeclarationTypesTwoService,
    DepotsService,
    DocumentEditorService,
    DocumentTemplateService,
    EmailTemplateTypesService,
    EmailTemplatesService,
    ExampleDocumentsService,
    ExportFinanceDataService,
    ExternalContainerImportService,
    GridLayoutsService,
    HaulageArrangementsService,
    HsCodesService,
    ImoClassificationsService,
    ImportFinanceDataService,
    IncludedCustomsDocumentTypesService,
    IncomingInvoicesService,
    IncomingTaxCodesService,
    IncomingTaxSystemsService,
    IncotermsService,
    IntegrationsService,
    InttraMessageStatesService,
    InvoiceCommentsService,
    InvoiceTypesService,
    LanguagesService,
    LocationsService,
    MailService,
    MasterDataService,
    MessageTypesService,
    NotificationsService,
    OTLService,
    OcrKeysService,
    OcrSettingsService,
    OperationalFileService,
    OperationalFileContainerService,
    OperationalFileContainerGoodsService,
    OperationalFileContainerSealNumbersService,
    OperationalFileCostService,
    OperationalFileCustomsDocumentService,
    OperationalFileDocumentsService,
    OperationalFileFormService,
    OperationalFileFreeRemarksService,
    OperationalFileGeneralService,
    OperationalFileGoodsService,
    OperationalFilePartyService,
    OperationalFileRevenueService,
    OperationalFileStepsService,
    OperationalFileTransportStopsService,
    OperationalFileTransportsService,
    OperationalFileUsersService,
    OperationalFilesSynchronizationService,
    OutgoingInvoiceDocumentsService,
    OutgoingInvoiceLinesService,
    OutgoingInvoicesService,
    OutgoingTaxCodesService,
    PackageTypesService,
    PartyTypesService,
    PaymentTermsService,
    PlacesService,
    PortsService,
    ProspectsService,
    QuoteFileService,
    QuoteFileBudgetService,
    QuoteFileContainerService,
    QuoteFileCostService,
    QuoteFileDocumentsService,
    QuoteFileGoodsService,
    QuoteFilePartyService,
    QuoteFileRevenueService,
    QuoteReportingService,
    ReleaseNoteService,
    ReleaseRightsService,
    ReportingService,
    SealNumberTypesService,
    ShippingInstructionService,
    StandardTextsService,
    StepTemplatesService,
    StepsService,
    SupplierIntegrationService,
    SuppliersService,
    TariffService,
    TerminalsService,
    TransportModesService,
    TransportStopLocationService,
    TransportStopTypeService,
    UnitsOfMeasurementService,
    ValidatedCostsService,
    VesselsService,
    VgmMessagesService,
    VgmWeighingMethodsService,
    YearlyBudgetReportService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
