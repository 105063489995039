import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EntityDetailsComponent } from "../../../../../core/components/abstractions/entity-details.component";
import { SearchObjectHelper } from "../../../../../core/helpers/search-object-helper";
import { UnsavedChangesService } from "../../../../../core/services/helpers/unsaved-changes.service";
import { OperationalFilePartyService, PartyLookupDTO, ReleaseRightsService } from "../../../../../core/services/swagger-gen/fordesk";
import { ToasterService } from "../../../../layout/toaster/toaster.service";

@Component({
  selector: "transfer-release-right",
  templateUrl: "./transfer-release-right.component.html",
  styleUrls: ["./transfer-release-right.component.scss"]
})
export class TransferReleaseRightComponent extends EntityDetailsComponent implements OnInit, AfterViewInit {
  @Input()
  public sendEvent: Observable<void>;
  @Input()
  public containerId: number;
  @Input()
  public operationalFileId: number;
  @Input()
  public releaseRightId: number;
  @Output()
  public closeMainModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  public parties: PartyLookupDTO[] = [];

  constructor(
    protected route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private releaseRightService: ReleaseRightsService,
    private partyService: OperationalFilePartyService,
    private translate: TranslateService,
    public cdRef: ChangeDetectorRef,
    private toaster: ToasterService,
    public searchObjectHelper: SearchObjectHelper,
    unsavedChangedService: UnsavedChangesService
  ) {
    super(route, unsavedChangedService);
  }

  public ngOnInit() {
    this.createForm();
    this.fetchData();

    this.sendEvent.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(() => this.handleSend());
  }

  public ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  private createForm() {
    this.detailsForm = this.fb.group({
      party: [null, Validators.required]
    });
  }

  private fetchData() {
    this.partyService.getFiltered(this.operationalFileId)
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(parties => {
        this.parties = parties.filter(p => p.vatNumber);
      });
  }

  public handleSend() {
    this.submitted = true;
    if (this.detailsForm.invalid) {
      return;
    }

    this.releaseRightService.transferReleaseRight(this.operationalFileId, this.containerId, { releaseRightId: this.releaseRightId, transferToPartyId: this.f.party.value })
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(() => {
        this.toaster.success(this.translate.instant("OPERATIONAL.CONTAINERS.RELEASE_RIGHT.MESSAGES.TRANSFER_REQUEST_SENT"));
        this.closeMainModal.emit(true);
      });
  }

  protected saveInternal() { }
}
