import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { FormlyHelper } from "../../../helpers/formly-helper";
import { Validators } from "@angular/forms";

@Component({
  selector: "formly-date",
  templateUrl: "./formly-date.component.html",
  styleUrls: ["./formly-date.component.css"]
})
export class FormlyDateComponent extends FieldType implements OnInit {
  public submitted: boolean;

  constructor(public formlyHelper: FormlyHelper) {
    super();
  }

  public ngOnInit() {
    this.formlyHelper.getGeneralFormSubmission$()
      .subscribe(res => this.submitted = true);
    if (this.field.templateOptions.required) {
      this.formControl.setValidators(Validators.required);
    }
  }
}
