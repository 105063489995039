import { Component, Inject } from "@angular/core";
import { ConfirmBase } from "../abstractions/confirm-base.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ConfirmDialogData } from "../../../core/models/dialogs/confirm-dialog-data";
import { ConfirmDialog } from "../confirm/confirm.component";

export class ConfirmWithWarningDialogData extends ConfirmDialogData {
  public warningText?: string;
}

@Component({
  selector: 'confirm-with-warning',
  templateUrl: './confirm-with-warning-dialog.component.html',
  styleUrls: ['./confirm-with-warning-dialog.component.scss']
})
export class ConfirmWithWarningDialogComponent extends ConfirmBase {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmWithWarningDialogData
  ) {
    super(dialogRef, data);
  }
}
