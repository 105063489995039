/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface EmailTemplateTypeDTO { 
    id?: number;
    templateName?: string;
    category?: EmailTemplateTypeDTO.CategoryEnum;
    isDefault?: boolean;
}
export namespace EmailTemplateTypeDTO {
    export type CategoryEnum = 0 | 1 | 2 | 3 | 4;
    export const CategoryEnum = {
        NUMBER_0: 0 as CategoryEnum,
        NUMBER_1: 1 as CategoryEnum,
        NUMBER_2: 2 as CategoryEnum,
        NUMBER_3: 3 as CategoryEnum,
        NUMBER_4: 4 as CategoryEnum
    };
}