import {Component, Input, OnInit} from "@angular/core";
import {TerminalDTO} from "../../../../services/swagger-gen/fordesk";

@Component({
  selector: 'terminal-option',
  templateUrl: './terminal-option.component.html'
})
export class TerminalOptionComponent {
  @Input()
  public item: TerminalDTO;

  constructor() { }
}
