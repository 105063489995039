import { AbstractControl, ValidatorFn } from "@angular/forms";

export function minDateValidator(minDate: Date): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {

    if (minDate) {
      let currentValue = control.value;
      if (typeof control.value === "string" || control.value instanceof String) {
        currentValue = new Date(currentValue);
      }

      return currentValue != null && currentValue < minDate ? { minDate: true } : null;
    }
  };
}
