/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CompanyAddressDTO { 
    eoriNumber?: string;
    addressLine1: string;
    addressLine2?: string;
    alias?: string;
    stateProvinceRegion?: string;
    id?: number;
    placeName?: string;
    countryName?: string;
    zipCode?: string;
    companyName?: string;
    partyTypeCode?: string;
    partyTypeId?: number;
    currencyCode?: string;
    vatNumber?: string;
    companyId?: number;
    companyType?: CompanyAddressDTO.CompanyTypeEnum;
    partyType?: CompanyAddressDTO.PartyTypeEnum;
    isGoodsOwner?: boolean;
    invoiceable?: boolean;
    masterDataStatus?: CompanyAddressDTO.MasterDataStatusEnum;
}
export namespace CompanyAddressDTO {
    export type CompanyTypeEnum = 0 | 1 | 2;
    export const CompanyTypeEnum = {
        NUMBER_0: 0 as CompanyTypeEnum,
        NUMBER_1: 1 as CompanyTypeEnum,
        NUMBER_2: 2 as CompanyTypeEnum
    };
    export type PartyTypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25;
    export const PartyTypeEnum = {
        NUMBER_0: 0 as PartyTypeEnum,
        NUMBER_1: 1 as PartyTypeEnum,
        NUMBER_2: 2 as PartyTypeEnum,
        NUMBER_3: 3 as PartyTypeEnum,
        NUMBER_4: 4 as PartyTypeEnum,
        NUMBER_5: 5 as PartyTypeEnum,
        NUMBER_6: 6 as PartyTypeEnum,
        NUMBER_7: 7 as PartyTypeEnum,
        NUMBER_8: 8 as PartyTypeEnum,
        NUMBER_9: 9 as PartyTypeEnum,
        NUMBER_10: 10 as PartyTypeEnum,
        NUMBER_11: 11 as PartyTypeEnum,
        NUMBER_12: 12 as PartyTypeEnum,
        NUMBER_13: 13 as PartyTypeEnum,
        NUMBER_14: 14 as PartyTypeEnum,
        NUMBER_15: 15 as PartyTypeEnum,
        NUMBER_16: 16 as PartyTypeEnum,
        NUMBER_17: 17 as PartyTypeEnum,
        NUMBER_18: 18 as PartyTypeEnum,
        NUMBER_19: 19 as PartyTypeEnum,
        NUMBER_20: 20 as PartyTypeEnum,
        NUMBER_21: 21 as PartyTypeEnum,
        NUMBER_22: 22 as PartyTypeEnum,
        NUMBER_23: 23 as PartyTypeEnum,
        NUMBER_24: 24 as PartyTypeEnum,
        NUMBER_25: 25 as PartyTypeEnum
    };
    export type MasterDataStatusEnum = 0 | 1;
    export const MasterDataStatusEnum = {
        NUMBER_0: 0 as MasterDataStatusEnum,
        NUMBER_1: 1 as MasterDataStatusEnum
    };
}