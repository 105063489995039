export const operational_file_synchronization = {
  TITLE: "Reporting Synchronization",
  TABS: {
    OPERATIONAL_FILES: "Operational files",
  },
  COMMON: {
    SPECIFIC_FILE_NUMBERS: "Specific file numbers",
    AT_LEAST_ONE_FIELD_REQUIRED: "Number of Files for synchronization of Specific file numbers is required."
  },
  BUDGET_SUMMARY: {
    TITLE: "Budget Summary Synchronization",
    NUMBER_OF_MISSED_FILES: "Budget Summary has {{numberOfMissedBudgetSummaryFiles}} missed files.",
    NUMBER_OF_FILE_FOR_SYNC: "Number of Files for synchronization",
  },
  SUMMARY: {
    TITLE: "Summary Synchronization",
    NUMBER_OF_MISSED_FILES: "Summary has {{numberOfMissedSummaryFiles}} missed files.",
    NUMBER_OF_FILE_FOR_SYNC: "Number of Files for synchronization",
  }
}
