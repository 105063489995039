import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export class NotifyDialogData {
  dialogTitle: string;
  confirmationText: string;
}

@Component({
  selector: 'notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss']
})
export class NotifyDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NotifyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: NotifyDialogData
  ) { }

  ngOnInit(): void { }

  public cancel() {
    this.dialogRef.close();
  }

}
