import { Component, Input, OnInit } from "@angular/core";
import { OperationalFileGoodsDTO } from "../../../../services/swagger-gen/fordesk";

@Component({
  selector: "goods-in-container-option",
  templateUrl: "./goods-in-container-option.component.html",
  styleUrls: ["./goods-in-container-option.component.scss"]
})
export class GoodsInContainerOptionComponent implements OnInit {
  @Input()
  public item: OperationalFileGoodsDTO;

  constructor() { }

  public ngOnInit() {
  }

}
