import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { EntityDetailsComponent } from '../../../core/components/abstractions/entity-details.component';
import { NameValue } from '../../../core/models/name-value.model';
import { UnsavedChangesService } from '../../../core/services/helpers/unsaved-changes.service';
import { OperationalFilePartyService } from '../../../core/services/swagger-gen/fordesk';
import { ToasterService } from '../../layout/toaster/toaster.service';

export class SetGoodsOwnerForInvoiceCustomerDialogComponentData {
  public invoiceCustomerNames: string;
  public operationalFileId: number;
}

@Component({
  selector: 'set-goods-owner-for-invoice-customer-dialog',
  templateUrl: './set-goods-owner-for-invoice-customer-dialog.component.html',
  styleUrls: ['./set-goods-owner-for-invoice-customer-dialog.component.scss']
})
export class SetGoodsOwnerForInvoiceCustomerDialogComponent extends EntityDetailsComponent implements OnInit {

  public isGoodsOwnerTypes: NameValue<boolean>[] = [
    {
      name: this.translate.instant("ACTIONS.CAPITAL_YES"),
      value: true
    },
    {
      name: this.translate.instant("ACTIONS.CAPITAL_NO"),
      value: false
    }
  ];

  constructor(public dialogRef: MatDialogRef<SetGoodsOwnerForInvoiceCustomerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: SetGoodsOwnerForInvoiceCustomerDialogComponentData,
    protected route: ActivatedRoute,
    protected unsavedChangedService: UnsavedChangesService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private fb: UntypedFormBuilder,
    public cdRef: ChangeDetectorRef,
    private operationalFilePartyService: OperationalFilePartyService) {
    super(route, unsavedChangedService);
  }

  ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.detailsForm = this.fb.group({
      isGoodsOwner: [null, Validators.required]
    });
  }


  protected saveInternal() {
    this.operationalFilePartyService.setGoodsOwnerForInvoiceCustomers(this.data.operationalFileId, this.f.isGoodsOwner.value)
      .pipe(takeUntil(this.unsubscribe)).subscribe(response => {
        this.toaster.success(this.translate.instant("MESSAGES.CHANGES_SAVED"));
        this.confirm();
      });
  }

  public cancel() {
    return this.dialogRef.close({ isConfirmed: false })
  }

  private confirm() {
    return this.dialogRef.close({ isConfirmed: true })
  }
}
