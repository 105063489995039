export const yearly_budget_overview_locale = {
  OPEN_COSTS: "Open costs",
  OUTSTANDING_REVENUES: "Open revenues",
  TITLE: "Yearly budget overview",
  DATA: "Data",
  SELECT_CUSTOMER: "Select customer (optional)",
  SELECT_SUPPLIER: "Select supplier (optional)",
  SELECT_DEPARTMENT: "Select department (optional)",
  SELECT_EXCLUDED_ACTIVITYCODES: "Exclude activity code(s) (optional)",
  TOTALINVOICEDREVENUES: "Total actualised revenues",
  TOTALINVOICEDREVENUESINCLVAT: "Total actualised revenues (Incl VAT)",
  TOTALOPENREVENUES: "Total open revenues",
  TOTALBOOKEDCOSTS: "Total actualised costs",
  TOTALBOOKEDCOSTSINCLVAT: "Total actualised costs (Incl VAT)",
  TOTALOPENCOSTS: "Total open costs",
  TOTALEXCLUDEDINVOICEDREVENUES: "Total excluded actualised revenues",
  TOTALEXCLUDEDOPENREVENUES: "Total excluded open revenues",
  TOTALEXCLUDEDBOOKEDCOSTS: "Total excluded actualised costs",
  TOTALEXCLUDEDOPENCOSTS: "Total excluded open costs",
  COSTINBUDGET: "Total costs in budget",
  COSTOUTBUDGET: "Total costs out of budget",
  CURRENTRESULT: "Current result",
  EXCLUDEDRESULT: "Excluded result",
  ACTUALRESULT: "Actualised result",
  ACTUALRESULTINCLVAT: "Actualised result (Incl VAT)",
  EXCLUDEDACTUALRESULT: "Excluded actualised result",
  NUMBEROFTEU: "Number of TEU",
  NUMBEROFFILES: "Number of files",
  PERCENTTO: "% TO",
  TOTAL: "Total",
  REPORT_NOT_FOUND: "No suitable report was found report or this report has been removed.",
  GENERATE_AGAIN: "You can generate new report using the normal procedure.",
  OLD_GENERATED_DATA_TEXT: "This report was generated on {{dateTime}} by {{userName}}. All data was current at the time of report generation only.",
  OLD_GENERATED_DATA_WITHOUT_USER_NAME_TEXT: "This report was generated on {{dateTime}}. All data was current at the time of report generation only.",
  DATA_REQUESTED_TEXT: "The data you requested is being calculated and will be generated in the background.",
  USER_CAN_LEAVE_THE_PAGE: " You do not need to remain on this page: you will receive a notification when your data is ready.",
  TRY_TO_FIND_REPORT: "try to find a previously created report with the same filters",
  CAN_NOT_FIND_PREVIOUSLY_GENERATED_REPORT: "Could not find a generated report with the same filters.",
  VALIDATION: {
    YEAR_MUST_BE_PROVIDED: "Year cannot be empty when a month is entered"
  },
  LEGEND: {
    TOTALINVOICEDREVENUES: "The total of all revenues, that have been invoiced.",
    TOTALINVOICEDREVENUESINCLVAT: "The total (Incl VAT) of all revenues, that have been invoiced.",
    TOTALEXCLUDEDINVOICEDREVENUES: "The total of all invoiced revenues, that have been excluded by filtering.",
    TOTALOPENREVENUES: "The total of all revenues, that have NOT been invoiced.",
    TOTALEXCLUDEDOPENREVENUES: "The total of all open revenues, that have been excluded by filtering.",
    TOTALBOOKEDCOSTS: "The total of all costs, that have been booked.",
    TOTALBOOKEDCOSTSINCLVAT: "The total (Incl VAT) of all costs, that have been booked.",
    TOTALEXCLUDEDBOOKEDCOSTS: "The total of all booked costs, that have been excluded by filtering.",
    TOTALOPENCOSTS: "The total of all costs, that have NOT been booked.",
    TOTALEXCLUDEDOPENCOSTS: "The total of all open costs, that have been excluded by filtering.",
    RESULT: "The difference between revenue (invoiced or not) and costs (booked or not).",
    EXCLUDEDRESULT: "The difference between revenues (invoiced or not) and costs in budget (booked or not), that have been excluded by filtering.",
    ACTUALRESULT: "The difference between booked costs and invoiced revenues.",
    ACTUALRESULTINCLVAT: "The difference between booked costs (Incl VAT) and invoiced revenues (Incl VAT).",
    EXCLUDEDACTUALRESULT: "The difference between booked costs and invoiced revenues, that have been excluded by filtering.",
    NUMBEROFTEU: "The number of TEUs.",
    NUMBEROFFILES: "The number of operational files.",
    PERCENTTO: "Actualised revenues minus actualised costs as a ratio against the costs.",
    ALL_EXCL_VAT: "All amounts are excl. VAT. except where indicated.",
    COSTINBUDGET: "Costs that have been added IN budget.",
    COSTOUTBUDGET: "Costs that have been added OUT of budget."
  }
};
