export const quote_parties_locale = {
  PARTIES: "Parties",
  ADD: "Add party",
  DETAILS: "Party details",
  COMPANY_MASTERDATA: "Company (masterdata)",
  COMPANY: "Company",
  CONTACT_PERSON: "Contact person",
  COMPANY_FREE_INPUT: "Company (free input)",
  TYPE: "Party type",
  VALIDATION: {
    COMPANY_OR_DETAILS_REQUIRED: "Either a MasterData company must be chosen or a manually input company provided.",
    COMPANY_MASTERDATA_REQUIRED: "MasterData company must be chosen."
  },
};
