import {
	Component,
	OnInit,
	Input,
	ChangeDetectionStrategy
} from '@angular/core';
import { Observable } from 'rxjs';
import { LogData } from '../../../../core/interfaces/log-data';

@Component({
	selector: 'm-list-timeline',
	templateUrl: './list-timeline.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class ListTimelineComponent implements OnInit {
	@Input() type: any;
	@Input() heading: any;

	@Input() logList: Observable<LogData[]>;

	constructor() {
	}

	ngOnInit() {

	}
}
