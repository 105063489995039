export const validation_locale = {
  WARNING_DATE_FORMAT: "The date {{date}} is incorrect.",
  INVALID: "{{name}} is not valid.",
  INVALID_DATE: "{{name}} must be a valid date.",
  NOT_BEFORE_CURRENT_DATE: "The date cannot be earlier than the current date",
  NOT_BEFORE_DATE: "{{name}} cannot be before {{before}}",
  NOT_AFTER_DATE: "{{name}} cannot be after {{after}}",
  NOT_BEFORE_DATE_OR_EQUAL: "'{{name}}' cannot be before or equal to '{{before}}'",
  NOT_AFTER_DATE_OR_EQUAL: "'{{name}}' cannot be after or equal to '{{after}}'",
  REQUIRED: "{{name}} is required.",
  MAXIMUM_DECIMALS: "{{name}} can only have up to {{decimals}} decimals precision.",
  MAXIMUM_WHOLES_DECIMALS: "{{name}} can only have up to {{wholes}} numbers before the comma and up to {{decimals}} numbers after the comma",
  REQUIRED_PLURAL: "{{name}} are required.",
  MIN_LENGTH: "{{name}}'s minimum length is {{min}}.",
  MAX_LENGTH: "{{name}}'s maximum length is {{max}}.",
  INVALID_PHONE_NUMBER: "Invalid phone number.",
  INVALID_MOBILE_NUMBER: "Invalid mobile number.",
  MAX_LINE_LENGTH: "{{name}}'s maximum length per line is {{max}}.",
  AGREEMENT_REQUIRED: "Please accept the terms & conditions to continue.",
  NOT_FOUND: "The requested {{name}} is not found.",
  INVALID_LOGIN: "The username or password you entered is incorrect.",
  PLEASE_LOGIN: "Please login with your e-mail address and password.",
  PLACE_OR_PORT_REQUIRED: "Either a place or port is required.",
  NUMERIC_REQUIRED: "{{name}} must be a number.",
  INVALID_RANGE: "{{name}} must be a number between {{min}} and {{max}}.",
  MIN_VALUE: "{{name}} must be greater than {{min}}.",
  MAX_VALUE: "{{name}} must be less than {{max}}.",
  AT_LEAST: "{{name}} requires at least {{min}} selection(s).",
  MUST_BE_POSITIVE: "{{name}} must be positive.",
  MUST_BE_NEGATIVE: "{{name}} must be negative.",
  INVALID_EMAIL: "{{name}} does not contain a valid email address.",
  MUST_NOT_BE_EQUAL_TO: "{{name}} cannot be equal to '{{value}}'.",
  MUST_BE_LT: "{{name}} must be less than {{value}}.",
  MUST_BE_GT: "{{name}} must be greater than {{value}}.",
  MUST_BE_LTE: "{{name}} must be less than or equal to {{value}}.",
  MUST_BE_GTE: "{{name}} must be greater than or equal to {{value}}.",
  ABSOLUTE_VALUE_GT_ZERO: "Please enter an amount greater than 0.",
  MUST_BE_NATURAL_NUMBER: "Please enter a natural number.",
  ONLY_INTEGERS: "Only integers are allowed.",
  MIN_DATE_BEFORE_END_DATE: "Start date must be before end date.",
  CONTAIN_INVALID_CHARACTERS: "'{{name}}' contains invalid characters.",
  MAX_FOR_INTTRA: "{{max}} max for Inttra",
  CANNOT_COMPARE_DATE: "Cannot compare '{{firstDate}}' and '{{secondDate}}' because at least one field is invalid.",
  CANNOT_COMPARE_DATE_TARGET: "Cannot compare '{{firstDate}}' and '{{secondDate}}' because '{{targetDate}}' is invalid.",
  YEAR_BETWEEN_DEFAULT: "The year must be between 2000 and 2999",
  YEAR_BETWEEN: "The year must be between {{minDate}} and {{maxDate}}"
};
