import { Injectable } from "@angular/core";
import { Subject, Observable, timer } from "rxjs";
import { RequestStorageService } from "../request-storage-service";

@Injectable({ providedIn: "root" })
export class LoadingOverlayService {
  private loading: boolean;
  public isRequestHidden: boolean = false;
  private change: Subject<boolean> = new Subject<boolean>();

  constructor(public requestStorageService: RequestStorageService) {
  }

  public startLoading() {
    if (this.isRequestHidden) return;
    this.loading = true;
    this.emitLoading(this.loading);
  }

  public stopLoading() {
    this.requestStorageService.clearAll();
    this.loading = false;
    this.emitLoading(this.loading);
  }

  public emitLoading(loading: boolean) {
    if (this.isRequestHidden) return;
    if (this.change) {
      this.change.next(loading);
    }
  }

  public hideRequest(timeOut: number = 10) {
    if (this.isRequestHidden) return;
    this.isRequestHidden = true;
    timer(timeOut).subscribe(() => this.isRequestHidden = false);
  }

  public getLoadingSubjectAsObservable(): Observable<boolean> {
    return this.change.asObservable();
  }
}
