import { ValidatorFn, UntypedFormArray } from "@angular/forms";

export function requireOneCheckboxToBeCheckedValidator(minRequired = 1, key = "isChecked"): ValidatorFn {
  return function validate(formArray: UntypedFormArray) {
    let checked = 0;

    formArray.controls.forEach(fg => {
      let isCheckedControl = fg.get(key);
      if (isCheckedControl) {
        if (isCheckedControl.value === true) {
          checked++;
        }
      }
    });

    if (checked < minRequired) {
      return {
        requireOneCheckboxToBeChecked: true,
      };
    }

    return null;
  };
}