/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OperationalFileCourierInstructionDTO { 
    invoiceReference?: string;
    instructionDate?: Date;
    timePeriodType?: OperationalFileCourierInstructionDTO.TimePeriodTypeEnum;
    pickupLocation?: OperationalFileCourierInstructionDTO.PickupLocationEnum;
    instructionTypeId?: number;
    ourReference?: string;
    theirReference?: string;
    amount?: number;
    containerNumbers?: Array<string>;
    billOfLadingNumbers?: Array<string>;
    remarks?: string;
    isExpressProcessing?: boolean;
    signatureTypeId?: number;
    paymentRequirementType?: number;
    status?: string;
    chequeNumber?: string;
    operationalFileId?: number;
    portId?: number;
    vesselId?: number;
    deadline?: Date;
    operationalFilePartyIds?: Array<number>;
    operationalFileDocumentIds?: Array<string>;
    price?: number;
}
export namespace OperationalFileCourierInstructionDTO {
    export type TimePeriodTypeEnum = 0 | 1;
    export const TimePeriodTypeEnum = {
        NUMBER_0: 0 as TimePeriodTypeEnum,
        NUMBER_1: 1 as TimePeriodTypeEnum
    };
    export type PickupLocationEnum = 0 | 1;
    export const PickupLocationEnum = {
        NUMBER_0: 0 as PickupLocationEnum,
        NUMBER_1: 1 as PickupLocationEnum
    };
}