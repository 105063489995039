import { Injectable } from "@angular/core";
import { NumberRange } from "../models/number-range";

@Injectable({ providedIn: "root" })
export class NumberInputsHelperService {

  constructor() { }

  public transformNumberRangeToString(numberRange: NumberRange): string {
    let result: string;

    if (numberRange.min && numberRange.max) {
      result = `${numberRange.min} - ${numberRange.max}`;
    } else if (numberRange.min) {
      return `>= ${numberRange.min}`;
    } else if (numberRange.max) {
      return `<= ${numberRange.max}`;
    }

    return result;
  }
}
