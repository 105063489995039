import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CustomValidators } from "ngx-custom-validators";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EntityDetailsComponent } from "../../../../../core/components/abstractions/entity-details.component";
import { UnsavedChangesService } from "../../../../../core/services/helpers/unsaved-changes.service";
import { ReleaseRightsService } from "../../../../../core/services/swagger-gen/fordesk";
import { ToasterService } from "../../../../layout/toaster/toaster.service";

@Component({
  selector: "revoke-release-right",
  templateUrl: "./revoke-release-right.component.html",
  styleUrls: ["./revoke-release-right.component.scss"]
})
export class RevokeReleaseRightComponent extends EntityDetailsComponent implements OnInit {
  @Input()
  public sendEvent: Observable<void>;
  @Input()
  public containerId: number;
  @Input()
  public operationalFileId: number;
  @Input()
  public releaseRightId: number;

  @Output()
  public emitConfirm: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public closeMainModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    protected route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private releaseRightService: ReleaseRightsService,
    private translate: TranslateService,
    private toaster: ToasterService,
    unsavedChangedService: UnsavedChangesService
  ) {
    super(route, unsavedChangedService);
  }

  public ngOnInit() {
    this.createForm();
    this.emitConfirm.emit(false);

    this.sendEvent.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(() => this.handleSend());
  }

  private createForm() {
    this.detailsForm = this.fb.group({
      confirm: [false, CustomValidators.equal(true)]
    });
  }

  public confirmRevoke() {
    this.emitConfirm.emit(this.f.confirm.value);
  }

  public handleSend() {
    this.submitted = true;
    if (this.detailsForm.invalid) {
      return;
    }

    this.releaseRightService.revokeReleaseRight(this.operationalFileId, this.containerId, { releaseRightId: this.releaseRightId })
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(() => {
        this.toaster.success(this.translate.instant("OPERATIONAL.CONTAINERS.RELEASE_RIGHT.MESSAGES.REVOKE_REQUEST_SENT"));
        this.closeMainModal.emit(true);
      });
  }

  protected saveInternal() { }
}
