import { Component, Input } from "@angular/core";
import { CompanyAddressDTO } from "../../../../services/swagger-gen/fordesk";

@Component({
  selector: "company-address-option",
  templateUrl: "./company-address-option.html",
})
// tslint:disable-next-line: component-class-suffix
export class CompanyAddressOption {
  @Input()
  public item: CompanyAddressDTO;

  @Input()
  public showType: boolean = false;

  constructor() { }
}
