import { Component, OnInit } from "@angular/core";
import { FormlyHelper } from "../../../helpers/formly-helper";
import { FieldType } from "@ngx-formly/core";
import { Validators } from "@angular/forms";

@Component({
  selector: "formly-booking-references",
  templateUrl: "./formly-booking-references.component.html",
  styleUrls: ["./formly-booking-references.component.scss"]
})
export class FormlyBookingReferencesComponent extends FieldType implements OnInit {
  public submitted: boolean;


  constructor(
    private formlyHelper: FormlyHelper
  ) {
    super();
  }

  public ngOnInit() {
    this.formlyHelper
      .getGeneralFormSubmission$()
      .subscribe(() => this.submitted = true);

    if (this.field.templateOptions.required) {
      this.formControl.setValidators([Validators.required, Validators.maxLength(256)]);
    } else {
      this.formControl.setValidators([Validators.maxLength(256)]);
    }
  }
}
