export const of_general_locale = {
  TITLE: "General details",
  GENERAL_SECTION: "General",
  FREE_UNTIL: "Free until",
  CLOSING_DATA: "Closing",
  EXCHANGE_RATE: "Exchange rate",
  BILLOFLADINGNR: "Bill of lading nr.",
  HOUSEBLNR: "House BL nr.",
  VALIDATORS: {
    EXCHANGE_RATE_PRECISION: "Exchange rate can have up to 8 precision.",
    INVALID_DATE_TIME: "Please provide a valid date and time.",
    BILL_OF_LADING_MAX_CHARS: "Multiple numbers are allowed, but they need to be separated with a space and cannot have more than {{max_chars}} characters"
  }
};
