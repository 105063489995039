import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreModule } from "../../../../../core/core.module";
import { PortletComponent } from "./portlet.component";
import { MaterialModule } from "../../../../../material/material.module";

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialModule
  ],
  declarations: [PortletComponent],
  exports: [PortletComponent]
})
export class PortletModule { }
