import { UntypedFormGroup, UntypedFormControl, ValidationErrors } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Output, Input, Directive } from "@angular/core";
import { ComponentBase } from "./component-base";
import { BehaviorSubject, interval, Observable, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, pairwise, retry, startWith, take, takeUntil, tap } from "rxjs/operators";
import { UnsavedChangesService } from "../../services/helpers/unsaved-changes.service";
import { BlockableType } from "../../models/utilities/blockable-type.model";
import { MasterDataStatusEnum } from "../../enumerations/master-data-status.enum";

@Directive()
export abstract class EntityDetailsComponent extends ComponentBase {
  @Input() @Output()
  public submitted: boolean = false;

  public valueBeforeSaving = null;
  public unsavedChangesMode: boolean = true;
  public unsavedChangesMainlyUnsubscribe = false;
  // TODO: this does not belong here, introduce an "operational file base component", since not every component can be "closed"
  public isClosed: boolean = false;
  public isReadonly: boolean = false;

  protected cacheLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public selectionCache: Record<string, Array<any>> = {};


  /**
   * When overriding this, use the exact path that's used in the formControl
   */
  public cachableTypes: Array<string> = [];

  public initialForm: BehaviorSubject<any>;

  public get isDirty(): boolean {
    return this.detailsForm.dirty &&
      !this.unsavedChangedService.areFormValuesEqual(this.initialForm.value, this.detailsForm.value);
  }

  public detailsForm: UntypedFormGroup;

  get f() {
    return this.detailsForm.controls;
  }



  constructor(
    protected route: ActivatedRoute, protected unsavedChangedService: UnsavedChangesService
  ) {
    super();
    this.trySetupChangeDetection();
  }

  private trySetupChangeDetection() {
    interval(100)
      .pipe(
        tap(() => {
          try {
            this.setUpChangeDetection();
          } catch (e) {
            return e;
          }
        }),
        retry(25),
        take(1),
        tap(() => this.setupSelectionCacheHandlers()), takeUntil(this.unsubscribe))
      .subscribe();
  }

  protected setupSelectionCacheHandlers() {
    for (const cachableType of this.cachableTypes) {
      const control = this.detailsForm.get(cachableType.split("."));
      const value = control?.value;
      this.selectionCache[cachableType] = [];

      this.addToCache(value, cachableType);

      if (control) {
        control.valueChanges.pipe(takeUntil(this.unsubscribe), filter(x => !!x), distinctUntilChanged())
          .subscribe((x) => {
            this.addToCache(x, cachableType);
          });
      }
    }

    this.cacheLoaded.next(true);

  }

  protected addToCache(value: any, cachableType: string) {
    if (value) {
      if (Array.isArray(value)) {
        for (const subvalue of value) {
          this.selectionCache[cachableType].push(subvalue);
        }
      } else if (typeof value === "object" && "id" in value) {
        this.selectionCache[cachableType].push(value.id);
      } else {
        this.selectionCache[cachableType].push(value);
      }
    }
  }

  protected setUpChangeDetection() {
    this.initialForm = new BehaviorSubject<any>(null);
    const notify: Subject<void> = new Subject();

    if (this.unsavedChangesMode) {
      this.detailsForm.valueChanges
        .pipe(
          takeUntil(notify),
          startWith(this.detailsForm.value),
          pairwise()).
        subscribe(([prev, _]) => {
          if (!this.initialForm.value && this.detailsForm.dirty) {
            this.initialForm.next({ ...prev });
            this.unsavedChangedService.setUp(this, this.unsubscribe);
            notify.next();
          }
        });
    }
  }

  public resetForm() {
    if (this.detailsForm?.value && this.initialForm?.value) {
      this.detailsForm.patchValue({ ...this.initialForm.value });
    }
  }

  public shouldDisplayValidationMessage(fieldName: string, validation: any, always: boolean = false): boolean {
    if (always) {
      return this.detailsForm.get(fieldName).hasError(validation);
    } else {
      return (
        this.detailsForm.get(fieldName).hasError(validation) &&
        (this.detailsForm.get(fieldName).dirty ||
          this.detailsForm.get(fieldName).touched)
      );
    }
  }

  protected getFormValue(formField: string): any {
    return this.detailsForm.get(formField).value;
  }

  protected setFormValue(formField: string, value: any): void {
    return this.detailsForm.get(formField).patchValue(value);
  }

  protected getId(): number {
    return Number(this.route.snapshot.params.id);
  }

  public isNew(): boolean {
    return !this.getId();
  }

  protected validateAllFormFields(formGroup: UntypedFormGroup): boolean {
    if (!formGroup || !formGroup.controls) {
      return false;
    }

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });

    this.logValidationErrors(formGroup);
    return formGroup.valid;
  }

  /**
   * Saves the details form.
   */
  protected abstract saveInternal(shouldRedirect?: boolean);

  protected preValidate(preValidationFunction?: Function): void {
    if (preValidationFunction) {
      preValidationFunction();
    }
  }

  /**
   * Validates the current form and calls the saveInternal() function. Do not overrides this method unless absolutely necessary.
   */
  public save(preValidationFunction?: Function, shouldRedirect: boolean = true): void {
    this.preValidate(preValidationFunction);

    if (!this.validate()) {
      return;
    }

    this.saveInternal(shouldRedirect);

    this.updateInitialForm();

    this.resetCache();
  }

  public updateInitialForm() {
    if (this.initialForm) {
      this.valueBeforeSaving = this.initialForm.value;
      this.initialForm.next({ ... this.detailsForm.value });
    }
  }

  public validate(): boolean {
    this.submitted = true;

    return (this.validateAllFormFields(this.detailsForm) && this.detailsForm.valid);
  }

  /**
   * Logs all validation errors to the console.
   */
  private logValidationErrors(form: UntypedFormGroup) {
    if (!form || !form.controls) {
      return;
    }
    if (!form.valid) {
      // console.error("Form invalid!");
    }

    Object.keys(form.controls).forEach(key => {
      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors)
          .forEach(() => {
            // console.log("Key control: " + key + ", keyError: " + keyError + ", err value: ", controlErrors[keyError]);
          });
      }
    });
  }


  /**
   * Use this when working with Observables
   * For example: pipe(this.filterOutBlockedTypesPipe(formField))
   * FormField: Should be the exact path that's used in the formControl
   */
  protected filterOutBlockedTypesPipe(formField: string) {
    return (source: Observable<Array<BlockableType>>) => {
      return source.pipe(map(arr => this.filterOutBlockedTypes(arr, formField)), takeUntil(this.unsubscribe));
    };
  }

  /**
   * Use this when working directly on lists
   * For example: this.filterOutBlockedTypes(list, formField)
   * FormField: Should be the exact path that's used in the formControl
   */
  protected filterOutBlockedTypes(list: Array<BlockableType>, formField: string) {
    return list.filter(dto => this.shouldBeShown(formField, dto));
  }

  private shouldBeShown(formField: string, model: BlockableType): boolean {
    if (model.masterDataStatus !== MasterDataStatusEnum.Blocked) {
      return true;
    }

    const cache = this.selectionCache[formField];

    if (cache) {
      return this.isInitiallySelected(cache, model, formField);
    }

    return false;
  }

  // formField is not used here, but is used in overriden methods in extended components
  protected isInitiallySelected(cache: any[], model: BlockableType, formField: string): boolean {
    return cache.includes(model.id);
  }

  protected resetCache() {
    for (const cachableType of this.cachableTypes) {
      const control = this.detailsForm?.get(cachableType.split("."));
      const value = control?.value;

      while (this.selectionCache[cachableType].length > 0) {
        this.selectionCache[cachableType].pop();
      }

      this.addToCache(value, cachableType);
    }
  }

  protected refreshByCache(list: string, newList: Array<BlockableType>, formField: string) {
    if (!this[list]) {
      return;
    }
    if (this.cacheLoaded.value) {
      this[list] = this.filterOutBlockedTypes(newList, formField);
    } else {
      this.cacheLoaded.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
        this[list] = this.filterOutBlockedTypes(newList, formField);
      });
    }
  }

}
