import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { FieldType } from "@ngx-formly/core";
import { filter, map, pairwise, takeUntil } from "rxjs/operators";
import { FormlyHelper } from "../../../helpers/formly-helper";
import { ActualMinMaxDateChangedService } from "../../../services/operational/operational-file/actual-time-min-max-date-changed.servise";
import { Subject } from "rxjs";

@Component({
  selector: "formly-actual-time-of-departure",
  templateUrl: "./formly-actual-time-of-departure.component.html",
  styleUrls: ["./formly-actual-time-of-departure.component.scss"]
})
export class FormlyActualTimeOfDepartureComponent extends FieldType implements OnInit, OnDestroy {
  public submitted: boolean;
  public maxDate: NgbDateStruct = { year: 3100, day: 1, month: 1 };

  constructor(public formlyHelper: FormlyHelper, private actualMinMaxDateChangedService: ActualMinMaxDateChangedService) {
    super();
  }

  private unsubscribe: Subject<void> = new Subject();
  public ngOnDestroy() {
    if (!this.unsubscribe) {
      return;
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public ngOnInit() {
    this.formlyHelper.getGeneralFormSubmission$()
      .subscribe(res => this.submitted = true);

    this.actualMinMaxDateChangedService.isMaxDateChanged().subscribe((data: NgbDateStruct) => {
      this.maxDate = data;
    })

    if (this.formControl.value) {
      const minDate = new Date(this.formControl.value);
      const ngbDateStruct = { day: minDate.getDate(), month: minDate.getMonth() + 1, year: minDate.getFullYear() };
      this.actualMinMaxDateChangedService.getNextMinDate(ngbDateStruct);
    }


    this.formControl.valueChanges.pipe(
      pairwise(),
      filter(([prev, curr]) => prev !== curr),
      map(([prev, curr]) => curr), takeUntil(this.unsubscribe))
      .subscribe(value => {
        if (!value) {
          this.actualMinMaxDateChangedService.getNextMinDate(null);
          return;
        }
        const minDate = new Date(value);
        const ngbDateStruct = { day: minDate.getDate(), month: minDate.getMonth() + 1, year: minDate.getFullYear() };
        this.actualMinMaxDateChangedService.getNextMinDate(ngbDateStruct);
      });
  }

  public getMaxDate() {
    return this.maxDate;
  }

}
