import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ConfirmDialog } from "../confirm/confirm.component";
import {
  DisplayedColumnsDialogData,
  DisplayedColumnsDialogResultData
} from "../../../core/models/dialogs/displayed-columns-dialog.data";
import { ConfirmBase } from "../abstractions/confirm-base.component";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

export interface ExcelExportConfig {
  excelExportAction?: (displayedColumns: string[]) => void;
}

@Component({
  selector: "displayed-columns",
  templateUrl: "./displayed-columns.component.html",
  styleUrls: ["./displayed-columns.component.scss"]
})
export class DisplayedColumnsComponent extends ConfirmBase implements OnInit {
  public displayedColumns: string[];
  public allChangeableColumns: string[];
  public translateInstant: object;

  public excelExportConfig: ExcelExportConfig;

  public dialogData: DisplayedColumnsDialogResultData = {
    displayedColumns: [],
    isConfirmed: false
  };

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DisplayedColumnsDialogData
  ) {
    super(dialogRef, data);
    this.excelExportConfig = data.excelExportConfig;
    this.translateInstant = data.translateInstant;
    this.displayedColumns = data?.displayedColumns ? [...data.displayedColumns] : [];
    this.allChangeableColumns = data?.changeableColumns ?? [];
  }

  public ngOnInit(): void {
    //Here we take only the sticky columns available for display in the modal window (for example there will be no columns "IsSelected", "Actions", and so on)
    this.data.stickyEndColumns = this.data.stickyEndColumns.filter(x => this.allChangeableColumns.includes(x));
    this.data.stickyStartColumns = this.data.stickyStartColumns.filter(x => this.allChangeableColumns.includes(x));

    //Remove sticky column to add them in the desired order after sorting
    this.removeStickyColumnsFromArray();

    const invisibleChangeableColumns = this.allChangeableColumns.filter(c => !this.displayedColumns.includes(c));

    //Current visible columns
    this.allChangeableColumns = this.allChangeableColumns.filter(c => this.displayedColumns.includes(c));

    //Sort allChangeableColumnsin the order in which the columns in displayedColumns
    this.allChangeableColumns.sort((a, b) => {
      return this.displayedColumns.indexOf(a) - this.displayedColumns.indexOf(b);
    });

    //Add sticky start columns
    this.allChangeableColumns.unshift(...this.data.stickyStartColumns);

    //Add invisible changeable columns
    this.allChangeableColumns.push(...invisibleChangeableColumns);

    //Add sticky end columns
    this.allChangeableColumns.push(...this.data.stickyEndColumns);
  }

  public isChecked(column: string): boolean {
    return this.displayedColumns.includes(column);
  }

  public changeColumnStateHandler(column: string, isChecked: boolean): void {
    if (this.isColumnDisabled(column)) {
      return;
    }

    this.changeColumnState(column, isChecked);
  }

  public confirm() {

    //Sort the columns by the new order that we set in the modal window
    this.displayedColumns.sort((a, b) => {
      return this.allChangeableColumns.indexOf(a) - this.allChangeableColumns.indexOf(b);
    });

    this.dialogData.isConfirmed = true;
    this.dialogData.displayedColumns = this.displayedColumns;
    this.dialogRef.close(this.dialogData);
  }

  public excelExport() {
    if (this.excelExportConfig.excelExportAction) {
      this.excelExportConfig.excelExportAction(this.getDisplayedChangeableColumns());
    }
  }

  public drop(event: CdkDragDrop<string[]>) {
    ///Since sticky columns are not taken into account when dragging and dropping,
    ///they are not indexed, but they are contained in this array, so when moving elements,
    /// the sticky columns need to be removed, then swap the columns that we need and return the columns back

    this.removeStickyColumnsFromArray();

    moveItemInArray(this.allChangeableColumns, event.previousIndex, event.currentIndex);

    this.addStickyColumnsToArray();
  }

  private removeStickyColumnsFromArray() {
    this.allChangeableColumns = this.allChangeableColumns.filter(c => ![...this.data.stickyEndColumns, ...this.data.stickyStartColumns].includes(c));
  }

  private addStickyColumnsToArray() {
    //Add sticky start columns
    this.allChangeableColumns.unshift(...this.data.stickyStartColumns);

    //Add sticky end columns
    this.allChangeableColumns.push(...this.data.stickyEndColumns);
  }


  public isColumnDisabled(currentColumn: string): boolean {
    const displayedChangeableColumns = this.getDisplayedChangeableColumns();
    if (displayedChangeableColumns?.length === 1) {
      return displayedChangeableColumns.includes(currentColumn);
    }

    return false;
  }

  private changeColumnState(column: string, isChecked: boolean): void {
    if (isChecked) {
      this.displayedColumns.push(column);
    } else {
      this.displayedColumns = this.displayedColumns.filter(c => c !== column);
    }
  }

  private getDisplayedChangeableColumns(): string[] {
    const displayedChangeableColumns = this.allChangeableColumns.filter(c => this.displayedColumns.includes(c));

    return displayedChangeableColumns;
  }

  public isDragDisabled(column: string) {
    return [...this.data.stickyEndColumns, ...this.data.stickyStartColumns].includes(column);
  }
}
