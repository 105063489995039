export const outgoing_invoices_displayed_columns_locale = {
  type: "Type",
  invoiceReference: "Invoice number",
  fileReference: "Operational file nr.",
  customerName: "Customer name",
  customerAccountingNumber: "Customer accounting nr.",
  invoiceDate: "Invoice date",
  invoiceExpirationDate: "Invoice expiration date",
  creationDate: "Creation date",
  totalIncludingVat: "Amount (incl. VAT)",
  status: "Status"
};
