import { HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiConfiguration } from "../../../environments/environment-config";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
// TODO: should be renamed to ForDeskApiConfigService
export class ApiConfigService {
  private apiConfig: ApiConfiguration;

  constructor() {
    this.apiConfig = {
      host: environment.forDeskApiConfig.host,
      baseUrl: environment.forDeskApiConfig.baseUrl
    };
  }

  public isCrossHostRequest(request: HttpRequest<any>): boolean {
    const host: string = this.apiConfig.host as string;
    return request && host && !request.urlWithParams.startsWith(host);
  }

  public createApiUrl(endpoint: string) {
    const host: string = `${this.apiConfig.host}`;
    return host + `/${this.apiConfig.baseUrl}/${endpoint}`.replace("//", "/");
  }

  public createMasterDataApiUrl(endpoint: string) {
    return this.createApiUrl(`/masterdata/${endpoint}`);
  }

  /** @deprecated use createOperationalFileApiUrl2() */
  public createOperationalFileApiUrl(endpoint: string) {
    return this.createApiUrl(`/operational/operational-files/${endpoint}`);
  }

  // TODO: rename this once createOperationalFileApiUrl() is gone
  public createOperationalFileApiUrl2(operationalFileId: number, endpoint: string) {
    return this.createApiUrl(`/operational/operational-files/${operationalFileId}/${endpoint}`);
  }

  public createOperationalApiUrl(endpoint: string) {
    return this.createApiUrl(`/operational/${endpoint}`);
  }

  public createFinanceApiUrl(endpoint: string) {
    return this.createApiUrl(`/finance/${endpoint}`);
  }

  public createReportingApiUrl(endpoint: string) {
    return this.createApiUrl(`/reporting/${endpoint}`);
  }

  public createUtilitiesApiUrl(endpoint: string) {
    return this.createApiUrl(`/utilities/${endpoint}`);
  }

  public createQuotesApiUrl(endpoint: string) {
    return this.createApiUrl(`/quotes/${endpoint}`);
  }

  public createQuoteFileApiUrl(quoteFileId: number, endpoint: string) {
    return this.createApiUrl(`/quotes/quote-files/${quoteFileId}/${endpoint}`);
  }
}
