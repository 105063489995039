/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ExactOnlineIntegrationDTO { 
    createdByUserName?: string;
    rowCreatedTimestamp?: Date;
    status?: ExactOnlineIntegrationDTO.StatusEnum;
}
export namespace ExactOnlineIntegrationDTO {
    export type StatusEnum = 0 | 1;
    export const StatusEnum = {
        NUMBER_0: 0 as StatusEnum,
        NUMBER_1: 1 as StatusEnum
    };
}