export const quote_containers_locale = {
  CONTAINERS: "Containers",
  ADD: "Add container",
  DETAILS: "Container details",
  GROSS_WEIGHT: "Gross weight",
  ISO_CODE_DESCRIPTION: "Size type",
  MESSAGES: {
    ADDED: "The container have been successfully added!",
    UPDATED: "The container have been successfully updated!",
    COPIED: "The selected containers have been copied {{times}} times.",
    QUOTE_FILE_CONTAINERS_MAX_QUANTITY_EXCEEDED: "Quote files cannot contain more than 200 containers."
  },
  DESCRIPTION: "Description",
  NO_CONTAINERS_FOUND: "No containers in quote file.",
  SELECT_GOODS: "Select goods",
  GOODS: "Goods",
  COPY_CONTAINERS_TIMES: "How many copies of these containers would you like?",
  COPY_CONTAINERS_TITLE: "Goods copy"
};
