import { Component, Input, OnInit } from "@angular/core";
import { OperationalFileGoodsDTO } from "../../../../services/swagger-gen/fordesk";

@Component({
  selector: "goods-in-container-label",
  templateUrl: "./goods-in-container-label.component.html",
  styleUrls: ["./goods-in-container-label.component.scss"]
})
export class GoodsInContainerLabelComponent implements OnInit {
  @Input()
  public item: OperationalFileGoodsDTO;

  @Input()
  public truncate: boolean = false;

  constructor() { }

  public ngOnInit() {
  }

}
