/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TransportStopLocationIdentifierDTO } from './transportStopLocationIdentifierDTO';

export interface TransportStopLocationDTO { 
    id?: TransportStopLocationIdentifierDTO;
    description?: string;
    code?: string;
    alias?: string;
    masterDataStatus?: TransportStopLocationDTO.MasterDataStatusEnum;
}
export namespace TransportStopLocationDTO {
    export type MasterDataStatusEnum = 0 | 1;
    export const MasterDataStatusEnum = {
        NUMBER_0: 0 as MasterDataStatusEnum,
        NUMBER_1: 1 as MasterDataStatusEnum
    };
}