/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { WizardTransportStopDTO } from './wizardTransportStopDTO';

export interface AddOperationalFileTransportWizardDetailsDTO { 
    transportModeId?: number;
    transportCompanyId?: number;
    warehouseTransportType?: AddOperationalFileTransportWizardDetailsDTO.WarehouseTransportTypeEnum;
    containerIds?: Array<number>;
    goodsIds?: Array<number>;
    readonly transportStops?: Array<WizardTransportStopDTO>;
}
export namespace AddOperationalFileTransportWizardDetailsDTO {
    export type WarehouseTransportTypeEnum = 0 | 1;
    export const WarehouseTransportTypeEnum = {
        NUMBER_0: 0 as WarehouseTransportTypeEnum,
        NUMBER_1: 1 as WarehouseTransportTypeEnum
    };
}