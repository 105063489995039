import { AbstractControl, UntypedFormGroup, ValidatorFn } from "@angular/forms";

export const atLeastOneFieldProvided = (formGroup: UntypedFormGroup, fieldNames: string[]): { [key: string]: boolean } => {
  if (!fieldNames || fieldNames.length <= 0) {
    return null;
  }

  const nonEmptyValues = fieldNames.filter(fn => formGroup.get(fn).value);
  const areAllFieldsDisabled: boolean = fieldNames.every(fn => formGroup.get(fn).disabled);

  if (areAllFieldsDisabled || (nonEmptyValues && nonEmptyValues.length >= 1)) {
    return null;
  }

  return { noFieldProvided: true };
};

export function atLeastOneRequired(): ValidatorFn {
  return function validate(formGroup: UntypedFormGroup) {
    let allUnchecked = true;
    for (const key of Object.keys(formGroup.controls)) {
      if (formGroup.controls[key].value) {
        allUnchecked = false;
        break;
      }
    }

    return allUnchecked ? { noFieldProvided: true } : null;
  };
}
