import { Injectable } from "@angular/core";
import { FileExtensions } from "../../enumerations/file-extentions.enum";
import { ToolbarClickEventArgs, MenuClickEventArgs } from "@syncfusion/ej2-filemanager";

@Injectable({
  providedIn: "root"
})
export class FileExtensionHelper {

  public allowedExtensions: string = ".txt,.doc,.docx,.xls,.xlsm,.xlsx,.xlsb,.xltx,.pdf,.rtf,.eml,.msg,.jpg,.jpeg,.png,.gif";


  public getFileNameExtension(newFileName: string, oldFileName?: string): FileExtensions {

    if (oldFileName) {
      if (!newFileName.includes(".") && oldFileName.includes(".")) {
        return;
      }
    }

    if (!newFileName.includes(".")) {
      return FileExtensions.folder;
    }

    const extension = newFileName.split(".").pop().toLowerCase();

    switch (extension) {
      case FileExtensions.docx:
      case FileExtensions.doc:
        return FileExtensions.docx;
      default:
        return FileExtensions[extension];
    }
  }

  public getFileNameFromToolbarClickEvent(args: ToolbarClickEventArgs | MenuClickEventArgs): string {
    if (!args || !args.fileDetails || args.fileDetails.length <= 0) {
      return null;
    }
    return args.fileDetails[0]["name"];
  }

  public getAllowExtensions(): string {
    return this.allowedExtensions + "," + this.allowedExtensions.split(",").map(x => x.toUpperCase()).join();
  }
}
