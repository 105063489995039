import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'm-notfound',
  templateUrl: './notfound.component.html'
})
export class NotfoundComponent implements OnInit {
  @HostBinding('class') classes: string = 'm-grid m-grid--hor m-grid--root m-page';

  constructor() { }

  ngOnInit() {
  }

}
