/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { StructuredCompanyAddressDTO } from './structuredCompanyAddressDTO';

export interface OperationalFilePartyDetailsDTO { 
    operationalFileId: number;
    companyAddressId?: number;
    partyTypeId: number;
    companyFreeInput?: string;
    remarks?: string;
    contactPersonId?: number;
    showStructuredCompanyAddress?: boolean;
    structuredCompanyAddress?: StructuredCompanyAddressDTO;
    addressType?: OperationalFilePartyDetailsDTO.AddressTypeEnum;
    isGoodsOwner?: boolean;
}
export namespace OperationalFilePartyDetailsDTO {
    export type AddressTypeEnum = 0 | 1 | 2;
    export const AddressTypeEnum = {
        NUMBER_0: 0 as AddressTypeEnum,
        NUMBER_1: 1 as AddressTypeEnum,
        NUMBER_2: 2 as AddressTypeEnum
    };
}