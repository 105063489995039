export const quote_budget_details_overview_locale = {
  TITLE: "Budget details per quote",
  QUOTE_FILE_NR: "Quote file number",
  OPERATIONAL_FILE_NR: "Operational file number",
  QUOTATION_DATE: "Quotation date",
  PERIOD: "Period",
  CUSTOMER: "Customer",
  CUSTOMER_NUMBER: "Customer number",
  CUSTOMER_REF: "Customer reference",
  DEPARTMENT: "Department",
  USER: "User",
  INITIALS: "Initials",
  FILE_TYPE: "File type",
  SHIPMENT_TYPE: "Shipment type",
  TRANSPORT_TYPE: "Transport type",
  PORT_OF_LOADING: "Port of loading",
  PORT_OF_DISCHARGE: "Port of discharge",
  PLACE_OF_DESTINATION: "Place of destiantion",
  PLACE_OF_RECEIPT: "Place of receipt",
  PERCENT_TO: "TO %",
  CARRIER: "Carrier",
  TOTAL_COST: "Total cost",
  TOTAL_REVENUE: "Total revenue",
  RESULT: "Result",
  STATUS: "Status",
  PIVOT_FIELDS: {
    QUOTE_FILE_NR: "Quote file number",
    OPERATIONAL_FILE_NR: "Operational file number",
    QUOTATION_DATE: "Quotation date",
    PERIOD: "Period",
    CUSTOMER: "Customer",
    CUSTOMER_NUMBER: "Customer number",
    CUSTOMER_REF: "Customer reference",
    DEPARTMENT: "Department",
    USER: "User",
    INITIALS: "Initials",
    FILE_TYPE: "File type",
    SHIPMENT_TYPE: "Shipment type",
    TRANSPORT_TYPE: "Transport type",
    PORT_OF_LOADING: "Port of loading",
    PORT_OF_DISCHARGE: "Port of discharge",
    PLACE_OF_DESTINATION: "Place of destiantion",
    PLACE_OF_RECEIPT: "Place of receipt",
    PERCENT_TO: "TO %",
    CARRIER: "Carrier",
    TOTAL_COST: "Total cost",
    TOTAL_REVENUE: "Total revenue",
    RESULT: "Result",
    STATUS: "Status",
  }
};
