import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class NotificationEventsService {
  private notificationArchived$: Subject<void> = new Subject();

  constructor() {
  }

  public triggerArchive(): void {
    this.notificationArchived$.next();
  }

  public hasArchived(): Observable<void> {
    return this.notificationArchived$.asObservable();
  }
}