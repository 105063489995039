import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { PortLookupHelper } from "../../../helpers/port-lookup-helper";
import { Observable, Subject, of, concat } from "rxjs";
import { delay, map, share, takeUntil, tap } from "rxjs/operators";
import { AddressLookupHelper } from "../../../helpers/address-lookup-helper";
import { AddressDetailsDTO, AddressesService, PortDTO, PortsService } from "../../../services/swagger-gen/fordesk";
import { MasterDataStatusEnum } from "../../../enumerations/master-data-status.enum";

@Component({
  selector: "formly-type-ahead",
  templateUrl: "./formly-type-ahead.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyTypeAheadComponent extends FieldType implements OnInit, AfterViewInit, OnDestroy {
  public loading: boolean;
  public items$: Observable<PortDTO[] | AddressDetailsDTO[]> = of([]);
  public searchInput$: Subject<string> = new Subject<string>();
  public isPorts: boolean = true;
  private unsubscribe: Subject<void> = new Subject();

  private selectionCache: Array<number> = [];

  constructor(
    private portLookupHelper: PortLookupHelper,
    private portService: PortsService,
    public cdRef: ChangeDetectorRef,
    private addressLookupHelper: AddressLookupHelper,
    private addressesService: AddressesService
  ) {
    super();
  }

  public ngOnInit() {
    this.loadLookup();

    this.items$
      .pipe(
        delay(600),
        tap(() => this.cdRef.detectChanges(), takeUntil(this.unsubscribe))
      )
      .subscribe();
  }

  public ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  private loadLookup(): void {
    const id: number = typeof this.key === "string" || typeof this.key === "number" ? +this.model[this.key] : null;
    // TODO find better way of doing this
    if (this.key === "WarehouseAddressId") {
      this.isPorts = false;
      this.getItems$(id);
    } else {
      this.getItems$(id);
    }
  }

  private getItems$(id: number) {
    if (id) {
      this.selectionCache.push(id);

      this.items$ = this.isPorts ? concat(this.getPortById(id), this.createPortLookup()) : concat(this.getAddressById(id), this.createAddressLookup());
      this.formControl.setValue(id);
    } else {
      this.items$ = this.isPorts ? this.createPortLookup() : this.createAddressLookup();
    }
  }

  private getPortById(id: number): Observable<PortDTO[]> {
    return this.portService.getPortById(id).pipe(
      map((res) => {
        return [res];
      }),
      share(), takeUntil(this.unsubscribe)
    );
  }

  private createPortLookup(): Observable<PortDTO[]> {
    return this.portLookupHelper.getPortsLookup(
      this.portService,
      this.searchInput$,
      () => this.loading = true,
      () => {
        this.loading = false;
      }
    ).pipe(map((list) => list.filter(dto => dto.masterDataStatus === MasterDataStatusEnum.Active
      || this.selectionCache.includes(dto.id))), takeUntil(this.unsubscribe));
  }

  private getAddressById(id: number): Observable<AddressDetailsDTO[]> {
    return this.addressesService.getAddressById(id).pipe(
      map((res) => {
        return [res];
      }), takeUntil(this.unsubscribe)
    );
  }

  private createAddressLookup(): Observable<AddressDetailsDTO[]> {
    return this.addressLookupHelper.getAddressesLookup(
      this.addressesService,
      this.searchInput$,
      () => this.loading = true,
      () => {
        this.loading = false;
      }
    );
    // use this when implementing the blocking feature for addresses
    // .pipe(map((list) => list.filter(dto => dto.masterDataStatus === MasterDataStatusEnum.Active
    //   || this.selectionCache.includes(dto.id))));
  }

  ngOnDestroy(): void {
    if (!this.unsubscribe) {
      return;
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
