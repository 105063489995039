export const of_edit_locale = {
  RECOGNIZED_CONTAINERS: "Recognized containers",
  CURRENT_CONTAINERS: "Current containers",
  REMOVE_CONTAINER: "Remove container",
  ADD_CONTAINER: "Add container",
  REMOVE_ALL: "Remove all",
  SAVE_ALL: "Save all",

  OPEN_BUDGET: "open budget",
  CLOSE_BUDGET: "close budget",
  CLOSE_FILE: "close file",
  FILE_CLOSED: "File has been closed succesfully",
  FILE_CLOSED_FAILED: "Could not close file",
  OPEN_FILE: "open file",
  FILE_OPENED: "File has been opened succesfully",
  FILE_OPENED_FAILED: "Could not open file",
  SHOW_SIDEBAR: "Show sidebar",
  HIDE_SIDEBAR: "Hide sidebar",
  TOGGLE_STEPS: "Toggle steps",
  TOGGLE_STEPS_ORDER: "Toggle steps order",
  STEP_ORDER_BY_DEADLINE: "Order by deadline",
  STEP_ORDER_BY_SEQUENCE: "Order by sequence",
  BOOKINGREFERENCES: "Booking references",
  BERTHDATE: "Berth date",
  BILLOFLADINGNR: "Bill of lading nr.",
  CLOSINGREEFER: "Closing reefer",
  ETA: "ETA",
  ETD: "ETD",
  FREETIME: "Free time",
  HOUSEBLNR: "House BL nr.",
  PLACEOFDESTINATIONID: "Place of destination",
  PLACEOFRECEIPTID: "Place of receipt",
  PORTOFDISCHARGEID: "Port of discharge",
  PORTOFLOADINGID: "Port of loading",
  CARRIERID: "Carrier",
  STORAGE: "Storage",
  TERMINALID: "Terminal",
  RECOGNIZED: "Recognized",
  CURRENT_VALUE: "Current value",
  RECOGNIZED_VALUE: "Recognized value",
  KEEP_CURRENT: "Keep current",
  KEEP_NEW: "Keep new",
  LOCATIONS: "Locations",
  GENERAL: "General",
  DATES: "Dates",
  VESSELID: "Vessel",
  VOYAGE: "Voyage",
  UPLOAD_FILE: "Upload file",
  FEATURE_UNDER_DEVELOPMENT: "Feature is under development and may still have issues. Double-check any data the system identifies.",
  UPDATED_SUCCESSFULLY: "File updated successfully",
  WAREHOUSE: "Warehouse",
  WAREHOUSEADDRESS: "Warehouse address",
  TRANSPORT_TYPE: "Transport type",
  TRANSPORT_STOP_TYPE: "Transport stop Type",
  AIRFREIGHT: "Airfreight",
  AIRPORTOFDEPARTURE: "Airport of departure",
  AIRPORTOFDEPARTUREID: "Airport of departure",
  AIRWAYBILLNR: "Airway bill nr.",
  CARRIER: "Carrier",
  NO_FIELDS_WERE_RECOGNIZED: "No field was recognized",
  CLOSINGAMS: "Closing AMS",
  CLOSINGDOCUMENT: "Closing document",
  CLOSINGIMO: "Closing IMO",
  CLOSINGVESSEL: "Closing vessel",
  CLOSINGVGM: "Closing VGM",
  CLOSING_DATA: "Closing data",
  //TRANSPORT STOP TYPES
  LOADING: "Loading",
  EMPTY_PICKUP: "Empty pickup",
  DELIVERY: "Delivery",
  FULL_DELIVERY: "Full delivery",
  EMPTY_DROPP_OFF: "Empty dropp off",
  PICKUP: "Pickup",
  FULL_PICKUP: "Full pickup",
  MULTI_STOP: "Multi stop",

  // TODO: usages of "CRM nr." should be updated to "CMR nr." across the application (db as well)
  CRMNR: "CMR nr.",
  DEMURRAGE: "Demurrage",
  DETENTION: "Detention",
  FLIGHTNR: "Flight nr.",
  PLACEOFRECEIPT: "Place of receipt",
  TRUCKPLATE: "Truck plate",
  WAREHOUSEDATE: "Warehouse date",
  EXCHANGERATE: "Exchange rate",
  TAB_TITLES: {
    GENERAL: "General",
    GOODS: "Goods",
    CONTAINERS: "Containers",
    PARTIES: "Parties",
    CUSTOMS: "Customs",
    TRANSPORT: "Transport",
    BOOKING: "Booking",
    SHIPPING_INSTRUCTIONS: "Shipping instructions",
    COURIER_INSTRUCTIONS: "Courier instructions",
    DOCUMENTS: "Documents",
    BUDGET: "Budget"
  },
  PORTOFLOADING: "Port of loading",
  PORTOFDISCHARGE: "Port of discharge",
  PLACEOFDESTINATION: "Port of destination",
  AIRPORTOFDESTINATION: "Airport of destination",
  AIRPORTOFDESTINATIONID: "Airport of destination",
  INCOTERMID: "Incoterm",
  MESSAGES: {
    NO_DATA_RECOGNIZED_TOSUGGEST: "No data was recognized",
    CLOSE_BUDGET: "Are you sure you want to close the budget for operational file '{{file}}'?",
    OPEN_BUDGET: "Are you sure you want to open the budget for operational file '{{file}}'?",
    CLOSE_FILE: "Are you sure you want to close operational file '{{file}}?",
    OPEN_FILE: "Are you sure you want to open operational file '{{file}}?",
    CUSTOMER_REFERENCE_INTTRA_WARNING: "Customer reference should be limited to 35 characters if this file will be sent via INTTRA (booking or shipping instructions)."
  },
  CUSTOMERREFERENCE: "Customer reference",
  BOLNR: "Bol nr.",
  ACTUALTIMEOFARRIVAL: "Actual time of arrival",
  ACTUALTIMEOFDEPARTURE: "Actual time of departure",
  TRUCK_SORTIE: "SOR",
  ANTWERP_WAREHOUSE_IMPORT: "AWI",
  ANTWERP_WAREHOUSE_EXPORT: "AWE",
  ANTWERP_STORAGE_IMPORT: "ASI",
  ANTWERP_STORAGE_EXPORT: "ASE",
  REC: "REC",
  STEP_OVERDUE: "step overdue",
  STEP_OVERDUE_PLURAL: "steps overdue",
  STEP_NEARING_DEADLINE: "step nearing deadline",
  STEP_NEARING_DEADLINE_PLURAL: "steps nearing deadline",
  ACTUAL_DATE_OF_LOADING: "Actual date of loading",
};
