import { CapitalizeFirstPipe } from "./pipes/capitalize-first.pipe";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MenuAsideDirective } from "./directives/menu-aside.directive";
import { MenuAsideOffcanvasDirective } from "./directives/menu-aside-offcanvas.directive";
import { MenuHorizontalOffcanvasDirective } from "./directives/menu-horizontal-offcanvas.directive";
import { MenuHorizontalDirective } from "./directives/menu-horizontal.directive";
import { ScrollTopDirective } from "./directives/scroll-top.directive";
import { HeaderDirective } from "./directives/header.directive";
import { MenuAsideToggleDirective } from "./directives/menu-aside-toggle.directive";
import { QuickSidebarOffcanvasDirective } from "./directives/quick-sidebar-offcanvas.directive";
import { FirstLetterPipe } from "./pipes/first-letter.pipe";
import { TimeElapsedPipe } from "./pipes/time-elapsed.pipe";
import { QuickSearchDirective } from "./directives/quick-search.directive";
import { JoinPipe } from "./pipes/join.pipe";
import { GetObjectPipe } from "./pipes/get-object.pipe";
import { ConsoleLogPipe } from "./pipes/console-log.pipe";
import { SafePipe } from "./pipes/safe.pipe";
import { PortletDirective } from "./directives/portlet.directive";
import { FormatAddressPipe } from "./pipes/format-address.pipe";
import { AlphaNumericVatNumberDirective } from "./directives/alpha-numeric-vat-number.directive";
import { ToUpperCaseDirective } from "./directives/to-upper-case.directive";
import { DigitsOnlyDirective } from "./directives/digits-only.directive";
import { ClipboardDirective } from "./directives/clipboard.directive";
import { AutoFocusDirective } from "./directives/auto-focus.directive";
import { ContainerGoodsLabelPipe } from "./pipes/container-goods-label.pipe";
import { DateCharactersOnlyDirective } from "./directives/date-characters-only.directive";
import { TypeAheadHelperDirective } from "./directives/type-ahead-helper.directive";
import { AlphaOnlyDirective } from "./directives/alpha-only.directive";
import { NumbersOnlyDirective } from "./directives/numbers-only.directive";
import { TimepickerHelperDirective } from "./directives/timepicker-helper.directive";
import { DragDropDirective } from "./directives/dragDrop.directive";
import { TrimStartDirective } from "./directives/trim-start.directive";
import { UnsavedChangesDirective } from "./directives/unsaved-changes.directive";
import { NumberRangeDirective } from "./directives/number-range.directive";
import { TrimDirective } from "./directives/trim.directive";
import { NumbersOnlyPrecisionDirective } from "./directives/numbers-only-precision.directive";
import { TrimStartWithoutFormControlDirective } from "./directives/trim-start-without-form-control.directive";
import { TruncatePipe } from "./pipes/truncate.pipe";
import { DecimalFormatterDirective } from "./directives/decimal-formatter.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [
    // directives
    DragDropDirective,
    MenuAsideDirective,
    MenuAsideOffcanvasDirective,
    MenuHorizontalOffcanvasDirective,
    MenuHorizontalDirective,
    ScrollTopDirective,
    HeaderDirective,
    MenuAsideToggleDirective,
    QuickSidebarOffcanvasDirective,
    QuickSearchDirective,
    PortletDirective,
    AlphaNumericVatNumberDirective,
    ToUpperCaseDirective,
    DigitsOnlyDirective,
    AlphaOnlyDirective,
    NumbersOnlyDirective,
    DateCharactersOnlyDirective,
    TypeAheadHelperDirective,
    TimepickerHelperDirective,
    TrimStartDirective,
    TrimDirective,
    TrimStartWithoutFormControlDirective,
    UnsavedChangesDirective,
    NumberRangeDirective,
    NumbersOnlyPrecisionDirective,
    DecimalFormatterDirective,
    // pipes
    FirstLetterPipe,
    TimeElapsedPipe,
    JoinPipe,
    TruncatePipe,
    GetObjectPipe,
    ConsoleLogPipe,
    SafePipe,
    FormatAddressPipe,
    CapitalizeFirstPipe,
    ClipboardDirective,
    AutoFocusDirective,
    ContainerGoodsLabelPipe
  ],
  exports: [
    // directives
    DragDropDirective,
    MenuAsideDirective,
    MenuAsideOffcanvasDirective,
    MenuHorizontalOffcanvasDirective,
    MenuHorizontalDirective,
    ScrollTopDirective,
    HeaderDirective,
    MenuAsideToggleDirective,
    QuickSidebarOffcanvasDirective,
    QuickSearchDirective,
    PortletDirective,
    AlphaNumericVatNumberDirective,
    ToUpperCaseDirective,
    DigitsOnlyDirective,
    AlphaOnlyDirective,
    NumbersOnlyDirective,
    DateCharactersOnlyDirective,
    TypeAheadHelperDirective,
    TimepickerHelperDirective,
    TrimStartDirective,
    TrimDirective,
    TrimStartWithoutFormControlDirective,
    UnsavedChangesDirective,
    NumberRangeDirective,
    NumbersOnlyPrecisionDirective,
    DecimalFormatterDirective,
    // pipes
    FirstLetterPipe,
    TimeElapsedPipe,
    JoinPipe,
    TruncatePipe,
    GetObjectPipe,
    ConsoleLogPipe,
    SafePipe,
    FormatAddressPipe,
    CapitalizeFirstPipe,
    ClipboardDirective,
    AutoFocusDirective,
    ContainerGoodsLabelPipe,

  ],
  providers: []
})
export class CoreModule {
}
