import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  ConfirmWithReasonDialogData,
  ConfirmWithReasonDialogResultData
} from "../../../core/models/dialogs/confirm-with-reason";

@Component({
  selector: "confirm-with-reason",
  templateUrl: "./confirm-with-reason.component.html"
})
export class ConfirmWithReasonDialog implements OnInit {
  public detailsForm: UntypedFormGroup;
  public submitted: boolean = false;
  public dialogData: ConfirmWithReasonDialogResultData = {
    isConfirmed: false,
    reason: ""
  };

  public reasonEnabled: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfirmWithReasonDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmWithReasonDialogData,
    private fb: UntypedFormBuilder
  ) {
    this.reasonEnabled = data.reasonEnabled;
  }

  public ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.detailsForm = this.fb.group({
      reason: [null, [Validators.maxLength(1024)]]
    });
  }

  public cancel() {
    this.dialogData.isConfirmed = false;
    this.dialogRef.close(this.dialogData);
  }

  public save() {
    this.submitted = true;
    if (!this.detailsForm.valid) {
      return;
    }
    this.dialogData.reason = this.detailsForm.controls.reason.value;
    this.dialogData.isConfirmed = true;
    this.dialogRef.close(this.dialogData);
  }
}
