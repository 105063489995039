import { AbstractControl, ValidatorFn } from "@angular/forms";

export function maxDateValidator(maxDate: Date): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {

    if (maxDate) {
      let currentValue = control.value;
      if (typeof control.value === "string" || control.value instanceof String) {
        currentValue = new Date(currentValue);
      }

      return currentValue != null && currentValue > maxDate ? { maxDate: true } : null;
    }
  };
}
