import { Injectable } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class ActualMinMaxDateChangedService {

  private isMinDateChanged$: BehaviorSubject<NgbDateStruct> = new BehaviorSubject(null);
  private isMaxDateChanged$: BehaviorSubject<NgbDateStruct> = new BehaviorSubject(null);

  constructor() {
  }

  public getNextMinDate(event: NgbDateStruct): void {
    this.isMinDateChanged$.next(event);
  }


  public getNextMaxDate(event: NgbDateStruct): void {
    this.isMaxDateChanged$.next(event);
  }

  public isMinDateChanged(): Observable<NgbDateStruct> {
    return this.isMinDateChanged$.asObservable();
  }

  public isMaxDateChanged(): Observable<NgbDateStruct> {
    return this.isMaxDateChanged$.asObservable();
  }
}

