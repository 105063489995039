import { NgModule } from "@angular/core";
import { CommonModule, DecimalPipe } from "@angular/common";
import { ExchangeRateInputComponent } from "./formly/exchange-rate-input/exchange-rate-input.component";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyNgSelectComponent } from "./formly/formly-ng-select/formly-ng-select.component";
import { FormlyTypeAheadComponent } from "./formly/formly-type-ahead/formly-type-ahead.component";
import { FormlyVesselSelectComponent } from "./formly/formly-vessel-select/formly-vessel-select.component";
import { FormlyDateComponent } from "./formly/formly-date/formly-date.component";
import { FormlyDatetimeLocalComponent } from "./formly/formly-datetime-local/formly-datetime-local.component";
import { ExchangeRateComponent } from "./exchange-rate/exchange-rate.component";
import { DateInputsHelperService } from "../helpers/date-inputs-helper.service";
import { DecimalFormatterService } from "../helpers/decimal-formatter.service";
import { FormlyBillOfLadingComponent } from "./formly/formly-bill-of-lading/formly-bill-of-lading.component";
import { CompanyAddressOption } from "./ng-select-templates/company-address-dropdown/company-address-option/company-address-option";
import { CompanyAddressLabel } from "./ng-select-templates/company-address-dropdown/company-address-label/company-address-label";
import { PartyAddressLabel } from "./ng-select-templates/party-address/party-address-label/party-address-label";
import { PartyAddressOption } from "./ng-select-templates/party-address/party-address-option/party-address-option";
import { IncomingInvoiceStatusBadgeComponent } from "./status-templates/incoming-invoice-status-badge/incoming-invoice-status-badge.component";
import { OperationalFileCostStatusBadgeComponent } from "./status-templates/operational-file-cost-status-badge/operational-file-cost-status-badge.component";
import { AddressLabelComponent } from "./ng-select-templates/address-dropdown/address-label/address-label.component";
import { AddressOptionComponent } from "./ng-select-templates/address-dropdown/address-option/address-option.component";
import { PortLabelComponent } from "./ng-select-templates/port-dropdown/port-label/port-label.component";
import { PortOptionComponent } from "./ng-select-templates/port-dropdown/port-option/port-option.component";
import { ContainerGoodsLabel } from "./ng-select-templates/container-goods-dropdown/conatiner-goods-label/container-goods-label";
import { ContainerGoodsValue } from "./ng-select-templates/container-goods-dropdown/container-goods-value/container-goods-value";
import { ContainerReleaseRightStatusBadgeComponent } from "./status-templates/container-release-right-status-badge/container-release-right-status-badge.component";
import { GoodsInContainerLabelComponent } from "./ng-select-templates/goods-in-container/goods-in-container-label/goods-in-container-label.component";
import { GoodsInContainerOptionComponent } from "./ng-select-templates/goods-in-container/goods-in-container-option/goods-in-container-option.component";
import { TypeAheadErrorComponent } from "./errors/type-ahead-error/type-ahead-error.component";
import { SharedModule } from "../../content/shared/shared.module";
import { NumberInputsHelperService } from "../helpers/number-inputs-helper";
import { FormlyActualTimeOfArrivalComponent } from "./formly/formly-actual-time-of-arrival/formly-actual-time-of-arrival.component";
import { FormlyActualTimeOfDepartureComponent } from "./formly/formly-actual-time-of-departure/formly-actual-time-of-departure.component";
import { FormlyVoyageComponent } from "./formly/formly-voyage/formly-voyage.component";
import { FormlyBookingReferencesComponent } from "./formly/formly-booking-references/formly-booking-references.component";
import { FormlyCustomerReferenceComponent } from "./formly/formly-customer-reference/formly-customer-reference.component";
import { FormlyHouseBlComponent } from "./formly/formly-house-bl/formly-house-bl.component";
import { FormlyRecComponent } from "./formly/formly-rec/formly-rec.component";
import { FormlySorComponent } from "./formly/formly-sor/formly-sor.component";
import { FormlyAwiComponent } from "./formly/formly-awi/formly-awi.component";
import { FormlyAweComponent } from "./formly/formly-awe/formly-awe.component";
import { FormlyAsiComponent } from "./formly/formly-asi/formly-asi.component";
import { FormlyAseComponent } from "./formly/formly-ase/formly-ase.component";
import { TerminalLabelComponent } from "./ng-select-templates/terminal-dropdown/terminal-label/terminal-label.component";
import { TerminalOptionComponent } from "./ng-select-templates/terminal-dropdown/terminal-option/terminal-option.component";
import { DepotLabelComponent } from "./ng-select-templates/depot-dropdown/depot-label/depot-label.component";
import { DepotOptionComponent } from "./ng-select-templates/depot-dropdown/depot-option/depot-option.component";
import { InvoiceTypeStatusComponent } from "./status-templates/invoice-type-status/invoice-type-status.component";
import { GridLayoutDropdownComponent } from "./grid-layout-components/grid-layout-dropdown/grid-layout-dropdown.component";
import { GridLayoutComponent } from "./grid-layout-components/grid-layout/grid-layout.component";
import { AddGridLayoutComponent } from './grid-layout-components/add-grid-layout/add-grid-layout.component';
import { ManageGridLayoutsComponent } from './grid-layout-components/manage-grid-layouts/manage-grid-layouts.component';
import { ClickOutsideModule } from "ng-click-outside";
import { DocumentNewComponent } from "../../content/pages/operational/components/operational-file-edit/documents/document-new/document-new.component";

@NgModule({
  imports: [
    ClickOutsideModule,
    CommonModule,
    SharedModule,
    FormlyModule.forRoot({
      types: [
        { name: "select", component: FormlyNgSelectComponent },
        // TODO: rename "ExchangeRate" selector with kebab-case
        { name: "ExchangeRate", component: ExchangeRateInputComponent },
        { name: "type-ahead", component: FormlyTypeAheadComponent },
        { name: "vessel-select", component: FormlyVesselSelectComponent, wrappers: ["form-field"] },
        { name: "date", component: FormlyDateComponent },
        { name: "datetime-local", component: FormlyDatetimeLocalComponent },
        { name: "bill-of-lading", component: FormlyBillOfLadingComponent },
        { name: "actual-time-of-arrival", component: FormlyActualTimeOfArrivalComponent },
        { name: "actual-time-of-departure", component: FormlyActualTimeOfDepartureComponent },
        { name: "voyage", component: FormlyVoyageComponent },
        { name: "booking-references", component: FormlyBookingReferencesComponent },
        { name: "customer-reference", component: FormlyCustomerReferenceComponent },
        { name: "house-bl", component: FormlyHouseBlComponent },
        { name: "rec", component: FormlyRecComponent },
        { name: "sor", component: FormlySorComponent },
        { name: "awi", component: FormlyAwiComponent },
        { name: "awe", component: FormlyAweComponent },
        { name: "asi", component: FormlyAsiComponent },
        { name: "ase", component: FormlyAseComponent }
      ]
    })
  ],
  providers: [
    DateInputsHelperService,
    NumberInputsHelperService,
    DecimalFormatterService,
    DecimalPipe
  ],
  declarations: [
    DocumentNewComponent,
    ExchangeRateInputComponent,
    FormlyNgSelectComponent,
    FormlyTypeAheadComponent,
    FormlyCustomerReferenceComponent,
    FormlyVesselSelectComponent,
    FormlyBookingReferencesComponent,
    FormlyDateComponent,
    FormlyDatetimeLocalComponent,
    ExchangeRateComponent,
    CompanyAddressOption,
    FormlyVoyageComponent,
    FormlyBillOfLadingComponent,
    FormlyActualTimeOfDepartureComponent,
    FormlyActualTimeOfArrivalComponent,
    CompanyAddressLabel,
    ContainerGoodsValue,
    ContainerGoodsLabel,
    PartyAddressLabel,
    PartyAddressOption,
    IncomingInvoiceStatusBadgeComponent,
    OperationalFileCostStatusBadgeComponent,
    AddressLabelComponent,
    AddressOptionComponent,
    PortLabelComponent,
    PortOptionComponent,
    GoodsInContainerLabelComponent,
    GoodsInContainerOptionComponent,
    TypeAheadErrorComponent,
    ContainerReleaseRightStatusBadgeComponent,
    FormlyHouseBlComponent,
    FormlyRecComponent,
    FormlySorComponent,
    FormlyAwiComponent,
    FormlyAweComponent,
    FormlyAsiComponent,
    FormlyAseComponent,
    TerminalLabelComponent,
    TerminalOptionComponent,
    DepotLabelComponent,
    DepotOptionComponent,
    InvoiceTypeStatusComponent,
    GridLayoutDropdownComponent,
    GridLayoutComponent,
    AddGridLayoutComponent,
    ManageGridLayoutsComponent
  ],
  exports: [
    DocumentNewComponent,
    ExchangeRateComponent,
    ContainerGoodsValue,
    ContainerGoodsLabel,
    CompanyAddressOption,
    CompanyAddressLabel,
    PartyAddressLabel,
    PartyAddressOption,
    AddressLabelComponent,
    AddressOptionComponent,
    PortLabelComponent,
    PortOptionComponent,
    IncomingInvoiceStatusBadgeComponent,
    OperationalFileCostStatusBadgeComponent,
    GoodsInContainerLabelComponent,
    GoodsInContainerOptionComponent,
    ContainerReleaseRightStatusBadgeComponent,
    TypeAheadErrorComponent,
    TerminalLabelComponent,
    TerminalOptionComponent,
    DepotLabelComponent,
    DepotOptionComponent,
    InvoiceTypeStatusComponent,
    GridLayoutDropdownComponent,
    GridLayoutComponent
  ]
})
export class ForwardingComponentModule { }
