import { Component, OnDestroy, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormlyHelper } from "../../../helpers/formly-helper";

@Component({
  selector: "formly-ase",
  templateUrl: "./formly-ase.component.html"
})
export class FormlyAseComponent extends FieldType implements OnInit, OnDestroy {
  public submitted: boolean;
  public operationalFileId: number;
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private formlyHelper: FormlyHelper
  ) {
    super();
  }

  public ngOnInit() {
    this.formlyHelper
      .getGeneralFormSubmission$()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.submitted = true);
  }

  public ngOnDestroy(): void {
    if (!this.unsubscribe || !this.unsubscribe.observers || this.unsubscribe.observers.length <= 0) {
      return;
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
