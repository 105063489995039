import { Directive, HostListener, Input } from "@angular/core";
import { NgbCalendar, NgbDate, NgbTimepicker } from "@ng-bootstrap/ng-bootstrap";
import { UntypedFormControl } from "@angular/forms";
import { DateTimeInputComponent } from "../../content/shared/date-time-input/date-time-input.component";

@Directive({
  selector: "[timepickerHelper]"
})
export class TimepickerHelperDirective {
  @Input() public dateControl: UntypedFormControl | DateTimeInputComponent;
  @Input() public hoursMinutesControl: UntypedFormControl | NgbTimepicker;

  private maxHoursValue: number = 23;
  private maxMinutesValue: number = 59;

  constructor(private host: NgbTimepicker,
    private calendar: NgbCalendar) { }

  @HostListener("input", ["$event"])
  public Input(event: Event) {
    if (this.dateControl && this.hoursMinutesControl) {
      const element: any = event.target;
      const value = element.value as number;
      const label = element.ariaLabel;

      if (label === "Minutes" && value > this.maxMinutesValue) {
        this.minutesInputHandler(value);
        element.blur();
      } else if (label === "Hours" && value > this.maxHoursValue) {
        this.hoursInputHandler(value);
        element.blur();
      }
    }
  }

  @HostListener("change", ["$event"])
  public Change(event: Event) {
    const time = this.host.model;
    const timeInputs = (event.target as HTMLElement)
      .closest("ngb-timepicker")
      .getElementsByTagName("input");

    const hour = time.hour ? time.hour.toString() : "0";
    const minute = time.minute ? time.minute.toString() : "0";

    this.host.updateHour(hour);
    this.host.updateMinute(minute);

    (timeInputs[0] as HTMLInputElement).value = this.addLeadingZeroIfNecessary(hour);
    (timeInputs[1] as HTMLInputElement).value = this.addLeadingZeroIfNecessary(minute);
  }

  private addLeadingZeroIfNecessary(time: string): string {
    return ("00" + time).slice(-2);
  }

  private minutesInputHandler(value: number): void {
    let currentHours: number;
    if (this.hoursMinutesControl instanceof UntypedFormControl) {
      currentHours = this.hoursMinutesControl.value.hour;
    } else if (this.hoursMinutesControl instanceof NgbTimepicker) {
      currentHours = this.hoursMinutesControl.model.hour;
    }

    if (currentHours === 23) {
      this.updateDateControl();
    }
  }

  private hoursInputHandler(value: number): void {
    const extraDays = Math.floor(value / 24);

    this.updateDateControl(extraDays);
  }

  private updateDateControl(extraDays: number = 1): void {
    if (this.dateControl instanceof UntypedFormControl) {
      const controlValue = this.dateControl.value;

      if (controlValue) {
        const date = new Date(controlValue.year, controlValue.month, controlValue.day);
        date.setDate(date.getDate() + extraDays);

        this.dateControl.setValue({
          year: date.getFullYear(),
          month: date.getMonth(),
          day: date.getDate()
        });
      }
    } else if (this.dateControl instanceof DateTimeInputComponent) {
      let controlValue: NgbDate = this.dateControl.ngbDate;

      if (controlValue) {
        controlValue = this.calendar.getNext(controlValue, "d", extraDays);

        this.dateControl.ngbDate = controlValue;
        this.dateControl.onDateSelected(controlValue);
      }
    }
  }
}
