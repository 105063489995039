export const quote_costs_locale = {
  QUOTE_COST: "Cost",
  QUOTE_COSTS: "Costs",
  ADD: "Add cost",
  DETAILS: "Cost details",
  ACTIVITY_CODE: "Activity code",
  CURRENCY: "Currency",
  NAME: "Name",
  PARTY_NAME: "Party name",
  QUANTITY: "Quantity",
  PRICE: "Price",
  TOTAL: "Total",
  TOTAL_EXCL_VAT: "Total excl. VAT",
  UOM: "Unit of measurement",
  TOTAL_EURO: "Total (€)",
  PARTY: "Party"
};
