import {Directive, HostListener, Input} from "@angular/core";
import {UntypedFormControl} from "@angular/forms";

@Directive({
  selector: "[trimStart]"
})

export class TrimStartDirective {

  @Input("formControl")
  public formControl: UntypedFormControl;

  constructor() {}

  @HostListener("input", ["$event"]) public onKeyUp(event: KeyboardEvent) {
    if (this.formControl) {
      this.formControl.setValue(event.target["value"].trimStart());
    }
  }
}
