/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FoundBudgetDetailsPerOperationalFileDTO { 
    operationalFileNumber?: string;
    operationalFileId?: number;
    status?: FoundBudgetDetailsPerOperationalFileDTO.StatusEnum;
    customerReference?: string;
    userName?: string;
    userInitials?: string;
    department?: string;
    fileDate?: Date;
    period?: string;
    customer?: string;
    customerNumber?: string;
    totalOpenCosts?: number;
    totalOpenRevenues?: number;
    totalRealisedRevenues?: number;
    totalRealisedCosts?: number;
    result?: number;
    percentTO?: number;
    numberOfTEU?: number;
    portOfLoadingCode?: string;
    portOfLoading?: string;
    portOfDischargeCode?: string;
    portOfDischarge?: string;
    totalOutOfBudgetCosts?: number;
    totalOutOfBudgetRevenues?: number;
    resultOutOfBudget?: number;
    invoiceDate?: Date;
    budgetResult?: number;
    actualisedResult?: number;
}
export namespace FoundBudgetDetailsPerOperationalFileDTO {
    export type StatusEnum = 0 | 1 | 2;
    export const StatusEnum = {
        NUMBER_0: 0 as StatusEnum,
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum
    };
}