import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { EntityDetailsComponent } from '../../../core/components/abstractions/entity-details.component';
import { UnsavedChangesService } from '../../../core/services/helpers/unsaved-changes.service';
import { OperationalFIleStepDescriptionDTO, OperationalFilePartyService, OperationalFileStepDetails, OperationalFileStepsService } from '../../../core/services/swagger-gen/fordesk';
import { ToasterService } from '../../layout/toaster/toaster.service';

export class UpdateStepsPartiesLinkingDialogComponentData {
  public operationalFileId: number;
}

@Component({
  selector: 'update-steps-parties-linking-dialog',
  templateUrl: './update-steps-parties-linking-dialog.component.html',
  styleUrls: ['./update-steps-parties-linking-dialog.component.scss']
})
export class UpdateStepsPartiesLinkingDialogComponent extends EntityDetailsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UpdateStepsPartiesLinkingDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: UpdateStepsPartiesLinkingDialogComponentData,
    protected route: ActivatedRoute,
    protected unsavedChangedService: UnsavedChangesService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private fb: FormBuilder,
    public cdRef: ChangeDetectorRef,
    private operationalFileStepService: OperationalFileStepsService) {
    super(route, unsavedChangedService);
  }

  ngOnInit(): void {
    this.createForm();
    this.loadSteps();
  }

  private createForm(): void {
    this.detailsForm = this.fb.group({
      steps: this.fb.array([])
    });
  }

  private loadSteps(): void {
    this.operationalFileStepService.getStepsWithMissedLinkedParties(this.data.operationalFileId)
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(steps => {
        const stepsControl: FormArray = this.detailsForm.get("steps") as FormArray;
        steps.forEach(s => {
          const step = {
            id: [s.id],
            description: [s.description, [Validators.required, Validators.maxLength(1024)]],
          };

          stepsControl.push(this.fb.group(step));
        });
      });
  }

  protected saveInternal() {
    const steps: OperationalFIleStepDescriptionDTO[] = this.detailsForm
      .get("steps").value
      .map(step => ({
        id: step.id,
        description: step.description,
      }));

    this.operationalFileStepService.updateStepsWithMissedLinkedParties(this.data.operationalFileId, steps)
      .pipe(takeUntil(this.unsubscribe)).subscribe(response => {
        this.toaster.success(this.translate.instant("MESSAGES.CHANGES_SAVED"));
        this.confirm();
      });
  }

  public cancel() {
    return this.dialogRef.close({ isConfirmed: false });
  }

  private confirm() {
    return this.dialogRef.close({ isConfirmed: true });
  }
}
