import { UnsavedChangesOptions } from "./enums/unsaved-changes-options.enum";

export class UnsavedChangesDialogResultData {
  public response: UnsavedChangesOptions;
}

export class UnsavedChangesDialogData {
  public isSaveAllowed: boolean;
  public confirmationText: string;
  public dialogTitle: string;
}