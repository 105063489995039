import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ConfirmBase } from "../abstractions/confirm-base.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ConfirmDialog } from "../confirm/confirm.component";
import { ConfirmDescriptionDropDownDialogData, ConfirmDescriptionDropDownResultData } from "../../../core/models/dialogs/confirm-dialog-data";
import { NamedLookup } from "../../../core/models/masterdata/lookup";
import { LoadingOverlayService } from "../../../core/services/loading-overlay/loading-overlay.service";
import { map, takeUntil } from "rxjs/operators";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";

@Component({
  selector: "confirm-drop-down-with-description",
  templateUrl: "./confirm-drop-down-with-description.component.html",
  styleUrls: ["./confirm-drop-down-with-description.component.scss"]
})
// tslint:disable-next-line: component-class-suffix
export class ConfirmDropDownWithDescriptionDialog extends ConfirmBase implements OnInit {

  public submitted: boolean = false;
  public options: NamedLookup[];
  public confirmForm: UntypedFormGroup;
  public reasonEnabled: boolean = false;
  public reasonActive: boolean = false;
  private readonly amendment: string = "Amendment";

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDescriptionDropDownDialogData,
    public overlay: LoadingOverlayService,
    private fb: UntypedFormBuilder
  ) {
    super(dialogRef, data);

    this.reasonEnabled = data.reasonEnabled;
  }

  public ngOnInit(): void {
    this.createForm();
    this.overlay.startLoading();

    this.data.namedLookups$.
      pipe(
        map(x => this.options = x),
        map(() => {
          // tslint:disable-next-line:no-shadowed-variable
          let selectedOption: NamedLookup;
          if (this.data.selectedValue && this.options) {
            selectedOption = this.options.find(o => o.id === this.data.selectedValue);
          } else {
            selectedOption = this.options && this.options.length > 0 ? this.options[0] : null;
          }

          this.confirmForm.patchValue({ selectedOption: selectedOption });
          this.overlay.stopLoading();
        }),
      ).subscribe();


    const selectedOption = (this.confirmForm.get("selectedOption").value as NamedLookup);
    if (selectedOption) {
      this.toggleReasonActive(selectedOption.name);
    }
  }

  public confirm() {
    this.submitted = true;
    if (!this.confirmForm.valid) {
      return;
    }

    const dialogResult: ConfirmDescriptionDropDownResultData = {
      isConfirmed: true,
      option: this.confirmForm.get("selectedOption").value as NamedLookup,
      reason: this.confirmForm.controls.reason.value
    };

    this.dialogRef.close(dialogResult);
  }

  private createForm(): void {
    this.confirmForm = this.fb.group({
      selectedOption: [null, [Validators.required]],
      reason: [null]
    });
  }

  public changeSelectedOption(event: any) {
    this.toggleReasonActive(event.name);
  }

  private toggleReasonActive(optionName: string) {
    this.reasonActive = this.reasonEnabled && optionName && optionName.toLowerCase() === this.amendment.toLowerCase();
  }
}
