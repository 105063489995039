import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "../../content/layout/toaster/toaster.service";

@Directive({
  selector: "[numbersOnlyPrecision]"
})
export class NumbersOnlyPrecisionDirective {
  // ALSO ACCEPTS '+' for whole numbers, which stands for 'infinite'.
  @Input() public precision: string = "10:6";
  @Input() public separators: string = "[\\.,]";

  // Need for control value accessor
  @Input() public updateFunction: Function = null;
  @Input() public setValueFunction: Function = null;

  private get wholeNumbers(): number {
    const whole = this.precision?.split(":")[0];
    return whole !== "+" ? +whole : Number.MAX_SAFE_INTEGER;
  }

  private previousValue = null;

  private get decimals(): number {
    return +this.precision?.split(":")[1] ?? 6;
  }

  public get precisionRegex() {
    return `-?(\\d{0,${this.wholeNumbers}}(${this.separators}\\d{0,${this.decimals}})?)`;
  }
  constructor(private el: ElementRef, private toaster: ToasterService, private translate: TranslateService, private control: NgControl) { }

  private run() {
    setTimeout(() => {
      const currentValue: string = this.el.nativeElement.value;

      if (this.previousValue === currentValue) {
        return;
      }

      this.previousValue = currentValue;

      if (currentValue !== "" && !(this.check(currentValue)?.length > 0)) {
        this.el.nativeElement.value = this.findIdealMatch(currentValue);
        this.warnUser();
      }

      if (this.el.nativeElement.value && this.control.control) {
        this.control.control.setValue(this.el.nativeElement.value);
        this.control.control.updateValueAndValidity();

        if (this.setValueFunction) {
          this.setValueFunction(this.el.nativeElement.value);
        }

        if (this.updateFunction) {
          this.updateFunction();
        }
      }
    });
  }


  private check(value: string) {
    return String(value).match(new RegExp("^" + this.precisionRegex + "$"));
  }

  private findIdealMatch(value: string) {
    const regexSeperators = new RegExp(this.separators);
    let [convertedWhole, convertedDecimals, seperator] = ["", "", ""];
    const minus = value.includes("-") ? "-" : "";
    value = value.replace("-", "");

    if (value.match(regexSeperators)) {
      const [whole, decimals] = value.split(regexSeperators);
      convertedWhole = whole?.length > this.wholeNumbers ? whole.substring(0, this.wholeNumbers) : whole ? whole : "0";
      convertedDecimals = decimals?.length > this.decimals ? decimals.substring(0, this.decimals) : decimals;
      seperator = decimals ? "." : "";
    } else {
      convertedWhole = value.length > this.wholeNumbers ? value.substring(0, this.wholeNumbers) : value;
    }

    return `${minus}${convertedWhole}${seperator}${convertedDecimals}`;
  }

  private warnUser() {
    if (this.wholeNumbers !== Number.MAX_SAFE_INTEGER) {
      this.toaster.warning(this.translate.instant("VALIDATION.MAXIMUM_WHOLES_DECIMALS", { name: "This field", decimals: this.decimals, wholes: this.wholeNumbers }));
    } else {
      this.toaster.warning(this.translate.instant("VALIDATION.MAXIMUM_DECIMALS", { name: "This field", decimals: this.decimals }));
    }
  }

  @HostListener("keydown", ["$event"])
  public onKeyDown(_: KeyboardEvent) {
    this.run();
  }

  @HostListener("paste", ["$event"])
  public onPaste(_: ClipboardEvent) {
    this.run();
  }
}
