import { Component, Input, forwardRef, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors } from "@angular/forms";
import { DateInputsHelperService } from "../../../core/helpers/date-inputs-helper.service";
import { NgbDateHelper } from "../../../core/helpers/ngb-date-helper.service";
import { NgbDateStruct, NgbDateParserFormatter, NgbCalendar, NgbDatepicker, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { BelgianNgbDateParserFormatter } from "./belgian-ngbdate-parser-formatter.service";
import { DateInputsErrorConfig } from "../../../core/helpers/date-inputs-error-config";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "../../layout/toaster/toaster.service";

@Component({
  selector: "date-input",
  templateUrl: "./date-input.component.html",
  styleUrls: ["./date-input.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true
    },
    { provide: NgbDateParserFormatter, useClass: BelgianNgbDateParserFormatter }
  ]
})
export class DateInputComponent implements ControlValueAccessor {

  constructor(
    public dateInputHelper: DateInputsHelperService,
    public ngbDateHelper: NgbDateHelper,
    private calendar: NgbCalendar,
    private toaster: ToasterService,
    private translateService: TranslateService
  ) { }
  @ViewChild("datePicker") public datePicker: ElementRef<NgbDatepicker>;

  @Input()
  public tabIndex: number;

  // Possible values are "top", "top-left", "top-right", "bottom", "bottom-left", "bottom-right", "left", "left-top", "left-bottom", "right", "right-top", "right-bottom"
  @Input()
  public placement: string = "top-left";

  public selectedDate: string;
  public ngbDate: NgbDate;
  public isDisabled: boolean;
  public isDisabledTodayButton: boolean;
  public onChange: (_: any) => void;

  @Input()
  public startDate: NgbDateStruct;

  @Input()
  public formErrors: ValidationErrors | null;

  @Input()
  public errorConfig: DateInputsErrorConfig | null;

  @Input()
  public minDate: NgbDateStruct;

  @Input()
  public maxDate: NgbDateStruct;

  @Input()
  public onlyAllowDateCharacters: boolean = false;

  public onInput(event: any) {
    let dateString: string = event.target.value?.toString().replaceAll('.', '-').replaceAll('/', '-').replaceAll('\\', '-');
    let splittedDateString = dateString.split("-").filter(x => x);

    if (dateString && (splittedDateString.length == 3 && splittedDateString.some(x => x.length > 3) && splittedDateString.every(x => x.length >= 2))) {
      const regex = new RegExp(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/, "g");

      // check if date is valid
      if (!regex.test(dateString)) {
        event.target.value = this.selectedDate;
        this.toaster.warning(this.translateService.instant("VALIDATION.WARNING_DATE_FORMAT", { date: dateString }));
      }
      else {
        event.target.value = dateString;
      }
    }
  }

  public onDateSelected(dateStruct: NgbDateStruct) {
    this.updateChanges(dateStruct);
  }

  public todaySelected(): void {
    const date = this.calendar.getToday();
    this.updateChanges(date);
  }

  public minDateMoreThanToday(): boolean {
    if (!this.minDate) {
      return false;
    }

    const minDate = new Date(this.minDate.year, this.minDate.month - 1, this.minDate.day);

    const todayAsNgbDate = this.calendar.getToday();
    const today = new Date(todayAsNgbDate.year, todayAsNgbDate.month - 1, todayAsNgbDate.day);

    return minDate > today;
  }

  public maxDateLessThanToday(): boolean {
    if (!this.maxDate) {
      return false;
    }

    const maxDate = new Date(this.maxDate.year, this.maxDate.month - 1, this.maxDate.day);

    const todayAsNgbDate = this.calendar.getToday();
    const today = new Date(todayAsNgbDate.year, todayAsNgbDate.month - 1, todayAsNgbDate.day);

    return maxDate < today;
  }

  public onTextChanged(event: any) {
    const dateString = event.target.value;
    const parsedDate = this.dateInputHelper.parseDate(dateString, this.dateInputHelper.dateFormat);

    // check if date is valid
    if (parsedDate && this.dateInputHelper.isDateValid(parsedDate)) {
      this.updateChanges(this.ngbDateHelper.fromModel(parsedDate));
    } else {
      this.onChange(null);
      event.target.value = "";
      this.selectedDate = "";
    }
  }

  public writeValue(newDate: Date): void {
    if (typeof newDate === "string") {
      newDate = new Date(newDate);
    }

    if (!newDate || !(newDate instanceof Date)) {
      this.selectedDate = "";
    } else {
      this.selectedDate = this.dateInputHelper.transformDateInput(newDate);
      this.ngbDate = this.dateInputHelper.toNgbDate(newDate);

      this.setStartDate(this.ngbDate);
    }
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    if (fn) {
      this.onChange = fn;
    }
  }

  public registerOnTouched(fn: any): void { }

  private setStartDate(newStartDate: NgbDateStruct) {
    this.startDate = newStartDate;
  }

  private updateChanges(newDate: NgbDateStruct) {
    let newDateAsDate: Date;
    if (newDate) {
      newDateAsDate = this.ngbDateHelper.toModel(newDate);
      this.selectedDate = this.dateInputHelper.transformDateInput(newDateAsDate);
      const datePicker: any = this.datePicker;

      datePicker._model = newDate;
      datePicker._inputValue = this.selectedDate;

      this.setStartDate(newDate);
    }

    this.onChange(newDateAsDate.toUTCString());

  }
}
