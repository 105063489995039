import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class OperationalFileEditIdHelper {
  public getOperationalFileId(route: ActivatedRoute) {

    // TODO(jos): refactor
    let returnVal: number;
    returnVal = route?.parent?.parent?.parent?.parent?.snapshot?.params?.id;

    if (!returnVal) {
      returnVal = route?.parent?.parent?.parent?.snapshot?.params?.id;
    }
    if (!returnVal) {
      returnVal = route?.parent?.parent?.snapshot?.params?.id;
    }
    if (!returnVal) {
      returnVal = route?.snapshot?.parent?.params?.id;
    }
    if (!returnVal) {
      returnVal = route?.snapshot?.params?.id;
    }
    return returnVal;
  }

  public getOperationalFileId2(route: ActivatedRouteSnapshot) {
    let returnVal: number;
    returnVal = route.parent.parent.parent.parent.params.id;

    if (!returnVal) {
      returnVal = route.parent.parent.parent.params.id;
    }
    if (!returnVal) {
      returnVal = route.parent.parent.params.id;
    }
    if (!returnVal) {
      returnVal = route.parent.params.id;
    }
    if (!returnVal) {
      returnVal = route.params.id;
    }
    return Number(returnVal);
  }
}
