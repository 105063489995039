import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ContainerGoodsDTO } from "../services/swagger-gen/fordesk";

@Pipe({
  name: "containerGoodsLabel",
  pure: true
})
export class ContainerGoodsLabelPipe implements PipeTransform {
  constructor(
    private translate: TranslateService
  ) { }

  public transform(value: ContainerGoodsDTO, args?: any): any {
    if (!value) {
      return this.translate.instant("OPERATIONAL.CONTAINERS.NO_DESCRIPTION");
    }
    const part1 = value.containerNumber ? `${value.containerNumber} - `
      : value.containerIsoCode && value.containerIsoCode.code ? `${value.containerIsoCode.code} - ` : "";
    const part2 = value.goodsDescription ? value.goodsDescription : value.grossWeight
      ? `${value.grossWeight}` : this.translate.instant("OPERATIONAL.CONTAINERS.NO_DESCRIPTION");

    return `${part1}${part2}`;
  }
}
