import { NgOption } from "@ng-select/ng-select";
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { ToasterService } from "../../../../content/layout/toaster/toaster.service";
import { MessageHelper } from "../../../helpers/message-helper";
import { DialogResult } from "../../../enumerations/dialog-result";
import { ConfirmDialog } from "../../../../content/dialogs/confirm/confirm.component";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogData, ConfirmDialogResultData } from "../../../models/dialogs/confirm-dialog-data";
import { map, shareReplay, takeUntil, tap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { LookupDTO, VesselDTO, VesselsService } from "../../../services/swagger-gen/fordesk";
import { MasterDataStatusEnum } from "../../../enumerations/master-data-status.enum";

export const VesselSelectComponentSelector: string = "formly-vessel-select";

const cacheSize = 3;

@Component({
  selector: VesselSelectComponentSelector,
  templateUrl: "./formly-vessel-select.component.html",
  styleUrls: ["./formly-vessel-select.component.scss"]
})
export class FormlyVesselSelectComponent extends FieldType implements OnInit, AfterViewInit, OnDestroy {
  public loading: boolean;
  public mappedOptions: NgOption[] = [];

  private latestSearchTerm: string;

  public selectionCache: number[] = [];

  constructor(
    private toaster: ToasterService,
    private messageHelper: MessageHelper,
    private vesselsService: VesselsService,
    public cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private translate: TranslateService) {
    super();
  }

  private unsubscribe: Subject<void> = new Subject();
  public ngOnDestroy() {
    if (!this.unsubscribe) {
      return;
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public ngOnInit() {
    this.loading = true;

    this.getAllVessels()
      .subscribe({
        next: res => {
          this.mappedOptions = res;
          // Cast number of string to number via "+" operator
          const selectedValue: number = typeof this.key === "string" || typeof this.key === "number" ? +this.model[this.key] : null;
          if (selectedValue && !isNaN(selectedValue)) {
            this.formControl.setValue(selectedValue);
          } else {
            this.formControl.reset();
          }
        },
        error: () => this.toaster.error(this.messageHelper.getGenericDialogResultMessage(DialogResult.Error)),
        complete: () => this.loading = false
      });
  }

  public ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  private getAllVessels(): Observable<NgOption[]> {
    const selectedValue: number = typeof this.key === "string" || typeof this.key === "number" ? +this.model[this.key] : null;
    this.selectionCache.push(selectedValue);

    return this.vesselsService.getAll()
      .pipe(
        map(data => data ? data.results : []),
        map(data => data.filter(x => x.masterDataStatus !== MasterDataStatusEnum.Blocked || this.selectionCache.includes(x.id))),
        map(data => {
          data.push({ id: 0, description: `< ${this.translate.instant("MASTERDATA.VESSELS.ADD_VESSEL")} >` } as LookupDTO);
          return data;
        }),
        map(data => this.mapToNgOptions(data)),
        shareReplay(cacheSize), takeUntil(this.unsubscribe)
      );
  }

  private mapToNgOptions(lookup: LookupDTO[]): NgOption[] {
    return lookup.map(l => <NgOption>{
      id: l.id,
      description: l.description
    });
  }

  public vesselSearch(search: string, item: NgOption): boolean {
    // custom search function to always include the "< Add Vessel >" option in the search result
    search = search.toLocaleLowerCase();
    return item.description.toLocaleLowerCase().includes(search) || item.id === 0;
  }

  public selectionChanged(item: NgOption): void {
    if (!item) {
      return;
    }

    if (item.id === 0) {
      // User selected "Add vessel", show popup to ask to confirm to add the vessel

      if (!this.latestSearchTerm || (this.latestSearchTerm && this.latestSearchTerm.trim().length === 0)) {
        this.toaster.error(this.translate.instant("MASTERDATA.VESSELS.CAN_NOT_BE_EMPTY"));
        this.formControl.reset();
        return;
      }

      const dialogData: ConfirmDialogData = {
        dialogTitle: this.translate.instant("MASTERDATA.VESSELS.ADD_VESSEL"),
        confirmationText: this.translate.instant("MASTERDATA.VESSELS.CONFIRM_NEW_VESSEL", { name: this.latestSearchTerm })
      };

      const dialogRef = this.dialog.open(ConfirmDialog, { disableClose: true, data: dialogData });

      dialogRef
        .afterClosed()
        .subscribe((data: ConfirmDialogResultData) => {
          if (data?.isConfirmed) {
            const addRequest: VesselDTO = {
              name: this.latestSearchTerm,
              lloydNr: null
            };

            this.vesselsService
              .addVessel(addRequest)
              .subscribe(() => {
                this.toaster.success(this.translate.instant("MESSAGES.NEW_ITEM_WITH_NAME_ADDED", { name: addRequest.name }));

                this.getAllVessels()
                  .subscribe({
                    next: res => {
                      this.mappedOptions = res;
                      const vessel = this.mappedOptions.find(x => x.description === addRequest.name);
                      if (vessel) {
                        this.formControl.setValue(vessel.id);
                      } else {
                        this.formControl.reset();
                      }
                    },
                    error: () => this.toaster.error(this.messageHelper.getGenericDialogResultMessage(DialogResult.Error)),
                  });
              });
          } else {
            this.formControl.reset();
          }
        });
    }
  }

  public onSearched($event: any) {
    if (!$event && !$event.term) {
      return;
    }

    this.latestSearchTerm = $event.term;
  }
}
