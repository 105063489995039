import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ConfirmBase } from "../abstractions/confirm-base.component";
import { ConfirmDialogTextValueData } from "../../../core/models/dialogs/confirm-dialog-data";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ConfirmTextDialogResultData } from "../../../core/models/dialogs/confirm-with-text-dialog-result-data";

@Component({
  selector: "confirm-text-value",
  templateUrl: "./confirm-text-value.component.html",
  styleUrls: ["./confirm-text-value.component.scss"]
})
export class ConfirmTextValueComponent extends ConfirmBase implements OnInit {
  public form: UntypedFormGroup;
  public submitted: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmTextValueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogTextValueData, private fb: UntypedFormBuilder) {
    super(dialogRef, data);
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      text: [this.data.defaultValue, [
        Validators.required,
        Validators.maxLength(this.data.maxLength)
      ]]
    });
  }

  public confirm() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }

    const dialogResult: ConfirmTextDialogResultData = {
      isConfirmed: true,
      text: this.form.value.text
    };

    this.dialogRef.close(dialogResult);
  }
}
