import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { NgOption } from "@ng-select/ng-select";
import { map, shareReplay, switchMap } from "rxjs/operators";
import {
  AirportsService,
  CompanyAddressDTO,
  CompanyAddressesService,
  IncotermsService,
  LookupDTO,
  LookupResponse,
  MasterdataLookupDTO,
  OperationalFileFullDetailsDTO,
  PortDTO,
  PortsService,
  TerminalsService,
  VesselsService
} from "../../../../services/swagger-gen/fordesk";
import { MasterDataStatusEnum } from "../../../../enumerations/master-data-status.enum";

const cacheSize = 3;

// TODO: rename this class with kebab-case
@Injectable({ providedIn: "root" })
export class SelectOptionsService {
  constructor(
    private terminalsService: TerminalsService,
    private vesselService: VesselsService,
    private portService: PortsService,
    private airPortsService: AirportsService,
    private incotermService: IncotermsService,
    private companyAddressService: CompanyAddressesService
  ) {
  }

  public getOptions(controlKey: string, carrierId?: number, general?: any, operationalFile?: OperationalFileFullDetailsDTO, isAirport?: boolean): Observable<NgOption[] | PortDTO[]> {
    const lowerControlKey: string = controlKey ? controlKey.toLowerCase() : "";

    // TODO: carrierId will disappear eventually, leave this for now
    switch (lowerControlKey) {
      case "airportofdepartureid":
      case "airportofdestinationid":
        return this.createAirportsLookup(general[controlKey]);
      case "placeofdestinationid":
      case "portofdischargeid":
      case "portofloadingid":
      case "placeofreceiptid":
        return this.createSeaportsLookup(general[controlKey]);
      case "terminalid":
        return this.createTerminalsLookup(general, operationalFile, isAirport);
      case "vesselid":
        return this.createVesselsLookup(general[controlKey]);
      case "incotermid":
        return this.createIncotermsLookup(general[controlKey]);
      case "carrierid":
        return this.createCarrierLookup(carrierId);
      default:
        return of([]);
    }
  }

  private createTerminalsLookup(general: any, operationalFile: OperationalFileFullDetailsDTO, isAirport: boolean): Observable<NgOption[]> {

    const terminalId = general.TerminalId;

    const currentPortForTerminalId = this.getCurrentPortForTerminalId(general, operationalFile.operationalFileType, isAirport);

    return this.terminalsService.getAll(currentPortForTerminalId)
      .pipe(
        map(data => data ? data.results : []),
        map(data => data.map(lookup => {
          return {
            id: lookup.id,
            description: lookup.description,
            masterDataStatus: lookup.masterDataStatus
          };
        })),
        map(list => list.filter(dto => dto.masterDataStatus === MasterDataStatusEnum.Active || dto.id === +terminalId)),
        switchMap((data: MasterdataLookupDTO[]) => {

          if (!terminalId || data.some(x => x.id === +terminalId)) {
            return of(data);
          } else {
            return this.terminalsService.getTerminalById(+terminalId).pipe(
              map(terminal => {
                data = data ?? [];

                data.push({
                  id: terminal.id,
                  description: terminal.description,
                  masterDataStatus: terminal.masterDataStatus
                });

                return data;
              })
            );
          }
        }),
        shareReplay(cacheSize)
      );
  }

  private getCurrentPortForTerminalId(general: any, operationalFileType: OperationalFileFullDetailsDTO.OperationalFileTypeEnum, isAirport: boolean): any {
    let result: any = null;

    const isImport = operationalFileType === OperationalFileFullDetailsDTO.OperationalFileTypeEnum.NUMBER_0;
    const isExport = operationalFileType === OperationalFileFullDetailsDTO.OperationalFileTypeEnum.NUMBER_1;

    if (isImport) {
      result = isAirport ? general.AirportOfDestinationId : general.PortOfDischargeId;
    } else if (isExport) {
      result = isAirport ? general.AirportOfDepartureId : general.PortOfLoadingId;
    }

    return result;
  }

  private createSeaportsLookup(portId?: number): Observable<NgOption[]> {
    return this.portService.getPorts()
      .pipe(
        map(data => data ? data.results : []),
        map(list => list.filter(dto => dto.masterDataStatus !== MasterDataStatusEnum.Blocked || dto.id === +portId)),
        map(data => this.mapToNgOptions(data)),
        shareReplay(cacheSize)
      );
  }

  private createAirportsLookup(portId?: number): Observable<PortDTO[]> {
    return this.airPortsService.getAirportFullInformation()
      .pipe(
        map(data => data ? data.results : []),
        map(list => list.filter(dto => dto.masterDataStatus !== MasterDataStatusEnum.Blocked || dto.id === +portId)),
        shareReplay(cacheSize)
      );

  }

  private createVesselsLookup(vesselId?: number): Observable<NgOption[]> {
    return this.vesselService.getAll()
      .pipe(
        map(data => data ? data.results : []),
        map(list => list.filter(dto => dto.masterDataStatus !== MasterDataStatusEnum.Blocked || dto.id === +vesselId)),
        map(data => this.mapToNgOptions(data)),
        shareReplay(cacheSize)
      );
  }

  private createIncotermsLookup(incotermId: number): Observable<NgOption[]> {
    return this.incotermService.getAllIncoterms()
      .pipe(
        map(data => data ? data.results : []),
        map(list => list.filter(dto => dto.masterDataStatus !== MasterDataStatusEnum.Blocked || dto.id === +incotermId)),
        map(data => this.mapToNgOptions(data)),
        shareReplay(cacheSize)
      );
  }

  private createCarrierLookup(carrierId: number): Observable<NgOption[]> {
    // TODO: temporary solution, carrier id will eventually be removed
    if (!carrierId) {
      return of([]);
    }

    return this.companyAddressService.getCompanyAddress(carrierId)
      .pipe(
        map((data: CompanyAddressDTO) => {
          const addresses: CompanyAddressDTO[] = data ? [data] : [];

          return addresses.map(ca => this.mapCompanyAddressToNgOption(ca));
        }),
        shareReplay(cacheSize)
      );
  }

  private mapToNgOptions(lookup: LookupDTO[]): NgOption[] {
    return lookup.map(l => <NgOption>{
      id: l.id,
      description: l.description
    });
  }

  private mapCompanyAddressToNgOption(companyAddress: CompanyAddressDTO): NgOption {
    let description: string;

    if (companyAddress.alias) {
      description = companyAddress.alias;

      if (companyAddress.companyName) {
        description = `${companyAddress.companyName} - ${description}`;
      }
    } else {
      description = `${companyAddress.companyName} - ${companyAddress.addressLine1}`;
    }

    if (companyAddress.vatNumber) {
      description = description.concat(` - ${companyAddress.vatNumber}`);
    }

    return <NgOption>{
      id: companyAddress.id,
      description: description
    };
  }
}
