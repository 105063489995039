export const budget_per_activity_code_overview_locale = {
  TITLE: "Budget per activity code",
  FILE_NUMBER: "Operational file number",
  CREATEDAT: "Created on",
  CUSTOMER: "Customer",
  CODE: "Activity code",
  DESCRIPTION: "Activity description",
  BUDGET_COST: "Budget costs",
  BUDGET_REVENUE: "Budget revenues",
  BUDGET_RESULT: "Budget result",
  ACTUAL_COSTS: "Actualised costs",
  ACTUAL_REVENUES: "Actualised revenues",
  ACTUAL_RESULT: "Actualised result",
  CUSTOMERS: "Customer",
  FILENUMBERS: "Operational file number",
  CREATIONDATES: "Creation date",
  ACTIVITYCODES: "Activity code",
  ACTIVITYDESCRIPTIONS: "Activity description",
  FILETYPES: "Operational file type",
  SHIPMENTTYPES: "Shipment type",
  TRANSPORTTYPES: "Transport type"
};
