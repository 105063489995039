/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CompanyAddressDTO } from './companyAddressDTO';
import { DateRange } from './dateRange';
import { LookupDTO } from './lookupDTO';

export interface OperationalSummaryPerUserFilter { 
    pageSize?: number;
    page?: number;
    readonly listSortDirection?: OperationalSummaryPerUserFilter.ListSortDirectionEnum;
    sortDirection?: string;
    sortProperty?: string;
    customer?: Array<CompanyAddressDTO>;
    carrier?: Array<CompanyAddressDTO>;
    creationDate?: DateRange;
    fileDate?: DateRange;
    incoterms?: Array<LookupDTO>;
    userName?: Array<string>;
    readonly incotermIds?: Array<number>;
    readonly carrierIds?: Array<number>;
    readonly customerIds?: Array<number>;
}
export namespace OperationalSummaryPerUserFilter {
    export type ListSortDirectionEnum = 0 | 1;
    export const ListSortDirectionEnum = {
        NUMBER_0: 0 as ListSortDirectionEnum,
        NUMBER_1: 1 as ListSortDirectionEnum
    };
}