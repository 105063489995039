export const of_party_locale = {
  ADD: "Add a party",
  EDIT: "Edit a party",
  TYPE: "Party type",
  COMPANY_NAME: "Company name",
  COMPANY_MASTERDATA: "Company (masterdata)",
  CONTACT_PERSON: "Contact Person",
  CUSTOMER_LABEL: "(Customer)",
  LOCATION_LABEL: "(Location)",
  SUPPLIER_LABEL: "(Supplier)",
  NO_CONTACT_PERSONS_FOUND: "No contact persons found for this company",
  CONTACT_PERSON_FORBIDDEN_FOR_TERMINALS: "Contact person cannot be selected for terminals",
  CONTACT_PERSON_FORBIDDEN_FOR_DEPOTS: "Contact person cannot be selected for depots",
  SELECT_COMPANY_FIRST: "Please select a company first",
  COMPANY_FREE_INPUT: "Company (free input)",
  REMARKS: "Remarks",
  CLIENTNUMBER: "Client no.",
  VAT_NO: "VAT no.",
  DETAILS: "Details",
  ADD_TITLE: "Add a new party",
  MSG_PARTY_ADDED: "Party has been successfully added.",
  USE_STRUCTURED_ADDRESS: "Use structured address",
  GOODS_OWNER: "Goods owner",
  SET_GOODS_OWNER: "Set goods owner",
  STATUS: {
    STATUS: "Status",
    STATUS_ACTIVE: "Active",
    STATUS_BLOCKED: "Blocked",
  },
  VALIDATION: {
    COMPANY_OR_DETAILS_REQUIRED: "Either a MasterData company must be chosen or a manually input company provided.",
    COMPANY_MASTERDATA_REQUIRED: "MasterData company must be chosen."
  },
  MESSAGES: {
    PARTIES_CANNOT_BE_DELETED: "Please remove all budget linked to {{parties}} if you want to remove this party.",
    DETAILS_OUT_OF_BOUNDS: "For best results on the BL, we recommend not exceeding 6 lines and 35 characters per line for the party's details."
  },
  MODALS: {
    GOODS_OWNER_MESSAGE: "Changing goods ownership may impact open or finalized outgoing invoices. You will have to manually check the tax codes on the invoice lines of these outgoing invoices."
  },
  INTTRA_PREVIEW: {
    TITLE: "INTTRA preview",
    DESCRIPTION: "This is how this address will look when sent to INTTRA.",
    COMPANY_NAME_SECTION: "Company name:",
    ADDRESS_SECTION: "Address:",
    TRUNCATED_ADDRESS_WARNING: "Some address data will not be sent to INTTRA."
  }
};
