import { Component, Input } from "@angular/core";
import { PortDTO } from "../../../../services/swagger-gen/fordesk";

@Component({
  selector: "port-label",
  templateUrl: "./port-label.component.html"
})
export class PortLabelComponent {
  @Input()
  public item: PortDTO;

  constructor() { }
}
