import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import AddGridDialogData from "../../../models/utilities/grid-layouts/add-grid-dialog-data.model";
import { AddGridLayoutDetailsForm } from "../../../models/utilities/grid-layouts/add-grid-layout.model";
import { UnsavedChangesService } from "../../../services/helpers/unsaved-changes.service";
import { GridLayoutsService, NewGridLayoutDTO, UpdatedGridLayoutDTO } from "../../../services/swagger-gen/fordesk";
import { ColumnsCacheService } from "../../../services/utilities/columns-cache.service";
import { FilterCacheService } from "../../../services/utilities/filter-cache.service";
import { GridLayoutCacheService } from "../../../services/utilities/grid-layout-cache.service";
import { GridType } from "../../../types/grid-type.type";
import { EntityDetailsComponent } from "../../abstractions/entity-details.component";
import { GridLayoutUpserted } from "../../../models/utilities/grid-layouts/grid-layout-upserted.model";

@Component({
  selector: "add-grid-layout",
  templateUrl: "./add-grid-layout.component.html",
  styleUrls: ["./add-grid-layout.component.scss"]
})
export class AddGridLayoutComponent extends EntityDetailsComponent implements OnInit {
  public hasAdminRole: boolean = false;
  public isShared: boolean = false;
  public gridType: GridType;
  public isLoggedInUserCreator: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AddGridDialogData,
    private dialogRef: MatDialogRef<AddGridLayoutComponent>,
    protected route: ActivatedRoute,
    unsavedChangesService: UnsavedChangesService,
    private fb: UntypedFormBuilder,
    private gridLayoutCacheService: GridLayoutCacheService,
    private filterCacheService: FilterCacheService<string>,
    private columnsCacheService: ColumnsCacheService,
    private gridLayoutService: GridLayoutsService
  ) {
    super(route, unsavedChangesService);
  }

  public ngOnInit(): void {
    this.setDialogData();
    this.createForm();
  }
  private setDialogData() {
    this.hasAdminRole = this.data.hasAdminRole;
    this.isShared = this.data.isShared;
    this.gridType = this.data.gridType;
    this.isLoggedInUserCreator = this.data.isLoggedInUserCreator;
  }

  private createForm(): void {
    this.detailsForm = this.fb.group({
      viewName: [this.data.name, [Validators.required, Validators.maxLength(25)]],
      isShared: [this.hasAdminRole && this.isShared],
    });
    if (!this.isLoggedInUserCreator) {
      this.detailsForm.controls["isShared"].disable();
    }
  }

  public saveInternal(shouldRedirect?: boolean) {
    if (!this.validate()) {
      return;
    }

    const detailsForm: AddGridLayoutDetailsForm = this.detailsForm.getRawValue();
    detailsForm.isShared = this.hasAdminRole && detailsForm.isShared;

    if (this.data.id) {
      const dto: UpdatedGridLayoutDTO = this.mapToUpdatedGridLayoutDTO(detailsForm);

      this.gridLayoutService.update(this.data.id, dto)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          const response: GridLayoutUpserted = {
            shared: dto.shared,
            name: dto.name,
            id: this.data.id
          };

          this.dialogRef.close(response);
        });

    } else {
      const dto: NewGridLayoutDTO = this.mapToNewGridLayoutDTO(detailsForm);

      this.gridLayoutService.add(dto)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          const response: GridLayoutUpserted = {
            shared: dto.shared,
            name: dto.name
          };

          this.dialogRef.close(response);
        });
    }
  }

  private mapToNewGridLayoutDTO(detailsForm: AddGridLayoutDetailsForm) {
    const columnsKey = this.gridLayoutCacheService.mapGridTypeToColumnKeys(this.gridType);
    const filtersKey = this.gridLayoutCacheService.mapGridTypeToFilterKeys(this.gridType);

    const filters = this.filterCacheService.get(filtersKey);
    const columns = this.columnsCacheService.get(columnsKey);

    const dto: NewGridLayoutDTO = {
      name: detailsForm.viewName,
      type: this.gridLayoutCacheService.mapGridTypeToEnum(this.gridType),
      shared: detailsForm.isShared ?? false,
      page: filters?.currentPage ?? 0,
      pageSize: filters?.pageSize ?? 10,
      sortDirection: filters?.sortDirection ?? "desc",
      sortProperty: filters?.sortProperty,
      filters: filters?.filters?.map(x => ({ jsonValue: JSON.stringify(x.value), type: x.type ?? x.description })),
      displayedColumns: columns?.displayedColumns
    };

    return dto;
  }

  private mapToUpdatedGridLayoutDTO(detailsForm: AddGridLayoutDetailsForm): UpdatedGridLayoutDTO {
    const dto: UpdatedGridLayoutDTO = {
      name: detailsForm.viewName,
      shared: detailsForm.isShared
    };

    return dto;
  }

  public cancel() {
    this.dialogRef.close(null);
  }
}
