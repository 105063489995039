import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take, takeUntil } from 'rxjs/operators';
import { ReleaseNoteDTO, ReleaseNoteService } from '../../../core/services/swagger-gen/fordesk';
import { ComponentBase } from '../../../core/components/abstractions/component-base';

export class ReleasePreviewData {
  public releases: ReleaseNoteDTO[] = [];
  public title: string = "";
}

@Component({
  selector: 'release-preview-dialog',
  templateUrl: './release-preview-dialog.component.html',
  styleUrls: ['./release-preview-dialog.component.scss']
})
export class ReleasePreviewDialogComponent extends ComponentBase implements OnInit {
  public releases: ReleaseNoteDTO[] = [];
  public previewHtml: string = "";
  public activeReleaseId: number = 0;
  public title: string = "";

  constructor(public dialogRef: MatDialogRef<ReleasePreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReleasePreviewData,
    private releaseService: ReleaseNoteService) {
    super();
  }

  ngOnInit(): void {
    this.releases = this.data.releases ?? [];
    this.title = this.data.title;

    if (this.releases && this.releases.length > 0) {
      this.getReleasePreview(this.releases[0].id);
    }
  }

  public cancel() {
    this.dialogRef.close();
  }

  public getReleasePreview(releaseId: number) {
    if (this.activeReleaseId === releaseId) {
      return;
    }

    this.releaseService.getReleaseNotePreview(releaseId).pipe(take(1),
      takeUntil(this.unsubscribe)).subscribe(html => {
        this.previewHtml = html;

        this.releaseService.addUserToViewedReleaseNote(releaseId).pipe(take(1),
          takeUntil(this.unsubscribe)).subscribe();
      });

    this.activeReleaseId = releaseId;
  }
}
