import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TenantConfigurationService } from "../../core/services/client-tenant-configuration/tenant-config.service";

@Component({
  selector: "default-page-routing",
  template: "",
})
export class DefaultPageRoutingComponent implements OnInit {
  constructor(
    private router: Router,
    private tenantConfig: TenantConfigurationService) { }

  public ngOnInit() {
    this.redirectToDefaultPage();
  }

  redirectToDefaultPage() {
    this.tenantConfig.initialize()
      .subscribe(config => {
        if (config?.moduleSettings?.dashboards) {
          this.router.navigate(['/dashboards']);
        } else {
          this.router.navigate(['/operational/files']);
        }
      });
  }
}
