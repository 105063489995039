/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DateRange } from './dateRange';

export interface OperationalFileSearchCondition { 
    pageSize?: number;
    page?: number;
    readonly listSortDirection?: OperationalFileSearchCondition.ListSortDirectionEnum;
    sortDirection?: string;
    sortProperty?: string;
    fileNumber?: Array<string>;
    billOfLading?: Array<string>;
    customer?: Array<string>;
    customerReference?: Array<string>;
    containerNumber?: Array<string>;
    booking?: Array<string>;
    carrier?: Array<string>;
    vessel?: Array<string>;
    portOfLoading?: Array<string>;
    currency?: Array<number>;
    portOfDischarge?: Array<string>;
    houseBlNr?: Array<string>;
    incoterms?: Array<string>;
    airwayBillNr?: Array<string>;
    flightNr?: Array<string>;
    stepsCompletion?: Array<OperationalFileSearchCondition.StepsCompletionEnum>;
    fileType?: Array<OperationalFileSearchCondition.FileTypeEnum>;
    shipmentType?: Array<OperationalFileSearchCondition.ShipmentTypeEnum>;
    transportType?: Array<OperationalFileSearchCondition.TransportTypeEnum>;
    status?: Array<OperationalFileSearchCondition.StatusEnum>;
    creationDate?: Array<DateRange>;
    fileDate?: Array<DateRange>;
    isBudgetClosed?: Array<boolean>;
    showOnlyMyFiles?: boolean;
    showOnlyDeadlineExpired?: boolean;
}
export namespace OperationalFileSearchCondition {
    export type ListSortDirectionEnum = 0 | 1;
    export const ListSortDirectionEnum = {
        NUMBER_0: 0 as ListSortDirectionEnum,
        NUMBER_1: 1 as ListSortDirectionEnum
    };
    export type StepsCompletionEnum = '0' | '1' | '2';
    export const StepsCompletionEnum = {
        _0: '0' as StepsCompletionEnum,
        _1: '1' as StepsCompletionEnum,
        _2: '2' as StepsCompletionEnum
    };
    export type FileTypeEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6';
    export const FileTypeEnum = {
        _0: '0' as FileTypeEnum,
        _1: '1' as FileTypeEnum,
        _2: '2' as FileTypeEnum,
        _3: '3' as FileTypeEnum,
        _4: '4' as FileTypeEnum,
        _5: '5' as FileTypeEnum,
        _6: '6' as FileTypeEnum
    };
    export type ShipmentTypeEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6';
    export const ShipmentTypeEnum = {
        _0: '0' as ShipmentTypeEnum,
        _1: '1' as ShipmentTypeEnum,
        _2: '2' as ShipmentTypeEnum,
        _3: '3' as ShipmentTypeEnum,
        _4: '4' as ShipmentTypeEnum,
        _5: '5' as ShipmentTypeEnum,
        _6: '6' as ShipmentTypeEnum
    };
    export type TransportTypeEnum = '0' | '1' | '2' | '3';
    export const TransportTypeEnum = {
        _0: '0' as TransportTypeEnum,
        _1: '1' as TransportTypeEnum,
        _2: '2' as TransportTypeEnum,
        _3: '3' as TransportTypeEnum
    };
    export type StatusEnum = '0' | '1' | '2';
    export const StatusEnum = {
        _0: '0' as StatusEnum,
        _1: '1' as StatusEnum,
        _2: '2' as StatusEnum
    };
}