/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TransportStopLocationDetailsDTO { 
    addressLine1?: string;
    addressLine2?: string;
    placeId?: number;
    countryId?: number;
    zipCode?: string;
    stateProvinceRegion?: string;
    stopLocationId?: number;
    stopLocationType?: TransportStopLocationDetailsDTO.StopLocationTypeEnum;
    portId?: number;
}
export namespace TransportStopLocationDetailsDTO {
    export type StopLocationTypeEnum = 0 | 1 | 2 | 3 | 4;
    export const StopLocationTypeEnum = {
        NUMBER_0: 0 as StopLocationTypeEnum,
        NUMBER_1: 1 as StopLocationTypeEnum,
        NUMBER_2: 2 as StopLocationTypeEnum,
        NUMBER_3: 3 as StopLocationTypeEnum,
        NUMBER_4: 4 as StopLocationTypeEnum
    };
}