import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupDTO } from '../../../core/services/swagger-gen/fordesk';

@Component({
  selector: 'entities-list-dialog',
  templateUrl: './entities-list-dialog-component.html',
  styleUrls: ['./entities-list-dialog-component.scss']
})
export class EntitiesListDialogComponent implements OnInit, AfterViewInit {

  public contactPersonTypes: LookupDTO[] = [];
  public languages: LookupDTO[] = [];
  constructor(
    public cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<EntitiesListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EntitiesListDialogData) {
  }

  public ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }
  public cancel() {
    this.dialogRef.close(null);
  }

  public navigate(selectedId: number) {
    const url = this.data.navigationUrlWithId.replace('{id}', selectedId.toString());
    // Open the URL in a new tab
    window.open(url, '_blank');
  }
}

export class EntitiesListDialogData {
  public entitesListLabel: string;
  public entitesListDescription: string;
  public navigationUrlWithId: string;
  public entities: ListEntity[];
}

export class ListEntity {
  constructor(label: string, id: number, details?: ListEntityDetails[]) {
    this.label = label;
    this.id = id;
    this.details = details
  }
  public label: string;
  public id: number;
  public details: ListEntityDetails[];
}

export class ListEntityDetails {
  constructor(label: string, value: string) {
    this.label = label;
    this.value = value;
  }
  public label: string;
  public value: string;
}
