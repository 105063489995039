import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { LoadingOverlayService } from "./loading-overlay.service";
import { RequestStorageService } from "../request-storage-service";
import { DISABLE_LOADER } from "../helpers/header-keys";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(
    private loaderService: LoadingOverlayService,
    private httpRequestSorage: RequestStorageService) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers?.get(DISABLE_LOADER) === "true") {
      req = req.clone({
        headers: req.headers.delete(DISABLE_LOADER),
      });
      return new Observable(observer => {
        // And subscribe to the original observable to ensure the HttpRequest is made
        const subscription = next.handle(req)
          .subscribe({
            next: event => {
              if (event) {
                observer.next(event);
              }
            },
            error: err => observer.error(err),
            complete: () => observer.complete()
          });
        // return teardown logic in case of cancelled requests
        return () => subscription.unsubscribe();
      });
    }

    if (!this.loaderService.isRequestHidden) {
      this.addRequestLoading(req);
    }

    this.loaderService.emitLoading(true);
    // We create a new observable which we return instead of the original
    return new Observable(observer => {
      // And subscribe to the original observable to ensure the HttpRequest is made
      const subscription = next.handle(req)
        .subscribe({
          next: event => {
            if (event instanceof HttpResponse) {
              this.removeRequestLoading(req);
              observer.next(event);
            }
          },
          error: err => { this.removeRequestLoading(req); observer.error(err); },
          complete: () => { this.removeRequestLoading(req); observer.complete(); }
        });
      // return teardown logic in case of cancelled requests
      return () => {
        this.removeRequestLoading(req);
        subscription.unsubscribe();
      };
    });
  }

  private addRequestLoading(req: HttpRequest<any>) {
    this.httpRequestSorage.addHttpRequest(req);
  }

  private removeRequestLoading(req: HttpRequest<any>) {
    this.httpRequestSorage.removeHttpRequest(req);
    this.loaderService.emitLoading(this.httpRequestSorage.getCountRequests() > 0);
  }
}
