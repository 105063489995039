/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DateRange } from './dateRange';

export interface BudgetperActivityCodeFilter { 
    pageSize?: number;
    page?: number;
    readonly listSortDirection?: BudgetperActivityCodeFilter.ListSortDirectionEnum;
    sortDirection?: string;
    sortProperty?: string;
    fileNumbers?: Array<string>;
    activityCodes?: Array<string>;
    activityDescriptions?: Array<string>;
    creationDates?: Array<DateRange>;
    customers?: Array<string>;
    shipmentTypes?: Array<BudgetperActivityCodeFilter.ShipmentTypesEnum>;
    fileTypes?: Array<BudgetperActivityCodeFilter.FileTypesEnum>;
    transportTypes?: Array<BudgetperActivityCodeFilter.TransportTypesEnum>;
}
export namespace BudgetperActivityCodeFilter {
    export type ListSortDirectionEnum = 0 | 1;
    export const ListSortDirectionEnum = {
        NUMBER_0: 0 as ListSortDirectionEnum,
        NUMBER_1: 1 as ListSortDirectionEnum
    };
    export type ShipmentTypesEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6';
    export const ShipmentTypesEnum = {
        _0: '0' as ShipmentTypesEnum,
        _1: '1' as ShipmentTypesEnum,
        _2: '2' as ShipmentTypesEnum,
        _3: '3' as ShipmentTypesEnum,
        _4: '4' as ShipmentTypesEnum,
        _5: '5' as ShipmentTypesEnum,
        _6: '6' as ShipmentTypesEnum
    };
    export type FileTypesEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6';
    export const FileTypesEnum = {
        _0: '0' as FileTypesEnum,
        _1: '1' as FileTypesEnum,
        _2: '2' as FileTypesEnum,
        _3: '3' as FileTypesEnum,
        _4: '4' as FileTypesEnum,
        _5: '5' as FileTypesEnum,
        _6: '6' as FileTypesEnum
    };
    export type TransportTypesEnum = '0' | '1' | '2' | '3';
    export const TransportTypesEnum = {
        _0: '0' as TransportTypesEnum,
        _1: '1' as TransportTypesEnum,
        _2: '2' as TransportTypesEnum,
        _3: '3' as TransportTypesEnum
    };
}