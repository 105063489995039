import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct, NgbDateNativeAdapter, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable({ providedIn: "root" })
export class BelgianNgbDateParserFormatter extends NgbDateParserFormatter {
  private readonly DELIMITER: string = "-";

  public parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }

    return null;
  }

  public format(date: NgbDateStruct | null): string {
    return !date
      ? ""
      : date.day.toString().padStart(2, "0") + this.DELIMITER +
      date.month.toString().padStart(2, "0") + this.DELIMITER +
      date.year.toString().padStart(2, "0");
  }
}