import { Component, Input, forwardRef, ViewChild, AfterViewInit, ElementRef } from "@angular/core";
import { DateInputsHelperService } from "../../../core/helpers/date-inputs-helper.service";
import { NgbDateHelper } from "../../../core/helpers/ngb-date-helper.service";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgbDateStruct, NgbTimeStruct, NgbDateParserFormatter, NgbTimepicker, NgbCalendar, NgbDatepicker, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { BelgianNgbDateParserFormatter } from "../date-input/belgian-ngbdate-parser-formatter.service";
import { ToasterService } from "../../layout/toaster/toaster.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "date-time-input",
  templateUrl: "./date-time-input.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeInputComponent),
      multi: true
    },
    { provide: NgbDateParserFormatter, useClass: BelgianNgbDateParserFormatter }]
})
export class DateTimeInputComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild("timePicker") private timePicker: NgbTimepicker;
  @ViewChild("datePicker") public datePicker: ElementRef<NgbDatepicker>;

  constructor(
    protected dateInputHelper: DateInputsHelperService,
    protected ngbDateHelper: NgbDateHelper,
    private calendar: NgbCalendar,
    private toaster: ToasterService,
    private translateService: TranslateService
  ) { }

  @Input()
  public minDate: NgbDateStruct;

  @Input()
  public maxDate: NgbDateStruct;

  @Input()
  public tabIndex: number;

  // Possible values are "top", "top-left", "top-right", "bottom", "bottom-left", "bottom-right", "left", "left-top", "left-bottom", "right", "right-top", "right-bottom"
  @Input()
  public placement: string = "top-left";

  public isDisabled: boolean;
  public selectedDateAsString: string;
  public ngbDate: NgbDate;
  public selectedTime: NgbTimeStruct;
  public onChange: (_: any) => void;
  public startDate: NgbDateStruct;

  private selectedDateAsDate: Date;

  public ngAfterViewInit(): void {
    this.handleTimeInputClick();
  }

  public onInput(event: any) {
    let dateString: string = event.target.value?.toString().replaceAll('.', '-').replaceAll('/', '-').replaceAll('\\', '-');
    let splittedDateString = dateString.split("-").filter(x => x);

    if (dateString && (splittedDateString.length == 3 && splittedDateString.some(x => x.length > 3) && splittedDateString.every(x => x.length >= 2))) {
      const regex = new RegExp(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/, "g");

      // check if date is valid
      if (!regex.test(dateString)) {
        event.target.value = this.selectedDateAsString;
        this.toaster.warning(this.translateService.instant("VALIDATION.WARNING_DATE_FORMAT", { date: dateString }));
      }
      else {
        event.target.value = dateString;
      }
    }
  }

  public onDateSelected(dateStruct: NgbDateStruct): void {
    this.updateDate(dateStruct);
  }

  public todaySelected(): void {
    const date = this.calendar.getToday();
    this.updateDate(date);
  }

  public onTextChanged(event: any) {
    const dateString = event.target.value;

    const parsedDate = this.dateInputHelper.parseDate(dateString, this.dateInputHelper.dateFormat);

    // check if date is valid
    if (this.dateInputHelper.isDateValid(parsedDate)) {
      this.updateDate(this.ngbDateHelper.fromModel(parsedDate));
    } else {
      this.onChange(null);
      this.selectedDateAsDate = null;
      event.target.value = "";
      this.selectedDateAsString = "";
    }
  }

  public onTimeChanged(): void {
    const timePickerModel = this.timePicker.model;
    if (timePickerModel.hour > 0 && !timePickerModel.minute) {
      timePickerModel.changeMinute(0);
    }
    if (timePickerModel.minute > 0 && !timePickerModel.hour) {
      timePickerModel.changeHour(0);
    }
    if (!timePickerModel.minute && !timePickerModel.hour) {
      timePickerModel.changeHour(0);
      timePickerModel.changeMinute(0);
    }

    this.selectedTime = { hour: timePickerModel.hour, minute: timePickerModel.minute, second: null };

    if (this.selectedDateAsDate) {
      this.setTime(this.selectedDateAsDate, this.selectedTime);
    }

    if (this.onChange) {
      this.onChange(this.selectedDateAsDate?.toUTCString());
    }
  }

  public writeValue(newDate: string): void {
    this.selectedDateAsString = "";
    this.selectedDateAsDate = null;

    if (newDate) {
      this.selectedDateAsDate = new Date(newDate);
      this.selectedDateAsString = this.dateInputHelper.transformDateInput(this.selectedDateAsDate);
      this.ngbDate = this.dateInputHelper.toNgbDate(this.selectedDateAsDate);
      this.setStartDate(this.ngbDate);

      this.selectedTime = {
        hour: this.selectedDateAsDate.getHours(),
        minute: this.selectedDateAsDate.getMinutes(),
        second: 0
      };
    } else {
      this.selectedTime = { hour: null, minute: null, second: null };
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    if (fn) {
      this.onChange = fn;
    }
  }

  public registerOnTouched(fn: any): void {
  }

  private setStartDate(startDate: NgbDateStruct) {
    this.startDate = startDate;
  }

  private setTime(date: Date, time: NgbTimeStruct) {
    if (!time) {
      return;
    }

    date.setHours(time.hour);
    date.setMinutes(time.minute);
  }

  private updateDate(event: NgbDateStruct) {
    if (event) {
      this.selectedDateAsDate = this.dateInputHelper.fromNgbDateStructToDate(event);
      this.selectedDateAsString = this.dateInputHelper.transformDateInput(this.selectedDateAsDate);
      this.setTime(this.selectedDateAsDate, this.selectedTime);
      this.setStartDate(event);

      const datePicker: any = this.datePicker;
      datePicker._model = event;
      datePicker._inputValue = this.selectedDateAsString;

      if (this.onChange) {
        this.onChange(this.selectedDateAsDate.toUTCString());
      }
    } else {
      this.onChange(null);
    }
  }

  private handleTimeInputClick(): void {
    // Couldn't access the NgbTimepicker's nativeElement directly (it's not exposed by the npm package).
    // So instead listened to the click event and determined if the input element was "Hours", "Minutes" or "Seconds" by its built-in placeholder.
    document.onclick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target instanceof HTMLInputElement && (target.placeholder === "HH" || target.placeholder === "MM" || target.placeholder === "SS")) {
        target.select();
      }
    };
  }

  public minDateMoreThanToday(): boolean {

    if (!this.minDate) {
      return false;
    }

    const minDate = new Date(this.minDate.year, this.minDate.month - 1, this.minDate.day);

    const todayAsNgbDate = this.calendar.getToday();
    const today = new Date(todayAsNgbDate.year, todayAsNgbDate.month - 1, todayAsNgbDate.day);

    return minDate > today;

  }
}
