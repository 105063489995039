/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FoundNotificationDTO { 
    id?: number;
    title?: string;
    message?: string;
    priority?: FoundNotificationDTO.PriorityEnum;
    type?: FoundNotificationDTO.TypeEnum;
    archived?: boolean;
    archivedBy?: string;
    archivedOn?: Date;
    deadline?: Date;
    userId?: number;
    department?: string;
    role?: string;
    origin?: string;
    createdOn?: Date;
    resourceIdentifier?: string;
}
export namespace FoundNotificationDTO {
    export type PriorityEnum = 0 | 1 | 2;
    export const PriorityEnum = {
        NUMBER_0: 0 as PriorityEnum,
        NUMBER_1: 1 as PriorityEnum,
        NUMBER_2: 2 as PriorityEnum
    };
    export type TypeEnum = 0 | 1;
    export const TypeEnum = {
        NUMBER_0: 0 as TypeEnum,
        NUMBER_1: 1 as TypeEnum
    };
}