import { Component, OnInit, OnDestroy } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { LoadingOverlayService } from "../../../services/loading-overlay/loading-overlay.service";
import { Validators, ValidatorFn } from "@angular/forms";
import { DecimalFormatterService } from "../../../helpers/decimal-formatter.service";
import { FormlyHelper } from "../../../helpers/formly-helper";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "exchange-rate-input",
  templateUrl: "./exchange-rate-input.component.html",
  styleUrls: ["./exchange-rate-input.component.scss"]
})
export class ExchangeRateInputComponent extends FieldType implements OnInit, OnDestroy {

  public submitted: boolean;
  public operationalFileId: number;
  private unsubscribe: Subject<void> = new Subject();
  private isRequired: boolean = false;
  public get showIsRequired(): boolean {
    return this.isRequired && !this.field?.templateOptions?.disabled;
  }
  constructor(
    private overlay: LoadingOverlayService,
    public formatService: DecimalFormatterService,
    public formlyHelper: FormlyHelper
  ) {
    super();
  }

  public ngOnInit() {

    this.formlyHelper
      .getGeneralFormSubmission$()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.submitted = true);

    //Uncomment if the numbersOnlyPrecision directive is removed in  exchange-rate
    // const max8DigitsValidator: ValidatorFn = Validators.pattern(this.formatService.getRequiredExchangeRateRegex());
    // if (this.field.templateOptions.required) {
    //   this.formControl.setValidators([Validators.required, max8DigitsValidator]);
    // } else {
    //   this.formControl.setValidators(max8DigitsValidator);
    // }

    if (this.field.templateOptions.required) {
      this.formControl.setValidators([Validators.required]);
      this.isRequired = true;
    } else {
      this.formControl.setValidators(null);
    }

    this.overlay.stopLoading();
  }

  public ngOnDestroy(): void {
    if (!this.unsubscribe || !this.unsubscribe.observers || this.unsubscribe.observers.length <= 0) {
      return;
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
