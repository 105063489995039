import {Component, Input} from "@angular/core";
import {DepotDTO} from "../../../../services/swagger-gen/fordesk";

@Component({
  selector: 'depot-option',
  templateUrl: './depot-option.component.html'
})
export class DepotOptionComponent {

  @Input()
  public item: DepotDTO;

  constructor() { }
}
