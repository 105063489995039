import { invoice_line_displayed_columns } from "./invoice-line-displayed-columns.en";

export const invoices_lines_locale = {
  TITLE: "Outgoing Invoice line",
  VAT_DETAILS: "VAT code",
  PARTY: "Party",
  CURRENCY: "Currency",
  EXCHANGE_RATE: "Exchange rate",
  PRICE_CONVERTED: "Price",
  VAT: "VAT",
  TOTAL: "Total",
  TOTAL_CONVERTED: "Total",
  QUANTITY: "Quantity",
  VAT_CLAUSE: "VAT clause",
  ACTIVITY_CODE: "Activity code",
  PRICE: "Price",
  TAX_DESCRIPTION: "VAT description",
  OUTGOING_TITLE: "Outgoing invoice line",
  INCOMING_TITLE: "Incoming invoice line",
  TAX_SYSTEM: "Tax system",
  VALIDATION: {
    TAX_SYSTEM_CODE_REQUIRED: "A tax system and code are required to validate a cost.",
    TAX_SYSTEM_CODE_REQUIRED_SPLIT: "A tax system and code are required to split a cost.",
    EXCHANGE_RATE_REQUIRED: "An exchange-rate is required to validate a cost.",
    EXCHANGE_RATE_REQUIRED_SPLIT: "An exchange-rate is required to split a cost.",
    ALLOWED_MINIMUM_ADJUSTED_AMOUNT: "The total price cannot be less than {{AdjustedPrice}}",
    ALLOWED_MAXIMUM_ADJUSTED_AMOUNT: "The total price cannot be more than {{AdjustedPrice}}"
  },
  VAT_AMOUNT: "VAT amount",
  DISPLAYED_COLUMNS: invoice_line_displayed_columns
};
