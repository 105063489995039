import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class PaginationComponentData {
  public limit: number = 2000;
  public total: number = 2000;
}

export class PaginationData {
  public page: number;
  public text: string;
}

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  public items: PaginationData[] = [];
  public detailsForm: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<PaginationComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: PaginationComponentData) { }

  ngOnInit(): void {
    this.loadPaginationItems();
    this.createForm();
  }

  private createForm(): void {
    this.detailsForm = this.fb.group({
      pagination: [0]
    });
  }

  public cancel() {
    this.dialogRef.close();
  }

  public confirm() {
    this.dialogRef.close(this.detailsForm.controls.pagination.value);
  }

  private loadPaginationItems() {

    let total = this.data.total;
    let limit = this.data.limit;
    let initPage = 1;
    while (total > 0) {
      if (total - limit >= 0) {
        total -= limit;
        if (initPage != 1) {
          let from = ((initPage - 1) * limit) + 1;

          this.items.push({
            text: `${from} -  ${from + limit - 1}`,
            page: initPage - 1
          });

        }
        else {
          this.items.push({
            text: `${initPage} - ${initPage * limit}`,
            page: initPage - 1
          });
        }

      }
      else {
        let from = ((initPage - 1) * limit) + 1;

        this.items.push({
          text: `${from} - ${((initPage - 1) * limit) + total}`,
          page: initPage - 1
        });

        break;
      }
      initPage += 1;
    }
  }
}
