export const reporting_list_locale = {
  AIRPORTOFDEPARTURE: "Air port of departure",
  AIRPORTOFDESTINATION: "Air port of destination",
  TITLE: "Operational summary per user",
  TITLE_OPEN_BUDGET: "Open budget per operational file",
  TITLE_BUDGET_DETAILS: "Budget details per operational file",
  TITLE_OUTGOING_INVOICES: "Outgoing invoices per operational file",
  FIRSTNAME: "First name",
  LASTNAME: "Last name",
  FCLCOUNT: "# of FCL files",
  LCLCOUNT: "# of LCL files",
  BREAKBULKCOUNT: "# of break bulk files",
  ROROFILESCOUNT: "# RoRo files",
  TOTALTEU: "Total TEUs",
  COST_EXCL_VAT: "Total cost excl. VAT",
  REVENUE: "Total revenue excl. VAT",
  RESULT_EXCL_VAT: "Result excl. VAT",
  CURRENT_COST_EXCL_VAT: "Current Cost excl. VAT",
  CURRENT_REVENUE_EXCL_VAT: "Current Revenue excl. VAT",
  CURRENT_RESULT_EXCL_VAT: "Current Result excl. VAT",
  OPEN_COST_EXCL_VAT: "Open costs excl. VAT",
  OPEN_REVENUE_EXCL_VAT: "Open revenues excl. VAT",
  OPEN_RESULT_EXCL_VAT: "Open result excl. VAT",
  TOTAL: "Total",
  FILE_NUMBER: "Operational file number",
  OPEN_COSTS: "Open costs",
  OUTSTANDING_REVENUES: "Open revenues",
  REALISED_COSTS: "Realised costs",
  REALISED_REVENUES: "Realised revenues",
  YEARLY_BUDGET_OVERVIEW_TITLE: "Yearly budget overview",
  DATA: "Data",
  SELECT_CUSTOMER: "Select customer (optional)",
  SELECT_DEPARTMENT: "Select department (optional)",
  TOTALINVOICEDREVENUES: "Total realised revenues",
  TOTALOPENREVENUES: "Total open revenues",
  TOTALBOOKEDCOSTS: "Total realised costs",
  TOTALOPENCOSTS: "Total open costs",
  RESULT: "Result",
  ACTUAL_RESULT: "Actual result",
  NUMBEROFTEU: "Number of TEU",
  NUMBEROFFILES: "Number of files",
  PERCENTTO: "% TO",
  STATUS: "Status",
  CUSTOMER_REFERENCE: "Customer reference",
  USER_NAME: "User",
  USER_INITIALS: "Initials",
  DEPARTMENT: "Department",
  DATE: "Date",
  PERIOD: "Period",
  CUSTOMER: "Customer",
  CUSTOMER_NUMBER: "Customer number",
  TOTAL_OPEN_COSTS: "Open costs",
  TOTAL_OPEN_REVENUES: "Open revenues",
  TOTAL_OUT_OF_BUDGET_REVENUES: "OOB revenues",
  RESULT_OUT_OF_BUDGET: "OOB Total",
  TOTAL_REALISED_REVENUES: "Realised revenues",
  TOTAL_REALISED_COSTS: "Realised costs",
  TOTAL_OUT_OF_BUDGET_COSTS: "OOB costs",
  POL_CODE: "POL Code",
  POL: "POL",
  POD_CODE: "POD Code",
  POD: "POD",
  VALIDATION: {
    MIN_DATE_MUST_BE_SMALLER: "Minimum date must be before the maximum date",
    YEAR_MUST_BE_PROVIDED: "Year cannot be empty when a month is entered"
  },
  QUOTE_REPORTS: "Quote reports",
  GRID_VIEW: "grid view",
  PIVOT_VIEW: "pivot view",
  SHOW_FIELD_LIST: "Show field list",
  HIDE_FIELD_LIST: "Hide field list",
  TOGGLE_FIELD_LIST: "Toggle field list",
  FILENUMBER: "Operational file number",
  CUSTOMERNAME: "Customer name",
  CUSTOMERNUMBER: "Customer number",
  INVOICENUMBER: "Invoice number",
  INVOICEDATE: "Invoice date",
  TOTALAMOUNT: "Total amount",
  CURRENCY: "Currency",
  TOTALLOCALCURRENCY: "Total local currency",
  SHIPMENTTYPE: "Shipment type",
  FILETYPE: "File type",
  TRANSPORTTYPE: "Transport type",
  PORTOFLOADING: "Port of loading",
  PORTOFDISCHARGE: "Port of discharge",
  RESULTOUTOFBUDGET: "OOB Total",
  OPERATIONAL_FILES_OVERVIEW: "Operational files overview",
  USERNAME: "User name",
  CARRIER: "Carrier",
  INCOTERMS: "Incoterm",
  CREATIONDATE: "Creation date",
  FILEDATE: "File date",
  BUDGETRESULT: "Budget result",
  ACTUALISEDRESULT: "Actualised result",
  ACTUALISEDREVENUES: "Actualised revenues",
  ACTUALISEDCOSTS: "Actualised costs",
  CURRENTRESULT: "Current result"
};
