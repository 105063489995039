import { Component, Input, OnInit } from "@angular/core";
import { FoundOperationalFileContainerDTO } from "../../../services/swagger-gen/fordesk";

@Component({
  selector: "container-release-right-status-badge",
  templateUrl: "./container-release-right-status-badge.component.html",
  styleUrls: ["./container-release-right-status-badge.component.scss"]
})
export class ContainerReleaseRightStatusBadgeComponent implements OnInit {
  @Input()
  public status: FoundOperationalFileContainerDTO.ReleaseRightStatusEnum;

  // tslint:disable-next-line: typedef
  public ReleaseRightStatus = FoundOperationalFileContainerDTO.ReleaseRightStatusEnum;

  constructor() { }

  public ngOnInit() {
  }

}
