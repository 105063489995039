import { Component, Input, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, Observable, of, Subject } from "rxjs";
import { concatMap, takeUntil } from "rxjs/operators";
import { ReleaseRightEnum } from "../../../../core/enumerations/release-right.enum";
import { TenantConfigurationService } from "../../../../core/services/client-tenant-configuration/tenant-config.service";
import { ContainerIsoCodeDTO, ContainerPickupSettingsDTO, ContainerReleaseRightsDTO, OperationalFileContainerDTO, OperationalFilePortsDTO, OperationalFileService, ReleaseRightsService } from "../../../../core/services/swagger-gen/fordesk";
import { ComponentBase } from "../../../../core/components/abstractions/component-base";

@Component({
  selector: "release-right-action",
  templateUrl: "./release-right-action.component.html",
  styleUrls: ["./release-right-action.component.scss"]
})

export class ReleaseRightActionComponent extends ComponentBase implements OnInit {
  public releaseRightContainer: ContainerReleaseRightsDTO = {};
  public containerNumber: string;
  public container: OperationalFileContainerDTO;
  public containerIsoCode: ContainerIsoCodeDTO = {};
  public activePorts: string[] = [];
  public billOfLading: string;
  public sendSubject: Subject<void> = new Subject<void>();
  public sendEnabled: boolean = true;
  public requiresAction: boolean;
  public releaseRightAction: ReleaseRightEnum;

  @Input()
  public containerId: number;
  @Input()
  public operationalFileId: number;
  @Input()
  public isReleaseRightsEnabled: boolean;
  @Input()
  public fileAndContainerSelectionDisabled: boolean = false;
  @Input()
  public releaseRightId: number;

  // tslint:disable-next-line: typedef
  public ReleaseRightAction = ReleaseRightEnum;

  private statusesThatCanBeSent: ContainerReleaseRightsDTO.StatusEnum[] = [ContainerReleaseRightsDTO.StatusEnum.NUMBER_0, ContainerReleaseRightsDTO.StatusEnum.NUMBER_3, ContainerReleaseRightsDTO.StatusEnum.NUMBER_4];
  constructor(public dialogRef: MatDialogRef<ReleaseRightActionComponent>,
    private releaseRightService: ReleaseRightsService,
    private translate: TranslateService,
    private tenantConfigurationService: TenantConfigurationService,
    private operationalFileService: OperationalFileService) {
    super();
  }


  public ngOnInit() {
    const settings: ContainerPickupSettingsDTO = this.tenantConfigurationService.get().containerPickupSettings;
    this.activePorts = settings && settings.activePorts ? settings.activePorts : [];

    this.fetchData();
  }

  public close() {
    this.dialogRef.close();
  }

  private fetchData() {
    if (this.containerId && this.operationalFileId) {
      this.releaseRightService.getReleaseRightInfo(this.containerId, this.operationalFileId).subscribe(data => {
        if (data) {
          this.releaseRightContainer = data;
          this.billOfLading = data.billOfLadingNumbers.join("\n");
          this.setReleaseRightAction(data.status);
        }
      },
        () => {
          this.clearContainerData();
        });
    } else if (this.releaseRightId) {
      this.releaseRightService.getReleaseRight(this.releaseRightId).pipe(
        concatMap(data => forkJoin({
          operationalFilePorts: data.operationalFileId ? this.operationalFileService.getOperationalFilePorts(data.operationalFileId) : of(null),
          data: of(data)
        })), takeUntil(this.unsubscribe)
      ).subscribe(res => {
        if (res.data) {
          this.releaseRightContainer = res.data;
          this.billOfLading = res.data.billOfLadingNumbers.join("\n");
          this.setReleaseRightAction(res.data.status);
          this.containerId = res.data.operationalFileContainerId;
          this.operationalFileId = res.data.operationalFileId;
          this.isReleaseRightsEnabled = res.operationalFilePorts && res.operationalFilePorts.portOfDischarge && res.operationalFilePorts.portOfDischarge.unloCode
            && this.activePorts && this.activePorts.map(y => y.toUpperCase()).includes(res.operationalFilePorts.portOfDischarge.unloCode.toUpperCase());
        }
      },
        () => {
          this.clearContainerData();
        });
    }
  }

  private clearContainerData() {
    this.containerNumber = null;
    this.container = null;
    this.containerIsoCode = {};
  }

  public send() {
    if (!this.isReleaseRightsEnabled) {
      return;
    }

    this.sendSubject.next();
  }

  public getStatusAsText(status: ContainerReleaseRightsDTO.StatusEnum): string {
    switch (status) {
      case ContainerReleaseRightsDTO.StatusEnum.NUMBER_0:
        return this.translate.instant("OPERATIONAL.CONTAINERS.RELEASE_RIGHT.STATUSES.OFFERED_TO_US");
      case ContainerReleaseRightsDTO.StatusEnum.NUMBER_1:
        return this.translate.instant("OPERATIONAL.CONTAINERS.RELEASE_RIGHT.STATUSES.DECLINED_BY_US");
      case ContainerReleaseRightsDTO.StatusEnum.NUMBER_2:
        return this.translate.instant("OPERATIONAL.CONTAINERS.RELEASE_RIGHT.STATUSES.ACCEPTED_BY_US");
      case ContainerReleaseRightsDTO.StatusEnum.NUMBER_3:
        return this.translate.instant("OPERATIONAL.CONTAINERS.RELEASE_RIGHT.STATUSES.OWNED_BY_US");
      case ContainerReleaseRightsDTO.StatusEnum.NUMBER_4:
        return this.translate.instant("OPERATIONAL.CONTAINERS.RELEASE_RIGHT.STATUSES.OFFERED_BY_US");
      case ContainerReleaseRightsDTO.StatusEnum.NUMBER_5:
        return this.translate.instant("OPERATIONAL.CONTAINERS.RELEASE_RIGHT.STATUSES.NOT_OWNED_BY_US");
      case ContainerReleaseRightsDTO.StatusEnum.NUMBER_6:
        return this.translate.instant("OPERATIONAL.CONTAINERS.RELEASE_RIGHT.STATUSES.REVOKED_BY_US");
      case ContainerReleaseRightsDTO.StatusEnum.NUMBER_7:
        return this.translate.instant("OPERATIONAL.CONTAINERS.RELEASE_RIGHT.STATUSES.TRANSFERRED_TO_NEXT_PARTY");
      default:
        return this.translate.instant("OPERATIONAL.CONTAINERS.RELEASE_RIGHT.STATUSES.UNKNOWN");
    }
  }

  public updateContainerIsoCode(containerIsoCode: ContainerIsoCodeDTO) {
    this.containerIsoCode = containerIsoCode;
  }

  public updateContainer({ container, operationalFileId }) {
    this.container = container;
    this.isReleaseRightEnabledForPort(operationalFileId)
      .subscribe(operationalFilePorts => {
        this.isReleaseRightsEnabled = operationalFilePorts && operationalFilePorts.portOfDischarge && operationalFilePorts.portOfDischarge.unloCode
          && this.activePorts && this.activePorts.map(y => y.toUpperCase()).includes(operationalFilePorts.portOfDischarge.unloCode.toUpperCase());
      })
  }

  private isReleaseRightEnabledForPort(operationalFileId: number): Observable<OperationalFilePortsDTO> {
    return operationalFileId ? this.operationalFileService.getOperationalFilePorts(operationalFileId) : of(null);
  }

  public toggleSend(confirmed: boolean) {
    this.sendEnabled = confirmed;
  }

  public closeMainModal(closeMainModal: boolean) {
    if (closeMainModal) {
      this.dialogRef.close();
    }
  }

  public canSendByStatus(): boolean {
    if (!this.releaseRightContainer || isNaN(this.releaseRightContainer.status)) {
      return false;
    }

    return this.statusesThatCanBeSent.includes(this.releaseRightContainer.status);
  }

  private setReleaseRightAction(releaseRightStatus: ContainerReleaseRightsDTO.StatusEnum) {
    this.requiresAction = true;
    switch (releaseRightStatus) {
      case ContainerReleaseRightsDTO.StatusEnum.NUMBER_0:
        this.releaseRightAction = ReleaseRightEnum.Accept;
        return;
      case ContainerReleaseRightsDTO.StatusEnum.NUMBER_4:
        this.releaseRightAction = ReleaseRightEnum.Revoke;
        return;
      case ContainerReleaseRightsDTO.StatusEnum.NUMBER_3:
        this.releaseRightAction = ReleaseRightEnum.Transfer;
        return;
    }
    this.requiresAction = false;
  }
}
