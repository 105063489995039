export const digital_archive_component_locale = {
  MESSAGES: {
    DOCUMENT_SAVED: "The document has been successfully saved.",
    NO_DOCUMENT_SELECTED: "Please select a document.",
    FILE_IN_USE: "This file is in use and cannot be deleted or renamed.",
    FILE_NOT_SUPPORTED: "This file type cannot be opened in Fordesk.",
    INVALID_FILE_EXTENSION: "The new file name extension is invalid.",
    CANCEL_UPLOADED_FILES: "Before closing the pop-up window, cancel all active upload files or wait for their upload to complete.",
    FILE_NAME_TAKEN: "A file with the given name already exists.",
    FILE_SIZE_TOO_LARGE: "File size is too large. Max file size: 20MB",
    CONFIRM_ABORT_UPLOAD: "Are you sure you want to abort the file upload?",
    ABORT_UPLOAD: "Are you sure you want to abort the upload of a file named '{{ name }}'?",
    FILE_NAME_CONTAINS_INVALID_CHARACTERS: "The file or folder name contains invalid characters. Please use a different name. Valid file or folder names cannot end with a dot or space, and cannot contain any of the following characters: /:*?\"<>|\\",
    FOLDER_NAME_CANNOT_BE_EMPTY: "The folder name cannot be empty.",
    FILE_NAME_CANNOT_BE_EMPTY: "The file name cannot be empty.",
    DIRECTORY_CANNOT_CONTAINS_EXTENSION: "Folder name cannot contain an extension.",
    INVALID_FILE_NAME_PATTERN: "The file name cannot consist only of the extension.",
    FILE_TOO_MANY_WHITESPACES: "Two or more consecutive whitespaces are not allowed.",
    DOCUMENT_WAS_SUCCESFULLY_UPLOADED_TO_LINKED_OF: "Document was successfully uploaded to the linked operational files."
  },
  NAME: "Name"
};
