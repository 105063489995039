/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PreviewDocumentRequest { 
    documentTemplateId: number;
    operationalFileGoodsIds?: Array<number>;
    operationalfileContainerIds?: Array<number>;
    billOfLadingNumbers?: Array<string>;
    customsId?: number;
    operationalFilePartyId?: number;
    operationalFileCustomsAgentPartyId?: number;
    transportId?: number;
    operationalFileShippingInstructionsId?: number;
    bookingId?: number;
    fileFormat: PreviewDocumentRequest.FileFormatEnum;
    additionalTextValue?: string;
    transportStopId?: number;
    languageId?: number;
    customerAddressId?: number;
    contactPersonId?: number;
}
export namespace PreviewDocumentRequest {
    export type FileFormatEnum = 0 | 1 | 2;
    export const FileFormatEnum = {
        NUMBER_0: 0 as FileFormatEnum,
        NUMBER_1: 1 as FileFormatEnum,
        NUMBER_2: 2 as FileFormatEnum
    };
}