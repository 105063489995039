import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";

@Component({
  selector: "char-counter",
  templateUrl: "./char-counter.component.html",
  styleUrls: ["./char-counter.component.scss"]
})
export class CharCounterComponent implements OnInit {
  @Input() public control: UntypedFormControl | ElementRef;
  @Input() public maxCharCount: number;
  @Input() public additionalText: string;

  public get currentTextLength() {
    return (this.control as UntypedFormControl)?.value?.length
      ?? (this.control as ElementRef)?.nativeElement?.innerText?.length
      ?? 0;
  }

  public textLength$: Observable<any>;


  constructor() { }

  public ngOnInit(): void {
    this.textLength$ = (this.control as UntypedFormControl)?.valueChanges;
  }

}
