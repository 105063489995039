export const invoice_line_displayed_columns = {
  partyName: "Name",
  activityCodeDescription: "Description",
  quantity: "Quantity",
  priceExclVat: "Price excl. VAT",
  taxSystem: "Tax system",
  taxCode: "Tax code",
  totalExclVat: "Total excl. VAT",
  totalExclVatFileCurrency: "taxSystem",
  fileNumber: "Operational file number",
  vatAmount: "VAT",
  totalInclVat: "Total",
  status: "Status"
};
