import { Component, HostBinding, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take, takeUntil } from 'rxjs/operators';
import { ReleaseNoteDTO, ReleaseNoteService } from '../../../../../core/services/swagger-gen/fordesk';
import { ReleasePreviewDialogComponent } from '../../../../dialogs/release-preview-dialog/release-preview-dialog.component';
import { ComponentBase } from '../../../../../core/components/abstractions/component-base';

@Component({
  selector: 'release-preview',
  templateUrl: './release-preview.component.html',
  styleUrls: ['./release-preview.component.scss']
})
export class ReleasePreviewComponent extends ComponentBase implements OnInit {
  @HostBinding("class")
  public classes: string = "";
  private visibleClass = "m-dropdown m-dropdown--align-center m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--large m-dropdown--mobile-full-width m-nav__item m-topbar__notifications m-topbar__notifications--img ng-star-inserted";

  public containsRelease: boolean = false;
  public releases: ReleaseNoteDTO[] = [];
  constructor(public dialog: MatDialog, private releaseService: ReleaseNoteService) {
    super();
  }

  ngOnInit(): void {
    this.initReleasesInformation();
  }

  public openReleasePreviewDialog() {
    this.initReleasesInformation(() => {
      if (this.containsRelease)
        this.dialog.open(ReleasePreviewDialogComponent, {
          disableClose: true,
          height: "90vh",
          width: "90vw",
          maxWidth: "90vw",
          maxHeight: "90vh",
          autoFocus: false,
          data: { releases: this.releases }
        });
    });
  }

  private initReleasesInformation(callback?: Function) {
    this.releaseService.getAllActiveReleaseNotes({ disableLoader: true }).pipe(take(1),
      takeUntil(this.unsubscribe)).subscribe(data => {
        this.containsRelease = data && data.length > 0;
        this.releases = data ?? [];

        if (this.containsRelease) {
          this.classes = this.visibleClass;
        }
        else {
          this.classes = "";
        }

        if (callback) {
          callback();
        }
      });
  }
}
