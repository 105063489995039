import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "truncate"
})
export class TruncatePipe implements PipeTransform {
  public transform(value: string, limit: number, textOverflow: string = "...") {

    if (!value || value.length <= limit) {
      return value;
    }

    return value.slice(0, limit) + textOverflow;
  }
}
