export enum ForwardingRole {
  OCRUser = "OCR_USER",
  ForwardingUser = "FORWARDING_USER",
  ForwardingAdmin = "FORWARDING_ADMIN",
  ForwardingFinancialUser = "FORWARDING_FINANCIAL_USER",
  ForwardingReportingUser = "FORWARDING_REPORTING_USER",
  FordeskMasterdataUser = "FORDESK_MASTERDATA",
  ForwardingQuoteUser = "FORWARDING_QUOTE_USER",
  ForwardingUserManagement = "FORWARDING_USER_MANAGEMENT",
  FordeskNotification = "FORDESK_NOTIFICATIONS",
  FordeskSales = "FORDESK_SALES",
  FordeskProspects = "FORDESK_PROSPECTS",
  DiflexmoAdmin = "DIFLEXMO_ADMIN",
  FordeskClientPortalConfig = "FORDESK_CLIENTPORTAL_CONFIG",
  FordeskClientPortal = "FORDESK_CLIENTPORTAL",
  FinanceExtension = "FINANCE_EXTENSION",
}
