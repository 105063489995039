import { Directive, HostListener, ElementRef, Input, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Directive({
  selector: "[digitsOnly]"
})
export class DigitsOnlyDirective {
  private regexStr: string = "^[0-9]*$";

  @Input()
  public isZeroValidValue: boolean = true;

  @Input()
  public formControl: UntypedFormControl;

  constructor(private el: ElementRef) {
  }

  @HostListener("keypress", ["$event"])
  public onKeyPress(event: KeyboardEvent): boolean {

    if (!this.isZeroValidValue && event.key === "0") {
      const currentValue = this.getCurrentValue();

      return currentValue !== null;
    }

    return new RegExp(this.regexStr).test(event.key);
  }

  @HostListener("paste", ["$event"])
  public blockPaste(event: KeyboardEvent): void {
    this.validateFields(event);
  }

  public validateFields(event: any): void {
    setTimeout(() => {
      let currentValue: number = this.getCurrentValue();

      if (!this.isZeroValidValue && currentValue === 0) {
        currentValue = null;
      }

      this.el.nativeElement.value = currentValue;

      if (this.formControl) {
        this.formControl.setValue(currentValue);
      }

      event.preventDefault();
    }, 100);
  }

  private getCurrentValue(): number | null {
    if (!this.el.nativeElement.value) {
      return null;
    }

    const value = this.el.nativeElement.value.replace(/[^0-9]/g, "");

    if (!value) {
      return null;
    }

    return parseInt(value, null);
  }

}
