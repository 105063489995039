import { HttpResponse, HttpResponseBase } from "@angular/common/http";

export class NamedBlob {
  public blob: Blob;
  public fileName: string;

  public initFromHttpResponse(response: HttpResponse<Blob>, fallbackFileName: string) {
    this.blob = response.body;

    const fileName = this.getFileName(response, "fileName");
    if (fileName) {
      this.fileName = fileName;
    } else {
      this.fileName = fallbackFileName;
    }
  }

  private getFileName(response: HttpResponseBase, headerName: string): string {
    return response.headers.get(headerName);
  }
}