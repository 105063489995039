/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ImoClassificationDTO { 
    id?: number;
    unCode?: string;
    _class?: string;
    packageGroup?: ImoClassificationDTO.PackageGroupEnum;
    flashPoint?: number;
    emsCode?: string;
    mfagCode?: string;
    properShippingName?: string;
    technicalName?: string;
    limitedQuantity?: number;
    innerQuantity?: number;
    expectedQuantity?: string;
    isMarinePollutant?: boolean;
    adr?: string;
    rid?: string;
    isDeleted?: boolean;
}
export namespace ImoClassificationDTO {
    export type PackageGroupEnum = 0 | 1 | 2;
    export const PackageGroupEnum = {
        NUMBER_0: 0 as PackageGroupEnum,
        NUMBER_1: 1 as PackageGroupEnum,
        NUMBER_2: 2 as PackageGroupEnum
    };
}