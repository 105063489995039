export const budget_details_overview_locale = {
  LEGEND: "Legend",
  OPEN_COSTS: "Open costs",
  OPEN_REVENUES: "Open revenues",
  REALISED_REVENUES: "Realised revenues",
  REALISED_COSTS: "Realised costs",
  RESULT: "Result",
  INCLUDE_FILES_WITHOUT_BUDGET: "Include files without budget",
  DESCRIPTIONS: {
    OPEN_COSTS: "Open (not booked) costs, incl. out of budget",
    OPEN_REVENUES: "Open (not invoiced) revenues, incl. out of budget",
    REALISED_REVENUES: "Actualised (invoiced) revenues, incl. out of budget",
    REALISED_COSTS: "Actualised (booked) costs, incl. out of budget",
    RESULT: "Result of all revenues (actualised and open) minus all costs (actualised and open)",
    ACTUALISED_RESULT: "Result of all actualised (invoiced) revenues minus all actualised (booked) costs ",
    BUDGET_RESULT: "Result of all in budget revenues minus all in budget costs"
  },
  PIVOT_FIELDS: {
    USERNAME: "Username",
    STATUS: "Status",
    OF_NUMBER: "Operational file number",
    NO_OF_TEU: "Number of TEU",
    PERCENT_TO: "Turnover %",
    RESULT: "Result",
    RESULT_OUT_OF_BUDGET: "OOB Total",
    TOTAL_OUT_OF_BUDGET_COSTS: "OOB costs",
    TOTAL_OUT_OF_BUDGET_REVENUES: "OOB revenues",
    TOTAL_REALISED_COSTS: "Total realised costs",
    TOTAL_REALISED_REVENUE: "Total realised revenue",
    TOTAL_OPEN_REVENUE: "Total open revenues",
    TOTAL_OPEN_COSTS: "Total open costs",
    CUSTOMER_NUMBER: "Customer number",
    PERIOD: "Period",
    FILE_DATE: "File date",
    DEPARTMENT: "Department",
    USER_INITIALS: "User initials",
    CUSTOMER_REFERENCE: "Customer reference",
    CUSTOMER: "Customer",
    PORT_OF_LOADING_CODE: "Port of loading code",
    PORT_OF_DISCHARGE_CODE: "Port of discharge code",
    PORT_OF_LOADING: "Port of loading",
    PORT_OF_DISCHARGE: "Port of discharge",
    INVOICE_DATE: "Invoice date",
    ACTUALISED_RESULT: "Actualised result",
    BUDGET_RESULT: "Budget result"
  }
};
