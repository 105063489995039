import { Directive, OnDestroy } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { Subject } from "rxjs";

@Directive()
export class FormlyBase extends FieldType implements OnDestroy {
  protected unsubscribe: Subject<void> = new Subject();

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
