import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbTabChangeEvent, NgbTabset } from "@ng-bootstrap/ng-bootstrap/tabset/tabset";

@Injectable({
  providedIn: "root"
})
export class TabSetNavigationHelper {
  private previousAction: NgbTabChangeEvent;
  private tabset: NgbTabset;


  constructor(private router: Router) {


  }

  public setTabset(tabset: NgbTabset) {
    this.tabset = tabset;

    this.tabset.tabChange.subscribe((tab: NgbTabChangeEvent) => {
      this.previousAction = tab;
    });
  }

  public resetTabset() {
    if (this.previousAction) {
      const resetId = this.previousAction.activeId;

      this.navigateTo(resetId);
      const element = document.getElementById(resetId) as HTMLLinkElement;

      if (element) {
        element.parentElement.classList.add("active");
      }
    }
  }

  public setActiveTabByUrl() {
    const opfRoute = this.router.url.match(/\((.*?)\)/);

    if (opfRoute) {
      const opf = opfRoute[1].split("/")[0];
      const tabs = Array.from<HTMLElement>(document.querySelectorAll("ngb-tabset > ul > li > a > a"));
      const currentActiveTab = tabs.find(x => x.getAttribute("href").includes(opf));
      if (currentActiveTab) {
        this.tabset.activeId = currentActiveTab.parentElement.id;
      }
    }
  }

  public navigateTo(path: string) {
    this.tabset.select(path);
  }
}
