import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupDTO } from '../../../core/services/swagger-gen/fordesk';
import { SuggesteDialogResultData } from "../../../core/models/dialogs/suggestiong-dialog-result.data";
import { SuggestionsDialogData } from "../../../core/models/dialogs/suggestions-dialog-data";

@Component({
  selector: 'selectable-suggestion-dialog-component',
  templateUrl: './selectable-suggestion-dialog-component.html',
  styleUrls: ['./selectable-suggestion-dialog-component.scss']
})
export class SelectableSuggestionDialogComponent implements OnInit, AfterViewInit {

  public contactPersonTypes: LookupDTO[] = [];
  public languages: LookupDTO[] = [];

  public cachableTypes: string[] = ["contactPersonTypeId"];
  constructor(
    public cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<SelectableSuggestionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SuggestionsDialogData) {
  }

  public ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }
  public cancel() {
    this.dialogRef.close(null);
  }

  public select(selectedId: number, isNewEntity: boolean) {
    const dialogResult: SuggesteDialogResultData = {
      selectedId: selectedId,
      isNewEntity: isNewEntity
    }
    this.dialogRef.close(dialogResult);
  }
}
