import { NamedBlob } from "../../../../../core/models/files/named-blob";
import { Component, ElementRef, Injector, Input, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { takeUntil } from "rxjs/operators";
import { ToasterService } from "../../../../layout/toaster/toaster.service";
import { MessageHelper } from "../../../../../core/helpers/message-helper";
import { DialogResult } from "../../../../../core/enumerations/dialog-result";
import { OperationalFileDocumentsRefreshService } from "../../../../../core/services/operational/operational-file/operational-file-documents-refresh.service";
import { ComponentBase } from "../../../../../core/components/abstractions/component-base";
import { MatDialog } from "@angular/material/dialog";
import { MailComponent } from "../mail/mail.component";
import { PdfViewerButtonOptions as PdfViewerButtonOptions } from "../../../../../core/models/dialogs/pdf-viewer-button-options";
import { CompanyRelatedEmailDTO, EmailTemplateTypeDTO, ExampleDocumentsService, FormattedEmailTemplateDTO } from "../../../../../core/services/swagger-gen/fordesk";
import { PreviewFileHelper } from "../../../../../core/services/operational/operational-file/preview-file-helper.service";
import { UblConfiguration } from "../../../../../core/models/dialogs/ubl-configuration";
import { DocumentFile } from "../../../../../core/models/documents/document-file";
import { IMailingSourceBehavior } from "../../../../../core/helpers/mailing/mailing-source-behavior";

@Component({
  selector: "pdf-viewer-dialog",
  templateUrl: "./pdf-viewer-dialog.component.html"
})
export class PdfViewerDialogComponent extends ComponentBase implements OnInit {
  public onClose: Function = null;
  public fileName: string;
  public buttonOptions: PdfViewerButtonOptions;

  @ViewChild("downloadLink", { static: false })
  private downloadLink: ElementRef;

  private fileSource: Observable<NamedBlob>;
  public file: Blob;
  public ublConfig: UblConfiguration = {};

  public preFilledRecipients: CompanyRelatedEmailDTO[] = [];
  public bccMailRecipients: string[] = [];
  public actionAfterSend: Function;
  public mailingSourceBehavior: IMailingSourceBehavior;
  public getEmailsAutocompleteSuggestions: (text: string) => Observable<CompanyRelatedEmailDTO[]>;

  private isDataSuccessfullyLoaded: boolean = false;

  public saveDocument: (document: Blob, fileName: string) => Observable<NamedBlob>;

  @Input()
  public getDocuments: () => Observable<DocumentFile[]>;

  @Input()
  public mainFilePath: string;

  constructor(
    private injector: Injector,
    protected route: ActivatedRoute,
    public translationService: TranslateService,
    public fb: UntypedFormBuilder,
    public http: HttpClient,
    public toaster: ToasterService,
    public translate: TranslateService,
    public message: MessageHelper,
    public dialog: MatDialog,
    private documentsRefreshService: OperationalFileDocumentsRefreshService,
    public modal: NgbModal) {
    super();
  }

  public ngOnInit() { }

  /**
   * Attempts to load and display a PDF file from a provided source Observable.
   * @param source The source observable from the PDF blob will be loaded.
   * @param onComplete
   */
  public tryLoadPdf(source: Observable<NamedBlob>, buttonOptions: PdfViewerButtonOptions, onComplete?: Function) {
    this.fileSource = source;
    this.buttonOptions = buttonOptions;

    if (!source) {
      this.closeDialog();
      return;
    }

    this.fileSource
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: res => {
          this.loadPdf(res, buttonOptions, onComplete);
        },
        error: () => this.closeDialog(),
        complete: () => {
          if (!this.isDataSuccessfullyLoaded) {
            this.closeDialog();
          }
        }
      });
  }

  public loadPdf(source: NamedBlob, buttonOptions: PdfViewerButtonOptions, onComplete?: Function) {

    this.isDataSuccessfullyLoaded = true;

    this.file = source ? source.blob : new Blob();
    this.fileName = source.fileName;
    this.buttonOptions = buttonOptions;

    if (onComplete) {
      onComplete();
    }
  }

  public cancel(): void {
    this.closeDialog();
  }

  public closeDialog(): void {
    if (this.onClose) {
      this.onClose();
    }
    else {
      const dialogRef = this.injector.get(NgbActiveModal);
      dialogRef.close();
    }
  }

  public download() {
    this.removeHover("download-btn");

    // post or get depending on your requirement
    // TODO: implement file download service when available
    if (!this.file) {
      return;
    }

    const actualFileName: string = this.fileName ? this.fileName : `preview-${new Date().getTime()}.pdf`;
    const link = this.downloadLink.nativeElement;
    link.href = window.URL.createObjectURL(this.file);
    link.download = actualFileName;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }

  public save() {
    if (!this.saveDocument) {
      this.toaster.error(this.message.getGenericDialogResultMessage(DialogResult.Error));
    }

    this.saveDocument(this.file, this.fileName)
      .subscribe({
        next: () => this.toaster.success(this.translate.instant("DIGITAL_ARCHIVE.MESSAGES.DOCUMENT_SAVED")),
        error: () => this.toaster.error(this.message.getGenericDialogResultMessage(DialogResult.Error)),
        complete: () => {
          this.closeDialog();
          this.documentsRefreshService.refresh();
        }
      });
  }

  public print() {
    this.removeHover("print-btn");
    const blobUrl = URL.createObjectURL(this.file);
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }

  public mail() {
    this.removeHover("mail-btn");
    const namedBlob: NamedBlob = new NamedBlob();
    namedBlob.fileName = `${this.mainFilePath && this.mainFilePath != "/"
      ? `${this.mainFilePath.replace(/\//g, '')}/`
      : ""}${this.fileName}`
    namedBlob.blob = this.file;

    const files: NamedBlob[] = [namedBlob];
    const modalRef = this.modal.open(MailComponent, {
      backdrop: 'static',
      keyboard: false,
      size: <any>"xl"
    });
    (<MailComponent>modalRef.componentInstance).files = files;
    (<MailComponent>modalRef.componentInstance).ublConfig = this.ublConfig;
    (<MailComponent>modalRef.componentInstance).preFilledRecipients = this.preFilledRecipients;
    (<MailComponent>modalRef.componentInstance).bccMailRecipients = this.bccMailRecipients;
    (<MailComponent>modalRef.componentInstance).mailingSourceBehavior = this.mailingSourceBehavior;
    (<MailComponent>modalRef.componentInstance).actionAfterSend = this.actionAfterSend;
    (<MailComponent>modalRef.componentInstance).getDocuments = this.getDocuments;
    (<MailComponent>modalRef.componentInstance).getEmailsAutocompleteSuggestions = this.getEmailsAutocompleteSuggestions;
  }

  private removeHover(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.blur();
    }
  }
}
