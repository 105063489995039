/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { NewGridLayoutFilterDTO } from './newGridLayoutFilterDTO';

export interface NewGridLayoutDTO { 
    type?: NewGridLayoutDTO.TypeEnum;
    sortDirection?: string;
    sortProperty?: string;
    page?: number;
    pageSize?: number;
    name?: string;
    shared?: boolean;
    filters?: Array<NewGridLayoutFilterDTO>;
    displayedColumns?: Array<string>;
}
export namespace NewGridLayoutDTO {
    export type TypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14;
    export const TypeEnum = {
        NUMBER_0: 0 as TypeEnum,
        NUMBER_1: 1 as TypeEnum,
        NUMBER_2: 2 as TypeEnum,
        NUMBER_3: 3 as TypeEnum,
        NUMBER_4: 4 as TypeEnum,
        NUMBER_5: 5 as TypeEnum,
        NUMBER_6: 6 as TypeEnum,
        NUMBER_7: 7 as TypeEnum,
        NUMBER_8: 8 as TypeEnum,
        NUMBER_9: 9 as TypeEnum,
        NUMBER_10: 10 as TypeEnum,
        NUMBER_11: 11 as TypeEnum,
        NUMBER_12: 12 as TypeEnum,
        NUMBER_13: 13 as TypeEnum,
        NUMBER_14: 14 as TypeEnum
    };
}