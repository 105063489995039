export const prospects_list_locale = {
  TITLE: "Prospects",
  COMPANY_NAME: "Company name",
  CONTACT: "Contact",
  CONTACT_PERSON: "Contact person",
  PHONE_NUMBER: "Telephone number",
  FILTER: {
    COMPANYNAME: "Company name",
    CONTACTPERSON: "Contact person",
    STATUS: "Status"
  },
  ACTIVE: "Active",
  ON_HOLD: "On Hold",
  CLOSED: "Closed",
  PROMOTED: "Promoted",
};
