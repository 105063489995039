export const of_steps_locale = {
  ADD_NEW_STEP: "Add new step",
  EDIT_STEP: "Edit step",
  CONFIRM_UNCOMPLETE: "Mark this step as pending?",
  CONFIRM_COMPLETE: "Mark this step as complete?",
  VALIDATION: {
    CANT_REMOVE_RELATED_STEPS: "It is not possible to delete this step because it is used in another steps."
  },
  MESSAGES: {
    NO_STEPS_FOUND: "No steps have been found.",
    STEP_UPDATED: "Step successfully updated.",
    MISSED_PARTY_LINKS_TITLE: "Missed steps links with parties",
    MISSED_PARTY_LINKS_MESSAGE_PART_1: "It seems that these steps contain party names not associated with any operational file party",
    MISSED_PARTY_LINKS_MESSAGE_PART_2: "and they will not be updated when operational file parties change.",
    MISSED_PARTY_LINKS_PLACEHOLDERS_SUGGESTION_PART_1: "You can manually edit these steps and replace the party names with the following placeholders",
    MISSED_PARTY_LINKS_PLACEHOLDERS_SUGGESTION_PART_2: "to allow them to be automatically associated with operational file parties:",
    MISSED_PARTY_LINKS_BUTTONS_DETAILS_PART_1: "Please press the 'Save' button once the necessary changes have been made or if no changes are needed.",
    MISSED_PARTY_LINKS_BUTTONS_DETAILS_PART_2: "Alternatively, press 'Cancel' if you prefer to make changes the next time the operational file is opened."
  },
};
