/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ContainerReleaseRightsDTO { 
    id?: number;
    status?: ContainerReleaseRightsDTO.StatusEnum;
    operationalFileContainerId?: number;
    operationalFileId?: number;
    receivedFrom?: string;
    receivedFromTin?: string;
    transferredTo?: string;
    transferredToTin?: string;
    releaseId?: string;
    validFrom?: Date;
    validTo?: Date;
    portLoCode?: string;
    billOfLadingNumbers?: Array<string>;
    terminalId?: string;
    receivedOnTimestamp?: Date;
    containerNumber?: string;
}
export namespace ContainerReleaseRightsDTO {
    export type StatusEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
    export const StatusEnum = {
        NUMBER_0: 0 as StatusEnum,
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum,
        NUMBER_3: 3 as StatusEnum,
        NUMBER_4: 4 as StatusEnum,
        NUMBER_5: 5 as StatusEnum,
        NUMBER_6: 6 as StatusEnum,
        NUMBER_7: 7 as StatusEnum
    };
}