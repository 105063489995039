export const dashboards_locale = {
  ADD_DASHBOARD: "Add new dashboard",
  EDIT_DASHBOARD: "Edit dashboard",
  REFRESH: "Refresh",
  ADD_WIDGET: "Add widget",
  SAVE_DASHBOARD: "Save dashboard",
  DELETE_DASHBOARD: "Delete dashboard",
  CANCEL: "Cancel",
  AVAILABLE_WIDGETS: "Available widgets",
  NO_DASHBOARD_FOUND: "No dashboards could be found.",
  MODALS: {
    SET_DASHBOARD_NAME_TITLE: 'Dashboard name',
    SET_DASHBOARD_NAME: 'Choose a dashboard name',
    DELETE_DASHBOARD_TITLE: "Delete dashboard",
    DELETE_DASHBOARD: "Are you sure you want to delete this dashboard?",
    CANCEL_CONFIRMATION_TITLE: "Cancel dashboard changes",
    CANCEL_CONFIRMATION: "Are you sure you want to cancel unsaved changes?",
  }
};
