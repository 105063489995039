/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CopyRequestData } from '../model/copyRequestData';
import { GetAllResponseFoundOperationalFileContainerCompactDetailsDTO } from '../model/getAllResponseFoundOperationalFileContainerCompactDetailsDTO';
import { GetAllResponseFoundOperationalFileContainerDTO } from '../model/getAllResponseFoundOperationalFileContainerDTO';
import { OperationalContainerIdentifierDTO } from '../model/operationalContainerIdentifierDTO';
import { OperationalFileContainerDTO } from '../model/operationalFileContainerDTO';
import { OperationalFileContainersExcelExportSearchCondition } from '../model/operationalFileContainersExcelExportSearchCondition';
import { PagedResponseFoundOperationalFileContainerDTO } from '../model/pagedResponseFoundOperationalFileContainerDTO';
import { Response } from '../model/response';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OperationalFileContainerService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add an operational file container
     * 
     * @param operationalFileId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOperationalFileContainer(operationalFileId: number, body?: OperationalFileContainerDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<number>;
    public addOperationalFileContainer(operationalFileId: number, body?: OperationalFileContainerDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<number>>;
    public addOperationalFileContainer(operationalFileId: number, body?: OperationalFileContainerDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<number>>;
    public addOperationalFileContainer(operationalFileId: number, body?: OperationalFileContainerDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling addOperationalFileContainer.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<number>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Can copy operational file containers or not
     * 
     * @param operationalFileId 
     * @param goodsCount 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canCopyOperationalFileContainers(operationalFileId: number, goodsCount?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<boolean>;
    public canCopyOperationalFileContainers(operationalFileId: number, goodsCount?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<boolean>>;
    public canCopyOperationalFileContainers(operationalFileId: number, goodsCount?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<boolean>>;
    public canCopyOperationalFileContainers(operationalFileId: number, goodsCount?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling canCopyOperationalFileContainers.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (goodsCount !== undefined && goodsCount !== null) {
            queryParameters = queryParameters.set('goodsCount', <any>goodsCount);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<boolean>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/can-copy-containers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Copy an operational file containers
     * 
     * @param operationalFileId 
     * @param containerId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copyOperationalFileContainers(operationalFileId: number, containerId: number, body?: CopyRequestData, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public copyOperationalFileContainers(operationalFileId: number, containerId: number, body?: CopyRequestData, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public copyOperationalFileContainers(operationalFileId: number, containerId: number, body?: CopyRequestData, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public copyOperationalFileContainers(operationalFileId: number, containerId: number, body?: CopyRequestData, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling copyOperationalFileContainers.');
        }

        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling copyOperationalFileContainers.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/${encodeURIComponent(String(containerId))}/copy`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Delete an operational file container
     * 
     * @param operationalFileId 
     * @param containerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOperationalFileContainer(operationalFileId: number, containerId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public deleteOperationalFileContainer(operationalFileId: number, containerId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public deleteOperationalFileContainer(operationalFileId: number, containerId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public deleteOperationalFileContainer(operationalFileId: number, containerId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling deleteOperationalFileContainer.');
        }

        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling deleteOperationalFileContainer.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.delete<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/${encodeURIComponent(String(containerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Download a report
     * 
     * @param operationalFileId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadReport(operationalFileId: number, body?: OperationalFileContainersExcelExportSearchCondition, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public downloadReport(operationalFileId: number, body?: OperationalFileContainersExcelExportSearchCondition, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public downloadReport(operationalFileId: number, body?: OperationalFileContainersExcelExportSearchCondition, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public downloadReport(operationalFileId: number, body?: OperationalFileContainersExcelExportSearchCondition, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling downloadReport.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/download`,
            body,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * External file container import
     * 
     * @param operationalFileId 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public externalFileContainerImport(operationalFileId: number, file?: Blob, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public externalFileContainerImport(operationalFileId: number, file?: Blob, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public externalFileContainerImport(operationalFileId: number, file?: Blob, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public externalFileContainerImport(operationalFileId: number, file?: Blob, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling externalFileContainerImport.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/external-file-imports`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get operational file containers compact detail
     * 
     * @param operationalFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllContainersCompactDetails(operationalFileId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<GetAllResponseFoundOperationalFileContainerCompactDetailsDTO>;
    public getAllContainersCompactDetails(operationalFileId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<GetAllResponseFoundOperationalFileContainerCompactDetailsDTO>>;
    public getAllContainersCompactDetails(operationalFileId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<GetAllResponseFoundOperationalFileContainerCompactDetailsDTO>>;
    public getAllContainersCompactDetails(operationalFileId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getAllContainersCompactDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<GetAllResponseFoundOperationalFileContainerCompactDetailsDTO>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/get-all-compact-details`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get all goods weight in container
     * 
     * @param operationalFileId 
     * @param containerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllGoodsWeightInContainer(operationalFileId: string, containerId?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<number>;
    public getAllGoodsWeightInContainer(operationalFileId: string, containerId?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<number>>;
    public getAllGoodsWeightInContainer(operationalFileId: string, containerId?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<number>>;
    public getAllGoodsWeightInContainer(operationalFileId: string, containerId?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getAllGoodsWeightInContainer.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (containerId !== undefined && containerId !== null) {
            queryParameters = queryParameters.set('containerId', <any>containerId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<number>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/get-all-goods-weight`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get all unsplit goods volume in container
     * 
     * @param operationalFileId 
     * @param containerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllUnsplitGoodsVolumeInContainer(operationalFileId: string, containerId?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<number>;
    public getAllUnsplitGoodsVolumeInContainer(operationalFileId: string, containerId?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<number>>;
    public getAllUnsplitGoodsVolumeInContainer(operationalFileId: string, containerId?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<number>>;
    public getAllUnsplitGoodsVolumeInContainer(operationalFileId: string, containerId?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getAllUnsplitGoodsVolumeInContainer.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (containerId !== undefined && containerId !== null) {
            queryParameters = queryParameters.set('containerId', <any>containerId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<number>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/get-all-unsplit-goods-volume`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get the template for container import
     * 
     * @param operationalFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContainerImportTemplate(operationalFileId: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Blob>;
    public getContainerImportTemplate(operationalFileId: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Blob>>;
    public getContainerImportTemplate(operationalFileId: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Blob>>;
    public getContainerImportTemplate(operationalFileId: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getContainerImportTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/get-container-import-template`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get an operational file container details
     * 
     * @param operationalFileId 
     * @param containerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOperationalFileContainerDetails(operationalFileId: number, containerId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<OperationalFileContainerDTO>;
    public getOperationalFileContainerDetails(operationalFileId: number, containerId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<OperationalFileContainerDTO>>;
    public getOperationalFileContainerDetails(operationalFileId: number, containerId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<OperationalFileContainerDTO>>;
    public getOperationalFileContainerDetails(operationalFileId: number, containerId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getOperationalFileContainerDetails.');
        }

        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling getOperationalFileContainerDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<OperationalFileContainerDTO>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/${encodeURIComponent(String(containerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get an operational file containers
     * 
     * @param operationalFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOperationalFileContainers(operationalFileId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<GetAllResponseFoundOperationalFileContainerDTO>;
    public getOperationalFileContainers(operationalFileId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<GetAllResponseFoundOperationalFileContainerDTO>>;
    public getOperationalFileContainers(operationalFileId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<GetAllResponseFoundOperationalFileContainerDTO>>;
    public getOperationalFileContainers(operationalFileId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling getOperationalFileContainers.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<GetAllResponseFoundOperationalFileContainerDTO>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Recalculate container weight and volume
     * 
     * @param operationalFileId 
     * @param containerId 
     * @param recalculateWeight 
     * @param recalculateVolume 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public recalculateContainer(operationalFileId: number, containerId: number, recalculateWeight?: boolean, recalculateVolume?: boolean, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public recalculateContainer(operationalFileId: number, containerId: number, recalculateWeight?: boolean, recalculateVolume?: boolean, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public recalculateContainer(operationalFileId: number, containerId: number, recalculateWeight?: boolean, recalculateVolume?: boolean, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public recalculateContainer(operationalFileId: number, containerId: number, recalculateWeight?: boolean, recalculateVolume?: boolean, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling recalculateContainer.');
        }

        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling recalculateContainer.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recalculateWeight !== undefined && recalculateWeight !== null) {
            queryParameters = queryParameters.set('recalculateWeight', <any>recalculateWeight);
        }
        if (recalculateVolume !== undefined && recalculateVolume !== null) {
            queryParameters = queryParameters.set('recalculateVolume', <any>recalculateVolume);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/${encodeURIComponent(String(containerId))}/recalculate`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Retrieve container-related identifiers based on a container number
     * 
     * @param containerNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchContainerIdentifiers(containerNumber?: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<OperationalContainerIdentifierDTO>>;
    public searchContainerIdentifiers(containerNumber?: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<OperationalContainerIdentifierDTO>>>;
    public searchContainerIdentifiers(containerNumber?: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<OperationalContainerIdentifierDTO>>>;
    public searchContainerIdentifiers(containerNumber?: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (containerNumber !== undefined && containerNumber !== null) {
            queryParameters = queryParameters.set('containerNumber', <any>containerNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<OperationalContainerIdentifierDTO>>(`${this.basePath}/api/operational-files/containers/identifiers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Retrieve container-related identifiers in an operational file based on a container number
     * 
     * @param operationalFileId 
     * @param containerNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchOperationalContainerIdentifiers(operationalFileId: number, containerNumber?: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<OperationalContainerIdentifierDTO>>;
    public searchOperationalContainerIdentifiers(operationalFileId: number, containerNumber?: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<OperationalContainerIdentifierDTO>>>;
    public searchOperationalContainerIdentifiers(operationalFileId: number, containerNumber?: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<OperationalContainerIdentifierDTO>>>;
    public searchOperationalContainerIdentifiers(operationalFileId: number, containerNumber?: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling searchOperationalContainerIdentifiers.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (containerNumber !== undefined && containerNumber !== null) {
            queryParameters = queryParameters.set('containerNumber', <any>containerNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<OperationalContainerIdentifierDTO>>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/identifiers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Search an operational file containers
     * 
     * @param operationalFileId 
     * @param searchTerm 
     * @param sortDirection 
     * @param sortProperty 
     * @param pageSize 
     * @param page 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchOperationalFileContainers(operationalFileId: number, searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PagedResponseFoundOperationalFileContainerDTO>;
    public searchOperationalFileContainers(operationalFileId: number, searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PagedResponseFoundOperationalFileContainerDTO>>;
    public searchOperationalFileContainers(operationalFileId: number, searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PagedResponseFoundOperationalFileContainerDTO>>;
    public searchOperationalFileContainers(operationalFileId: number, searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling searchOperationalFileContainers.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchTerm !== undefined && searchTerm !== null) {
            queryParameters = queryParameters.set('SearchTerm', <any>searchTerm);
        }
        if (sortDirection !== undefined && sortDirection !== null) {
            queryParameters = queryParameters.set('SortDirection', <any>sortDirection);
        }
        if (sortProperty !== undefined && sortProperty !== null) {
            queryParameters = queryParameters.set('SortProperty', <any>sortProperty);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<PagedResponseFoundOperationalFileContainerDTO>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Update an operational file container
     * 
     * @param operationalFileId 
     * @param containerId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOperationalFileContainer(operationalFileId: number, containerId: number, body?: OperationalFileContainerDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public updateOperationalFileContainer(operationalFileId: number, containerId: number, body?: OperationalFileContainerDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public updateOperationalFileContainer(operationalFileId: number, containerId: number, body?: OperationalFileContainerDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public updateOperationalFileContainer(operationalFileId: number, containerId: number, body?: OperationalFileContainerDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (operationalFileId === null || operationalFileId === undefined) {
            throw new Error('Required parameter operationalFileId was null or undefined when calling updateOperationalFileContainer.');
        }

        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling updateOperationalFileContainer.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.put<Response>(`${this.basePath}/api/operational/operational-files/${encodeURIComponent(String(operationalFileId))}/containers/${encodeURIComponent(String(containerId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

}
