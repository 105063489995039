/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FoundOperationalFileTransportStop { 
    id?: number;
    stopTypeDescription?: string;
    location?: string;
    addressLine1?: string;
    addressLine2?: string;
    zipCode?: string;
    placeName?: string;
    countryName?: string;
    stateProvinceRegion?: string;
    reference?: string;
    timestamp?: Date;
    locationCode?: string;
    stopType?: FoundOperationalFileTransportStop.StopTypeEnum;
    wizardType?: FoundOperationalFileTransportStop.WizardTypeEnum;
    stopTypeId?: number;
    operationalFileTransportId?: number;
}
export namespace FoundOperationalFileTransportStop {
    export type StopTypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
    export const StopTypeEnum = {
        NUMBER_0: 0 as StopTypeEnum,
        NUMBER_1: 1 as StopTypeEnum,
        NUMBER_2: 2 as StopTypeEnum,
        NUMBER_3: 3 as StopTypeEnum,
        NUMBER_4: 4 as StopTypeEnum,
        NUMBER_5: 5 as StopTypeEnum,
        NUMBER_6: 6 as StopTypeEnum,
        NUMBER_7: 7 as StopTypeEnum,
        NUMBER_8: 8 as StopTypeEnum
    };
    export type WizardTypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const WizardTypeEnum = {
        NUMBER_0: 0 as WizardTypeEnum,
        NUMBER_1: 1 as WizardTypeEnum,
        NUMBER_2: 2 as WizardTypeEnum,
        NUMBER_3: 3 as WizardTypeEnum,
        NUMBER_4: 4 as WizardTypeEnum,
        NUMBER_5: 5 as WizardTypeEnum,
        NUMBER_6: 6 as WizardTypeEnum
    };
}