/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FoundOperationalFileRevenueDTO { 
    id?: number;
    lastRowModificationTimestamp?: Date;
    isInvoiced?: boolean;
    companyId?: number;
    partyName?: string;
    activityCodeDescription?: string;
    quantity?: number;
    priceExclVat?: number;
    exchangeRate?: number;
    totalExclVat?: number;
    totalExclVatOriginalCurrency?: number;
    currencyCode?: string;
    isOutOfBudget?: boolean;
    sortValue?: number;
    partyId?: number;
    vatAmount?: number;
    totalInclVat?: number;
    totalInclVatOriginalCurrency?: number;
    outgoingInvoiceId?: number;
    invoiceReference?: string;
    proformaReference?: string;
    taxPercentage?: number;
    taxSystem?: string;
    invoiceDate?: Date;
    invoiceStatus?: FoundOperationalFileRevenueDTO.InvoiceStatusEnum;
    remarks?: string;
}
export namespace FoundOperationalFileRevenueDTO {
    export type InvoiceStatusEnum = 0 | 1 | 2 | 3;
    export const InvoiceStatusEnum = {
        NUMBER_0: 0 as InvoiceStatusEnum,
        NUMBER_1: 1 as InvoiceStatusEnum,
        NUMBER_2: 2 as InvoiceStatusEnum,
        NUMBER_3: 3 as InvoiceStatusEnum
    };
}