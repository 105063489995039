import { Injectable } from "@angular/core";
import _ from "lodash";

@Injectable({
  providedIn: "root"
})
export class SearchObjectHelper {
  // [0] = propertyName in Title Case, [1] = UI | input
  private specialCases: [string, string][] = [
    ["File number", "Operational File Number"],
    ["Operational File Type", "File type"],
    ["House Bl Nr", "House Bl Number"],
    ["InclVat", "Amount (Incl. VAT)"],
    ["Flight Nr", "Flight Number"],
    ["Airport Bill Nr", "Airport Bill Number"],
    ["Is Budget Closed", "Budget Status"]
  ].map(this.lowerCaseAll);

  private isDbItem: boolean = false;

  constructor() {
    this.searchStringInObject = this.searchStringInObject.bind(this);
  }

  public searchStringInObject(searchTerm: string, item: object): boolean {
    this.isDbItem = !!item["id"];
    const itemToSearch = this.typeCheckItem(item);

    this.makeIdsUnsearchable(itemToSearch);

    let propertiesToBeSearched = this.convertObjectToString(itemToSearch);

    propertiesToBeSearched = this.isDbItem
      ? propertiesToBeSearched.toLocaleLowerCase()
      : this.handleNoDbItem(propertiesToBeSearched);

    return propertiesToBeSearched.includes(searchTerm.toLocaleLowerCase());
  }

  private typeCheckItem(item: object): any {
    return (typeof (item) === "string") ? { value: item } : { ...item };
  }

  private makeIdsUnsearchable(itemToSearch: any) {
    const regexId = new RegExp("\\w*(Id|id)\\b");
    const keysToRemove = Object.keys(itemToSearch).filter(key => regexId.test(key));

    keysToRemove.forEach(key => delete itemToSearch[key]);
  }

  private handleNoDbItem(itemToSearch: any) {
    const propertiesInSentenceCase = this.convertToSentenceCase(itemToSearch).toLocaleLowerCase();
    return this.handleSpecialCases(propertiesInSentenceCase);
  }

  private handleSpecialCases(propertiesToBeSearched: string) {
    for (const kvp of this.specialCases) {
      propertiesToBeSearched = propertiesToBeSearched.replace(kvp[0], kvp[1]);
    }

    return propertiesToBeSearched;
  }

  private lowerCaseAll(cases: [string, string]): [string, string] {
    return [cases[0].toLocaleLowerCase(), cases[1].toLocaleLowerCase()];
  }

  private convertObjectToString(itemToSearch: any) {
    const arr = [];
    this.recurseObject(itemToSearch, arr);
    return arr.join(" ");
  }

  private recurseObject(itemToSearch: any, arr: string[]): void {
    for (const key in itemToSearch) {
      if (Object.prototype.hasOwnProperty.call(itemToSearch, key)) {
        const element = itemToSearch[key];
        this.checkObject(element, arr);
      }
    }
  }

  private checkObject(element: any, arr: string[]) {
    if (element) {
      if (typeof element === "object") {
        this.recurseObject(element, arr);
      } else {
        arr.push("" + element);
      }
    }
  }

  private convertToSentenceCase(itemToSearch: string) {
    return itemToSearch.replace(/([a-z])([A-Z])/g, "$1 $2");
  }
}
