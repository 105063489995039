export const integrations = {
  TITLE: "Integrations",
  TABS: {
    EXACT_ONLINE: "Exact Online",
  },
  EXACT_ONLINE: {
    INTEGRATION_TITLE: "Exact Online integration",
    INTEGRATION_CONNECT_DESCRIPTION: "Connect Fordesk application with your Exact Online account",
    INTEGRATION_CONNECTED_DESCRIPTION: "Fordesk application already connected with your Exact Online account",
    DELETE_INTEGRATION: "Are you sure you want to remove the integration with your Exact Online account?",
    DELETE_INTEGRATION_TITLE: "Removing Exact Online integration",
  },
  CONNECTED_STATE: "Connected",
  USER: "User",
  NO_CONFIGURABLE_INTEGRATIONS: "No configurable integrations requiring your attention",
  OPEN_AUTH_POPUP_ERROR: "Unable to open authentication window. Please allow popups for this site",
}
