export class SuggestionsDialogData {
  public suggestions: Suggestion[]
  public suggestionsListlabel: string;
  public origianlDataLabel: string;
  public originalData: Suggestion;
}

export class Suggestion {
  constructor(label: string, value: number, financialData: FinancialValue[], isExistingEntity: boolean) {
    this.label = label
    this.value = value;
    this.isExistingEntity = isExistingEntity;
    this.financialData = financialData;
  }
  public isExistingEntity: boolean;
  public label: string;
  public value: number;
  public description: string;
  public financialData: FinancialValue[]
}

export class FinancialValue {
  constructor(label: string, value: number, currencyCode: string, emptyValueMessage: string) {
    this.value = value
    this.currencyCode = currencyCode;
    this.label = label;
    this.emptyValueMessage = emptyValueMessage;
  }
  public emptyValueMessage: string
  public label: string;
  public value: number;
  public currencyCode: string;
}
