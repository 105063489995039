import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { replaceEncodedQuotes } from "../../../../../../../core/helpers/filename-helper";
import { notAllowedDocumentNameCharactersRegex } from "../../../../../../../core/helpers/regex.helper";

export class FileRenameData {
  public fileName: string;
  public initialItemName?: string;
}

@Component({
  selector: "file-rename-dialog",
  templateUrl: "./file-rename-dialog.component.html",
  styleUrls: ["./file-rename-dialog.component.scss"]
})
export class FileRenameDialogComponent implements OnInit, AfterViewInit {
  @ViewChild("input", { static: false }) public input: ElementRef;
  public fileExtension: string = "";

  @Input()
  public data: FileRenameData;

  public submitted: boolean = false;
  public detailsForm: UntypedFormGroup;
  private oldFileName: string;
  private initialItemName: string;

  private readonly _maxFileNameLength: number = 228;

  public get maxFileNameLength() {
    return this._maxFileNameLength - (this.fileExtension?.length ?? 0) - 1;
  }

  constructor(private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<FileRenameDialogComponent>) {
  }

  public ngAfterViewInit(): void {
    this.input.nativeElement.select();
  }

  public ngOnInit() {
    this.oldFileName = this.data.fileName.trim();
    this.initialItemName = this.data.initialItemName?.trim();
    this.fileExtension = this.getFileNameExtension();
    this.createForm();
  }

  private createForm() {

    this.detailsForm = this.fb.group({
      fileName: [this.getFileNameName(), [Validators.required, Validators.maxLength(this.maxFileNameLength)]]
    });
  }

  public cancel() {
    this.dialogRef.close();
  }

  private validateFileName(): boolean {
    const fileNameControl = this.detailsForm.controls["fileName"];

    if (fileNameControl.errors) {
      return false;
    }

    const fileName = replaceEncodedQuotes(this.getFullFileName().trim());

    const isValidFolderName = /(^[^\\/?%*:|"<>#\.]+$)$/.test(fileName);

    if (!this.fileExtension) {
      if (!isValidFolderName) {
        fileNameControl.setErrors({ invalidFileName: true });
        return false;
      }
      if (this.oldFileName === fileName) {
        fileNameControl.setErrors({ equalFileNames: true });
        return false;
      }
      if (this.initialItemName === fileName) {
        fileNameControl.setErrors({ equalInitialFileName: true });
        return false;
      }

      fileNameControl.setErrors(null);
      return true;
    }

    let isValidFileName = !notAllowedDocumentNameCharactersRegex.test(fileName);

    if (!isValidFileName) {
      fileNameControl.setErrors({ invalidFileName: true });
    } else if (isValidFileName && this.oldFileName === fileName) {
      isValidFileName = false;
      fileNameControl.setErrors({ equalFileNames: true });
    } else if (this.initialItemName === fileName) {
      fileNameControl.setErrors({ equalInitialFileName: true });
    } else {
      fileNameControl.setErrors(null);
    }

    return isValidFileName;

  }


  public onSubmit() {
    this.submitted = true;

    if (this.validateFileName()) {
      this.dialogRef.close(this.getFullFileName());
    }

  }

  public formatFileName() {
    this.detailsForm.controls["fileName"].setValue(this.detailsForm.controls["fileName"].value.replace(/\s+/g, " "));
    this.validateFileName();
  }

  private getFullFileName() {

    const fileNameControl = this.detailsForm.controls["fileName"];

    return `${fileNameControl.value.trim()}${this.fileExtension ? "." + this.getFileNameExtension() : ""}`;
  }


  private getFileNameExtension(): string {
    if (!this.data.fileName.includes(".")) {
      return null;
    }
    return this.data.fileName.split(".").pop();
  }

  private getFileNameName() {
    return this.data.fileName.replace(/\.[^/.]+$/, "");

  }
}
