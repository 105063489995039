import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { HeaderService } from "../../../core/services/layout/header.service";
import { LayoutRefService } from "../../../core/services/layout/layout-ref.service";

@Component({
	selector: "m-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
	changeDetection: ChangeDetectionStrategy.Default
})
export class HeaderComponent implements OnInit, AfterViewInit {
	@ViewChild("mHeader", { read: ElementRef, static: false }) public mHeader: ElementRef;

	constructor(
		private layoutRefService: LayoutRefService,
		public headerService: HeaderService
	) {
	}

	public ngOnInit(): void {
	}

	public ngAfterViewInit(): void {
		// keep header element in the service
		this.layoutRefService.addElement("header", this.mHeader.nativeElement);
	}
}
