export const of_costs_displayed_columns_locale = {
  activityCodeDescription: "Name",
  partyName: "Party",
  quantity: "Quantity",
  priceExclVat: "Price",
  totalExclVat: "Total with currency",
  totalExclVatOriginalCurrency: "Total",
  isBooked: "Status",
  vatAmount: "Vat",
  totalInclVat: "Total (incl. VAT) with currency",
  totalInclVatOriginalCurrency: "Total (incl. VAT)",
  accountingOrDraftNumber: "Accounting or Draft number",
  invoiceStatus: "Invoice status",
  issueDate: "Issue date",
  taxSystem: "Tax system",
  taxPercentage: "Tax percentage",
  invoiceNumber: "Invoice number"
};
