import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ConfirmBase } from "../abstractions/confirm-base.component";
import { ConfirmDialogTextValueData } from "../../../core/models/dialogs/confirm-dialog-data";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ConfirmTextDialogResultData } from "../../../core/models/dialogs/confirm-with-text-dialog-result-data";
import { UploadDocumentsDialog } from "../../../core/models/dialogs/upload-documents-dialog";
import { takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "upload-documents-dialog-component",
  templateUrl: "./upload-documents-dialog-component.html",
  styleUrls: ["./upload-documents-dialog-component.scss"]
})
export class UploadDocumensDialogComponent implements OnInit {

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<UploadDocumensDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UploadDocumentsDialog) {
  }

  public filesToUpload: File[] = [];
  @ViewChild("uploader", { static: false }) private uploaderInput: ElementRef;

  public ngOnInit(): void {

  }

  public close() {
    this.dialogRef.close();
  }

  public uploadFiles(): void {
    if (this.filesToUpload.length > 0) {
      this.dialogRef.close(this.filesToUpload);
    }
  }

  public addFileToUploader(event) {
    if (event && event[0]) {
      this.filesToUpload = [];

      const files = event as any[];
      if (files.length > 1 && !this.data.uploadMultiple) {
        this.toastr.error(this.translate.instant("MODALS.ONLY_ONE_FILE_CAN_BE_SELECTED"));
        return
      }
      for (let i = 0; i < files.length; i++) {
        if (!(files[i].type === "application/pdf" || files[i].type === "image/png" || files[i].type === "image/jpg" || files[i].type === "image/jpeg" || files[i].type === "image/tiff")) {
          this.toastr.error(this.translate.instant("MODALS.INCORRECT_FILE_TYPE"));
          return;
        }

        this.filesToUpload.push(files[i]);
      }
    }
  }

  public deleteAttachment(fileName) {
    this.filesToUpload = this.filesToUpload.filter(x => x.name != fileName)
    this.uploaderInput.nativeElement.value = null;
  }
}
