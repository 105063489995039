import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { Router, NavigationStart } from "@angular/router";
import { MenuConfig } from "../../config/menu";
import { RoleService } from "../auth/roles/role.service";


@Injectable({ providedIn: "root" })
export class MenuConfigService {
  public configModel: MenuConfig;
  public onMenuUpdated$: BehaviorSubject<MenuConfig>;
  public menuHasChanged: any = false;
  private menuConfigLoaded$: Subject<void> = new Subject<void>();

  constructor(private router: Router,
    public roleService: RoleService) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(() => {
        if (this.menuHasChanged) {
          this.resetModel();
        }
      });

    this.configModel = new MenuConfig(roleService, this.menuConfigLoaded$);
    this.onMenuUpdated$ = new BehaviorSubject(this.configModel);
    this.menuConfigLoaded$.asObservable().subscribe(() => this.setModel(this.configModel));
  }

  public setModel(menuModel: MenuConfig) {
    this.configModel = Object.assign(this.configModel, menuModel);
    this.onMenuUpdated$.next(this.configModel);
    this.menuHasChanged = true;
  }

  public resetModel() {
    this.configModel = new MenuConfig(this.roleService, this.menuConfigLoaded$);
    this.onMenuUpdated$.next(this.configModel);
    this.menuHasChanged = false;
  }
}
