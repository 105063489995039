export const quote_revenues_displayed_columns_locale = {
  name: "Name",
  quantity: "Quantity",
  priceExclVat: "Price",
  totalExclVat: "Total excl. VAT",
  vatAmount: "Vat",
  totalInclVat: "Total incl. VAT",
  totalInclVatExchangeRateApplied: "Total",
  partyDescription: "Party"
};
