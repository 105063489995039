import { Component, Inject, OnInit } from "@angular/core";
import { ConfirmBase } from "../abstractions/confirm-base.component";
import { NamedLookup } from "../../../core/models/masterdata/lookup";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ConfirmDialog } from "../confirm/confirm.component";
import { ConfirmVGMDialogData, ConfirmVGMDialogDataResultData } from "../../../core/models/dialogs/confirm-dialog-data";
import { LoadingOverlayService } from "../../../core/services/loading-overlay/loading-overlay.service";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { CustomValidators } from "ngx-custom-validators";
import { DateInputsHelperService } from "../../../core/helpers/date-inputs-helper.service";
import { atLeastOneFieldProvided } from "../../../core/validators/at-least-one-field-required.validator";
import { OperationalFileCompactDetailsDTO } from "../../../core/services/swagger-gen/fordesk";

@Component({
  selector: "vgm-confirm",
  templateUrl: "./vgm-confirm.component.html",
  styleUrls: ["./vgm-confirm.component.scss"]
})
export class VgmConfirmComponent extends ConfirmBase implements OnInit {
  public submitted: boolean = false;
  public intraStateMessages: NamedLookup[];
  public vmgWeighingMethods: NamedLookup[];
  public billOfLadingNumbers: string[];
  public bookingReferences: string[];
  public confirmForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmVGMDialogData,
    public overlay: LoadingOverlayService,
    private fb: UntypedFormBuilder,
    private dateInputHelper: DateInputsHelperService
  ) {
    super(dialogRef, data);
  }

  public ngOnInit(): void {
    this.createForm();
    this.overlay.startLoading();

    const loadOperations = [this.data.intraStateMessages$, this.data.operationalFileCompact$];
    if (this.data.vmgWeighingMethods$) {
      loadOperations.push(this.data.vmgWeighingMethods$);
    }

    forkJoin(loadOperations)
      .pipe(
        map(ops => {
          this.intraStateMessages = ops[0] as NamedLookup[];
          this.billOfLadingNumbers = (ops[1] as OperationalFileCompactDetailsDTO).billOfLadingNumbers;
          this.bookingReferences = (ops[1] as OperationalFileCompactDetailsDTO).bookingReferences;
          this.vmgWeighingMethods = ops[2] as NamedLookup[];

          if (this.vmgWeighingMethods) {
            this.confirmForm.addControl("vmgWeighingMethodOption", this.fb.control(null, [Validators.required]));
          }
        }),
        map(() => {
          this.confirmForm.patchValue({
            inttraStateMessageOption: this.intraStateMessages[0],
            vmgWeighingMethodOption: this.vmgWeighingMethods ? this.vmgWeighingMethods[0] : null
          });
        }),
        map(() => this.overlay.stopLoading())
      )
      .subscribe();
  }

  public confirm() {
    this.submitted = true;
    if (!this.confirmForm.valid) {
      return;
    }

    const dialogResult: ConfirmVGMDialogDataResultData = {
      isConfirmed: true,
      inttraStateMessageId: this.confirmForm.get("inttraStateMessageOption").value.id,
      authorizedContactPerson: this.confirmForm.get("authorizedContactPerson").value,
      billOfLadingNumber: this.confirmForm.controls.billOfLadingNumber.value,
      bookingReference: this.confirmForm.controls.bookingReference.value,
      phoneNumber: ""// this.confirmForm.get("phoneNumber").value,
    };

    if (this.vmgWeighingMethods) {
      dialogResult.vmgWeighingMethodId = this.confirmForm.get("vmgWeighingMethodOption").value.id;
    }

    const submissionDate = this.confirmForm.get("submissionDate").value;
    dialogResult.submissionDate = this.dateInputHelper.fromNgbDateToDate(submissionDate);

    this.dialogRef.close(dialogResult);
  }

  private createForm(): void {
    const now = new Date();
    const today = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };

    this.confirmForm = this.fb.group({
      inttraStateMessageOption: [null, [Validators.required]],
      submissionDate: [today, [CustomValidators.date]],
      authorizedContactPerson: [null, [Validators.required]],
      billOfLadingNumber: [],
      bookingReference: []
      // phoneNumber: []
    },
    {
      validator: (fg: UntypedFormGroup) => atLeastOneFieldProvided(fg, ["billOfLadingNumber", "bookingReference"])
    });
  }

  public authorizedContactPersonEntered(currentValue: string): void {
    if (!this.confirmForm) {
      return;
    }

    this.confirmForm.patchValue({
      authorizedContactPerson: currentValue ? currentValue.toUpperCase() : currentValue
    });
  }
}
