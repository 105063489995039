/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OcrSettingDTO { 
    id?: number;
    usageCategory: OcrSettingDTO.UsageCategoryEnum;
    key: string;
    type: OcrSettingDTO.TypeEnum;
    value: string;
    isBlocked?: boolean;
}
export namespace OcrSettingDTO {
    export type UsageCategoryEnum = 0 | 1;
    export const UsageCategoryEnum = {
        NUMBER_0: 0 as UsageCategoryEnum,
        NUMBER_1: 1 as UsageCategoryEnum
    };
    export type TypeEnum = 0 | 1;
    export const TypeEnum = {
        NUMBER_0: 0 as TypeEnum,
        NUMBER_1: 1 as TypeEnum
    };
}