import { environment } from "./../../../environments/environment";
import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";

@Injectable({ providedIn: "root" })
export class AuthGuardService implements CanActivate {
  constructor(public auth: OAuthService, public router: Router) { }

  private debug: boolean;

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.hasValidAccessToken()) {
      if (!environment.production && this.debug) {
        console.log("access token:");
        console.log(this.auth.getAccessToken());
        console.log("id token:");
        console.log(this.auth.getIdToken());
        console.log("issuer:");
        console.log(this.auth.issuer);
        console.log("auth header:");
        console.log(this.auth.authorizationHeader);
        console.log("auth obj:");
        console.log(this.auth);
      }

      return true;
    }

    console.log("invalid access token detected - redirecting to login");
    return false;
  }
}