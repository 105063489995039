import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";
import { AddressDetailsDTO, AddressesService } from "../services/swagger-gen/fordesk";

@Injectable({ providedIn: "root" })
export class AddressLookupHelper {
  private maxLength: number = 3950;

  constructor() { }

  public getAddressesLookup(service: AddressesService, input: Subject<string>, onStart: Function, onComplete: Function): Observable<AddressDetailsDTO[]> {
    return input.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string) => {
        let addresses: Observable<AddressDetailsDTO[]>;
        if (term && term.length > 2 && term.length < this.maxLength) {
          onStart();
          addresses = service.getAddresses(term)
            .pipe(
              map((res) => {
                return res && res.results ? res.results : [];
              }),
              catchError(() => of([])), // empty list on error
              tap(() => onComplete?.())
            );
        } else {
          onComplete?.();
          addresses = of([]);
        }
        return addresses;
      })
    );
  }
}
