import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { FieldType } from "@ngx-formly/core";
import { filter, map, pairwise, takeUntil } from "rxjs/operators";
import { FormlyHelper } from "../../../helpers/formly-helper";
import { ActualMinMaxDateChangedService } from "../../../services/operational/operational-file/actual-time-min-max-date-changed.servise";
import { Subject } from "rxjs";

@Component({
  selector: "formly-actual-time-of-arrival",
  templateUrl: "./formly-actual-time-of-arrival.component.html",
  styleUrls: ["./formly-actual-time-of-arrival.component.scss"]
})
export class FormlyActualTimeOfArrivalComponent extends FieldType implements OnInit, OnDestroy {
  public submitted: boolean;
  constructor(public formlyHelper: FormlyHelper, private actualMinMaxDateChangedService: ActualMinMaxDateChangedService) {
    super();
  }

  private unsubscribe: Subject<void> = new Subject();
  public ngOnDestroy() {
    if (!this.unsubscribe) {
      return;
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public minDate: NgbDateStruct = { year: 1970, day: 1, month: 1 };

  public ngOnInit() {
    this.formlyHelper.getGeneralFormSubmission$()
      .subscribe(res => this.submitted = true);

    this.actualMinMaxDateChangedService.isMinDateChanged().subscribe((data: NgbDateStruct) => {
      this.minDate = data;
    });

    if (this.formControl.value) {
      const maxDate = new Date(this.formControl.value);
      const ngbDateStruct = { day: maxDate.getDate(), month: maxDate.getMonth() + 1, year: maxDate.getFullYear() };
      this.actualMinMaxDateChangedService.getNextMaxDate(ngbDateStruct);
    }

    this.formControl.valueChanges.pipe(
      pairwise(),
      filter(([prev, curr]) => prev !== curr),
      map(([prev, curr]) => curr), takeUntil(this.unsubscribe))
      .subscribe(value => {
        if (!value) {
          this.actualMinMaxDateChangedService.getNextMaxDate(null)
          return;
        }
        const maxDate = new Date(value);
        const ngbDateStruct = { day: maxDate.getDate(), month: maxDate.getMonth() + 1, year: maxDate.getFullYear() };
        this.actualMinMaxDateChangedService.getNextMaxDate(ngbDateStruct);
      });
  }

  public getMinDate() {
    return this.minDate;
  }

}
