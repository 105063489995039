/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DateRange } from './dateRange';

export interface OperationalFileOverviewSearchCondition { 
    pageSize?: number;
    page?: number;
    readonly listSortDirection?: OperationalFileOverviewSearchCondition.ListSortDirectionEnum;
    sortDirection?: string;
    sortProperty?: string;
    fileNumbers?: Array<string>;
    invoiceCustomers?: Array<string>;
    invoiceCustomerNumbers?: Array<string>;
    customers?: Array<string>;
    customerNumbers?: Array<string>;
    portsOfLoading?: Array<string>;
    portsOfDischarge?: Array<string>;
    incoterms?: Array<string>;
    operationalFileTypes?: Array<OperationalFileOverviewSearchCondition.OperationalFileTypesEnum>;
    shipmentTypes?: Array<OperationalFileOverviewSearchCondition.ShipmentTypesEnum>;
    transportTypes?: Array<OperationalFileOverviewSearchCondition.TransportTypesEnum>;
    statuses?: Array<OperationalFileOverviewSearchCondition.StatusesEnum>;
    operationalFileDates?: Array<DateRange>;
}
export namespace OperationalFileOverviewSearchCondition {
    export type ListSortDirectionEnum = 0 | 1;
    export const ListSortDirectionEnum = {
        NUMBER_0: 0 as ListSortDirectionEnum,
        NUMBER_1: 1 as ListSortDirectionEnum
    };
    export type OperationalFileTypesEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6';
    export const OperationalFileTypesEnum = {
        _0: '0' as OperationalFileTypesEnum,
        _1: '1' as OperationalFileTypesEnum,
        _2: '2' as OperationalFileTypesEnum,
        _3: '3' as OperationalFileTypesEnum,
        _4: '4' as OperationalFileTypesEnum,
        _5: '5' as OperationalFileTypesEnum,
        _6: '6' as OperationalFileTypesEnum
    };
    export type ShipmentTypesEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6';
    export const ShipmentTypesEnum = {
        _0: '0' as ShipmentTypesEnum,
        _1: '1' as ShipmentTypesEnum,
        _2: '2' as ShipmentTypesEnum,
        _3: '3' as ShipmentTypesEnum,
        _4: '4' as ShipmentTypesEnum,
        _5: '5' as ShipmentTypesEnum,
        _6: '6' as ShipmentTypesEnum
    };
    export type TransportTypesEnum = '0' | '1' | '2' | '3';
    export const TransportTypesEnum = {
        _0: '0' as TransportTypesEnum,
        _1: '1' as TransportTypesEnum,
        _2: '2' as TransportTypesEnum,
        _3: '3' as TransportTypesEnum
    };
    export type StatusesEnum = '0' | '1' | '2';
    export const StatusesEnum = {
        _0: '0' as StatusesEnum,
        _1: '1' as StatusesEnum,
        _2: '2' as StatusesEnum
    };
}