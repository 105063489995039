import { LayoutModule } from "../layout/layout.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PagesRoutingModule } from "./pages-routing.module";
import { PagesComponent } from "./pages.component";
import { PartialsModule } from "../partials/partials.module";
import { ActionComponent } from "./header/action/action.component";
import { ProfileComponent } from "./header/profile/profile.component";
import { CoreModule } from "../../core/core.module";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { NotfoundComponent } from "./error/notfound/notfound.component";
import { MaterialModule } from "../../material/material.module";
import { DialogModule } from "../dialogs/dialog.module";
import { ExactOnlineIntegrationAuthCallbackComponent } from "./auth-callbacks/exact-online-integration-auth-callback/exact-online-integration-auth-callback.component";

@NgModule({
  declarations: [
    PagesComponent,
    ActionComponent,
    ProfileComponent,
    NotfoundComponent,
    ExactOnlineIntegrationAuthCallbackComponent,
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    CoreModule,
    LayoutModule,
    PartialsModule,
    AngularEditorModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MaterialModule,
    DialogModule
  ],
  providers: []
})

export class PagesModule { }
