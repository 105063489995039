/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CompanyAddressDTO } from '../model/companyAddressDTO';
import { CompanyAddressDetailsDTO } from '../model/companyAddressDetailsDTO';
import { CompanyRelatedEmailDTO } from '../model/companyRelatedEmailDTO';
import { NewCompanyAddress } from '../model/newCompanyAddress';
import { PagedResponseFoundCompanyAddressDTO } from '../model/pagedResponseFoundCompanyAddressDTO';
import { PartyAddressDTO } from '../model/partyAddressDTO';
import { Response } from '../model/response';
import { UpdatedCompanyAddress } from '../model/updatedCompanyAddress';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CompanyAddressesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add a company address
     * 
     * @param companyId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCompanyAddress(companyId: number, body?: NewCompanyAddress, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public addCompanyAddress(companyId: number, body?: NewCompanyAddress, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public addCompanyAddress(companyId: number, body?: NewCompanyAddress, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public addCompanyAddress(companyId: number, body?: NewCompanyAddress, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling addCompanyAddress.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Response>(`${this.basePath}/api/masterdata/company-addresses/${encodeURIComponent(String(companyId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Delete addresses
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAddresses(id: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public deleteAddresses(id: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public deleteAddresses(id: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public deleteAddresses(id: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteAddresses.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.delete<Response>(`${this.basePath}/api/masterdata/company-addresses/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get all company addresses by company id
     * 
     * @param companyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllCompanyAddressesByCompanyId(companyId?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<CompanyAddressDTO>>;
    public getAllCompanyAddressesByCompanyId(companyId?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<CompanyAddressDTO>>>;
    public getAllCompanyAddressesByCompanyId(companyId?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<CompanyAddressDTO>>>;
    public getAllCompanyAddressesByCompanyId(companyId?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (companyId !== undefined && companyId !== null) {
            queryParameters = queryParameters.set('companyId', <any>companyId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<CompanyAddressDTO>>(`${this.basePath}/api/masterdata/company-addresses/search-in-company-by-company-id`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get all company addresses by party type
     * 
     * @param partyType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllCompanyAddressesByPartyType(partyType?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<CompanyAddressDTO>>;
    public getAllCompanyAddressesByPartyType(partyType?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<CompanyAddressDTO>>>;
    public getAllCompanyAddressesByPartyType(partyType?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<CompanyAddressDTO>>>;
    public getAllCompanyAddressesByPartyType(partyType?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (partyType !== undefined && partyType !== null) {
            queryParameters = queryParameters.set('partyType', <any>partyType);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<CompanyAddressDTO>>(`${this.basePath}/api/masterdata/company-addresses/search-in-company-by-party-type`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get all company addresses by party type id
     * 
     * @param partyTypeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllCompanyAddressesByPartyTypeId(partyTypeId: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<CompanyAddressDTO>>;
    public getAllCompanyAddressesByPartyTypeId(partyTypeId: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<CompanyAddressDTO>>>;
    public getAllCompanyAddressesByPartyTypeId(partyTypeId: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<CompanyAddressDTO>>>;
    public getAllCompanyAddressesByPartyTypeId(partyTypeId: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (partyTypeId === null || partyTypeId === undefined) {
            throw new Error('Required parameter partyTypeId was null or undefined when calling getAllCompanyAddressesByPartyTypeId.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (partyTypeId !== undefined && partyTypeId !== null) {
            queryParameters = queryParameters.set('partyTypeId', <any>partyTypeId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<CompanyAddressDTO>>(`${this.basePath}/api/masterdata/company-addresses/search-in-company-by-party-type-id`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get applicable company addresses
     * 
     * @param partyTypeId 
     * @param partyTypeValue 
     * @param applicableCompanyTypes 
     * @param excludeOtherPartyLocations 
     * @param invoiceable 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getApplicablePartyAddresses(partyTypeId?: number, partyTypeValue?: number, applicableCompanyTypes?: Array<number>, excludeOtherPartyLocations?: Array<number>, invoiceable?: boolean, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<PartyAddressDTO>>;
    public getApplicablePartyAddresses(partyTypeId?: number, partyTypeValue?: number, applicableCompanyTypes?: Array<number>, excludeOtherPartyLocations?: Array<number>, invoiceable?: boolean, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<PartyAddressDTO>>>;
    public getApplicablePartyAddresses(partyTypeId?: number, partyTypeValue?: number, applicableCompanyTypes?: Array<number>, excludeOtherPartyLocations?: Array<number>, invoiceable?: boolean, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<PartyAddressDTO>>>;
    public getApplicablePartyAddresses(partyTypeId?: number, partyTypeValue?: number, applicableCompanyTypes?: Array<number>, excludeOtherPartyLocations?: Array<number>, invoiceable?: boolean, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (partyTypeId !== undefined && partyTypeId !== null) {
            queryParameters = queryParameters.set('partyTypeId', <any>partyTypeId);
        }
        if (partyTypeValue !== undefined && partyTypeValue !== null) {
            queryParameters = queryParameters.set('partyTypeValue', <any>partyTypeValue);
        }
        if (applicableCompanyTypes) {
            applicableCompanyTypes.forEach((element) => {
                queryParameters = queryParameters.append('applicableCompanyTypes', <any>element);
            })
        }
        if (excludeOtherPartyLocations) {
            excludeOtherPartyLocations.forEach((element) => {
                queryParameters = queryParameters.append('excludeOtherPartyLocations', <any>element);
            })
        }
        if (invoiceable !== undefined && invoiceable !== null) {
            queryParameters = queryParameters.set('invoiceable', <any>invoiceable);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<PartyAddressDTO>>(`${this.basePath}/api/masterdata/company-addresses/party-addresses`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get a company address
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyAddress(id: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<CompanyAddressDTO>;
    public getCompanyAddress(id: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<CompanyAddressDTO>>;
    public getCompanyAddress(id: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<CompanyAddressDTO>>;
    public getCompanyAddress(id: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getCompanyAddress.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<CompanyAddressDTO>(`${this.basePath}/api/masterdata/company-addresses/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get a company address details
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyAddressDetails(id: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<CompanyAddressDetailsDTO>;
    public getCompanyAddressDetails(id: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<CompanyAddressDetailsDTO>>;
    public getCompanyAddressDetails(id: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<CompanyAddressDetailsDTO>>;
    public getCompanyAddressDetails(id: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getCompanyAddressDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<CompanyAddressDetailsDTO>(`${this.basePath}/api/masterdata/company-addresses/${encodeURIComponent(String(id))}/compact`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get a list of email addresses for mailing autocomplete for a company.
     * 
     * @param companyAddressId 
     * @param searchTerm 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEmailSuggestions(companyAddressId: number, searchTerm?: string, pageSize?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<CompanyRelatedEmailDTO>>;
    public getEmailSuggestions(companyAddressId: number, searchTerm?: string, pageSize?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<CompanyRelatedEmailDTO>>>;
    public getEmailSuggestions(companyAddressId: number, searchTerm?: string, pageSize?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<CompanyRelatedEmailDTO>>>;
    public getEmailSuggestions(companyAddressId: number, searchTerm?: string, pageSize?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (companyAddressId === null || companyAddressId === undefined) {
            throw new Error('Required parameter companyAddressId was null or undefined when calling getEmailSuggestions.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchTerm !== undefined && searchTerm !== null) {
            queryParameters = queryParameters.set('searchTerm', <any>searchTerm);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<CompanyRelatedEmailDTO>>(`${this.basePath}/api/masterdata/company-addresses/${encodeURIComponent(String(companyAddressId))}/mails/autocomplete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get filtered company addresses
     * 
     * @param partyTypes 
     * @param companyTypes 
     * @param operationalFileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFiltered(partyTypes?: Array<number>, companyTypes?: Array<number>, operationalFileId?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<CompanyAddressDTO>>;
    public getFiltered(partyTypes?: Array<number>, companyTypes?: Array<number>, operationalFileId?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<CompanyAddressDTO>>>;
    public getFiltered(partyTypes?: Array<number>, companyTypes?: Array<number>, operationalFileId?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<CompanyAddressDTO>>>;
    public getFiltered(partyTypes?: Array<number>, companyTypes?: Array<number>, operationalFileId?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (partyTypes) {
            partyTypes.forEach((element) => {
                queryParameters = queryParameters.append('partyTypes', <any>element);
            })
        }
        if (companyTypes) {
            companyTypes.forEach((element) => {
                queryParameters = queryParameters.append('companyTypes', <any>element);
            })
        }
        if (operationalFileId !== undefined && operationalFileId !== null) {
            queryParameters = queryParameters.set('operationalFileId', <any>operationalFileId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<CompanyAddressDTO>>(`${this.basePath}/api/masterdata/company-addresses`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get filtered company addresses respecting blocking status
     * 
     * @param partyTypes 
     * @param companyTypes 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFilteredRespectingBlockingStatus(partyTypes?: Array<number>, companyTypes?: Array<number>, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<CompanyAddressDTO>>;
    public getFilteredRespectingBlockingStatus(partyTypes?: Array<number>, companyTypes?: Array<number>, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<CompanyAddressDTO>>>;
    public getFilteredRespectingBlockingStatus(partyTypes?: Array<number>, companyTypes?: Array<number>, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<CompanyAddressDTO>>>;
    public getFilteredRespectingBlockingStatus(partyTypes?: Array<number>, companyTypes?: Array<number>, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (partyTypes) {
            partyTypes.forEach((element) => {
                queryParameters = queryParameters.append('partyTypes', <any>element);
            })
        }
        if (companyTypes) {
            companyTypes.forEach((element) => {
                queryParameters = queryParameters.append('companyTypes', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<CompanyAddressDTO>>(`${this.basePath}/api/masterdata/company-addresses/filtered-blocking-status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Search company addresses
     * 
     * @param companyId 
     * @param searchTerm 
     * @param sortDirection 
     * @param sortProperty 
     * @param pageSize 
     * @param page 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchCompanyAddressesPaged(companyId: number, searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<PagedResponseFoundCompanyAddressDTO>;
    public searchCompanyAddressesPaged(companyId: number, searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<PagedResponseFoundCompanyAddressDTO>>;
    public searchCompanyAddressesPaged(companyId: number, searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<PagedResponseFoundCompanyAddressDTO>>;
    public searchCompanyAddressesPaged(companyId: number, searchTerm?: string, sortDirection?: string, sortProperty?: string, pageSize?: number, page?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling searchCompanyAddressesPaged.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchTerm !== undefined && searchTerm !== null) {
            queryParameters = queryParameters.set('SearchTerm', <any>searchTerm);
        }
        if (sortDirection !== undefined && sortDirection !== null) {
            queryParameters = queryParameters.set('SortDirection', <any>sortDirection);
        }
        if (sortProperty !== undefined && sortProperty !== null) {
            queryParameters = queryParameters.set('SortProperty', <any>sortProperty);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (companyId !== undefined && companyId !== null) {
            queryParameters = queryParameters.set('companyId', <any>companyId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<PagedResponseFoundCompanyAddressDTO>(`${this.basePath}/api/masterdata/company-addresses/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Update an address
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAddress(id: number, body?: UpdatedCompanyAddress, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public updateAddress(id: number, body?: UpdatedCompanyAddress, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public updateAddress(id: number, body?: UpdatedCompanyAddress, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public updateAddress(id: number, body?: UpdatedCompanyAddress, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateAddress.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.put<Response>(`${this.basePath}/api/masterdata/company-addresses/${encodeURIComponent(String(id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

}
